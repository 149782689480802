import { ProfileAvatar } from '@/components/ProfileAvatar';
import { Caption, H5, H6, Small } from '@/components/Typography';
import { FollowUpStatusTypes } from '@/graphql/account';
import { color } from '@/styles/assets/colors';
import type { FollowUpRemindersTypes } from '@/types/accountProfile';
import { getAccountUserFullName } from '@/util/account';
import { followupTypeHandler } from '@/util/followUp';
import { formatDateAndTime } from '@/util/format';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import { Grid, Paper, Stack } from '@mui/material';
import parse from 'html-react-parser';
import type { FC, ReactNode } from 'react';

type Props = {
  followUp: FollowUpRemindersTypes;
};

type FollowTskSectionType = {
  [key: string]: {
    screenSize: number;
    content: ReactNode | string;
  };
};

const FollowUpTaskSection = ({ title, value }: { title: string; value: { screenSize: number; content: ReactNode | string } }) => {
  return (
    <Grid xs={value.screenSize}>
      <Stack direction="row" alignItems="center" gap={0.5} mb={0.5}>
        <DataUsageIcon sx={{ fontSize: '.75rem' }} />
        <H6>{title}</H6>
      </Stack>
      {typeof value.content === 'string' ? <Small sx={{ color: color.black100, ml: 2 }}>{parse(value.content)}</Small> : value.content}
    </Grid>
  );
};

const RpmPatientReviewFollowUpCard: FC<Props> = ({ followUp }) => {
  const followUpInfo: FollowTskSectionType = {
    'Follow-up Task Due at': {
      screenSize: 6,
      content: formatDateAndTime(followUp.followUpOn)
    },
    'Follow-up Task Status': {
      screenSize: 6,
      content: followUp.status
    },
    'Follow-up Task Type': {
      screenSize: 12,
      content: followupTypeHandler(followUp)
    },
    'Follow-up Task Note': {
      screenSize: 12,
      content: followUp.notes
    }
  };

  return (
    <Stack
      component={Paper}
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={2}
      marginBottom={1.5}
      marginLeft={1}
      padding={2}
      elevation={0}
      sx={{ backgroundColor: followUp.status === FollowUpStatusTypes.COMPLETED ? color.green50 : color.indigo50 }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" gap={0.5}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} maxWidth="14.375rem">
          <ProfileAvatar
            avatarUrl={followUp?.relyingPartyAdmin?.presignedAvatarUrl}
            name={getAccountUserFullName(followUp.relyingPartyAdmin.user)}
            sx={{ bgcolor: color.primaryMain, width: 30, height: 30, fontSize: 'small' }}
          />
          <H5 sx={{ color: color.black100, width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {getAccountUserFullName(followUp.relyingPartyAdmin.user)}
          </H5>
        </Stack>
        <Caption sx={{ color: color.grey600 }}>{formatDateAndTime(followUp.createdAt)}</Caption>
      </Stack>
      <Grid container rowGap={1}>
        {Object.entries(followUpInfo).map(([key, value]) => (
          <FollowUpTaskSection title={key} value={value} />
        ))}
      </Grid>
    </Stack>
  );
};

export default RpmPatientReviewFollowUpCard;
