import ActivityDrawer from '@/components/ActivityDrawer/ActivityDrawer';
import Button from '@/components/Button';
import ChipColumn from '@/components/ChipLabel/ChipColumn';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import EditCoverLetterDialog from '@/components/Dialogs/EditCoverLetterDialog';
import type { SplitActionButton } from '@/components/MuiMenu';
import MuiMenu from '@/components/MuiMenu';
import MuiTabs from '@/components/MuiTabs';
import PDFViewer from '@/components/PDFViewer';
import { Body1, Caption, H1, H4, Label } from '@/components/Typography';
import PatientDetailContext from '@/context/patientDetailContext';
import type {
  CreatePdfRpmChartReviewReportResponse,
  CreatePdfRpmReportResponse,
  CreatePdfRpmSummaryReport,
  CreateRpmCoverLetterResponse,
  GetReportAvailabilitySenderMethodAvailability,
  GetRpmReportsMonthlyResponse,
  RegenerateRpmReportResponse,
  ReviewRpmReportResponse
} from '@/graphql/rpmReports';
import {
  CREATE_PDF_RPM_CHART_REVIEW_REPORT,
  CREATE_PDF_RPM_COVER_LETTER,
  CREATE_PDF_RPM_REPORT,
  CREATE_PDF_RPM_SUMMARY_REPORT,
  GET_RPM_REPORTS_MONTHLY,
  GET_RPM_REPORT_SENDER_METHOD_AVAILABILITY,
  REGENERATE_RPM_REPORT,
  REVIEW_RPM_REPORT
} from '@/graphql/rpmReports';
import BaseRpmWorkflowTabContent from '@/routes/PatientDetails/RemotePatientMonitoring/BaseRpmWorkflowTabContent';
import { color } from '@/styles/assets/colors';
import { theme } from '@/styles/mui-theme';
import type { ReportMonth } from '@/types/remotePatientMonitoring';
import {
  ActivityLogType,
  ReportActivityLogTypes,
  ReportCommunicationActivityLogTypes,
  ReviewSubmitTypeEnum
} from '@/types/remotePatientMonitoring';
import { getAccountUserFullName } from '@/util/account';
import { formatDate } from '@/util/format';
import { fetchDocumentUrl, generateDynamicContent } from '@/util/rpm';
import { useAccountStore } from '@/zustand/AccountStore';
import { useRpmWorkflowStore } from '@/zustand/SessionTimers';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Box, LinearProgress, Paper, Stack } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

export enum RpmWorkflowReportType {
  RPM_REPORT,
  COVER_LETTER,
  CHART_REVIEW,
  SUMMARY_REPORT
}

export type ProviderContactType = {
  email: string | null;
  phone: string | null;
  fax: string | null;
};

export type MessageAvailabilityType = {
  isConfigAvailability: boolean;
  message: {
    providers: {
      providerName: string;
      providerMethodAvailability: {
        isRpmReportProviderByEmail: boolean;
        isRpmReportProviderByText: boolean;
        isRpmReportProviderByFax: boolean;
      };
      providerContacts: ProviderContactType;
    }[];
    patients: {
      patientName: string;
      patientMethodAvailability: {
        isRpmReportPatientByEmail: boolean;
        isRpmReportPatientByText: boolean;
      };
      patientContacts: Partial<ProviderContactType>;
    };
    orgProviders: {
      providerName: string;
      providerMethodAvailability: {
        isRpmReportProviderByEmail: boolean;
        isRpmReportProviderByText: boolean;
        isRpmReportProviderByFax: boolean;
      };
      providerContacts: ProviderContactType;
    }[];
  };
};

const Reports = () => {
  const { isReadOnly } = useRpmWorkflowStore();
  const { id: truentityId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { showModal } = useModal();
  const { patientInfo } = useContext(PatientDetailContext);
  const {
    patientReviewWorkflowMonthYear,
    setPatientReviewWorkflowMonthYear,
    setShowPatientReviewWindow,
    isReviewWorkflowChanged,
    setIsReviewWorkflowChanged
  } = useAccountStore();

  const [selectedReportType, setSelectedReportType] = useState<RpmWorkflowReportType>(RpmWorkflowReportType.RPM_REPORT);
  const [reportMonth, setReportMonth] = useState<ReportMonth>({} as ReportMonth);
  const [rpmMonthlyReportUrl, setRpmMonthlyReportUrl] = useState<string>('');
  const [chartReviewReportUrl, setChartReviewReportUrl] = useState<string>('');
  const [summaryReportUrl, setSummaryReportUrl] = useState<string>('');
  const [rpmCoverLetterUrl, setRpmCoverLetterUrl] = useState<string>('');
  const [isActivityDrawerOpen, setIsActivityDrawerOpen] = useState(false);
  const [reportMenuOptionsDict, setReportMenuOptionsDict] = useState<{ [key: string]: ReportMonth & SplitActionButton }>({});
  const [dynamicConfirmationMessage, setDynamicConfirmationMessage] = useState<string>('');
  const [isPatientProvidersAvailable, setIsPatientProvidersAvailable] = useState<boolean>(false);
  const [isCompanyProvidersAvailable, setIsCompanyProvidersAvailable] = useState<boolean>(false);

  const isCurrentMonthReport = reportMonth?.label === 'Current';

  const resetReportUrls = () => {
    setRpmMonthlyReportUrl('');
    setChartReviewReportUrl('');
    setSummaryReportUrl('');
  };

  const [fetchMonthlyRpmReports, { refetch: refetchRpmMonthlyReports }] = useLazyQuery<GetRpmReportsMonthlyResponse>(
    GET_RPM_REPORTS_MONTHLY,
    {
      variables: {
        truentityId
      },
      onCompleted: rpmMonthlyReportData => {
        if (rpmMonthlyReportData?.getRpmReportsMonthly) {
          const refactoredMonths: ReportMonth[] = rpmMonthlyReportData.getRpmReportsMonthly.map(item => {
            return {
              id: item.report.id,
              docS3Key: item.report.reportedDocS3Key,
              rpmAccountReviewWorkflow: item.report?.rpmAccountReviewWorkflow,
              summaryReportS3Key: item.report.summaryReportS3Key,
              monthYear: item.report.monthYear,
              label: item.monthYearName,
              disable: false,
              onAction: () => {
                if (item.monthYearName !== patientReviewWorkflowMonthYear) {
                  resetReportUrls();
                }
                setPatientReviewWorkflowMonthYear(item.monthYearName);
              }
            };
          });

          const currentElement = {
            id: '',
            label: 'Current',
            disable: false,
            onAction: () => {
              if (patientReviewWorkflowMonthYear !== null) {
                resetReportUrls();
              }
              setPatientReviewWorkflowMonthYear(null);
            }
          };
          refactoredMonths.unshift(currentElement);
          setReportMenuOptionsDict(
            refactoredMonths.reduce((acc, option) => {
              acc[option.label] = option;
              return acc;
            }, {})
          );
        }
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  const { data: reportSenderMethodAvailability } = useQuery<GetReportAvailabilitySenderMethodAvailability>(
    GET_RPM_REPORT_SENDER_METHOD_AVAILABILITY,
    { variables: { truentityId }, fetchPolicy: 'cache-and-network' }
  );

  const [createRpmReport, { error: createRpmReportError, loading: rpmReportLoading }] = useMutation<CreatePdfRpmReportResponse>(
    CREATE_PDF_RPM_REPORT,
    {
      onCompleted: async rpmReportData => {
        if (
          rpmReportData?.createPdfRpmReport?.rpmReport &&
          selectedReportType === RpmWorkflowReportType.RPM_REPORT &&
          isCurrentMonthReport
        ) {
          const createdRpmReport = rpmReportData?.createPdfRpmReport?.rpmReport;
          const previewUrl = await fetchDocumentUrl(createdRpmReport?.reportedDocS3Key);
          setRpmMonthlyReportUrl(previewUrl);
        } else if (rpmReportData?.createPdfRpmReport?.errors?.length > 0) {
          setRpmMonthlyReportUrl('');
          enqueueSnackbar(rpmReportData.createPdfRpmReport.errors[0], { variant: 'error' });
        }
      }
    }
  );
  const [createRpmCoverLetter, { error: createRpmCoverLetterError, loading: rpmCoverLetterLoading }] =
    useMutation<CreateRpmCoverLetterResponse>(CREATE_PDF_RPM_COVER_LETTER, {
      onCompleted: async rpmCoverLetterData => {
        if (rpmCoverLetterData?.createPdfRpmCoverLetter?.pdfDownloadUrl) {
          const previewUrl = await fetchDocumentUrl(rpmCoverLetterData.createPdfRpmCoverLetter.pdfDownloadUrl);
          setRpmCoverLetterUrl(previewUrl);
        } else if (rpmCoverLetterData?.createPdfRpmCoverLetter?.errors?.length > 0) {
          setRpmCoverLetterUrl('');
          enqueueSnackbar(rpmCoverLetterData.createPdfRpmCoverLetter.errors[0], { variant: 'error' });
        }
      }
    });
  const [reviewRpmReport, { loading: reviewRpmReportLoading }] = useMutation<ReviewRpmReportResponse>(REVIEW_RPM_REPORT);
  const [regenerateRpmReport, { loading: regenerateRpmReportLoading }] = useMutation<RegenerateRpmReportResponse>(REGENERATE_RPM_REPORT);
  const [createRpmChartReviewReport, { loading: createChartReviewReportLoading }] =
    useMutation<CreatePdfRpmChartReviewReportResponse>(CREATE_PDF_RPM_CHART_REVIEW_REPORT);
  const [createRpmSummaryReport, { loading: createRpmSummaryReportLoading }] =
    useMutation<CreatePdfRpmSummaryReport>(CREATE_PDF_RPM_SUMMARY_REPORT);

  const handleEditCoverLetter = () => {
    const modal = showModal(EditCoverLetterDialog, {
      title: 'Edit Cover Letter Content',
      onCoverLetterUpdated: async () => {
        await createRpmCoverLetter({
          variables: {
            truentityId: truentityId
          }
        });
      },
      hideDialog: () => {
        modal.hide();
      }
    });
  };

  const handelReportLogs = () => setIsActivityDrawerOpen(true);
  const closeActivityDrawer = () => setIsActivityDrawerOpen(false);

  const handleRpmReportReview = useCallback(
    async (reviewType: ReviewSubmitTypeEnum) => {
      try {
        const reportMenuOptionsArray = Object.values(reportMenuOptionsDict);
        if (reportMenuOptionsArray?.length === 0) {
          enqueueSnackbar('Could not load RPM report for review', {
            variant: 'error'
          });
          return;
        }
        const { data, errors } = await reviewRpmReport({
          variables: {
            rpmReportId: reportMonth?.id,
            truentityId: truentityId,
            reviewSubmitType: reviewType
          }
        });

        if (data?.reviewRpmReport) {
          const { status, message } = data.reviewRpmReport;
          const variant = status === 'Success' ? 'success' : 'error';

          enqueueSnackbar(message, {
            variant
          });
        }
        if (errors) {
          console.error('Error in ReviewRpmReport:', errors);
          enqueueSnackbar('Unable to send RPM report for review', {
            variant: 'error'
          });
        }
      } catch (error) {
        console.error('Error in ReviewRpmReport:', error);
        enqueueSnackbar('Unable to send RPM report for review', {
          variant: 'error'
        });
      }
    },
    [enqueueSnackbar, reportMonth?.id, reviewRpmReport]
  );

  const regenerateRpmSummaryReport = async (initial?: boolean) => {
    try {
      const response = await createRpmSummaryReport({
        variables: {
          truentityId: truentityId,
          monthYear: reportMonth?.label === 'Current' ? 'current' : reportMonth?.monthYear
        }
      });

      if (response?.data?.createPdfRpmSummaryReport?.status === 'Success') {
        enqueueSnackbar(`RPM Summarized Report ${initial ? 'generated' : 'regenerated'} successfully`, {
          variant: 'success'
        });
        const previewUrl = await fetchDocumentUrl(response?.data?.createPdfRpmSummaryReport?.pdfDownloadUrl);
        setSummaryReportUrl(previewUrl);
      } else {
        const errorMessage = response?.errors?.[0]?.message || `Unable to ${initial ? 'generate' : 'regenerate'} RPM Summarized report`;
        enqueueSnackbar(errorMessage, {
          variant: 'error'
        });
      }
    } catch (error) {
      enqueueSnackbar(`Unable to ${initial ? 'generate' : 'regenerate'} RPM Summarized report`, {
        variant: 'error'
      });
    }
  };

  const regenerateRpmChartReviewReport = async (initial?: boolean) => {
    try {
      const reviewWorkflowId = reportMonth?.rpmAccountReviewWorkflow?.id;
      if (!reviewWorkflowId) {
        enqueueSnackbar(`Unable to ${initial ? 'generate' : 'regenerate'} RPM Chart Review report. Could not find Review details.`, {
          variant: 'error'
        });
        return;
      }

      const response = await createRpmChartReviewReport({
        variables: {
          rpmAccountReviewWorkflowId: reviewWorkflowId
        }
      });

      if (response?.data?.createPdfRpmChartReviewReport?.status === 'Success') {
        enqueueSnackbar(`RPM Chart Review report ${initial ? 'generated' : 'regenerated'} successfully`, {
          variant: 'success'
        });
        refetchRpmMonthlyReports();
      } else {
        const errorMessage = response?.errors?.[0]?.message || `Unable to ${initial ? 'generate' : 'regenerate'} RPM Chart Review report`;
        enqueueSnackbar(errorMessage, {
          variant: 'error'
        });
      }
    } catch (error) {
      enqueueSnackbar(`Unable to ${initial ? 'generate' : 'regenerate'} RPM Chart Review report`, {
        variant: 'error'
      });
    }
  };

  const regenerateMonthlyRpmReport = async () => {
    try {
      const { data, errors } = await regenerateRpmReport({
        variables: {
          reportIds: [reportMonth?.id]
        }
      });

      if (data?.regenerateRpmReports) {
        const { status, message } = data.regenerateRpmReports;
        const variant = status === 'Success' ? 'success' : 'error';

        if (status === 'Success') {
          refetchRpmMonthlyReports();
        }
        enqueueSnackbar(message, {
          variant
        });
      }
      if (errors) {
        console.error('Error in regenerateRpmReport:', errors);
        enqueueSnackbar('Unable to regenerate RPM report', {
          variant: 'error'
        });
      }
    } catch (error) {
      console.error('Error in regenerateRpmReport:', error);
      enqueueSnackbar('Unable to regenerate RPM report', {
        variant: 'error'
      });
    }
  };

  const handleConfirmationModal = useCallback(
    (reviewType: ReviewSubmitTypeEnum) => {
      const modal = showModal(ConfirmDialog, {
        title:
          reviewType === 'SEND_NOW'
            ? 'Are you sure you want to send this report for review?'
            : 'Are you sure you want to submit this report as ready for review?',
        message: dynamicConfirmationMessage,
        onAgree: () => {
          if (!isPatientProvidersAvailable) {
            enqueueSnackbar(
              <Box>
                Please select and{' '}
                <Link
                  style={{
                    color: color.white
                  }}
                  to={`/patients/${truentityId}/details/rpm/patient-setup/care-activity/configuration`}
                >
                  configure
                </Link>{' '}
                patient providers to send the report to.
              </Box>,
              { variant: 'error' }
            );
            return;
          } else {
            handleRpmReportReview(reviewType);
            modal.hide();
          }
        },
        onDisagree: () => {
          modal.hide();
        },
        fullWidth: true,
        maxWidth: 'md'
      });
    },
    [dynamicConfirmationMessage, handleRpmReportReview, isCompanyProvidersAvailable, isPatientProvidersAvailable]
  );

  const regenerateRpmMonthReport = useCallback(
    (selectedMonth: string) => {
      const modal = showModal(ConfirmDialog, {
        title: 'Are you sure?',
        message: `Are you sure you want to regenerate the report for <b>${selectedMonth}</b>? Regenerating the report will create a new version for the selected month, and access to the <b>previous report</b> for <b>${selectedMonth}</b> will no longer be available.`,
        onAgree: () => {
          regenerateMonthlyRpmReport();
          modal.hide();
        },
        onDisagree: () => {
          modal.hide();
        }
      });
    },
    [dynamicConfirmationMessage, regenerateMonthlyRpmReport]
  );

  const handleReviewReport = useCallback(() => {
    setShowPatientReviewWindow(true);
  }, [setShowPatientReviewWindow]);

  const loadRpmSummaryReport = async () => {
    console.log('reportMonth: ', reportMonth);
    if (reportMonth?.summaryReportS3Key) {
      console.log('fetchDocumentUrl()');
      const pdfUrl = await fetchDocumentUrl(reportMonth.summaryReportS3Key);
      setSummaryReportUrl(pdfUrl);
    } else {
      console.log('regenerateRpmSummaryReport()');
      regenerateRpmSummaryReport(true);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedReportType === RpmWorkflowReportType.RPM_REPORT) {
        if (isCurrentMonthReport) {
          createRpmReport({
            variables: {
              truentityId: truentityId
            }
          });
        } else if (reportMonth?.docS3Key) {
          const pdfUrl = await fetchDocumentUrl(reportMonth.docS3Key);
          setRpmMonthlyReportUrl(pdfUrl);
        }
      } else if (selectedReportType === RpmWorkflowReportType.CHART_REVIEW) {
        if (reportMonth?.rpmAccountReviewWorkflow?.reportS3Key) {
          const pdfUrl = await fetchDocumentUrl(reportMonth.rpmAccountReviewWorkflow.reportS3Key);
          setChartReviewReportUrl(pdfUrl);
        } else {
          if (reportMonth?.rpmAccountReviewWorkflow?.id) {
            regenerateRpmChartReviewReport(true);
          }
          setChartReviewReportUrl('');
        }
      } else if (selectedReportType === RpmWorkflowReportType.SUMMARY_REPORT) {
        loadRpmSummaryReport();
      }
    };
    fetchData();
  }, [reportMonth]);

  useEffect(() => {
    if (createRpmReportError) {
      enqueueSnackbar('Could not get RPM Summary Report.', { variant: 'error' });
    }
  }, [createRpmReportError]);

  useEffect(() => {
    if (createRpmCoverLetterError) {
      enqueueSnackbar('Could not get RPM Cover Letter.', { variant: 'error' });
    }
  }, [createRpmCoverLetterError]);

  useEffect(() => {
    if (selectedReportType === RpmWorkflowReportType.COVER_LETTER) {
      if (rpmCoverLetterUrl === '') {
        createRpmCoverLetter({
          variables: {
            truentityId: truentityId
          }
        });
      }
    } else if (selectedReportType === RpmWorkflowReportType.SUMMARY_REPORT) {
      loadRpmSummaryReport();
    } else {
      setRpmCoverLetterUrl('');
      fetchMonthlyRpmReports();
    }
  }, [selectedReportType]);

  useEffect(() => {
    if (isReviewWorkflowChanged) {
      const timeout = setTimeout(() => {
        regenerateRpmChartReviewReport();
        setIsReviewWorkflowChanged(false);
      }, 5000);

      return () => {
        clearTimeout(timeout);
        setIsReviewWorkflowChanged(false);
      };
    }
  }, [isReviewWorkflowChanged]);

  useEffect(() => {
    if (reportSenderMethodAvailability?.getRpmReportMethodAvailability?.jsonMessage) {
      const jsonObj = JSON.parse(reportSenderMethodAvailability?.getRpmReportMethodAvailability.jsonMessage ?? '');
      const methodAvailability: MessageAvailabilityType = {
        isConfigAvailability: jsonObj?.isConfigAvailability,
        message: {
          providers: jsonObj?.message?.providers?.map(provider => ({
            providerName: provider?.providerName,
            providerMethodAvailability: {
              isRpmReportProviderByEmail: provider?.providerMethodAvailability?.isRpmReportProviderByEmail,
              isRpmReportProviderByText: provider?.providerMethodAvailability?.isRpmReportProviderByText,
              isRpmReportProviderByFax: provider?.providerMethodAvailability?.isRpmReportProviderByFax
            },
            providerContacts: provider?.providerContacts
          })),
          patients: {
            patientName: jsonObj?.message?.patients?.patientName,
            patientMethodAvailability: {
              isRpmReportPatientByEmail: jsonObj?.message?.patients?.patientMethodAvailability?.isRpmReportPatientByEmail,
              isRpmReportPatientByText: jsonObj?.message?.patients?.patientMethodAvailability?.isRpmReportPatientByText
            },
            patientContacts: jsonObj?.message?.patients?.patientContacts
          },
          orgProviders: jsonObj?.message?.orgProviders?.map(provider => ({
            providerName: provider?.providerName,
            providerMethodAvailability: {
              isRpmReportProviderByEmail: provider?.providerMethodAvailability?.isRpmReportProviderByEmail,
              isRpmReportProviderByText: provider?.providerMethodAvailability?.isRpmReportProviderByText,
              isRpmReportProviderByFax: provider?.providerMethodAvailability?.isRpmReportProviderByFax
            },
            providerContacts: provider?.providerContacts
          }))
        }
      };
      const content = generateDynamicContent(methodAvailability);
      setDynamicConfirmationMessage(content);
      if (jsonObj?.message?.providers?.length > 0) {
        setIsPatientProvidersAvailable(true);
      }
      if (jsonObj?.message?.orgProviders?.length > 0) {
        setIsCompanyProvidersAvailable(true);
      }
    } else {
      setIsPatientProvidersAvailable(false);
      setIsCompanyProvidersAvailable(false);
    }
  }, [reportSenderMethodAvailability]);

  useEffect(() => {
    if (reportMenuOptionsDict) {
      if (patientReviewWorkflowMonthYear) {
        const selectedReportMonth = reportMenuOptionsDict?.[patientReviewWorkflowMonthYear] ?? ({} as ReportMonth);
        setReportMonth(selectedReportMonth);
      } else {
        setReportMonth(reportMenuOptionsDict['Current']);
      }
    } else {
      setReportMonth({} as ReportMonth);
    }
  }, [patientReviewWorkflowMonthYear, reportMenuOptionsDict]);

  const loadReportReviewStatusChip = useCallback((reportMonth: ReportMonth) => {
    if (reportMonth?.label === 'Current') return null;

    const { rpmAccountReviewWorkflow } = reportMonth || {};

    if (rpmAccountReviewWorkflow?.signOffAt) {
      return (
        <ChipColumn
          label={<Label>Chart Review</Label>}
          value={<Body1>Signed Off</Body1>}
          secondaryValue={
            <Box sx={{ margin: 1 }}>
              <Caption textTransform="none" color="primary">
                {`Signed off on: ${formatDate(rpmAccountReviewWorkflow.signOffAt)} by ${
                  rpmAccountReviewWorkflow.provider?.nameWithTitle || 'Provider'
                }`}
              </Caption>
            </Box>
          }
        />
      );
    }

    const status = rpmAccountReviewWorkflow ? 'In Progress' : 'Pending';

    return <ChipColumn label={<Label>Chart Review</Label>} value={<Body1>{status}</Body1>} />;
  }, []);

  return (
    <BaseRpmWorkflowTabContent
      sx={{
        background: theme.palette.background.paper,
        boxShadow: 'none'
      }}
      isReadOnly={isReadOnly}
    >
      <Stack display={'flex'} flexDirection={'row'} justifyContent={'flex-start'} alignItems={'stretch'} width={'100%'}>
        <Stack width="100%" flexDirection="column" justifyContent="flex-start" alignItems="stretch">
          <Stack flexDirection="row" justifyContent="flex-start" alignItems="flex-start">
            <H1 textAlign="left" sx={{ fontSize: '38px', color: color.black50, lineHeight: '50px' }}>
              Reports
            </H1>
            <Stack width="100%" flex={1} flexDirection="row" justifyContent="flex-end" alignItems="center">
              <Box
                sx={{
                  margin: 1
                }}
              >
                <MuiMenu
                  variant="contained"
                  ButtonProps={{
                    isLoading: reviewRpmReportLoading
                  }}
                  disabled={reviewRpmReportLoading || !reportMonth}
                  label="Send for Review"
                  options={[
                    {
                      label: 'Schedule for Later',
                      disabled: isCurrentMonthReport,
                      onAction: () => handleConfirmationModal(ReviewSubmitTypeEnum.READY_TO_SEND)
                    },
                    {
                      label: 'Send Now',
                      onAction: () => handleConfirmationModal(ReviewSubmitTypeEnum.SEND_NOW)
                    }
                  ]}
                />
              </Box>
              <Box sx={{ margin: 1 }}>
                <Button
                  disabled={selectedReportType === RpmWorkflowReportType.COVER_LETTER || isCurrentMonthReport}
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={handleReviewReport}
                >
                  Review
                </Button>
              </Box>
              <Box sx={{ margin: 1 }}>
                <Button
                  type="button"
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    handelReportLogs();
                  }}
                >
                  Audit Summary
                </Button>
              </Box>
            </Stack>
          </Stack>
          <Stack flexDirection="row" justifyContent="flex-end" alignItems="center">
            <Button
              disabled={selectedReportType === RpmWorkflowReportType.RPM_REPORT}
              type="button"
              variant="contained"
              color="primary"
              onClick={handleEditCoverLetter}
              sx={{
                margin: 1
              }}
            >
              Edit Cover Letter
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Paper sx={{ background: theme.palette.background.default }}>
        <MuiTabs
          tabVariant="standard"
          sx={{}}
          onTabChange={selectedTabIndex => {
            switch (selectedTabIndex) {
              case 0:
                setSelectedReportType(RpmWorkflowReportType.RPM_REPORT);
                break;
              case 1:
                setSelectedReportType(RpmWorkflowReportType.CHART_REVIEW);
                break;
              case 2:
                setSelectedReportType(RpmWorkflowReportType.SUMMARY_REPORT);
                break;
              case 3:
                setSelectedReportType(RpmWorkflowReportType.COVER_LETTER);
                break;
              default:
                setSelectedReportType(RpmWorkflowReportType.RPM_REPORT);
                break;
            }
          }}
          tabs={[
            {
              label: 'RPM Report',
              children: (
                <Stack>
                  <Stack
                    sx={{
                      m: 1
                    }}
                    width="100%"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <MuiMenu disabled={isReadOnly} label={reportMonth?.label} options={Object.values(reportMenuOptionsDict)} />
                    <Stack
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-end"
                      alignItems="flex-start"
                      sx={{
                        marginTop: 2
                      }}
                    >
                      {loadReportReviewStatusChip(reportMonth) ?? <></>}
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        isLoading={regenerateRpmReportLoading}
                        onClick={() => regenerateRpmMonthReport(reportMonth?.label)}
                        disabled={!reportMonth || isCurrentMonthReport}
                        sx={{
                          marginX: 1
                        }}
                      >
                        Regenerate the report
                      </Button>
                    </Stack>
                  </Stack>
                  {rpmReportLoading ? (
                    <Box sx={{ width: '100%' }}>
                      <LinearProgress />
                    </Box>
                  ) : (
                    <></>
                  )}
                  <Stack
                    width="100%"
                    height="100%"
                    sx={{ border: '1px solid #ccc', height: '750px', justifyContent: 'stretch', alignItems: 'stretch' }}
                  >
                    <PDFViewer src={rpmMonthlyReportUrl} />
                  </Stack>
                </Stack>
              )
            },
            {
              label: 'Chart Review Report',
              children: (
                <Stack>
                  <Stack
                    sx={{
                      m: 1
                    }}
                    width="100%"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <MuiMenu disabled={isReadOnly} label={reportMonth?.label} options={Object.values(reportMenuOptionsDict)} />
                    <Stack
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-end"
                      alignItems="flex-start"
                      sx={{
                        marginTop: 2
                      }}
                    >
                      {loadReportReviewStatusChip(reportMonth) ?? <></>}
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        isLoading={createChartReviewReportLoading}
                        onClick={() => regenerateRpmChartReviewReport()}
                        disabled={!reportMonth?.rpmAccountReviewWorkflow?.id}
                        sx={{
                          marginX: 1
                        }}
                      >
                        Regenerate the report
                      </Button>
                    </Stack>
                  </Stack>
                  {createChartReviewReportLoading ? (
                    <Box sx={{ width: '100%' }}>
                      <LinearProgress />
                    </Box>
                  ) : (
                    <></>
                  )}

                  {reportMonth?.rpmAccountReviewWorkflow?.id ? (
                    <Stack
                      width="100%"
                      height="100%"
                      sx={{ border: '1px solid #ccc', height: '750px', justifyContent: 'stretch', alignItems: 'stretch' }}
                    >
                      <PDFViewer src={chartReviewReportUrl} />
                    </Stack>
                  ) : (
                    <Stack
                      width="100%"
                      height="100%"
                      sx={{ border: '1px solid #ccc', height: 'auto', justifyContent: 'center', alignItems: 'center' }}
                    >
                      <H4 margin={4}>No RPM Patient Review Workflow found for this Month</H4>
                    </Stack>
                  )}
                </Stack>
              )
            },
            {
              label: 'Summary Report',
              children: (
                <Stack>
                  <Stack
                    sx={{
                      m: 1
                    }}
                    width="100%"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <MuiMenu disabled={isReadOnly} label={reportMonth?.label} options={Object.values(reportMenuOptionsDict)} />
                    <Stack
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-end"
                      alignItems="flex-start"
                      sx={{
                        marginTop: 2
                      }}
                    >
                      {loadReportReviewStatusChip(reportMonth) ?? <></>}
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        isLoading={createRpmSummaryReportLoading}
                        onClick={() => regenerateRpmSummaryReport()}
                        disabled={!reportMonth}
                        sx={{
                          marginX: 1
                        }}
                      >
                        Regenerate the report
                      </Button>
                    </Stack>
                  </Stack>
                  {createRpmSummaryReportLoading ? (
                    <Box sx={{ width: '100%' }}>
                      <LinearProgress />
                    </Box>
                  ) : (
                    <></>
                  )}
                  {summaryReportUrl && (
                    <Stack
                      width="100%"
                      height="100%"
                      sx={{ border: '1px solid #ccc', height: '750px', justifyContent: 'stretch', alignItems: 'stretch' }}
                    >
                      <PDFViewer src={summaryReportUrl} />
                    </Stack>
                  )}
                </Stack>
              )
            },
            {
              label: 'Cover Letter',
              children: (
                <Stack>
                  {rpmCoverLetterLoading ? (
                    <Box sx={{ width: '100%' }}>
                      <LinearProgress />
                    </Box>
                  ) : (
                    <></>
                  )}
                  <Stack
                    width="100%"
                    height="100%"
                    sx={{ border: '1px solid #ccc', height: '750px', justifyContent: 'stretch', alignItems: 'stretch' }}
                  >
                    <PDFViewer src={rpmCoverLetterUrl} />
                  </Stack>
                </Stack>
              )
            }
          ]}
        />
      </Paper>
      <ActivityDrawer
        isOpen={isActivityDrawerOpen}
        onClose={closeActivityDrawer}
        type={ActivityLogType.REPORTS_SUMMARY}
        filterOptions={{
          typeFilters: [...new Set(Object.values(ReportActivityLogTypes).concat(Object.values(ReportCommunicationActivityLogTypes)))]
        }}
        accountName={getAccountUserFullName(patientInfo?.user)}
      />
    </BaseRpmWorkflowTabContent>
  );
};

export default Reports;
