import type { AccountMessage } from '@/types/chat';
import type { FollowUpRemindersTypes } from './accountProfile';
import type { RelyingPartyAdminType, RelyingPartyType } from './graphql';
import type { AccountType } from './tasks';

export interface SubscriptionNotificationsResponse {
  relyingPartyAdminNotifications: {
    totalReadCount: number;
    totalUnreadCount: number;
  };
}

export interface NotificationsResponse {
  relyingPartyAdminConversations: NotificationMessage[];
}

export interface NotificationMessage {
  id: string;
  createdAt: string;
  account: {
    id: string;
    truentityId: string;
    user: {
      firstName: string;
      lastName: string;
    };
  };
  messageBody: string;
  isRead: boolean;
  messageDirection: string;
  messageFrom: string;
  messageTo: string;
  sourceType: string;
}

export enum NotificationTypeEnum {
  FOLLOWUP = 'FOLLOWUP',
  MESSAGE = 'MESSAGE'
}

export type NotificationReadStatusType = 'READ' | 'UNREAD';

export type NotificationType = {
  id: string;
  recipient: RelyingPartyAdminType;
  relyingParty: RelyingPartyType;
  createdByAdmin: RelyingPartyAdminType;
  account: AccountType;
  notificationType: NotificationTypeEnum;
  message: string;
  reference: FollowUpRemindersTypes | AccountMessage;
  isRead: boolean;
  updatedAt: Date;
};
