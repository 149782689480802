import Alert from '@/components/Alert';
import CloseIcon from '@mui/icons-material/Close';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import type { SxProps } from '@mui/material';
import { Box, IconButton, Stack } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { Accept } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import Button from './Button';
import { Body2 } from './Typography';

export type FileWithOrigin = {
  id: string;
  mediaFile: File | string;
};

export type DropzoneFile = {
  id: string;
  mediaFile: File;
};

type Props = {
  label?: string;
  maxFileCount?: number;
  dropzoneStyles?: SxProps;
  mediaFiles?: FileWithOrigin[];
  acceptedFiles?: { files: Accept; maxFileSize: number }[];
  onFileUpload: (files: DropzoneFile[]) => void;
  onRemoveFile: (files: DropzoneFile[]) => void;
};

const TruentityDropzone = ({
  label,
  onFileUpload,
  onRemoveFile,
  mediaFiles,
  dropzoneStyles,
  maxFileCount = 1,
  acceptedFiles = [{ files: { 'image/*': ['.jpg', '.jpeg', '.png'] }, maxFileSize: 2 }],
  ...props
}: Props) => {
  const [files, setFiles] = useState<DropzoneFile[]>([]);
  const [error, setError] = useState('');

  const isMultiple = useMemo(() => maxFileCount > 1, [maxFileCount]);
  const { acceptedFileTypes, maxFileSizes }: { acceptedFileTypes: Accept; maxFileSizes: Record<string, number> } = useMemo(() => {
    let acceptedFileTypes: Accept = {};
    const maxFileSizes: Record<string, number> = {};
    acceptedFiles.forEach(({ files, maxFileSize }) => {
      Object.keys(files).forEach(key => {
        maxFileSizes[key] = maxFileSize;
      });
      acceptedFileTypes = { ...acceptedFileTypes, ...files };
    });
    return { acceptedFileTypes, maxFileSizes };
  }, [acceptedFiles]);

  const onDrop = useCallback(
    (acceptedFiles: File[], existingFiles: DropzoneFile[]) => {
      const refactoredAcceptedFiles = acceptedFiles.map(
        acceptedFile =>
          ({
            id: uuidv4() as string,
            mediaFile: acceptedFile
          } as DropzoneFile)
      );

      const files = [...refactoredAcceptedFiles, ...existingFiles];

      const newFiles = files.filter(file => {
        const fileType = file.mediaFile.type;
        let maxSizeMB = maxFileSizes[fileType];

        if (!maxSizeMB && fileType.includes('/')) {
          const [mainType] = fileType.split('/');
          maxSizeMB = maxFileSizes[`${mainType}/*`];
        }

        const maxBytes = maxSizeMB ? maxSizeMB * 1024 * 1024 : null;

        if (maxBytes && file.mediaFile.size > maxBytes) {
          handleErrors(`File ${file.mediaFile.name} is too large. Max allowed size is ${maxSizeMB}MB.`);
          return false;
        }
        return true;
      });

      setFiles(newFiles);
      onFileUpload(newFiles);
    },
    [maxFileSizes, onFileUpload]
  );

  const removeFile = (fileToRemove: DropzoneFile) => () => {
    const updatedFiles = files.filter(file => file.id !== fileToRemove.id);
    setFiles(updatedFiles);
    onRemoveFile(updatedFiles);
  };

  const handleErrors = (errorMessage: string) => {
    setError(errorMessage);
    setTimeout(() => setError(''), 5000);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: acceptedFiles => onDrop(acceptedFiles, files),
    multiple: isMultiple,
    maxFiles: maxFileCount,
    accept: acceptedFileTypes
  });

  const fetchAndConvertFiles = useCallback(async (urls: string[]) => {
    const fetchedFiles = await Promise.all(
      urls.map(async url => {
        const response = await fetch(url);
        const blob = await response.blob();
        const newFile = new File([blob], 'filename', { type: blob.type });
        return newFile;
      })
    );
    return fetchedFiles;
  }, []);

  useEffect(() => {
    (async () => {
      const initialFiles: DropzoneFile[] = [];

      if (mediaFiles && mediaFiles.length > 0) {
        for (const media of mediaFiles) {
          if (typeof media.mediaFile === 'string') {
            try {
              const fetchedFiles = await fetchAndConvertFiles([media.mediaFile]);
              initialFiles.push({
                id: media.id,
                mediaFile: fetchedFiles[0]
              });
            } catch (error) {
              console.error(`Can not fetch image data: ${error}`);
            }
          } else {
            initialFiles.push(media as DropzoneFile);
          }
        }
      }
      setFiles(initialFiles);
    })();
  }, [mediaFiles, fetchAndConvertFiles]);

  useEffect(() => {
    if (files.length > maxFileCount) {
      const newFiles = files.slice(0, maxFileCount);
      setFiles(newFiles);
      onFileUpload(newFiles);
      handleErrors(`Maximum file count exceeded. Only ${maxFileCount} files can be uploaded at a time.`);
    }
  }, [files, maxFileCount, onFileUpload]);
  return (
    <Box>
      {error && <Alert status={'error'} title={'Failed to upload logo'} description={error} sx={{ marginBottom: 2 }} />}
      <Stack
        {...getRootProps()}
        gap={2}
        alignItems="center"
        sx={{
          border: '2px dashed',
          borderColor: error ? 'error' : 'gray',
          borderRadius: 2,
          p: 4,
          textAlign: 'center',
          cursor: 'pointer',
          ...dropzoneStyles
        }}
        {...props}
      >
        <input {...getInputProps()} />
        <VideoCameraBackIcon sx={{ fontSize: 40 }} />
        <Body2>{label ? label : 'Drag your photo here, or'}</Body2>
        <Button variant="contained" component="span" disabled={files?.length >= maxFileCount}>
          Browse File{isMultiple ? 's' : ''}
        </Button>
      </Stack>
      <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {files.map((file, index) => (
          <Box key={index} sx={{ position: 'relative', m: 1, maxWidth: 150, maxHeight: 100, aspectRatio: 'auto', borderRadius: '5px' }}>
            <IconButton
              onClick={removeFile(file)}
              sx={{
                position: 'absolute',
                top: '-10px',
                right: '-10px',
                p: 0.5,
                background: 'white',
                zIndex: 10,
                ':hover': {
                  background: 'grey'
                }
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
            {file.mediaFile.type.startsWith('image/') ? (
              <img
                src={URL.createObjectURL(file.mediaFile)}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                alt="preview"
                onLoad={() => URL.revokeObjectURL(file.mediaFile.name)}
              />
            ) : (
              <video
                src={URL.createObjectURL(file.mediaFile)}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                onLoad={() => URL.revokeObjectURL(file.mediaFile.name)}
              />
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default TruentityDropzone;
