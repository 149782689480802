import { useQuery } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import AdministrationContext from '@/context/administrationContext';
import { GET_RELYING_PARTY_ADMINS } from '@/graphql/account';
import { GET_CLIENT_ORGANIZATIONS } from '@/graphql/administration';
import Organizations from '@/routes/Administration/organizations';
import OrganizationSection from '@/routes/Administration/OrganizationSection';
import OrganizationDetails from '@/routes/Administration/OrganizationSection/OrganizationDetails';
import AssignedPatients from '@/routes/Administration/OrganizationSection/OrganizationDetails/patients';
import AssignedStores from '@/routes/Administration/OrganizationSection/OrganizationDetails/stores';
import AssignedTasks from '@/routes/Administration/OrganizationSection/OrganizationDetails/tasks';
import StoreSection from '@/routes/Administration/StoreSection';
import type { UsersData } from '@/routes/Administration/Users';
import Users from '@/routes/Administration/Users';
import type { RelyingPartyAdminSessionType } from '@/types/graphql';
import SystemSection from './System';

type OrganizationData = {
  id: number;
  name: string;
};

export type RelyingPartyAdminData = {
  id: string;
  name: string;
};

const getEmail = organizationInfo => {
  let email = '';
  if (organizationInfo?.contacts?.length > 0) {
    const filtered = organizationInfo.contacts.filter(contact => contact.type === 'Email');

    if (filtered.length > 0) {
      email = filtered[0].value;
    }
  }

  return email;
};

const getTelephone = organizationInfo => {
  let tel = '';
  if (organizationInfo?.contacts?.length > 0) {
    const filtered = organizationInfo.contacts.filter(contact => contact.type === 'Telephone');

    if (filtered.length > 0) {
      tel = filtered[0].value;
    }
  }

  return tel;
};

const AdministrationSection = () => {
  const navigate = useNavigate();

  const {
    loading: organizationDataLoading,
    data: organizationData,
    called: organizationDataCalled,
    refetch: organizationDataRefetch
  } = useQuery(GET_CLIENT_ORGANIZATIONS, {
    variables: {
      pageNum: 1,
      pageSize: DEFAULT_PAGE_SIZE
    },
    notifyOnNetworkStatusChange: true
  });

  const {
    loading: relyingPartyAdminDataLoading,
    data: relyingPartyAdminData,
    called: relyingPartyAdminDataCalled
  } = useQuery(GET_RELYING_PARTY_ADMINS, {
    variables: {
      pageNum: 1,
      pageSize: DEFAULT_PAGE_SIZE
    },
    notifyOnNetworkStatusChange: true
  });

  const [organizations, setOrganizations] = useState<OrganizationData[]>([]);
  const [organization, setOrganization] = useState<OrganizationData>({} as OrganizationData);
  const [clientStore, setClientStore] = useState<Object>({});
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<string>('');

  const [relyingPartyAdmins, setRelyingPartyAdmins] = useState<RelyingPartyAdminData[]>([]);
  const [selectedPatients, setSelectedPatients] = useState<string[]>([]);

  const [reloadOrganization, setReloadOrganization] = useState(false);
  const [reloadStore, setReloadStore] = useState(false);

  const [users, setUsers] = useState<UsersData[]>([]);
  const [sessions, setSessions] = useState<RelyingPartyAdminSessionType[]>([]);

  useEffect(() => {
    if (organizationDataCalled && !organizationDataLoading && organizationData) {
      setOrganizations(organizationData.clientOrganizations.clientOrganizations);
    }
  }, [organizationDataLoading, organizationData, organizationDataCalled]);

  useEffect(() => {
    if (relyingPartyAdminDataCalled && !relyingPartyAdminDataLoading && relyingPartyAdminData) {
      setRelyingPartyAdmins(relyingPartyAdminData.relyingPartyAdminsAll.relyingPartyAdmins);
    }
  }, [relyingPartyAdminDataLoading, relyingPartyAdminData, relyingPartyAdminDataCalled]);

  const goToAssignPatientsToOrganization = () => {
    navigate(`/organization/${selectedOrganizationId}/assign`);
  };

  const reloadOrganizations = () => {
    organizationDataRefetch();
  };

  const goToOrganizationsPage = () => {
    navigate(`/administration/organizations`);
  };

  const providerValue = useMemo(
    () => ({
      organizations,
      reloadOrganizations,
      goToAssignPatientsToOrganization,
      selectedOrganizationId,
      goToOrganizationsPage,
      setOrganization,
      organization,
      setClientStore,
      clientStore,
      relyingPartyAdmins,
      setRelyingPartyAdmins,
      selectedPatients,
      setSelectedPatients,
      reloadOrganization,
      setReloadOrganization,
      reloadStore,
      setReloadStore,
      setUsers,
      users,
      setSessions,
      sessions
    }),
    [
      organizations,
      reloadOrganizations,
      goToAssignPatientsToOrganization,
      selectedOrganizationId,
      goToOrganizationsPage,
      setOrganization,
      organization,
      setClientStore,
      clientStore,
      relyingPartyAdmins,
      setRelyingPartyAdmins,
      selectedPatients,
      setSelectedPatients,
      reloadOrganization,
      setReloadOrganization,
      reloadStore,
      setReloadStore,
      setUsers,
      users,
      setSessions,
      sessions
    ]
  );

  return (
    <AdministrationContext.Provider value={providerValue}>
      <Outlet />
    </AdministrationContext.Provider>
  );
};

export {
  Organizations,
  OrganizationDetails,
  StoreSection,
  SystemSection,
  AssignedPatients,
  AssignedStores,
  OrganizationSection,
  Users,
  getEmail,
  getTelephone,
  AssignedTasks
};
export default AdministrationSection;
