import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import type { Swiper as SwiperType } from 'swiper';
import 'swiper/css';
import { Navigation } from 'swiper/modules';
import type { SwiperProps } from 'swiper/react';
import { Swiper, SwiperSlide } from 'swiper/react';

interface TruentitySwiperProps extends SwiperProps {
  children: React.ReactNode;
}

interface SwiperControls {
  goNext: () => void;
  goPrev: () => void;
}

const TruentitySwiper = forwardRef<SwiperControls, TruentitySwiperProps>(
  ({ children, spaceBetween = 50, slidesPerView = 1, ...props }, ref) => {
    const swiperRef = useRef<SwiperType | null>(null);

    useImperativeHandle(ref, () => ({
      goNext: () => {
        swiperRef.current?.slideNext();
        swiperRef.current?.updateAutoHeight();
      },
      goPrev: () => {
        swiperRef.current?.slidePrev();
        swiperRef.current?.updateAutoHeight();
      },
      updateHeight: () => {
        swiperRef.current?.updateAutoHeight();
      }
    }));

    useEffect(() => {
      if (swiperRef.current) {
        swiperRef.current.updateAutoHeight();
      }
    }, [children]);

    return (
      <>
        <Swiper
          modules={[Navigation]}
          spaceBetween={spaceBetween}
          slidesPerView={slidesPerView}
          autoHeight
          onSwiper={swiper => {
            swiperRef.current = swiper;
            swiper.updateAutoHeight();
            swiper.wrapperEl.style.transition = '0.3s ease';
          }}
          preventClicks={false}
          simulateTouch={false}
          {...props}
        >
          {React.Children.map(children, child => (
            <SwiperSlide>{child}</SwiperSlide>
          ))}
        </Swiper>
      </>
    );
  }
);

export default TruentitySwiper;
