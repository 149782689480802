import AutoSave from '@/components/AutoSave';
import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import type { TextSelectOption } from '@/components/SelectList';
import SelectList from '@/components/SelectList';
import { H4 } from '@/components/Typography';
import PatientDetailContext from '@/context/patientDetailContext';
import { UPDATE_RPM_PATIENT_ALTERNATE_PROVIDER_ID } from '@/graphql/account';
import type { GetRelyingPartyProvidersResponse } from '@/graphql/remotePatientMonitoring';
import { GET_RELYING_PARTY_PROVIDERS } from '@/graphql/remotePatientMonitoring';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { Grid, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useMemo } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

type FormValues = {
  alternateRpmProviderId: string;
};

const defaultValues: FormValues = {
  alternateRpmProviderId: ''
};

const PatientBillingConfig = ({ isReadOnly }: { isReadOnly: boolean }) => {
  const { id } = useParams();
  const { patientInfo, setReloadPatientInfo } = useContext(PatientDetailContext);
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const { enqueueSnackbar } = useSnackbar();

  const { data: relyingPartyProviders } = useQuery<GetRelyingPartyProvidersResponse>(GET_RELYING_PARTY_PROVIDERS, {
    variables: { relyingPartyId: currentUser?.relyingParty.id, pageSize: DEFAULT_PAGE_SIZE, pageNum: 1 },
    fetchPolicy: 'cache-and-network',
    skip: isReadOnly
  });

  const [updateAccount] = useMutation(UPDATE_RPM_PATIENT_ALTERNATE_PROVIDER_ID, {
    variables: {
      truentityId: id
    }
  });

  const methods = useForm<FormValues>({ defaultValues });

  const alternateRelyingPartyProviders: TextSelectOption[] = useMemo(() => {
    const providers = relyingPartyProviders?.relyingPartyProviders?.relyingPartyProviders;

    if (!Array.isArray(providers)) return [];

    return providers.map(relyingPartyProvider => {
      const provider = relyingPartyProvider.provider;

      return {
        label: `${provider.individualFirstName} ${provider.individualLastName} (${provider.npiNumber})`,
        value: provider.id
      };
    });
  }, [relyingPartyProviders?.relyingPartyProviders?.relyingPartyProviders]);

  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const handleSubmitImpl = (values: FormValues) => {
    try {
      updateAccount({
        variables: {
          profileData: {
            rpmAlternateBillingProviderId: values.alternateRpmProviderId
          }
        }
      })
        .then(res => {
          if (res.data.updateUserAccountProfile) {
            setReloadPatientInfo(true);
            enqueueSnackbar('Patient billing configs updated successfully', { variant: 'success' });
          } else {
            enqueueSnackbar('Error occurred while updating patient billing configs', { variant: 'error' });
          }
        })
        .catch(() => {
          enqueueSnackbar('Request failed', { variant: 'error' });
        });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Request failed', { variant: 'error' });
    }
  };

  useEffect(() => {
    if (patientInfo?.rpmAlternateBillingProvider?.id) {
      methods.reset({ alternateRpmProviderId: patientInfo?.rpmAlternateBillingProvider?.id });
    }
  }, [patientInfo, methods.reset, methods]);

  return (
    <FormProvider {...methods}>
      <Grid container alignItems="center">
        <Grid item xs={6}>
          <Stack direction="row">
            <H4>Alternate RPM Billing provider</H4>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={methods.control}
            name="alternateRpmProviderId"
            render={({ field: { onChange, value } }) => (
              <SelectList
                options={alternateRelyingPartyProviders}
                placeholder="Select an option..."
                value={value}
                onChange={onChange}
                disabled={alternateRelyingPartyProviders.length === 0 || isReadOnly}
              />
            )}
          />
        </Grid>
      </Grid>
      <AutoSave defaultValues={defaultValues} onSubmit={onSubmit} disableProgressAnimation />
    </FormProvider>
  );
};

export default PatientBillingConfig;
