import { color } from '@/styles/assets/colors';
import type { Announcement } from '@/types/announcement';
import { Campaign, CheckCircleOutline } from '@mui/icons-material';
import { Box, DialogActions, DialogContent, Stack } from '@mui/material';
import Button from '../Button';
import PrimaryAnnouncementBody from '../PrimaryAnnouncementBody';
import { H3 } from '../Typography';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  announcement: Announcement;
  handleClose: (id: string) => void;
};

const PrimaryAnnouncementDialog = ({ title, hideDialog, announcement, handleClose, ...props }: Props) => {
  return (
    <BaseDialog {...props} hideDialog={hideDialog} title={title} fullWidth maxWidth="md">
      <DialogContent>
        <Stack direction="row" alignItems="center" justifyContent="center" gap={1} mb={2}>
          <Campaign sx={{ fontSize: '40px', color: color.truentityBlue[400] }} />
          {announcement.title && (
            <Box>
              <H3 sx={{ textTransform: 'capitalize', color: color.truentityBlue[800] }}>{announcement.title}</H3>
            </Box>
          )}
        </Stack>
        <PrimaryAnnouncementBody announcement={announcement} />
        <DialogActions>
          <Button startIcon={<CheckCircleOutline />} onClick={() => handleClose(announcement.id)}>
            Ok
          </Button>
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};

export default PrimaryAnnouncementDialog;
