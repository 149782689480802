import type { CopilotMessagingSubscriptionResponse } from '@/graphql/coPilot';
import { COPILOT_MESSAGING_SUBSCRIPTION } from '@/graphql/coPilot';
import type { MessageType } from '@/types/coPilot';
import { MessageState, SenderType } from '@/types/coPilot';
import { useCopilotMessageStore } from '@/zustand/CoPilotMessageStore';
import { useSubscription } from '@apollo/client';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

type Props = {
  truentityId: string | undefined;
  relyingPartyId: string | undefined;
  relyingPartyAdminId: string | undefined;
};

const CopilotMessageSubscriptionHandler = ({ truentityId, relyingPartyId, relyingPartyAdminId }: Props) => {
  const { id } = useParams();
  const { clearMessages, appendOrCreateMessage, setLoaderMessage } = useCopilotMessageStore();

  useSubscription<CopilotMessagingSubscriptionResponse>(COPILOT_MESSAGING_SUBSCRIPTION, {
    variables: {
      truentityId: truentityId,
      relyingPartyId: relyingPartyId,
      relyingPartyAdminId: relyingPartyAdminId
    },
    fetchPolicy: 'no-cache',
    onError: error => {
      console.error('Subscription error:', error);
    },
    skip: !(!!truentityId && !!relyingPartyId && !!relyingPartyAdminId),
    onSubscriptionData: ({ subscriptionData }) => {
      const data = subscriptionData?.data?.copilotMessaging;
      if (data) {
        const { id, message, messageState } = data;

        const messageBody: MessageType = {
          id: id || uuidv4(),
          type: messageState,
          isCompleted: messageState !== MessageState.STREAM,
          sender: SenderType.BOT,
          body: message
        };

        if (messageState === MessageState.LOADER) return setLoaderMessage(messageBody);
        setLoaderMessage(null);
        return appendOrCreateMessage(messageBody);
      } else {
        console.warn('No data or message received in subscription.');
      }
    }
  });

  useEffect(() => {
    clearMessages();
    setLoaderMessage(null);
  }, [clearMessages, setLoaderMessage, id]);

  return null;
};

export default CopilotMessageSubscriptionHandler;
