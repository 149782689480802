import type { GeneralTimezone, TruentityDateInput } from '@/types/date';
import { toTimezoneDate } from '@/util/date';
import type { DurationInputArg1, MomentInput } from 'moment';
import moment from 'moment';
import { emptyString } from './string';

export function formatDate(date: MomentInput, format = 'MMM DD, YYYY') {
  if (!date) {
    return '';
  }
  return moment(date).format(format);
}

export function formatToUTCDate(date: MomentInput, format = 'MMM DD, YYYY') {
  if (!date) {
    return '';
  }
  return moment.utc(date).format(format);
}

export function formatDateIgnoreTZ(date: MomentInput, format = 'MMM DD, YYYY') {
  if (!date) {
    return '';
  }
  return moment.utc(date).format(format);
}

export function formatDateTimeIgnoreTZ(date: MomentInput, format = 'MMM DD, YYYY (h:mm:ss A)') {
  if (!date) {
    return '';
  }
  return moment.utc(date).format(format);
}

export function formatDateIgnoreTZAddDays(date: MomentInput, days: number, format = 'MMM DD, YYYY') {
  if (!date) {
    return '';
  }
  return moment.utc(date).add(days, 'days').format(format);
}

export function formatDateIgnoreTZRemoveDays(date: MomentInput, days: number, format = 'MMM DD, YYYY') {
  if (!date) {
    return '';
  }
  return moment.utc(date).subtract(days, 'days').format(format);
}

export function addMinutes(date: MomentInput, minutes: number) {
  return moment(date).add('minutes', minutes).toDate();
}

export function removeMinutes(date: MomentInput, minutes: number) {
  return moment(date).subtract('minutes', minutes);
}

export function formatDateAndTime(date: MomentInput) {
  if (!date) {
    return '';
  }
  return moment(date).format('MMM DD, YYYY (h:mm A)');
}

export function formatDateAndTime2(date: TruentityDateInput, time: TruentityDateInput, format = 'YYYY-MM-DD HH:mm') {
  if (date && time) {
    return moment(`${formatDate(date, 'YYYY-MM-DD')} ${time.toString()}`, format).format();
  }
  return emptyString();
}

export function formatTime(date: MomentInput, format = 'h:mm A') {
  return moment(date).format(format);
}

export function duration(seconds: DurationInputArg1) {
  const duration = moment.duration(seconds, 'seconds');

  if (seconds && seconds > 3600) {
    return (
      Math.floor(moment.duration(seconds, 'seconds').asHours()).toString() +
      ':' +
      moment.duration(seconds, 'seconds').minutes().toString() +
      ':' +
      moment.duration(seconds, 'seconds').seconds().toString() +
      ' hours'
    );
  }

  if (seconds && seconds > 60) {
    return (
      moment.duration(seconds, 'seconds').minutes().toString() + ':' + moment.duration(seconds, 'seconds').seconds().toString() + ' minutes'
    );
  }

  return duration.seconds().toString() + ' seconds';
}

export function secondsToTime(seconds) {
  return moment.utc(seconds * 1000).format('HH:mm:ss');
}

export function secondsToMinutes(seconds: number): string {
  return moment.utc(seconds * 1000).format('mm:ss');
}

export function toLocalDate(date: MomentInput, resetTime = false) {
  let localDate = moment(date).local();
  if (resetTime) {
    localDate = resetDateTime(localDate);
  }
  return localDate.toDate();
}

export function resetDateTime(date: MomentInput) {
  return moment(date).hours(0).minutes(0).seconds(0);
}

export function formatSecondsToHMS(seconds: number) {
  const duration = moment.duration(seconds, 'seconds');
  const hours: number = duration.hours();
  const minutes: number = duration.minutes();
  const remainingSeconds: number = duration.seconds();

  const formattedTime: string[] = [];

  if (hours > 0) {
    formattedTime.push(`${hours}h`);
  }

  if (minutes > 0) {
    formattedTime.push(`${minutes}m`);
  }

  if (remainingSeconds > 0 || formattedTime.length === 0) {
    formattedTime.push(`${remainingSeconds}s`);
  }

  return formattedTime.join(' ');
}

export function formatMsToDaysString(milliseconds: string) {
  const duration = moment.duration(milliseconds);
  const hours = duration.hours();
  const minutes = duration.minutes();

  const parts: string[] = [];

  if (hours > 0) {
    parts.push(`${hours} hour${hours > 1 ? 's' : ''}`);
  }
  if (minutes > 0) {
    parts.push(`${minutes} minute${minutes > 1 ? 's' : ''}`);
  }

  return parts.join(' ');
}

export function minutesToSeconds(minutes: number | null) {
  return moment.duration(minutes, 'minutes').asSeconds();
}

export function formatTimeToISOString(time) {
  const currentDate = moment().format('YYYY-MM-DD');
  const [minutes, seconds] = time.split(':');
  const currentHour = moment().format('HH'); // Get current hour in 24-hour format

  // Use Moment.js to format the date-time with the provided minutes, current hour, and seconds
  const formattedDateTime = moment(`${currentDate}T${currentHour}:${minutes}:${seconds}Z`).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

  return formattedDateTime;
}

export function getCurrentDate() {
  return moment();
}

export function startOfDay(date: moment.Moment) {
  return date.startOf('day');
}

export function endOfDay(date: moment.Moment) {
  return date.endOf('day');
}

export function isSameOrAfter(date, compareTo) {
  return moment(date).isSameOrAfter(compareTo, 'day');
}

export function isSame(date, compareTo) {
  return moment(date).isSame(compareTo, 'day');
}

export function isBefore(date, compareTo) {
  return moment(date).isBefore(compareTo, 'day');
}

export function titleCase(string) {
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
}

export function setSpecificTimeOnDate(hours, minutes, seconds) {
  return moment({ hour: hours, minute: minutes, seconds: seconds }).format('HH:mm');
}

export function checkIfCurrentMonth(startDate) {
  const startDateMoment = moment(startDate);
  return moment().isSame(startDateMoment, 'month');
}

type FormatDateToTimezoneInput = {
  date: MomentInput;
  timezone: GeneralTimezone;
  format?: string;
};

export function formatDateToTimezone({ date, timezone, format = 'MMM DD, YYYY' }: FormatDateToTimezoneInput) {
  try {
    const momentDate = toTimezoneDate({ date, timezone });
    return momentDate.format(format);
  } catch (error) {
    return '';
  }
}

export const formatPhoneNumber = input => {
  const cleaned = input?.replace(/\D/g, '')?.replace(/^1/, '');
  if (cleaned.length === 10) {
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    return match ? `(${match[1]}) ${match[2]}-${match[3]}` : null;
  }
  return null;
};

export const formatOnlyDateTime = (date: MomentInput, format = 'MMM DD, YYYY') => {
  return moment(date).clone().utcOffset(0, true).format(format);
};

export const formatEndOfDay = (date: MomentInput) => {
  return moment(date).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss[z]');
};

export const getSameDayOfPreviousMonth = (monthsAgo: number): string => {
  const date = moment().subtract(monthsAgo, 'months');
  return date.format('YYYY-MM-DD');
};
