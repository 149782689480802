import type { Announcement } from '@/types/announcement';
import { Box, Divider, ImageList, ImageListItem, Skeleton, Stack } from '@mui/material';
import parse from 'html-react-parser';
import { useState } from 'react';
import { Body1 } from './Typography';

const PrimaryAnnouncementBody = ({ announcement }: { announcement: Announcement }) => {
  const [imageLoaded, setImageLoaded] = useState(new Array(announcement.media?.length).fill(false));
  const handleMediaLoad = (index: number) => {
    const updatedLoaded = [...imageLoaded];
    updatedLoaded[index] = true;
    setImageLoaded(updatedLoaded);
  };

  const isVideo = (url: string) => /\.(mp4|webm|ogg)$/i.test(url);
  return (
    <Box maxHeight={'30rem'} overflow="auto" bgcolor="white" py={2} px={1} borderRadius={1}>
      <Box>
        <Body1>{parse(announcement.content)}</Body1>
      </Box>
      {Array.isArray(announcement.media) && announcement.media?.length > 0 && (
        <>
          <Divider />
          {announcement.media.length === 1 ? (
            (() => {
              const media = announcement.media[0];
              const isMediaVideo = isVideo(media.s3Key);

              return (
                <>
                  {!imageLoaded[0] && <Skeleton variant="rectangular" width="100%" height={158} animation="wave" />}
                  <Stack mt={2} alignItems="center">
                    {isMediaVideo ? (
                      <video
                        controls
                        width="90%"
                        onLoadedData={() => handleMediaLoad(0)}
                        style={{
                          display: imageLoaded[0] ? 'block' : 'none',
                          borderRadius: '8px'
                        }}
                        controlsList="nodownload"
                        onContextMenu={e => e.preventDefault()}
                      >
                        <source src={media.s3PresignedUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <Box
                        component="img"
                        src={media.s3PresignedUrl}
                        maxWidth="80%"
                        onLoad={() => handleMediaLoad(0)}
                        style={{
                          visibility: imageLoaded[0] ? 'visible' : 'hidden',
                          borderRadius: '8px'
                        }}
                      />
                    )}
                  </Stack>
                </>
              );
            })()
          ) : (
            <ImageList variant="masonry" gap={15} cols={2} sx={{ mt: 2 }}>
              {announcement.media.map((media, index) => {
                const isMediaVideo = isVideo(media.s3Key);

                return (
                  <ImageListItem key={media.s3Key}>
                    {!imageLoaded[index] && <Skeleton variant="rectangular" width="100%" height={158} animation="wave" />}
                    {isMediaVideo ? (
                      <video
                        controls
                        width="100%"
                        onLoadedData={() => handleMediaLoad(index)}
                        style={{
                          display: imageLoaded[index] ? 'block' : 'none',
                          borderRadius: '8px'
                        }}
                        controlsList="nodownload"
                        onContextMenu={e => e.preventDefault()}
                      >
                        <source src={media.s3PresignedUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <img
                        src={media.s3PresignedUrl}
                        alt={`announcement-image-${index + 1}`}
                        loading="lazy"
                        onLoad={() => handleMediaLoad(index)}
                        style={{
                          visibility: imageLoaded[index] ? 'visible' : 'hidden',
                          width: '100%',
                          borderRadius: '8px'
                        }}
                      />
                    )}
                  </ImageListItem>
                );
              })}
            </ImageList>
          )}
        </>
      )}
    </Box>
  );
};

export default PrimaryAnnouncementBody;
