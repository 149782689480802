import Button from '@/components/Button';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import InformationDialog from '@/components/Dialogs/InformationDialog';
import FilterPanel from '@/components/FilterPanel/FilterPanel';
import MuiMenu from '@/components/MuiMenu';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import { H4 } from '@/components/Typography';
import type { RpmPatientPrioritizationReportType } from '@/types/remotePatientMonitoring';
import { ReviewSubmitTypeEnum, RpmStatusTypes } from '@/types/remotePatientMonitoring';
import { formatDate, formatTime } from '@/util/format';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, CircularProgress, Stack, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useRef } from 'react';
import { CSVLink } from 'react-csv';
import { useRpmMyPatientContext } from './RpmMyPatientsProvider';

const RpmMyPatientsFilters = () => {
  const { enqueueSnackbar } = useSnackbar();
  const defaultMonthYear = useRef<string | null>(null);

  const {
    tabs: { tab, rpmPatientStatusTab },
    filters: { monthAndYear, setMonthAndYear, filteredPatientsByCharts, setFilteredPatientsByCharts, isCurrentMonth },
    showModal,
    selectionModel,
    refreshEnrollmentAccountsMutation: {
      refreshEnrolledAccountsSnapshots,
      refreshSnapshotLoading,
      reviewAccountsRpmReports,
      reviewRpmReportsLoading
    },
    reports: { patientPrioritizationReportData },
    accountsByRpmStatusQuery: { rpmAccountsByStatusQueryRefetch }
  } = useRpmMyPatientContext();

  const handleRemoveFilter = (filterKey: string) => {
    const filteredFilters = filteredPatientsByCharts.filter(filteredPatientsByChart => filteredPatientsByChart.filterBy !== filterKey);
    setFilteredPatientsByCharts(filteredFilters);
  };

  const handleRefreshButton = () => {
    refreshEnrolledAccountsSnapshots();
  };

  const submitRpmReportsForReview = async ({
    truentityIds,
    monthYear,
    reviewSubmitType
  }: {
    truentityIds: string[];
    monthYear: string;
    reviewSubmitType: ReviewSubmitTypeEnum;
  }) => {
    try {
      const response = await reviewAccountsRpmReports({
        variables: {
          truentityIds: truentityIds,
          reviewSubmitType: reviewSubmitType,
          monthYear: monthYear
        }
      });
      if (response?.data?.reviewAccountsRpmReports?.status === 'Success') {
        enqueueSnackbar(response?.data?.reviewAccountsRpmReports?.message ?? 'Patients RPM reports submitted successfully', {
          variant: 'success'
        });
      } else {
        const modal = showModal(InformationDialog, {
          title: (
            <Stack flexDirection="row" alignItems="center" justifyContent="flex-start">
              <ErrorOutlineIcon color="error" />
              <H4>&nbsp;Failed to Submit Patients RPM Reports</H4>
            </Stack>
          ),
          customTitleHeader: true,
          message: response?.data?.reviewAccountsRpmReports?.message ?? 'Failed to submit patients RPM reports',
          actions: [
            {
              name: 'Close',
              action: () => {
                modal.hide();
              }
            }
          ]
        });
      }
    } catch (error) {
      enqueueSnackbar('Failed to submit patients RPM reports', { variant: 'error' });
    }
  };

  const onSubmitRpmReportsAsReady = useCallback(() => {
    const modal = showModal(ConfirmDialog, {
      title: 'Confirm Submit RPM Reports as Ready',
      message: `This will submit the RPM reports for the selected patients for the month of ${monthAndYear} as ready to send. Are you sure you want to proceed?`,
      onAgree: () => {
        submitRpmReportsForReview({
          truentityIds: selectionModel.map(id => id.toString().split('-')[0]),
          monthYear: monthAndYear,
          reviewSubmitType: ReviewSubmitTypeEnum.READY_TO_SEND
        })
          .then(r => r)
          .catch(e => e);
        modal.hide();
      },
      onDisagree: () => {
        modal.hide();
      },
      fullWidth: true,
      maxWidth: 'md'
    });
  }, [showModal, selectionModel, monthAndYear, submitRpmReportsForReview]);

  const handleMonthYear = monthYear => {
    setMonthAndYear(formatTime(monthYear, 'MMM YYYY'));
  };

  const handleRefreshRpmAccountsByStatus = () => {
    rpmAccountsByStatusQueryRefetch();
  };

  const onSubmitRpmReportsSendNow = useCallback(() => {
    const modal = showModal(ConfirmDialog, {
      title: 'Confirm Instantly Submit RPM Reports For Review',
      message: `This will <b>instantly send</b> the RPM reports related to selected patients for the month of ${monthAndYear}. Are you sure you want to proceed?`,
      onAgree: () => {
        submitRpmReportsForReview({
          truentityIds: selectionModel.map(id => id.toString().split('-')[0]),
          monthYear: isCurrentMonth ? 'current' : monthAndYear,
          reviewSubmitType: ReviewSubmitTypeEnum.SEND_NOW
        })
          .then(r => r)
          .catch(e => e);
        modal.hide();
      },
      onDisagree: () => {
        modal.hide();
      },
      fullWidth: true,
      maxWidth: 'md'
    });
  }, [showModal, selectionModel, monthAndYear, isCurrentMonth, submitRpmReportsForReview]);

  const generateCsvData = useCallback((rpmPatients: RpmPatientPrioritizationReportType[]) => {
    return rpmPatients.map(patient => ({
      id: patient.id,
      patientName: patient.patientName,
      rpmPriorityScore: patient.rpmPriorityScore,
      phone: patient?.phone,
      dateOfBirth: formatDate(patient?.dateOfBirth),
      healthPlan: patient?.healthPlan
    }));
  }, []);

  return (
    <>
      <Stack sx={{ pt: 2 }}>
        {rpmPatientStatusTab[tab]?.path === 'priorityCandidate' && (
          <Stack
            flexDirection="row"
            justifyContent="end"
            alignItems="end"
            gap="0.5rem"
            sx={{
              width: '100%',
              height: 'auto',
              paddingY: 1
            }}
          >
            <Box>
              <Tooltip title="Refresh patient priority list">
                <Box>
                  <Button
                    title=""
                    variant="outlined"
                    startIcon={<RefreshIcon />}
                    onClick={handleRefreshRpmAccountsByStatus}
                    isLoading={refreshSnapshotLoading}
                  >
                    Refresh
                  </Button>
                </Box>
              </Tooltip>
            </Box>

            <Box>
              <Button color="primary" variant="contained" size="small">
                <CSVLink
                  headers={[
                    { label: 'Patient Name', key: 'patientName' },
                    { label: 'Phone', key: 'phone' },
                    { label: 'Priority Score', key: 'rpmPriorityScore' },
                    { label: 'Date of birth', key: 'dateOfBirth' },
                    { label: 'Health plan', key: 'healthPlan' }
                  ]}
                  data={generateCsvData(patientPrioritizationReportData)}
                  filename={'Prioritized patients exoport'}
                  style={{ textDecoration: 'none', color: '#fff' }}
                >
                  Export to CSV
                </CSVLink>
              </Button>
            </Box>
          </Stack>
        )}
        {rpmPatientStatusTab[tab]?.status === RpmStatusTypes.ENROLLED && (
          <Stack
            flexDirection="column"
            justifyContent="stretch"
            alignItems="stretch"
            sx={{
              width: '100%',
              height: 'auto',
              paddingY: 1
            }}
          >
            <Stack
              flexDirection={'row'}
              justifyContent={'flex-end'}
              alignItems={'center'}
              sx={{
                flex: 1,
                width: '100%',
                height: 'auto',
                pt: 0,
                pb: 2,
                px: 2
              }}
            >
              <Box>
                <TruentityDatePicker
                  showMonth={true}
                  showYear={true}
                  slotProps={{
                    actionBar: {
                      actions: ['accept']
                    }
                  }}
                  openTo="month"
                  views={['month', 'year']}
                  value={monthAndYear}
                  onChange={handleMonthYear}
                  defaultValue={defaultMonthYear.current}
                />
              </Box>
            </Stack>
            <Stack
              flexDirection={'row'}
              justifyContent={'flex-end'}
              alignItems={'center'}
              gap={2}
              sx={{
                flex: 1,
                width: '100%',
                height: 'auto',
                px: 2
              }}
            >
              <FilterPanel
                filters={filteredPatientsByCharts.map(filterByChart => filterByChart.filterBy)}
                handleRemoveFilter={handleRemoveFilter}
              />
              <Box>
                <Tooltip
                  title={
                    isCurrentMonth
                      ? 'Refreshing enrolled accounts will update and re-calculate the data, which may take some time to complete.'
                      : 'You cannot refresh accounts that are not in the current month.'
                  }
                >
                  <Box>
                    <Button
                      title=""
                      variant="outlined"
                      startIcon={<RefreshIcon />}
                      onClick={handleRefreshButton}
                      isLoading={refreshSnapshotLoading}
                      disabled={!isCurrentMonth}
                    >
                      Refresh
                    </Button>
                  </Box>
                </Tooltip>
              </Box>
              <Box sx={{ position: 'relative' }}>
                <MuiMenu
                  variant="contained"
                  disabled={reviewRpmReportsLoading || selectionModel?.length === 0}
                  label="RPM Reports"
                  options={[
                    {
                      label: 'Schedule for Later',
                      onAction: onSubmitRpmReportsAsReady,
                      disabled: isCurrentMonth
                    },
                    {
                      label: 'Send Now',
                      onAction: onSubmitRpmReportsSendNow,
                      disabled: !isCurrentMonth
                    }
                  ]}
                />
                {reviewRpmReportsLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px'
                    }}
                  />
                )}
              </Box>
            </Stack>
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default RpmMyPatientsFilters;
