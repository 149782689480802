import type { GetPatientDetailResponse } from '@/graphql/account';
import { GET_PATIENT_DETAIL, REFRESH_ENROLLED_ACCOUNTS_SNAPSHOT } from '@/graphql/account';
import type {
  GetAccountsByRpmStatusResponse,
  GetRpmAccountsCountByStatusResponse,
  GetRpmEnrolledAccountsResponse,
  ReviewAccountsRpmReportsResponse
} from '@/graphql/remotePatientMonitoring';
import {
  GET_RPM_ACCOUNTS_BY_RPM_STATUS,
  GET_RPM_ACCOUNTS_COUNT_BY_STATUS,
  GET_RPM_ENROLLED_ACCOUNTS,
  REVIEW_ACCOUNTS_RPM_REPORTS
} from '@/graphql/remotePatientMonitoring';
import useToken from '@/hooks/useToken';
import { Role } from '@/types/admin';
import type { KpiFilterType, RpmPatientPrioritizationReportType, RpmStatusTypeTab } from '@/types/remotePatientMonitoring';
import { RpmStatusTypes } from '@/types/remotePatientMonitoring';
import { formatTime, getCurrentDate } from '@/util/format';
import type { ApolloQueryResult, LazyQueryExecFunction, MutationFunction, OperationVariables } from '@apollo/client';
import { useLazyQuery, useMutation } from '@apollo/client';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import type { GridRowId, GridSortModel } from '@mui/x-data-grid-pro';
import type { ShowFn } from 'mui-modal-provider';
import { useModal } from 'mui-modal-provider';
import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';
import { getDefaultSortModels, rpmPatientStatusTabBase } from './RpmMyPatientsUtils';

/*
 * The following provider is used to store the shared props of the RpmMyPatients component
 * The GraphQL queries and mutations are also defined in this provider and then passed to the child components
 */
interface IRpmMyPatientsContext {
  tabs: {
    tab: number;
    setTab: (tab: number) => void;
    rpmPatientStatusTab: RpmStatusTypeTab[];
  };
  filters: {
    monthAndYear: string;
    setMonthAndYear: (value: string) => void;
    sortModel: GridSortModel;
    setSortModel: (sortModel: GridSortModel) => void;
    filteredPatientsByCharts: KpiFilterType[];
    setFilteredPatientsByCharts: Dispatch<SetStateAction<KpiFilterType[]>>;
    currentMonthYear: string;
    isCurrentMonth: boolean;
  };
  reports: {
    patientPrioritizationReportData: RpmPatientPrioritizationReportType[];
    setPatientPriotizationReportData: (reportData: RpmPatientPrioritizationReportType[]) => void;
  };
  refreshEnrollmentAccountsMutation: {
    refreshEnrolledAccountsSnapshots: () => void;
    refreshSnapshotLoading: boolean;
    reviewAccountsRpmReports: MutationFunction<ReviewAccountsRpmReportsResponse, OperationVariables>;
    reviewRpmReportsLoading: boolean;
  };
  rpmEnrolledAccountsQuery: {
    callGetRpmEnrolledAccounts: LazyQueryExecFunction<GetRpmEnrolledAccountsResponse, OperationVariables>;
    rpmEnrolledAccountsData: GetRpmEnrolledAccountsResponse | undefined;
    rpmEnrolledAccountsLoading: boolean;
    refetchEnrolledAccounts: () => Promise<ApolloQueryResult<GetRpmEnrolledAccountsResponse>>;
  };
  accountsByRpmStatusQuery: {
    callGetAccountByRpmStatus: LazyQueryExecFunction<GetAccountsByRpmStatusResponse, OperationVariables>;
    rpmAccountsByStatusQueryData: GetAccountsByRpmStatusResponse | undefined;
    rpmAccountsByStatusQueryLoading: boolean;
    rpmAccountsByStatusQueryRefetch: () => Promise<ApolloQueryResult<GetAccountsByRpmStatusResponse>>;
  };
  rpmCountByStatusQuery: {
    getRpmAccountsCountByStatus: LazyQueryExecFunction<GetRpmAccountsCountByStatusResponse, OperationVariables>;
  };
  patientDetailsQuery: {
    getPatientDetail: LazyQueryExecFunction<GetPatientDetailResponse, OperationVariables>;
  };
  unenrolledAccountsCount: number;
  setUnenrolledAccountsCount: (value: number) => void;
  selectionModel: GridRowId[];
  setSelectionModel: (value: GridRowId[]) => void;
  showModal: ShowFn;
}

const RpmMyPatientContext = createContext<IRpmMyPatientsContext | null>(null);

export const useRpmMyPatientContext = () => {
  const context = useContext(RpmMyPatientContext) as IRpmMyPatientsContext;
  if (!context) {
    throw new Error('useRpmMyPatientContext must be within a RpmPatientProvider');
  }
  return context;
};

export const RpmMyPatientsProvider = (props: { children: ReactNode }) => {
  const { children } = props;

  const { showModal } = useModal();
  const { roleType } = useToken();
  const isProviderAdmin = useMemo(() => roleType === Role.PROVIDER, [roleType]);

  const [tab, setTab] = useState<number>(isProviderAdmin ? 2 : 3);
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [filteredPatientsByCharts, setFilteredPatientsByCharts] = useState<KpiFilterType[]>([]);
  const [unenrolledAccountsCount, setUnenrolledAccountsCount] = useState<number>(0);
  const [sortModel, setSortModel] = useState<GridSortModel>(
    isProviderAdmin ? [getDefaultSortModels()[RpmStatusTypes.SCHEDULED_FOR_ENROLLMENT]] : [getDefaultSortModels()[RpmStatusTypes.ENROLLED]]
  );

  const [patientPrioritizationReportData, setPatientPriotizationReportData] = useState<RpmPatientPrioritizationReportType[]>([]);

  const currentMonthYear = useMemo(() => formatTime(getCurrentDate(), 'MMM YYYY'), []);

  const [monthAndYear, setMonthAndYear] = useState<string>(currentMonthYear);

  const isCurrentMonth = useMemo(() => monthAndYear === currentMonthYear, [currentMonthYear, monthAndYear]);

  const [getRpmAccountsCountByStatus] = useLazyQuery<GetRpmAccountsCountByStatusResponse>(GET_RPM_ACCOUNTS_COUNT_BY_STATUS, {
    fetchPolicy: 'cache-and-network'
  });

  const [
    callGetAccountByRpmStatus,
    { data: rpmAccountsByStatusQueryData, loading: rpmAccountsByStatusQueryLoading, refetch: rpmAccountsByStatusQueryRefetch }
  ] = useLazyQuery<GetAccountsByRpmStatusResponse>(GET_RPM_ACCOUNTS_BY_RPM_STATUS, {
    fetchPolicy: 'cache-and-network'
  });

  const [
    callGetRpmEnrolledAccounts,
    { data: rpmEnrolledAccountsData, loading: rpmEnrolledAccountsLoading, refetch: refetchEnrolledAccounts }
  ] = useLazyQuery<GetRpmEnrolledAccountsResponse>(GET_RPM_ENROLLED_ACCOUNTS, { fetchPolicy: 'cache-and-network' });

  const [getPatientDetail] = useLazyQuery<GetPatientDetailResponse>(GET_PATIENT_DETAIL);

  const [refreshEnrolledAccountsSnapshots, { loading: refreshSnapshotLoading }] = useMutation(REFRESH_ENROLLED_ACCOUNTS_SNAPSHOT, {
    onCompleted: () => refetchEnrolledAccounts()
  });

  const [reviewAccountsRpmReports, { loading: reviewRpmReportsLoading }] =
    useMutation<ReviewAccountsRpmReportsResponse>(REVIEW_ACCOUNTS_RPM_REPORTS);

  const rpmPatientStatusTab = useMemo(() => {
    const tabs = [...rpmPatientStatusTabBase];
    if (unenrolledAccountsCount > 0) {
      tabs.push({
        status: RpmStatusTypes.UNENROLLED,
        label: 'Unenrolled',
        path: 'unenrolled',
        icon: <GroupRemoveIcon />
      });
    }
    return tabs;
  }, [unenrolledAccountsCount]);

  return (
    <RpmMyPatientContext.Provider
      value={{
        tabs: {
          tab,
          setTab,
          rpmPatientStatusTab
        },
        filters: {
          sortModel,
          setSortModel,
          monthAndYear,
          setMonthAndYear,
          filteredPatientsByCharts,
          setFilteredPatientsByCharts,
          currentMonthYear,
          isCurrentMonth
        },
        refreshEnrollmentAccountsMutation: {
          refreshEnrolledAccountsSnapshots,
          refreshSnapshotLoading,
          reviewAccountsRpmReports,
          reviewRpmReportsLoading
        },
        rpmEnrolledAccountsQuery: {
          callGetRpmEnrolledAccounts,
          refetchEnrolledAccounts,
          rpmEnrolledAccountsData,
          rpmEnrolledAccountsLoading
        },
        accountsByRpmStatusQuery: {
          callGetAccountByRpmStatus,
          rpmAccountsByStatusQueryData,
          rpmAccountsByStatusQueryLoading,
          rpmAccountsByStatusQueryRefetch
        },
        rpmCountByStatusQuery: {
          getRpmAccountsCountByStatus
        },
        patientDetailsQuery: {
          getPatientDetail
        },
        reports: {
          patientPrioritizationReportData,
          setPatientPriotizationReportData
        },
        unenrolledAccountsCount,
        setUnenrolledAccountsCount,
        selectionModel,
        setSelectionModel,
        showModal
      }}
    >
      {children}
    </RpmMyPatientContext.Provider>
  );
};
