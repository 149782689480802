import type { DefaultFollowUpEditType } from '@/components/Dialogs/FollowUpTaskAddEditDialog';
import SelectList from '@/components/SelectList';
import { FollowUpCategoryTypes } from '@/graphql/account';
import { followUpTaskTypes } from '@/util/followUp';
import { Grid } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const TaskReviewFollowUpForm = () => {
  const { control } = useFormContext<DefaultFollowUpEditType>();

  const category = FollowUpCategoryTypes.CHART_REVIEW;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name={`type`}
            render={({ field: { onChange, value } }) => (
              <SelectList
                label="Intervention Type"
                options={followUpTaskTypes(category)}
                placeholder="Select an option..."
                value={value}
                keepOriginalValue
                onChange={onChange}
                required
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default TaskReviewFollowUpForm;
