import { BrushOutlined, HorizontalRule, Redo, StrikethroughS, Undo } from '@mui/icons-material';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import LinkIcon from '@mui/icons-material/Link';
import type { SvgIconTypeMap } from '@mui/material';
import { Box, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import { type Editor } from '@tiptap/react';
import type React from 'react';
import { useState } from 'react';
import TruentityTextEditorHyperlinkPopover from '../Popovers/TruentityTextEditorHyperlinkPopover';
import type { TextSelectOption } from '../SelectList';
import SelectList from '../SelectList';

export type EditorItemGroupNames = 'history' | 'list' | 'style' | 'extras' | 'heading';

type EditorToolbarProps = {
  editor: Editor | null;
  disabledGroups?: Array<EditorItemGroupNames>;
};

type ButtonGroup = {
  name: EditorItemGroupNames;
  group: Array<
    [
      string,
      string,
      string,
      OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
        muiName: string;
      }
    ]
  >;
};

const selectListOptions: TextSelectOption[] = [
  { label: 'Normal', value: 'normal' },
  { label: 'Heading 1', value: '1' },
  { label: 'Heading 2', value: '2' },
  { label: 'Heading 3', value: '3' },
  { label: 'Heading 4', value: '4' },
  { label: 'Heading 5', value: '5' },
  { label: 'Heading 6', value: '6' }
];

const buttonGroups: ButtonGroup[] = [
  {
    name: 'history',
    group: [
      ['Undo', 'undo', 'undo', Undo],
      ['Redo', 'redo', 'redo', Redo]
    ]
  },
  {
    name: 'style',
    group: [
      ['Bold', 'bold', 'toggleBold', FormatBoldIcon],
      ['Italic', 'italic', 'toggleItalic', FormatItalicIcon],
      ['Underline', 'underline', 'toggleUnderline', FormatUnderlinedIcon],
      ['Strikethrough', 'strike', 'toggleStrike', StrikethroughS]
    ]
  },
  {
    name: 'list',
    group: [
      ['Bullet List', 'bulletList', 'toggleBulletList', FormatListBulletedIcon],
      ['Numbered List', 'orderedList', 'toggleOrderedList', FormatListNumberedIcon]
    ]
  },
  {
    name: 'extras',
    group: [
      ['Highlight', 'highlight', 'toggleHighlight', BrushOutlined],
      ['Horizontal Line', 'horizontalRule', 'setHorizontalRule', HorizontalRule],
      ['Hyper Link', 'link', 'toggleLink', LinkIcon]
    ]
  }
];

const EditorToolbar = ({ editor, disabledGroups }: EditorToolbarProps) => {
  const [anchorPosition, setAnchorPosition] = useState<{ top: number; left: number } | null>(null);

  if (!editor) return null;

  const handleFormat = (event: React.MouseEvent<HTMLElement>, value: string) => {
    if (value === 'toggleLink') return handelLink();
    editor.chain().focus()[value]().run();
  };

  const insertLink = (url: string) => {
    try {
      if (url.length === 0) {
        editor.chain().focus().extendMarkRange('link').unsetLink().run();
        return;
      }
      editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message);
      }
    }
  };

  const handelLink = () => {
    if (editor.isActive('link')) {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();
      return;
    }
    const { view } = editor;
    const selection = window.getSelection();

    if (!selection || selection.anchorOffset === selection.focusOffset) return;

    const editorContentElement = view.dom;
    if (!editorContentElement.contains(selection.anchorNode)) return;

    const range = selection.getRangeAt(0);
    const rect = range.getBoundingClientRect();

    setAnchorPosition({ top: rect.top + window.scrollY, left: rect.left + window.scrollX });
  };

  const renderToggleButton = (
    title: string,
    name: string,
    value: string,
    IconComponent: OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
      muiName: string;
    }
  ) => {
    return (
      <Tooltip key={title} title={title}>
        <ToggleButton selected={editor.isActive(name)} value={value}>
          <IconComponent fontSize="small" />
        </ToggleButton>
      </Tooltip>
    );
  };

  const isGroupDisabled = (groupName: EditorItemGroupNames) => disabledGroups?.includes(groupName);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          mb: 0.5,
          pt: 0.5,
          px: 1,
          alignItems: 'center',
          bgcolor: 'white',
          borderRadius: 1,
          border: '1px solid #c4c4c4'
        }}
      >
        <SelectList
          size="small"
          value={editor.getAttributes('heading')?.level?.toString() || 'normal'}
          onChange={event => {
            let previousValue = editor.getAttributes('heading')?.level;
            const value = event.target.value as string;
            const level = value === 'normal' ? previousValue : parseInt(value);
            previousValue = level;

            editor.chain().focus().toggleHeading({ level }).run();
          }}
          options={selectListOptions}
          displayEmpty
          sx={{ mr: 2 }}
          formControlProps={{ sx: { maxWidth: '12.5rem' } }}
          disabled={isGroupDisabled('heading')}
        />

        {buttonGroups.map((item, index) => (
          <ToggleButtonGroup key={index} size="small" onChange={handleFormat} disabled={isGroupDisabled(item.name)} exclusive>
            {item.group.map(([title, name, value, Icon]) => renderToggleButton(title, name, value, Icon))}
          </ToggleButtonGroup>
        ))}
      </Box>
      <TruentityTextEditorHyperlinkPopover anchorPosition={anchorPosition} setAnchorPosition={setAnchorPosition} insertLink={insertLink} />
    </>
  );
};

export default EditorToolbar;
