import TruentityButtonDatePicker from '@/components/TruentityButtonDatePicker';
import { H1, H3, H5 } from '@/components/Typography';
import { GET_TASK_STAT } from '@/graphql/taskEncounter';
import type { GetTaskByFilterStatsResponse } from '@/types/tasks';
import { getFirstDateOfCurrentMonth, getLstDateOfCurrentMonth } from '@/util/date';
import { formatDate } from '@/util/format';
import { useLazyQuery } from '@apollo/client';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { Box, IconButton, Paper, Skeleton, Stack } from '@mui/material';
import { type DatePickerProps, type DateView } from '@mui/x-date-pickers-pro';
import type { Moment } from 'moment';
import moment from 'moment';
import { useCallback, useMemo, useState, type ReactNode } from 'react';
import type { FormType } from '../Billing';

type BillingStatProp = {
  type: 'MONTH' | 'DATE';
  statItems: {
    title: string;
    type: 'BILLED' | 'BONUS';
    icon: ReactNode;
    color?: string;
  }[];
  datePickerProps?: Partial<DatePickerProps<Moment>>;
  status: string[];
};

type PerformedDateType = Pick<FormType, 'performedOnEndDate' | 'performedOnStartDate'>;

const BillingStatItem = ({ type, statItems, datePickerProps, status }: BillingStatProp) => {
  const USDollar = new Intl.NumberFormat('en-Us', { style: 'currency', currency: 'USD' });
  const datePickerView: DateView[] | undefined = type === 'MONTH' ? ['year', 'month'] : undefined;

  const [billingStats, setBillingStats] = useState<(BillingStatProp['statItems'][number] & Partial<{ value: number }>)[]>(statItems);

  const [performedDates, setPerformedDates] = useState<PerformedDateType>({
    performedOnStartDate:
      type === 'DATE' ? formatDate(moment(), 'YYYY-MM-DD') : formatDate(getFirstDateOfCurrentMonth(moment()), 'YYYY-MM-DD'),
    performedOnEndDate: type === 'DATE' ? formatDate(moment(), 'YYYY-MM-DD') : formatDate(getLstDateOfCurrentMonth(moment()), 'YYYY-MM-DD')
  });

  const [triggerBillingStat, { loading: isBillingStatLoading }] = useLazyQuery<GetTaskByFilterStatsResponse>(GET_TASK_STAT, {
    onCompleted(data) {
      if (data) {
        setBillingStats(prevState =>
          prevState.map(item => ({
            ...item,
            value:
              item.type === 'BILLED'
                ? data.taskStatsByFilter.totalBilledAmount || 0
                : item.type === 'BONUS'
                ? data.taskStatsByFilter.totalBonusAmount || 0
                : 0
          }))
        );
      }
    }
  });

  const titleSubstring = useMemo(() => {
    const format: Record<typeof type, string> = {
      MONTH: 'MMMM',
      DATE: 'Do'
    };
    return formatDate(performedDates.performedOnEndDate, format[type]) ?? '';
  }, [performedDates.performedOnEndDate, type]);

  const handlePerformedDate = useCallback((date: Moment | null, type: BillingStatProp['type']) => {
    if (!date) return;

    setPerformedDates({
      performedOnStartDate: type === 'DATE' ? formatDate(date, 'YYYY-MM-DD') : formatDate(getFirstDateOfCurrentMonth(date), 'YYYY-MM-DD'),
      performedOnEndDate: type === 'DATE' ? formatDate(date, 'YYYY-MM-DD') : formatDate(getLstDateOfCurrentMonth(date), 'YYYY-MM-DD')
    });
  }, []);

  return (
    <Box sx={{ gridColumn: 'span 6' }}>
      <Box component={Paper} p={2} bgcolor="grey.50" elevation={0}>
        <Stack direction="row" mb={2} justifyContent="space-between" alignItems="center">
          <H3>{titleSubstring} Totals</H3>
          <Stack direction="row" gap={0.5}>
            <TruentityButtonDatePicker
              value={moment(performedDates.performedOnStartDate as Moment)}
              onChange={value => handlePerformedDate(value, type)}
              iconProps={{ fontSize: 'small' }}
              datePickerProps={{
                views: datePickerView,
                disableFuture: true,
                openTo: 'month',
                ...datePickerProps
              }}
            />
            <IconButton
              size="small"
              onClick={() =>
                triggerBillingStat({
                  variables: {
                    filterOptions: {
                      performedOnStartDate: performedDates.performedOnStartDate,
                      performedOnEndDate: performedDates.performedOnEndDate,
                      status: status
                    }
                  }
                })
              }
            >
              <PlayCircleFilledWhiteIcon color="primary" />
            </IconButton>
          </Stack>
        </Stack>
        <Stack direction="row" gap={2}>
          {billingStats.map(({ icon, value, title, color }) =>
            isBillingStatLoading ? (
              <Skeleton variant="rounded" animation="wave" width="100%" height="100px" />
            ) : (
              <Stack flex={1} sx={{ backgroundColor: color ?? 'white' }} gap={2} borderRadius={4} p={2}>
                <Stack direction="row" gap={1} alignItems="center">
                  {icon}
                  <H5>{title}</H5>
                </Stack>
                <H1>{USDollar.format(value ?? 0)}</H1>
              </Stack>
            )
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default BillingStatItem;
