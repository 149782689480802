import Button from '@/components/Button';
import type { GetRelyingPartyAdminNotificationType } from '@/graphql/notifications';
import { GET_RELYING_PARTY_ADMIN_NOTIFICATIONS, READ_ALL_NOTIFICATIONS, READ_NOTIFICATION } from '@/graphql/notifications';
import useMessages from '@/hooks/useMessages';
import { color } from '@/styles/assets/colors';
import type { NotificationReadStatusType, NotificationType } from '@/types/notifications';
import { NotificationTypeEnum } from '@/types/notifications';
import { getAccountUserFullName } from '@/util/account';
import { formatDateAndTime } from '@/util/format';
import { useMutation, useQuery } from '@apollo/client';
import { BlockRounded } from '@mui/icons-material';
import CircleIcon from '@mui/icons-material/Circle';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { Box, CircularProgress, Divider, List, ListItem, ListItemAvatar, ListItemText, Popover, Stack } from '@mui/material';
import parse from 'html-react-parser';
import { useMemo, useState, type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProfileAvatar } from '../ProfileAvatar';
import { H4, Small } from '../Typography';

interface NotificationsPopoverProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  open: boolean;
}

const NOTIFICATION_SIZE = 8;

export const NotificationsPopover: FC<NotificationsPopoverProps> = ({ anchorEl, onClose, open }) => {
  const navigate = useNavigate();
  const { setIsMessageDrawerOpen } = useMessages();

  const [isModalOpen, setIsModalOpen] = useState(open);
  const [notificationReadStatus, setNotificationReadStatus] = useState<NotificationReadStatusType>('UNREAD');

  const {
    data: adminNotifications,
    loading: isAdminNotificationLoading,
    refetch: refetchAdminNotification
  } = useQuery<GetRelyingPartyAdminNotificationType>(GET_RELYING_PARTY_ADMIN_NOTIFICATIONS, {
    variables: {
      pageNum: 1,
      pageSize: NOTIFICATION_SIZE,
      readStatus: notificationReadStatus
    },
    fetchPolicy: 'cache-and-network',
    skip: !isModalOpen
  });

  const [triggerReadAllNotification] = useMutation(READ_ALL_NOTIFICATIONS, { onCompleted: () => refetchAdminNotification() });
  const [triggerReadNotification] = useMutation(READ_NOTIFICATION);

  const notifications = useMemo(
    () => adminNotifications?.relyingPartyAdminNotifications?.notifications || [],
    [adminNotifications?.relyingPartyAdminNotifications?.notifications]
  );

  const handleNotificationClick = async (notification: NotificationType) => {
    if (notification.notificationType === NotificationTypeEnum.MESSAGE) {
      setIsMessageDrawerOpen(true);
      navigate(`/patients/${notification?.account.truentityId}/details/medications/triage/list`);
    } else if (notification.notificationType === NotificationTypeEnum.FOLLOWUP) {
      navigate(`/remote-patient-monitoring/follow-up-tasks`);
    }
    onClose();
    await triggerReadNotification({
      variables: {
        notificationId: notification.id
      }
    });
  };

  const handleReadAllNotifications = async () => await triggerReadAllNotification();

  const onViewAllConversationsClick = () => {
    onClose();
    navigate('/patients/conversations');
  };

  const onViewAllNotifications = () => {
    onClose();
    navigate('/notifications');
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      marginThreshold={20}
      slotProps={{
        paper: {
          elevation: 4,
          sx: {
            width: 450,
            backgroundColor: 'white'
          }
        }
      }}
      onClose={() => {
        onClose();
        setNotificationReadStatus('UNREAD');
      }}
      open={open}
      onTransitionEnd={() => setIsModalOpen(open)}
    >
      <Stack p={2} pt={3} pr={0} gap={2}>
        <Stack direction="row" justifyContent="space-between" pr={2}>
          <H4>Your Notifications</H4>
          <Button
            color="primary"
            sx={{
              textTransform: 'none'
            }}
            variant="text"
            startIcon={<DoneAllIcon fontSize="inherit" />}
            onClick={() => handleReadAllNotifications()}
            disabled={notificationReadStatus === 'READ'}
          >
            Mark all as read
          </Button>
        </Stack>

        <Box width="100%">
          <Divider />
        </Box>
        <Box pr={0.5} sx={{ maxHeight: 320, minHeight: 100, overflowY: 'auto' }}>
          {isAdminNotificationLoading || notifications?.length === 0 ? (
            <Stack p={2} direction="row" alignItems="center" justifyContent="center" minHeight="inherit" gap={1}>
              {isAdminNotificationLoading ? (
                <CircularProgress />
              ) : notifications?.length === 0 ? (
                <>
                  <BlockRounded fontSize="small" />
                  <H4>There are no notifications</H4>
                </>
              ) : null}
            </Stack>
          ) : (
            <>
              <List disablePadding>
                {notifications?.map(notification => {
                  const isRead = notification.isRead;

                  return (
                    <ListItem
                      key={notification.id}
                      sx={{
                        my: 0.5,
                        cursor: 'pointer',
                        borderRadius: '.625rem',
                        backgroundColor: isRead ? color.grey100 : color.indigo50,
                        transition: 'ease .3s',
                        ':hover': {
                          backgroundColor: isRead ? '' : color.deepPurple50
                        }
                      }}
                      onClick={() => handleNotificationClick(notification)}
                    >
                      <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2} width="100%">
                        <Stack flex={1} direction="row" justifyContent="flex-start" gap={1.5}>
                          <ListItemAvatar sx={{ minWidth: 'initial' }}>
                            <ProfileAvatar
                              avatarUrl={notification?.createdByAdmin?.presignedAvatarUrl || '#'}
                              name={getAccountUserFullName(notification?.createdByAdmin?.user || notification?.account?.user)}
                              sx={{ bgcolor: color.primaryMain, width: 35, height: 35, fontSize: 'small' }}
                            />
                          </ListItemAvatar>
                          <Stack flex={1}>
                            <ListItemText primary={parse(notification.message)} />
                            <Small sx={{ alignSelf: 'flex-end' }}>{formatDateAndTime(notification.updatedAt)}</Small>
                          </Stack>
                        </Stack>
                        {!isRead ? <CircleIcon color="primary" sx={{ fontSize: '.9375rem' }} /> : null}
                      </Stack>
                    </ListItem>
                  );
                })}
              </List>
            </>
          )}
        </Box>
        <Divider />
        <Stack direction="row" justifyContent="flex-end" alignItems="center" pr={2} gap={2}>
          <Button type="button" variant="outlined" onClick={onViewAllConversationsClick} color="primary" size="small">
            All Conversations
          </Button>
          <Button type="button" variant="contained" onClick={onViewAllNotifications} color="primary" size="small">
            All notifications
          </Button>
        </Stack>
      </Stack>
    </Popover>
  );
};
