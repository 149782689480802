import { isValidUrl } from '@/util/string';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Paper, Popover, Stack, TextField } from '@mui/material';
import type { Dispatch, SetStateAction } from 'react';
import { useMemo, useState, type FC } from 'react';

type Props = {
  anchorPosition: { top: number; left: number } | null;
  setAnchorPosition: Dispatch<
    SetStateAction<{
      top: number;
      left: number;
    } | null>
  >;
  insertLink: (url: string) => void;
};

const TruentityTextEditorHyperlinkPopover: FC<Props> = ({ anchorPosition, setAnchorPosition, insertLink }) => {
  const [link, setLink] = useState<string>('');

  const isDisableAddHyperlink = useMemo(() => link.length === 0 || !isValidUrl(link), [link]);

  const handleClose = () => {
    setAnchorPosition(null);
    setLink('');
  };

  const handleLink = () => {
    handleClose();
    insertLink(link);
  };

  return (
    <Popover
      id={`${Boolean(anchorPosition) ? 'hyperlink-popover' : undefined}`}
      open={Boolean(anchorPosition)}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition ?? { top: 0, left: 0 }}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
    >
      <Stack component={Paper} direction="row" bgcolor="white" p={1} minWidth="21.875rem">
        <TextField size="small" value={link} onChange={event => setLink(event.currentTarget.value)} fullWidth />
        <Stack direction="row">
          <IconButton size="small" color="success" onClick={handleLink} disabled={isDisableAddHyperlink}>
            <CheckIcon fontSize="small" />
          </IconButton>
          <IconButton size="small" color="warning" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Stack>
      </Stack>
    </Popover>
  );
};

export default TruentityTextEditorHyperlinkPopover;
