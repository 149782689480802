import CollapsibleListGroup from '@/components/CollapsibleListGroup';
import ListItemNavLink from '@/components/ListItemNavLink';
import FiltersCollection from '@/components/PatientFilters/FiltersCollection';
import { Body1, H5 } from '@/components/Typography';
import useShowMedicalServices from '@/hooks/useShowMedicalServices';
import useToken from '@/hooks/useToken';
import { MedicalServices, Role } from '@/types/admin';
import { isProd } from '@/util/environment';
import { pathnameExcludes, pathnameIncludes, pathnameIncludesAny } from '@/util/location';
import type { MainMenuItem } from '@/zustand/AppFrameStore';
import { MainMenuKeys, useAppFrameStore } from '@/zustand/AppFrameStore';
import AssessmentIcon from '@mui/icons-material/Assessment';
import UserGroup from '@mui/icons-material/Group';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, CssBaseline, List, Stack } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import type { NavigateFunction } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

type MenuItemsProps = {
  items: MainMenuItem[];
  navigate: NavigateFunction;
};
const MenuItems = (props: MenuItemsProps) => {
  const { navigate, items } = props;
  return (
    <>
      <CssBaseline />
      {items.map(item => (
        <ListItemNavLink
          key={item.label}
          to={item.navigatePath}
          icon={item.icon}
          title={item.label}
          onClick={() => {
            navigate(item.navigatePath);
          }}
          listItemButtonProps={item.buttonProps}
        />
      ))}
    </>
  );
};

const PrimaryListHeader = ({ label }) => <H5 color={'inherit'}>{label}</H5>;

const MainSideMenu = () => {
  const { mainMenuItems } = useAppFrameStore();
  const navigate = useNavigate();
  const { roleType } = useToken();
  const isPharmacist = useMemo(() => roleType === Role.PHARMACIST, [roleType]);
  const isProvider = useMemo(() => roleType === Role.PROVIDER, [roleType]);
  const isRPMViewable = useShowMedicalServices(MedicalServices.RPM);
  const isMTMViewable = useShowMedicalServices(MedicalServices.MTM);
  const isTCMViewable = useShowMedicalServices(MedicalServices.TCM);
  const [recentPatients, setRecentPatients] = useState<MainMenuItem[]>([]);

  const isAnyAdmin = useMemo(
    () => roleType && [Role.SUPER, Role.PHARMACIST_ADMIN, Role.ADT_ADMIN, Role.ADMIN].includes(roleType as Role),
    [roleType]
  );

  const userAdminMenu = useMemo(() => mainMenuItems.get(MainMenuKeys.UserAdmin)?.options || [], [mainMenuItems]);
  const adminInvoicesMenu = useMemo(() => mainMenuItems.get(MainMenuKeys.TimesheetsInvoicesAdmin)?.options || [], [mainMenuItems]);

  useEffect(() => {
    if (mainMenuItems) {
      const section = mainMenuItems.get(MainMenuKeys.RecentPatients);
      if (section) {
        setRecentPatients(section.options);
      }
    }
  }, [mainMenuItems]);

  return (
    <Stack direction="row" height={'100%'}>
      <List component="nav" disablePadding={true} key={window.location.pathname}>
        <CollapsibleListGroup
          primaryText={<PrimaryListHeader label="Patients" />}
          defaultOpen={pathnameIncludes('/patients') && pathnameExcludes('remote-patient-monitoring')}
          my={2}
        >
          {recentPatients?.length > 0 && (
            <CollapsibleListGroup
              primaryText={
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                  <RecentActorsIcon fontSize={'small'} />
                  <Body1 color={'inherit'}>Recent Patients</Body1>
                </Stack>
              }
              defaultOpen={true}
            >
              <MenuItems items={recentPatients} navigate={navigate} />
            </CollapsibleListGroup>
          )}

          <MenuItems navigate={navigate} items={mainMenuItems.get(MainMenuKeys.Patients)?.options || []} />
          {!isRPMViewable && !isProd() && <FiltersCollection selected={pathnameIncludes('patients/list')} />}
        </CollapsibleListGroup>

        {isMTMViewable && (
          <CollapsibleListGroup
            primaryText={<PrimaryListHeader label="Medication Management" />}
            defaultOpen={pathnameIncludesAny(['/tasks', '/reports/my-dashboard'])}
          >
            <MenuItems navigate={navigate} items={mainMenuItems.get(MainMenuKeys.MedicationManagement)?.options || []} />
          </CollapsibleListGroup>
        )}

        {isRPMViewable && (
          <CollapsibleListGroup
            primaryText={<PrimaryListHeader label="Remote Patient Monitoring" />}
            defaultOpen={pathnameIncludes('/remote-patient-monitoring')}
          >
            <MenuItems navigate={navigate} items={mainMenuItems.get(MainMenuKeys.RemotePatientMonitoring)?.options || []} />
          </CollapsibleListGroup>
        )}

        {isAnyAdmin && isTCMViewable && (
          <CollapsibleListGroup
            primaryText={<PrimaryListHeader label="Transitional Care Management" />}
            defaultOpen={pathnameIncludes('/transitional-care-management')}
          >
            <MenuItems items={mainMenuItems.get(MainMenuKeys.TransitionalCareManagement)?.options || []} navigate={navigate} />
          </CollapsibleListGroup>
        )}

        {(isAnyAdmin || isMTMViewable || isRPMViewable || isProvider) && (
          <>
            <CollapsibleListGroup
              primaryText={<PrimaryListHeader label="Administration" />}
              defaultOpen={pathnameIncludesAny([
                '/reports/dashboard',
                '/reports/billing',
                '/reports/encounters',
                '/reports/claims',
                '/reports/tasks',
                '/reports/health-plan',
                '/reports/performance',
                '/reports/rpm-billing',
                '/reports/rpm-providers',
                '/reports/rpm-companies',
                '/reports/rpm-chart-reviews',
                '/administration/dashboard',
                '/administration/users',
                '/administration/organizations',
                '/administration/health-plans',
                '/administration/providers',
                '/administration/my-performance',
                '/administration/timesheets',
                '/administration/invoices',
                '/administration/logs',
                '/administration/resources',
                '/administration/announcements',
                '/administration/mock-apis',
                '/administration/system/company',
                '/administration/system/companies',
                '/administration/system/notification-files'
              ])}
            >
              {(isAnyAdmin || isProvider) && (
                <>
                  <CollapsibleListGroup
                    primaryText="Reports"
                    icon={<AssessmentIcon />}
                    defaultOpen={pathnameIncludesAny(['/administration/', '/reports/'])}
                  >
                    <List>
                      <Box sx={{ paddingLeft: 2 }}>
                        <MenuItems items={mainMenuItems.get(MainMenuKeys.Reports)?.options || []} navigate={navigate} />
                      </Box>
                    </List>
                  </CollapsibleListGroup>
                </>
              )}
              {(isAnyAdmin || (isPharmacist && (userAdminMenu.length > 0 || adminInvoicesMenu.length > 0))) && (
                <CollapsibleListGroup primaryText="Users" icon={<UserGroup />} defaultOpen={pathnameIncludes('/administration')}>
                  <List>
                    <Box sx={{ paddingLeft: 2 }}>
                      <MenuItems navigate={navigate} items={userAdminMenu} />
                      <MenuItems navigate={navigate} items={adminInvoicesMenu} />
                    </Box>
                  </List>
                </CollapsibleListGroup>
              )}
              {isAnyAdmin && (
                <>
                  <MenuItems navigate={navigate} items={mainMenuItems.get(MainMenuKeys.Administration)?.options || []} />

                  <CollapsibleListGroup
                    primaryText="System"
                    icon={<SettingsIcon />}
                    defaultOpen={pathnameIncludesAny(['/system/notification-files', '/administration/system/companies'])}
                  >
                    <List>
                      <Box sx={{ paddingLeft: 2 }}>
                        <MenuItems navigate={navigate} items={mainMenuItems.get(MainMenuKeys.SystemAdmin)?.options || []} />
                        <MenuItems navigate={navigate} items={mainMenuItems.get(MainMenuKeys.SystemCompany)?.options || []} />
                      </Box>
                    </List>
                  </CollapsibleListGroup>
                </>
              )}
              {!isAnyAdmin && (
                <MenuItems navigate={navigate} items={mainMenuItems.get(MainMenuKeys.TimesheetsInvoicesAdmin)?.options || []} />
              )}
              <MenuItems navigate={navigate} items={mainMenuItems.get(MainMenuKeys.QuickLinksAdmin)?.options || []} />
            </CollapsibleListGroup>
          </>
        )}
      </List>
    </Stack>
  );
};

export default MainSideMenu;
