import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import type { TabContent } from '@/components/MuiTabs';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import { H1 } from '@/components/Typography';
import type { GetRpmMonthlyReviewAccountsResponse, GetRpmMonthlyReviewReportsCountsResponse } from '@/graphql/remotePatientMonitoring';
import { GET_MONTHLY_REVIEW_RPM_REPORTS, GET_MONTHLY_REVIEW_RPM_REPORTS_COUNTS } from '@/graphql/remotePatientMonitoring';
import type { RpmReportsType } from '@/types/remotePatientMonitoring';
import { RpmReportReviewTypeEnum } from '@/types/remotePatientMonitoring';
import { getAccountUserFullName } from '@/util/account';
import { formatDate, formatDateAndTime, formatTime, getSameDayOfPreviousMonth } from '@/util/format';
import { unknown } from '@/util/string';
import { useAccountStore } from '@/zustand/AccountStore';
import { useQuery } from '@apollo/client';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import QueueIcon from '@mui/icons-material/Queue';
import SendIcon from '@mui/icons-material/Send';
import { Box, Chip, Paper, Stack, Tab, Tabs } from '@mui/material';
import type { GridColDef, GridColumnVisibilityModel, GridSortModel } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import type React from 'react';
import type { SyntheticEvent } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

const reviewAccountsTabs: TabContent[] = [
  {
    key: RpmReportReviewTypeEnum.PENDING,
    label: 'Pending',
    icon: <QueueIcon />
  },
  {
    key: RpmReportReviewTypeEnum.IN_PROGRESS,
    label: 'In Progress',
    icon: <CalendarTodayIcon />
  },
  {
    key: RpmReportReviewTypeEnum.SIGNED_OFF,
    label: 'Signed Off',
    icon: <SendIcon />
  }
];

const columnVisibility: Record<RpmReportReviewTypeEnum, Record<string, boolean>> = {
  PENDING: {
    patientName: true,
    numCriticalAlerts: true,
    signOffAt: false
  },
  IN_PROGRESS: {
    patientName: true,
    numCriticalAlerts: true,
    signOffAt: false
  },
  SIGNED_OFF: {
    patientName: true,
    numCriticalAlerts: true,
    signOffAt: true
  }
};

export const RpmReviewPatients: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { setPatientReviewWorkflowMonthYear } = useAccountStore();
  const previousMonthAndYear = useMemo(() => formatTime(getSameDayOfPreviousMonth(1), 'MMM YYYY'), []);

  const [activeTab, setActiveTab] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(columnVisibility.PENDING);
  const [monthAndYear, setMonthAndYear] = useState<string>(previousMonthAndYear);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const activeTabKey = useMemo(() => reviewAccountsTabs[activeTab].key, [activeTab]);

  const { data: rpmMonthlyReportData, loading: isRpmMonthlyReportDataLoading } = useQuery<GetRpmMonthlyReviewAccountsResponse>(
    GET_MONTHLY_REVIEW_RPM_REPORTS,
    {
      variables: {
        filterOptions: {
          reviewStatus: activeTabKey,
          filterRpmReportMonthYear: monthAndYear
        },
        pageNum: currentPage + 1,
        pageSize: DEFAULT_PAGE_SIZE,
        monthYear: monthAndYear
      },
      onError(error) {
        const errorMessage = error?.message ?? 'Something went wrong, failed to retrieve Review Patients. Please try again later.';
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      fetchPolicy: 'cache-and-network',
      skip: !activeTabKey
    }
  );

  const { data: rpmMonthlyReportsCountsData, loading: rpmMonthlyReportCountsLoading } = useQuery<GetRpmMonthlyReviewReportsCountsResponse>(
    GET_MONTHLY_REVIEW_RPM_REPORTS_COUNTS,
    {
      variables: {
        filterOptions: {
          filterRpmReportMonthYear: monthAndYear
        }
      },
      onError(error) {
        const errorMessage = error?.message ?? 'Something went wrong, failed to retrieve Review Patients counts. Please try again later.';
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  const columns: GridColDef<RpmReportsType>[] = useMemo(() => {
    return [
      {
        field: 'patientName',
        headerName: 'Patient Name',
        flex: 2,
        minWidth: 135,
        sortable: true,
        valueGetter: ({ row: { account } }) => getAccountUserFullName(account.user),
        renderCell: ({ row: report, value }) => (
          <Link
            to={`/patients/${report.account?.truentityId}/details/rpm/reports`}
            onClick={() => setPatientReviewWorkflowMonthYear(report?.monthYear ?? null)}
          >
            {value}
          </Link>
        )
      },
      {
        field: 'numCriticalAlerts',
        headerName: 'Num of Critical Alerts',
        sortable: true,
        headerAlign: 'center',
        align: 'center',
        flex: 2,
        minWidth: 110,
        valueGetter: ({ row: { account } }) => account.criticalAlertCount || 0
      },
      {
        field: 'monthYear',
        headerName: 'Report Month And Year',
        sortable: true,
        headerAlign: 'center',
        align: 'center',
        flex: 2,
        minWidth: 110
      },
      {
        field: 'dateOfBirth',
        headerName: 'Date of Birth',
        sortable: true,
        flex: 2,
        align: 'center',
        headerAlign: 'center',
        valueGetter: ({ row: { account } }) => formatDate(account.birthDate)
      },
      {
        field: 'signOffAt',
        headerName: 'Signed Off At',
        sortable: true,
        flex: 2,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => formatDateAndTime(params?.row?.rpmAccountReviewWorkflow?.signOffAt)
      },
      {
        field: 'healthPlan',
        headerName: 'Health Plan',
        sortable: true,
        flex: 2,
        minWidth: 110,
        width: 110,
        align: 'center',
        headerAlign: 'center',
        valueGetter: ({ row: { account } }) => (account.healthPlan ? account.healthPlan.orgName : unknown())
      }
    ];
  }, [setPatientReviewWorkflowMonthYear]);

  const getTabCount = (key: RpmReportReviewTypeEnum) => {
    if (key === RpmReportReviewTypeEnum.PENDING) {
      return rpmMonthlyReportsCountsData?.getMonthlyReviewRpmReportsCounts?.pendingCount ?? 0;
    } else if (key === RpmReportReviewTypeEnum.IN_PROGRESS) {
      return rpmMonthlyReportsCountsData?.getMonthlyReviewRpmReportsCounts?.inProgressCount ?? 0;
    } else if (key === RpmReportReviewTypeEnum.SIGNED_OFF) {
      return rpmMonthlyReportsCountsData?.getMonthlyReviewRpmReportsCounts?.signedOffCount ?? 0;
    } else {
      return 0;
    }
  };

  const handleMonthYear = monthYear => {
    setMonthAndYear(formatTime(monthYear, 'MMM YYYY'));
  };

  const handleTabChange = useCallback((_event: SyntheticEvent, selectedTabIndex: number): void => {
    setActiveTab(selectedTabIndex);
  }, []);

  useEffect(() => {
    if (activeTabKey) {
      setColumnVisibilityModel(columnVisibility[activeTabKey ?? RpmReportReviewTypeEnum.PENDING]);
      setCurrentPage(0);
    }
  }, [activeTabKey]);

  return (
    <Stack spacing={2}>
      <Paper component={Stack} padding={2} elevation={0} direction="row" justifyContent="space-between" alignItems="center">
        <H1>Chart Review</H1>
        <Box>
          <TruentityDatePicker
            showMonth={true}
            showYear={true}
            slotProps={{
              actionBar: {
                actions: ['accept']
              }
            }}
            openTo="month"
            views={['month', 'year']}
            value={monthAndYear}
            onChange={handleMonthYear}
            defaultValue={previousMonthAndYear}
            disableFuture
          />
        </Box>
      </Paper>
      <Paper component={Stack} padding={2} elevation={0}>
        <Box mb={3}>
          <Tabs value={activeTab} onChange={handleTabChange} variant="standard">
            {reviewAccountsTabs?.map(tab => (
              <Tab
                label={
                  <Stack flexDirection="row" alignItems="center">
                    <h6 style={{ marginBottom: 0 }}>{tab.label}</h6>
                    {!rpmMonthlyReportCountsLoading && (
                      <Chip sx={{ marginLeft: 1 }} label={getTabCount(tab.key as RpmReportReviewTypeEnum)} />
                    )}
                  </Stack>
                }
                key={tab?.key}
                id={`tab-${tab?.key}`}
                icon={tab.icon}
                iconPosition="start"
              />
            ))}
          </Tabs>
        </Box>
        <Stack
          sx={{
            width: '100%',
            height: 'auto'
          }}
          spacing={2}
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <TruentityDataGrid
            name={`dg-review-accounts-${activeTabKey}`}
            autoHeight
            paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
            sortModel={sortModel}
            onSortModelChange={newModel => {
              setSortModel(newModel);
            }}
            columnVisibilityModel={columnVisibilityModel}
            onPaginationModelChange={({ page }) => {
              setCurrentPage(page);
            }}
            rows={rpmMonthlyReportData?.getMonthlyReviewRpmReports?.reports ?? []}
            columns={columns}
            loading={isRpmMonthlyReportDataLoading}
            paginationMode="server"
            disableRowSelectionOnClick
            rowCount={rpmMonthlyReportData?.getMonthlyReviewRpmReports?.meta?.totalCount ?? 0}
          />
        </Stack>
      </Paper>
    </Stack>
  );
};

export default RpmReviewPatients;
