import type { DynamicTemplateType } from '@/types/remotePatientMonitoring';
import type { SxProps, Theme } from '@mui/material';
import type React from 'react';
import TruentityTextField from './TruentityTextField';
import { Body1 } from './Typography';

type Props = DynamicTemplateType & {
  onInputChange: (placeholder: string, value: string, templateId?: string) => void;
  textFieldStyle?: SxProps<Theme>;
  contentStyle?: SxProps<Theme>;
};

const DynamicTemplate: React.FC<Props> = ({
  templateText,
  validationRules,
  inputs,
  onInputChange,
  textFieldStyle,
  contentStyle,
  templateId
}) => {
  const templateParts = templateText.split(/(<<[^>]+>>)/g);

  return (
    <>
      {templateParts.map((part, index) => {
        const match = part.match(/<<(.+?)>>/);
        if (match) {
          const placeholder = match[1];
          const validation = validationRules?.[placeholder];
          const inputType = validation ? (validation.type === 'Integer' ? 'number' : 'text') : 'text';
          const value = inputs.find(input => input.key.toLowerCase() === placeholder.toLowerCase())?.value || '';

          return (
            <TruentityTextField
              key={`${templateId}-${placeholder}`}
              type={inputType}
              id={`${templateId}-${placeholder}`}
              size="small"
              margin="none"
              focused
              value={value}
              onChange={e => onInputChange(placeholder, e.target.value, templateId)}
              sx={{
                width: '60px',
                margin: 0.5,
                display: 'inline-flex',
                '& .MuiOutlinedInput-input': {
                  textAlign: 'center',
                  fontWeight: 'bold'
                },
                ...textFieldStyle
              }}
            />
          );
        } else {
          return (
            <Body1 key={index} sx={{ display: 'inline', mx: 0.5, lineHeight: '3rem', ...contentStyle }}>
              {part}
            </Body1>
          );
        }
      })}
    </>
  );
};

export default DynamicTemplate;
