import { color } from '@/styles/assets/colors';
import { keyframes } from '@emotion/react';
import { Box } from '@mui/material';
import React from 'react';
import { H4 } from '../Typography';

interface WavyTextProps {
  message: string;
  textColor?: string;
  hoverColor?: string;
  animationDuration?: number;
}

const WavyText: React.FC<WavyTextProps> = ({
  message,
  textColor = color.truentityBlue[500],
  hoverColor = color.truentityBlue[200],
  animationDuration = 1.5
}) => {
  const wave = keyframes`
    0%, 100% {
      color: ${textColor};
    }
    50% {
      color: ${hoverColor};
    }
  `;

  const words = message.split(' ');

  return (
    <Box sx={{ display: 'inline-block' }}>
      <Box
        component="div"
        sx={{
          display: 'inline-block',
          whiteSpace: 'nowrap'
        }}
      >
        {words.map((word, wordIndex) => (
          <React.Fragment key={wordIndex}>
            {word.split('').map((char, index) => (
              <H4
                key={index}
                component="span"
                sx={{
                  display: 'inline-block',
                  animation: `${wave} ${animationDuration}s infinite ease-in-out`,
                  animationDelay: `${index * 0.1}s`
                }}
              >
                {char}
              </H4>
            ))}
            {wordIndex < words.length - 1 && ' '}
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default WavyText;
