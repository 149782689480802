import Button from '@/components/Button';
import { H1 } from '@/components/Typography';
import { FollowUpCategoryTypes, FollowUpStatusTypes } from '@/graphql/account';
import type { RelyingPartyAdminFollowUpResponse } from '@/graphql/remotePatientMonitoring';
import { CURRENT_RELYING_PARTY_ADMIN_FOLLOWUP_ID } from '@/graphql/remotePatientMonitoring';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { useQuery, useReactiveVar } from '@apollo/client';
import { Badge, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const RpmMyPatientHeader = () => {
  const navigate = useNavigate();
  const currentUser = useReactiveVar(currentLoggedUserVar);

  const { data: currentAdminTaskId } = useQuery<RelyingPartyAdminFollowUpResponse>(CURRENT_RELYING_PARTY_ADMIN_FOLLOWUP_ID, {
    variables: {
      filterOptions: {
        relyingPartyAdminId: currentUser?.id || 'all',
        status: FollowUpStatusTypes.PENDING,
        category: [FollowUpCategoryTypes.ALERT_REVIEW, FollowUpCategoryTypes.CHART_REVIEW]
      }
    },
    fetchPolicy: 'cache-and-network'
  });

  const redirectToRpmPatientOnboardScreen = () => {
    navigate('/global-lookup');
  };

  const redirectToMyFollowUpTaskScreen = () => {
    navigate('/remote-patient-monitoring/follow-up-tasks');
  };

  return (
    <Stack spacing={2} direction="row" alignItems="center">
      <H1
        sx={{
          fontSize: '24px',
          fontWeight: 600,
          lineHeight: '32px',
          flex: 1,
          textAlign: 'left'
        }}
      >
        RPM Patients
      </H1>
      <Stack direction="row" gap={2}>
        <Badge badgeContent={currentAdminTaskId?.relyingPartyFollowups.accountFollowups.length || 0} color="error">
          <Button label="Follow-up Tasks" variant="outlined" onClick={() => redirectToMyFollowUpTaskScreen()} />
        </Badge>
        <Button label="Onboard Patients" onClick={() => redirectToRpmPatientOnboardScreen()} />
      </Stack>
    </Stack>
  );
};

export default RpmMyPatientHeader;
