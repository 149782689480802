import Alert from '@/components/Alert';
import PatientDetailContext from '@/context/patientDetailContext';
import type { AccountHandoutResponse } from '@/graphql/remotePatientMonitoring';
import { GET_ACCOUNT_HANDOUT_PDF } from '@/graphql/remotePatientMonitoring';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { base64ToUint8Array } from '@/util/array';
import { fetchImage } from '@/util/images';
import { useQuery, useReactiveVar } from '@apollo/client';
import { Box, Skeleton } from '@mui/material';
import { PDFDocument } from 'pdf-lib';
import QRCode from 'qrcode';
import { useCallback, useContext, useEffect, useState } from 'react';
import { PDFViewer } from '../DischargeReports/Summary';

type MapperType = {
  [key: string]: { type: string; value: string | boolean };
};

const PatientHandout = ({ accountTempVitalAccessURL }: { accountTempVitalAccessURL: string }) => {
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const { patientInfo } = useContext(PatientDetailContext);

  const [rpmMonthlyReportUrl, setRpmMonthlyReportUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const { data: accountHandout, loading: accountHandoutLoading } = useQuery<AccountHandoutResponse>(GET_ACCOUNT_HANDOUT_PDF);

  const modifyPdf = useCallback(
    async function modifyPdf(pdfUrl: string) {
      try {
        const existingPdfBytes = await fetch(pdfUrl).then(res => res.arrayBuffer());
        const pdfDoc = await PDFDocument.load(existingPdfBytes);

        const form = pdfDoc.getForm();

        const mappings: MapperType = {
          ...(currentUser?.relyingParty.primaryProvider?.name && {
            providerName: { type: 'textField', value: currentUser?.relyingParty.primaryProvider?.name }
          }),
          organizationLogo: { type: 'image', value: currentUser?.relyingParty?.logoUrl || '#' },
          ...(patientInfo?.isTempVitalAccessConfigured && {
            qrCodeImage: {
              type: 'image',
              value: await QRCode.toDataURL(accountTempVitalAccessURL)
            }
          }),
          BP: {
            type: 'checkbox',
            value: patientInfo?.accountRpmMonitoredCondition.includes('Blood Pressure') || false
          },
          DM: {
            type: 'checkbox',
            value: patientInfo?.accountRpmMonitoredCondition.includes('Blood Glucose') || false
          }
        };

        for (const [formFieldName, obj] of Object.entries(mappings)) {
          try {
            const { type, value } = obj;
            if (value === undefined || value === null) continue;

            if (type.toLowerCase() === 'checkbox') {
              const checkbox = form.getCheckBox(formFieldName);
              value === true ? checkbox.check() : checkbox.uncheck();
            } else if (type.toLowerCase() === 'radio') {
              const radioGroup = form.getRadioGroup(formFieldName);
              radioGroup.select(String(value));
            } else if (type.toLowerCase() === 'image' && typeof value === 'string') {
              let imageEmbed;
              if (value.startsWith('data:image/png')) {
                const imageBytes = base64ToUint8Array(value);
                imageEmbed = await pdfDoc.embedPng(imageBytes);
              } else if (value.startsWith('data:image/jpeg') || value.startsWith('data:image/jpg')) {
                const imageBytes = base64ToUint8Array(value);
                imageEmbed = await pdfDoc.embedJpg(imageBytes);
              } else if (value.toLowerCase().endsWith('.jpg') || value.toLowerCase().endsWith('.jpeg')) {
                const imageBytes = await fetchImage(value);

                if (!imageBytes) continue;
                imageEmbed = await pdfDoc.embedJpg(imageBytes);
              } else if (value.toLowerCase().endsWith('.png')) {
                const imageBytes = await fetchImage(value);
                if (!imageBytes) continue;
                imageEmbed = await pdfDoc.embedPng(imageBytes);
              } else {
                console.warn(`Unsupported image format for ${formFieldName}. Only JPG and PNG are supported.`);
                continue;
              }
              const imageField = form.getButton(formFieldName);
              if (imageField) {
                imageField.setImage(imageEmbed);
              } else {
                console.warn(`Image field not found: ${formFieldName}`);
              }
            } else {
              const textField = form.getTextField(formFieldName);
              textField.setText(String(value));
            }
          } catch (error) {
            console.error(`Error setting field ${formFieldName}:`, error);
          }
        }

        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const docUrl = URL.createObjectURL(blob);

        setRpmMonthlyReportUrl(docUrl);
      } catch (error) {
        console.error('Error modifying PDF:', error);
      } finally {
        setLoading(false);
      }
    },
    [
      accountTempVitalAccessURL,
      currentUser?.relyingParty?.logoUrl,
      currentUser?.relyingParty.primaryProvider?.name,
      patientInfo?.accountRpmMonitoredCondition,
      patientInfo?.isTempVitalAccessConfigured
    ]
  );

  useEffect(() => {
    if (accountHandout?.getAccountHandout.handoutS3Key) modifyPdf(accountHandout?.getAccountHandout.handoutS3Key);
  }, [modifyPdf, accountHandout, patientInfo?.isTempVitalAccessConfigured]);

  return (
    <Box>
      {!patientInfo?.isTempVitalAccessConfigured && (
        <Alert
          sx={{ mb: 2 }}
          description="Patients' temporary vitals access not configured. Therefore, the QR code will not be generated."
          status="warning"
        />
      )}

      <Box>
        {loading || accountHandoutLoading ? (
          <Skeleton variant="rounded" width="inherit" height="15.625rem" animation="wave" />
        ) : rpmMonthlyReportUrl ? (
          <PDFViewer src={rpmMonthlyReportUrl} />
        ) : (
          <Alert description="Failed to load the PDF." status="error" />
        )}
      </Box>
    </Box>
  );
};

export default PatientHandout;
