import type { ScheduleClaimSubmissionResponseType } from '@/graphql/claimSubmissions';
import { GET_CLAIM_SUBMISSION_INFO, SCHEDULE_CLAIM_SUBMISSION } from '@/graphql/claimSubmissions';
import OrganizationAlert from '@/routes/PatientDetails/RemotePatientMonitoring/Components/RpmClaimSubmission/OrganizationAlert';
import { color } from '@/styles/assets/colors';
import { MedicalServices } from '@/types/admin';
import type { GetClaimSubmissionInfoResponse } from '@/types/claimSubmission';
import { today } from '@/util/date';
import { formatDate } from '@/util/format';
import { useMutation, useQuery } from '@apollo/client';
import type { DateInput } from '@fullcalendar/core';
import { AddCircleRounded } from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, DialogActions, DialogContent, Divider, IconButton, Stack } from '@mui/material';
import type { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import Alert from '../Alert';
import Button from '../Button';
import SelectList from '../SelectList';
import TruentityDatePicker from '../TruentityDatePicker';
import { Body1, Body2, H4, Label } from '../Typography';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  description: string;
  monthAndYear: DateInput;
  selectedRelyingPartyId: string;
  onSuccess: () => void;
};

type ServiceDatesType = { id: string; organizationName: string; serviceDate: Moment | null; orgError: string[] };

type FormValues = {
  defaultServiceDate: Moment | null;
  serviceDates: ServiceDatesType[];
};

const CPT_CODE_TEXTS: Record<string, string> = {
  '99203': 'Date of Service for CPT code: 99203 will be the Date of Enrollment',
  '99213': 'Date of Service for CPT code: 99213 will be the Date of the Follow-up'
};

const ScheduleClaimSubmission = ({ title, monthAndYear, description, hideDialog, selectedRelyingPartyId, onSuccess, ...props }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, watch, handleSubmit, reset, setValue } = useForm<FormValues>({
    defaultValues: { defaultServiceDate: today() }
  });
  const { fields, append, remove } = useFieldArray({ control, name: 'serviceDates' });

  const defaultDate = watch('defaultServiceDate');
  const selectedRelyingParties = watch('serviceDates');

  const { data: claimInfo } = useQuery<GetClaimSubmissionInfoResponse>(GET_CLAIM_SUBMISSION_INFO, {
    variables: {
      monthYear: monthAndYear,
      ...(selectedRelyingPartyId !== 'ALL' && { relyingPartyIds: [selectedRelyingPartyId] })
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      const relyingParies = data.getClaimSubmissionInfo.submissionAllowedRelyingParties;
      reset({
        serviceDates: relyingParies.map(relyingParty => ({
          id: relyingParty.id,
          organizationName: relyingParty.name,
          serviceDate: today(),
          orgError: relyingParty.settingsValidationErrors ?? []
        }))
      });
    },
    onError: error => {
      console.error(error);
    }
  });

  const [handleScheduleSubmission, { loading: loadingScheduleSubmission }] = useMutation<ScheduleClaimSubmissionResponseType>(
    SCHEDULE_CLAIM_SUBMISSION,
    {
      variables: {
        monthYear: monthAndYear,
        serviceType: MedicalServices.RPM
      },
      onCompleted: data => {
        const { status = 'Error', message = {} } = data?.scheduleClaimSubmission ?? {};

        if (status === 'Success') {
          if (message['success']?.length)
            enqueueSnackbar(message['success'] || 'Claim submission scheduled successfully.', { variant: 'success' });
        } else if (status === 'Partial Success') {
          Object.entries(message)
            .filter(([key]) => key !== '__typename')
            .forEach(([key, value]) => {
              if ((value as string)?.length) {
                const variant = key.toLowerCase() as 'success' | 'skipped' | 'failed';
                enqueueSnackbar(value as string, { variant: variant === 'skipped' ? 'info' : variant === 'failed' ? 'error' : variant });
              }
            });
        } else {
          if (message['failed']?.length) enqueueSnackbar(message['failed'] || 'Failed to schedule claim submission.', { variant: 'error' });
        }

        onSuccess();
      },
      onError: err => {
        const errorMessage = err.graphQLErrors?.[0]?.message || 'Unable to schedule claim submissions. Please contact support.';
        enqueueSnackbar(errorMessage, { variant: 'error' });
      }
    }
  );

  const { visitCodes, other } = useMemo(() => {
    const cptCodes = claimInfo?.getClaimSubmissionInfo?.submissionAllowedCptCodes || [];

    return cptCodes.reduce(
      (acc, cptCode) => {
        if (cptCode.name.toLowerCase().includes('visit')) {
          acc.visitCodes[cptCode.code] = cptCode.name;
        } else {
          acc.other[cptCode.code] = cptCode.name;
        }
        return acc;
      },
      { visitCodes: {}, other: {} } as {
        visitCodes: Record<'visitCodes', Record<string, string>>;
        other: Record<'other', Record<string, string>>;
      }
    );
  }, [claimInfo]);

  const defaultServiceDateText = useMemo(() => {
    const cptCodes = Object.keys(other);
    return cptCodes.length > 0 ? `Default Service Date for ${cptCodes.join(', ')}` : 'Default Service Date';
  }, [other]);

  const infotext = useMemo(() => {
    const visitCptCodes = Object.keys(visitCodes);

    return visitCptCodes.length > 0
      ? visitCptCodes
          .map(visitCptCode => (CPT_CODE_TEXTS[visitCptCode] ? <Body1 key={visitCptCode}>{CPT_CODE_TEXTS[visitCptCode]}</Body1> : null))
          .filter(Boolean)
      : null;
  }, [visitCodes]);

  const getNonSelectedRelyingParties = useCallback(() => {
    const relyingParties = claimInfo?.getClaimSubmissionInfo.submissionAllowedRelyingParties || [];

    if (relyingParties.length === 0) return [];

    return relyingParties.filter(party => !selectedRelyingParties?.some(selected => selected.id === party.id));
  }, [claimInfo?.getClaimSubmissionInfo.submissionAllowedRelyingParties, selectedRelyingParties]);

  const getOrganizationSelectorOptions = useCallback(
    (value: string) => {
      const relyingParties = claimInfo?.getClaimSubmissionInfo.submissionAllowedRelyingParties || [];
      if (relyingParties.length === 0) return [];

      return relyingParties.map(({ id, name }) => {
        const isDisabled = selectedRelyingParties.some(selected => selected.id !== value && selected.id === id);

        return { label: name, value: id, disabled: isDisabled };
      });
    },
    [claimInfo?.getClaimSubmissionInfo.submissionAllowedRelyingParties, selectedRelyingParties]
  );

  const getNewOrg = useCallback(
    (defaultDate: Moment | null): ServiceDatesType => {
      const firstAvailableOrg = getNonSelectedRelyingParties()[0];

      return firstAvailableOrg
        ? {
            id: firstAvailableOrg.id,
            organizationName: firstAvailableOrg.name,
            serviceDate: defaultDate,
            orgError: firstAvailableOrg.settingsValidationErrors ?? []
          }
        : { id: '', organizationName: '', serviceDate: defaultDate, orgError: [] };
    },
    [getNonSelectedRelyingParties]
  );

  const getOrgById = useCallback(
    (id: string) => {
      const relyingParties = claimInfo?.getClaimSubmissionInfo.submissionAllowedRelyingParties || [];

      return relyingParties.find(relyingParty => relyingParty.id === id);
    },
    [claimInfo?.getClaimSubmissionInfo.submissionAllowedRelyingParties]
  );

  const onSubmit = (data: FormValues) => {
    const { serviceDates } = data;

    try {
      if (serviceDates.some(date => date.orgError.length > 0))
        return enqueueSnackbar('Can’t schedule claim submission. Please resolve the selected organization issue and try again.', {
          variant: 'warning'
        });

      const refactoredServiceDates = serviceDates.map(({ id, serviceDate }) => ({
        relyingPartyId: id,
        serviceDate: formatDate(serviceDate, 'DD-MM-YYYY')
      }));

      handleScheduleSubmission({
        variables: {
          relyingPartyServiceDates: refactoredServiceDates
        }
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to schedule the claim submissions. Please contact support.', { variant: 'error' });
    }
  };

  useEffect(() => {
    if (!defaultDate) return;

    const currentServiceDates = watch('serviceDates');

    const needsUpdate = currentServiceDates.some(serviceDate => !serviceDate.serviceDate?.isSame(defaultDate, 'day'));

    if (needsUpdate) {
      setValue(
        'serviceDates',
        currentServiceDates.map(serviceDate => ({
          ...serviceDate,
          serviceDate: defaultDate
        }))
      );
    }
  }, [defaultDate, setValue, watch]);

  return (
    <BaseDialog title={title} hideDialog={hideDialog} {...props} fullWidth maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Body2>{description}</Body2>
          <Stack direction="row" mt={2} alignItems="center" py={1} px={2} sx={{ borderRadius: 2, backgroundColor: color.grey100 }}>
            <Label flex={0.5}>{defaultServiceDateText}</Label>
            <Controller
              name="defaultServiceDate"
              control={control}
              render={({ field }) => (
                <Box flex={0.5}>
                  <TruentityDatePicker disableFuture value={field.value} onChange={field.onChange} />
                </Box>
              )}
            />
          </Stack>
          <Divider sx={{ my: 2 }} />
          <Stack alignItems="flex-end" overflow="auto" gap={2}>
            <Box>
              <Button
                startIcon={<AddCircleRounded />}
                onClick={() => append(getNewOrg(defaultDate))}
                disabled={getNonSelectedRelyingParties().length === 0}
              >
                Add Organization
              </Button>
            </Box>
            <Stack width="100%" py={1} px={2} gap={1.5} bgcolor="white" borderRadius={2} overflow="auto" maxHeight="13.4375rem">
              {fields.length === 0 ? (
                <Stack justifyContent="center" alignItems="center" width="100%" minHeight="6.25rem" textAlign="center">
                  <H4>
                    {getNonSelectedRelyingParties().length === 0
                      ? 'All the scheduled RPM billings generated by organizations have already been submitted'
                      : 'No Organization Selected'}
                  </H4>
                </Stack>
              ) : (
                fields.map(({ id }, index) => (
                  <Stack key={id} gap={0.5}>
                    <Stack direction="row" alignItems="center" key={id} gap={1}>
                      <Box flex={0.4}>
                        <Controller
                          name={`serviceDates.${index}.id`}
                          control={control}
                          render={({ field: { value, onChange } }, ...props) => (
                            <SelectList
                              value={value}
                              options={getOrganizationSelectorOptions(value)}
                              placeholder="Select Organization"
                              onChange={event => {
                                const org = getOrgById(event.target.value as string);
                                onChange(event);
                                setValue(`serviceDates.${index}.organizationName`, org?.name || '');
                                setValue(`serviceDates.${index}.orgError`, org?.settingsValidationErrors || [], {
                                  shouldValidate: true,
                                  shouldDirty: true,
                                  shouldTouch: true
                                });
                              }}
                              sx={{ width: '12.5rem' }}
                              {...props}
                            />
                          )}
                        />
                      </Box>

                      <Controller
                        name={`serviceDates.${index}.serviceDate`}
                        control={control}
                        render={({ field }, ...props) => (
                          <Box flex={0.6}>
                            <TruentityDatePicker disableFuture value={field.value} onChange={field.onChange} {...props} />
                          </Box>
                        )}
                      />

                      <IconButton color="error" onClick={() => remove(index)}>
                        <DeleteOutlineIcon />
                      </IconButton>
                    </Stack>
                    {watch(`serviceDates.${index}.orgError`)?.length > 0 &&
                      watch(`serviceDates.${index}.orgError`).map(error => <OrganizationAlert key={error} alertText={error} />)}
                  </Stack>
                ))
              )}
            </Stack>
          </Stack>
          {infotext && infotext?.length > 0 && (
            <Box my={1}>
              <Alert status="info" title="Claims Submission Info" description={<Stack>{infotext}</Stack>} />
            </Box>
          )}

          <DialogActions>
            <Button variant="outlined" onClick={hideDialog}>
              Cancel
            </Button>
            <Button type="submit" isLoading={loadingScheduleSubmission} disabled={fields.length === 0}>
              Schedule
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
    </BaseDialog>
  );
};

export default ScheduleClaimSubmission;
