import { emptyString } from './string';

export function stringAvatar(name: string) {
  const returnVal = stringInitials(name);

  return {
    children: returnVal
  };
}

export function stringInitials(name: string) {
  let returnVal = '';
  try {
    const nameSplit = name.split(' ');
    returnVal = nameSplit[0][0] || 'T';
    if (nameSplit.length > 1) {
      returnVal += nameSplit[1][0] || 'H';
    }
  } catch (err) {
    console.error(err);
    returnVal = emptyString();
  }

  return returnVal;
}
