import type { AccountsFollowupResponse, UpdateAccountFollowupResponse } from '@/graphql/account';
import {
  ADD_ACCOUNT_FOLLOWUP,
  FollowUpCategoryTypes,
  FollowUpStatusTypes,
  FollowUpSubTypes,
  FollowUpTaskTypes,
  UPDATE_ACCOUNT_FOLLOWUP
} from '@/graphql/account';
import type { RelyingPartyAdminData } from '@/routes/Administration';
import FollowUpTaskAddEditForm from '@/routes/PatientDetails/FollowUps/Components/FollowUpTaskAddEditForm';
import TaskReviewFollowUpForm from '@/routes/PatientDetails/FollowUps/Components/TaskReviewFollowUpForm';
import type { FollowUpRemindersTypes } from '@/types/accountProfile';
import { getAccountUserFullName } from '@/util/account';
import { formatDate, formatDateIgnoreTZAddDays, formatEndOfDay, getCurrentDate } from '@/util/format';
import { useMutation } from '@apollo/client';
import type { DateInput } from '@fullcalendar/core';
import { DialogActions, DialogContent } from '@mui/material';
import { useSnackbar } from 'notistack';
import type React from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import Button from '../Button';
import type { JSONData } from '../JsonKit/JsonEditor';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';
import { FollowupFormModeTypes } from './FollowUpAddEditDialog';

type Props = BaseDialogProps & {
  category: FollowUpCategoryTypes;
  formMode: FollowupFormModeTypes;
  truentityId?: string;
  accountAssignees?: RelyingPartyAdminData[];
  followUpTaskData?: FollowUpRemindersTypes;
  reviewNoteData?: { reviewNoteId: string; message: string };
  hideDialog: () => void;
  onUpdated: () => void;
};

export type DefaultFollowUpEditType = {
  id: string;
  truentityId: string;
  followUpDueDate: DateInput;
  type: string;
  note: string;
  status: string;
  taskAssignees: RelyingPartyAdminData;
  category: FollowUpCategoryTypes;
  immediateActions?: JSONData;
};

const FollowUpTaskAddEditDialog: React.FC<Props> = ({
  title,
  hideDialog,
  truentityId,
  accountAssignees = [],
  followUpTaskData,
  category,
  formMode,
  reviewNoteData,
  onUpdated,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();
  followUpTaskData = formMode === FollowupFormModeTypes.ADD ? undefined : followUpTaskData;

  const [addAccountFollowUp] = useMutation<AccountsFollowupResponse>(ADD_ACCOUNT_FOLLOWUP, {
    onCompleted: data => {
      const status = data?.addAccountFollowup?.status === 'Success' ? 'success' : 'error';
      const message = data?.addAccountFollowup?.message || 'Unexpected error occurred while adding a follow-up task';
      enqueueSnackbar(message, { variant: status });
      onUpdated();
    },
    onError: err => {
      const code = err.graphQLErrors?.[0]?.extensions?.code;
      const message = err.graphQLErrors?.[0]?.message;

      if (code && code === 'UNEXPECTED_CODE') {
        enqueueSnackbar(message, { variant: 'error' });
      } else {
        enqueueSnackbar('Unexpected error occurred while adding a follow-up task', { variant: 'error' });
      }
      hideDialog();
    }
  });

  const [updateAccountFollowUp] = useMutation<UpdateAccountFollowupResponse>(UPDATE_ACCOUNT_FOLLOWUP, {
    onCompleted: data => {
      const status = data?.updateAccountFollowup?.status === 'Success' ? 'success' : 'error';
      const message = data?.updateAccountFollowup?.message || 'Unexpected error occurred while updating a follow-up task';
      enqueueSnackbar(message, { variant: status });
      onUpdated();
    },
    onError: err => {
      const code = err.graphQLErrors?.[0]?.extensions?.code;
      const message = err.graphQLErrors?.[0]?.message;

      if (code && code === 'UNEXPECTED_CODE') {
        enqueueSnackbar(message, { variant: 'error' });
      } else {
        enqueueSnackbar('Unexpected error occurred while updating a follow-up task', { variant: 'error' });
      }
      hideDialog();
    }
  });

  const methods = useForm<DefaultFollowUpEditType>({
    defaultValues: {
      id: followUpTaskData?.id,
      truentityId: followUpTaskData?.account.truentityId || truentityId,
      followUpDueDate: followUpTaskData?.followUpOn
        ? formatDate(followUpTaskData.followUpOn)
        : formatDateIgnoreTZAddDays(getCurrentDate(), 10),
      type: followUpTaskData?.type || '',
      note: followUpTaskData?.notes || reviewNoteData?.message || '',
      status: followUpTaskData?.status || FollowUpStatusTypes.PENDING,
      immediateActions: followUpTaskData?.immediateActions,
      taskAssignees: followUpTaskData
        ? { id: followUpTaskData.relyingPartyAdmin.id, name: getAccountUserFullName(followUpTaskData.relyingPartyAdmin.user) }
        : undefined
    }
  });

  const { handleSubmit } = methods;

  const onSubmit: SubmitHandler<DefaultFollowUpEditType> = async data => {
    const assigneeIds = [data?.taskAssignees?.id || accountAssignees?.[0].id];

    if (assigneeIds.length === 0) enqueueSnackbar('Please add a follow-up task assignee or contact support.', { variant: 'error' });

    const followUpObjects = {
      ...(formMode === FollowupFormModeTypes.UPDATE ? { followUpIds: [data.id] } : { truentityId }),
      ...(category === FollowUpCategoryTypes.CHART_REVIEW ? { rpmAccountReviewNoteId: reviewNoteData?.reviewNoteId } : {}),
      followUpAssigneeIds: assigneeIds,
      followUpOn: formatEndOfDay(data.followUpDueDate),
      type: data.type,
      subType: FollowUpSubTypes.RPM,
      note: data.note,
      status: data.status,
      category: category
    };

    try {
      if (followUpObjects.type === FollowUpTaskTypes.NO_INTERVENTION_REQUIRED) {
        enqueueSnackbar(`Task type "${followUpObjects.type}" does not require intervention. No follow-up task was created.`, {
          variant: 'info'
        });
        hideDialog();
        return;
      }
      if (formMode === FollowupFormModeTypes.UPDATE) {
        await updateAccountFollowUp({ variables: followUpObjects });
      } else {
        await addAccountFollowUp({ variables: followUpObjects });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unexpected error occurred while creating or updating a follow-up task', { variant: 'error' });
    }
  };

  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog} maxWidth="sm" fullWidth={true}>
      <DialogContent>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {category === FollowUpCategoryTypes.CHART_REVIEW && formMode === FollowupFormModeTypes.ADD ? (
              <TaskReviewFollowUpForm />
            ) : (
              <FollowUpTaskAddEditForm accountAssignees={accountAssignees} category={category} formMode={formMode} />
            )}
            <DialogActions>
              <Button variant="outlined" onClick={hideDialog}>
                Cancel
              </Button>
              <Button type="submit">{formMode === FollowupFormModeTypes.ADD ? 'Submit' : 'Update'}</Button>
            </DialogActions>
          </form>
        </FormProvider>
      </DialogContent>
    </BaseDialog>
  );
};

export default FollowUpTaskAddEditDialog;
