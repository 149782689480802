import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import type { GetFilteredRelyingPartyAdminsResponse } from '@/graphql/account';
import { GET_FILTERED_RELYING_PARTY_ADMINS } from '@/graphql/account';
import type { RpmAccountReviewWorkflowNotesResponse } from '@/graphql/remotePatientMonitoring';
import { RPM_ACCOUNT_REVIEW_WORKFLOW_NOTES } from '@/graphql/remotePatientMonitoring';
import RpmPatientReviewNote from '@/routes/PatientDetails/RemotePatientMonitoring/Components/RpmPatientReviewWorkflow/RpmPatientReviewNote';
import { ReviewNoteCategoryType, type PatientReviewNoteType } from '@/types/remotePatientMonitoring';
import { useQuery, useSubscription } from '@apollo/client';
import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import type { Dispatch, FC, SetStateAction } from 'react';
import { useEffect, useState } from 'react';

type Props = {
  setIsSignOffEnable: Dispatch<SetStateAction<boolean>>;
  accountReviewWorkflowId?: string;
  truentityId?: string;
};

const RpmPatientReviewNotesList: FC<Props> = ({ accountReviewWorkflowId, truentityId, setIsSignOffEnable }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [reviewNotes, setReviewNotes] = useState<PatientReviewNoteType[]>([]);

  const { data: relyingPartyAdminData } = useQuery<GetFilteredRelyingPartyAdminsResponse>(GET_FILTERED_RELYING_PARTY_ADMINS, {
    variables: {
      pageNum: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      isPrimaryRpmAdmins: true
    }
  });

  useSubscription<RpmAccountReviewWorkflowNotesResponse>(RPM_ACCOUNT_REVIEW_WORKFLOW_NOTES, {
    variables: { accountReviewWorkflowId: accountReviewWorkflowId },
    skip: !accountReviewWorkflowId,
    onSubscriptionData: ({ subscriptionData }) => {
      const reviewNotes = subscriptionData.data?.rpmAccountReviewWorkflowNotes?.rpmAccountReviewNotes;

      if (reviewNotes) {
        setReviewNotes(reviewNotes);
        setIsSignOffEnable(checkSignOfEligible(reviewNotes));
      }
    },
    onError: err => {
      if (err) {
        const errorMessage = err?.message ?? 'Could not fetch Review Notes for RPM Report';
        enqueueSnackbar(errorMessage, {
          variant: 'error'
        });
        setReviewNotes([]);
      }
    }
  });

  const checkSignOfEligible = (reviewNotes: PatientReviewNoteType[]) => {
    const categoryCounts = {
      assessment: 0,
      intervention: 0
    };

    reviewNotes.forEach(note => {
      switch (note.noteCategory) {
        case ReviewNoteCategoryType.ASSESSMENT:
          categoryCounts.assessment++;
          break;
        case ReviewNoteCategoryType.INTERVENTION:
          categoryCounts.intervention++;
          break;
      }
    });

    return Object.values(categoryCounts).every(count => count > 0);
  };

  useEffect(() => {
    if (!accountReviewWorkflowId) {
      setReviewNotes([]);
    }
  }, [accountReviewWorkflowId]);

  return (
    <Stack direction="column" justifyContent="flex-start" alignItems="start" width="100%" height="auto" spacing={2}>
      {reviewNotes.map(note => (
        <RpmPatientReviewNote
          key={note.id}
          reviewWorkflowId={accountReviewWorkflowId}
          relyingPartyAdminId={note.relyingPartyAdmin.id}
          reviewNoteId={note.id}
          message={note.note}
          noteCategory={note.noteCategory}
          user={{
            avatar: note?.relyingPartyAdmin?.presignedAvatarUrl,
            name: `${note?.relyingPartyAdmin?.user?.firstName} ${note?.relyingPartyAdmin?.user?.lastName}`
          }}
          createdAt={note.createdAt}
          replyNotes={note.replies}
          followupReminders={note.accountsFollowupReminders}
          relyingPartyAdminData={relyingPartyAdminData}
          truentityId={truentityId}
        />
      ))}
    </Stack>
  );
};

export default RpmPatientReviewNotesList;
