import PatientDetailContext from '@/context/patientDetailContext';
import type { GetRpmFollowupConsultType } from '@/graphql/remotePatientMonitoring';
import { GET_RPM_FOLLOWUP_CONSULT } from '@/graphql/remotePatientMonitoring';
import type { GetRpmFollowupConsultTabsResponse, UpdateRpmFollowupConsultTabsTabsResponse } from '@/graphql/rpmWorkflow';
import { GET_RPM_FOLLOWUP_CONSULT_TABS, UPDATE_RPM_FOLLOWUP_CONSULT_TAB } from '@/graphql/rpmWorkflow';
import useToken from '@/hooks/useToken';
import RpmFollowupConsultStatusBar from '@/routes/PatientDetails/RemotePatientMonitoring/Components/FollowupConsults/RpmFollowupConsultStatusBar';
import RpmFollowupConsultToolbar from '@/routes/PatientDetails/RemotePatientMonitoring/Components/FollowupConsults/RpmFollowupConsultToolbar';
import RpmTabStatusHandler from '@/routes/PatientDetails/RemotePatientMonitoring/Components/RpmWorkflow/RpmTabStatusHandler';
import { color } from '@/styles/assets/colors';
import { theme } from '@/styles/mui-theme';
import { Role } from '@/types/admin';
import type { RpmFollowupConsultTabType, RpmWorkflowTabType } from '@/types/remotePatientMonitoring';
import { RpmSetupStatusTypes, RpmWorkflowTab, RpmWorkflowTabNames } from '@/types/remotePatientMonitoring';
import { checkTabDisability, checkTabProgressDisabled, checkTabReadOnly, pharmacistRoleTypes } from '@/util/adminstration';
import { mandatoryTabsForRpmFollowup } from '@/util/constants';
import { pathnameIncludes } from '@/util/location';
import { initialRpmFollowupConsultTabs, useRpmFollowupConsultStore } from '@/zustand/RpmFollowupConsultStore';
import { useMutation, useQuery } from '@apollo/client';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LocalHospital from '@mui/icons-material/LocalHospital';
import { Stack, Tab } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

const rpmFollowupConsultTabTypes: RpmWorkflowTabType[] = [
  {
    label: RpmWorkflowTabNames[RpmWorkflowTab.CLINICAL_SUMMARY],
    path: 'clinical-summary',
    tabType: RpmWorkflowTab.CLINICAL_SUMMARY,
    tabReadOnlyRoleTypes: [Role.PROVIDER],
    accessDisableRoleTypes: []
  },
  {
    label: RpmWorkflowTabNames[RpmWorkflowTab.MEDICAL_CONSULTS],
    path: 'medical-consults',
    tabType: RpmWorkflowTab.MEDICAL_CONSULTS,
    tabReadOnlyRoleTypes: [Role.PROVIDER, Role.ADMIN, ...pharmacistRoleTypes],
    accessDisableRoleTypes: [Role.ADMIN, ...pharmacistRoleTypes]
  },
  {
    label: RpmWorkflowTabNames[RpmWorkflowTab.MEDICAL_RECONCILIATION],
    path: 'medication-reconciliation',
    tabType: RpmWorkflowTab.MEDICAL_RECONCILIATION,
    tabReadOnlyRoleTypes: [Role.PROVIDER, Role.ADMIN, ...pharmacistRoleTypes],
    accessDisableRoleTypes: [Role.ADMIN, ...pharmacistRoleTypes]
  },
  {
    label: RpmWorkflowTabNames[RpmWorkflowTab.CARE_PLAN],
    path: 'care-plan',
    tabType: RpmWorkflowTab.CARE_PLAN,
    tabReadOnlyRoleTypes: [Role.ADMIN, ...pharmacistRoleTypes],
    accessDisableRoleTypes: [Role.ADMIN, ...pharmacistRoleTypes],
    disableProgress: true
  }
];

export const RpmFollowupConsultSection: React.FC = () => {
  const navigate = useNavigate();
  const { rpmFollowupConsultId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { patientInfo } = useContext(PatientDetailContext);
  const { roleType } = useToken();
  const {
    setRpmFollowupConsult,
    rpmFollowupConsult,
    setShouldReFetchRpmFollowupConsult,
    shouldReFetchRpmFollowupConsult,
    rpmFollowupConsultTabs,
    setRpmFollowupConsultTabs,
    setTriggerTabStatusUpdate
  } = useRpmFollowupConsultStore();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState<RpmFollowupConsultTabType>();

  const {
    data: rpmFollowupConsultsData,
    error: rpmFollowupConsultError,
    refetch: reFetchRpmFollowupConsult
  } = useQuery<GetRpmFollowupConsultType>(GET_RPM_FOLLOWUP_CONSULT, {
    variables: {
      followupConsultId: rpmFollowupConsultId
    },
    onCompleted: () => {
      setShouldReFetchRpmFollowupConsult(false);
    },
    skip: !rpmFollowupConsultId,
    fetchPolicy: 'cache-and-network'
  });
  const {
    data: rpmFollowupConsultTabsData,
    refetch: reFetchRpmFollowupConsultTabs,
    error: rpmFollowupConsultTabsError
  } = useQuery<GetRpmFollowupConsultTabsResponse>(GET_RPM_FOLLOWUP_CONSULT_TABS, {
    variables: {
      rpmFollowupConsultId: rpmFollowupConsultId,
      rpmFollowupConsultTabTypes: mandatoryTabsForRpmFollowup
    },
    skip: !rpmFollowupConsultId,
    fetchPolicy: 'cache-and-network'
  });
  const [updateRpmFollowupConsultTab] = useMutation<UpdateRpmFollowupConsultTabsTabsResponse>(UPDATE_RPM_FOLLOWUP_CONSULT_TAB);

  const getIcon = useCallback(
    (tabType: RpmWorkflowTab) => {
      const tabStatus = rpmFollowupConsultTabs[tabType]?.status;
      return (
        <Stack justifyContent="center" alignItems="center" gap="2px">
          <CheckCircleIcon
            sx={{ visibility: tabStatus === RpmSetupStatusTypes.COMPLETED ? 'visible' : 'hidden' }}
            fontSize={'medium'}
            color="success"
          />
        </Stack>
      );
    },
    [rpmFollowupConsultTabs]
  );

  const mapRpmFollowupConsultTabs = (fetchedRpmFollowupConsultTabs: RpmFollowupConsultTabType[]) => {
    const updatedRpmFollowupConsultTabs = { ...rpmFollowupConsultTabs };
    fetchedRpmFollowupConsultTabs.forEach(rpmFollowupConsultTab => {
      updatedRpmFollowupConsultTabs[rpmFollowupConsultTab.type] = {
        isDisabled: checkTabDisability(roleType, rpmFollowupConsultTab.type, patientInfo, rpmFollowupConsultTabTypes, rpmFollowupConsult),
        isReadOnly: checkTabReadOnly(roleType, rpmFollowupConsultTab.type, patientInfo, rpmFollowupConsultTabTypes, rpmFollowupConsult),
        isProgressDisabled: checkTabProgressDisabled(
          roleType,
          rpmFollowupConsultTab.type,
          patientInfo,
          rpmFollowupConsultTabTypes,
          rpmFollowupConsult
        ),
        ...rpmFollowupConsultTab
      };
    });
    setRpmFollowupConsultTabs(updatedRpmFollowupConsultTabs);
  };

  const onStatusChange = useCallback(
    async (completed: boolean, isManual?: boolean, tab?: RpmWorkflowTab) => {
      try {
        const currentTab = tab ? rpmFollowupConsultTabs[tab] : selectedTab;
        if (currentTab?.id) {
          const response = await updateRpmFollowupConsultTab({
            variables: {
              id: currentTab?.id,
              status: completed ? RpmSetupStatusTypes.COMPLETED : RpmSetupStatusTypes.IN_PROGRESS,
              isManualStatusUpdate: isManual ? isManual : true
            }
          });

          if (response?.data?.updateRpmFollowupConsultTab?.status === 'Success') {
            reFetchRpmFollowupConsultTabs();
            enqueueSnackbar('RPM Follow-up Visit tab status updated successfully', { variant: 'success' });
          } else {
            const errorMessage = response?.errors?.[0]?.message || 'Failed to update RPM Follow-up Visit tab status';
            enqueueSnackbar(errorMessage, {
              variant: 'error'
            });
          }
        }
      } catch (err: any) {
        enqueueSnackbar(err?.message ?? 'Failed to update RPM Follow-up Visit tab', { variant: 'error' });
      }
    },
    [rpmFollowupConsultTabs, selectedTab, reFetchRpmFollowupConsultTabs, enqueueSnackbar, updateRpmFollowupConsultTab]
  );

  useEffect(() => {
    return () => {
      setRpmFollowupConsult(null);
      setShouldReFetchRpmFollowupConsult(false);
      setRpmFollowupConsultTabs(initialRpmFollowupConsultTabs);
    };
  }, []);

  useEffect(() => {
    setTriggerTabStatusUpdate(onStatusChange);
  }, [onStatusChange, setTriggerTabStatusUpdate]);

  useEffect(() => {
    const tabIndex = rpmFollowupConsultTabTypes.findIndex(item => pathnameIncludes(item.path));
    if (tabIndex >= 0) {
      setSelectedTabIndex(tabIndex);
      setSelectedTab(rpmFollowupConsultTabs[rpmFollowupConsultTabTypes[tabIndex].tabType]);
    }
  }, [location.pathname, rpmFollowupConsultTabs]);

  useEffect(() => {
    if (rpmFollowupConsultError) {
      enqueueSnackbar(
        rpmFollowupConsultError?.message ?? 'Something went wrong. Could not fetch Follow-up Visit details, please try again.',
        {
          variant: 'error'
        }
      );
      setShouldReFetchRpmFollowupConsult(false);
    }
  }, [rpmFollowupConsultError]);

  useEffect(() => {
    if (rpmFollowupConsultsData?.getRpmFollowupConsult) {
      setRpmFollowupConsult(rpmFollowupConsultsData?.getRpmFollowupConsult);
    }
  }, [rpmFollowupConsultsData?.getRpmFollowupConsult]);

  useEffect(() => {
    if (shouldReFetchRpmFollowupConsult) {
      reFetchRpmFollowupConsult()
        .catch(err => console.error(err))
        .finally(() => {
          setShouldReFetchRpmFollowupConsult(false);
        });
    }
  }, [shouldReFetchRpmFollowupConsult, rpmFollowupConsultId, reFetchRpmFollowupConsult]);

  useEffect(() => {
    if (rpmFollowupConsultTabsData?.getRpmFollowupConsultTabs) {
      mapRpmFollowupConsultTabs(rpmFollowupConsultTabsData?.getRpmFollowupConsultTabs?.rpmFollowupConsultTabs);
    }
  }, [rpmFollowupConsultTabsData?.getRpmFollowupConsultTabs, rpmFollowupConsult]);

  useEffect(() => {
    if (rpmFollowupConsultTabsError) {
      enqueueSnackbar('Could not get completion status of Follow-up Visit tabs', {
        variant: 'error'
      });
    }
  }, [rpmFollowupConsultTabsError]);

  return (
    <Stack sx={{ background: theme.palette.background.default, borderRadius: 5 }} direction="column" spacing={1} padding={1}>
      <RpmFollowupConsultStatusBar />
      <RpmFollowupConsultToolbar />
      <Stack direction="column" padding={2} sx={{ background: theme.palette.background.paper, borderRadius: 4 }}>
        <Tabs
          orientation={'horizontal'}
          variant={'fullWidth'}
          value={selectedTabIndex}
          sx={{
            '& .MuiTab-root': {
              fontSize: '1rem',
              textTransform: 'capitalize'
            }
          }}
        >
          {rpmFollowupConsultTabTypes.map((tab, index) => (
            <Tab
              label={
                tab.label === RpmWorkflowTabNames[RpmWorkflowTab.CARE_PLAN] ? (
                  <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <LocalHospital fontSize="small" style={{ marginRight: 3 }} />
                    {tab.label}
                  </span>
                ) : (
                  tab.label
                )
              }
              key={`tab-${index}`}
              id={`tab-${index}`}
              icon={getIcon(tab.tabType)}
              iconPosition="top"
              data-typeid={tab.type}
              disabled={checkTabDisability(roleType, tab.tabType, patientInfo, rpmFollowupConsultTabTypes, rpmFollowupConsult)}
              onClick={() => {
                navigate(tab.path);
              }}
              style={{
                position: 'relative',
                color: tab.label === RpmWorkflowTabNames[RpmWorkflowTab.CARE_PLAN] ? color.secondaryDark : 'primary'
              }}
            />
          ))}
        </Tabs>
      </Stack>
      <Stack direction="column" spacing={2} p={4} sx={{ background: theme.palette.background.paper, borderRadius: 4 }}>
        {selectedTab?.status && (
          <RpmTabStatusHandler
            tabType={selectedTab.type as RpmWorkflowTab}
            tabStatus={selectedTab.status}
            onStatusChange={onStatusChange}
            canCurrentTabBeCompleted={!selectedTab.isProgressDisabled}
          />
        )}
        <Outlet />
      </Stack>
    </Stack>
  );
};

export default RpmFollowupConsultSection;
