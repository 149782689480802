import Button from '@/components/Button';
import EditPatientReviewNoteDialog from '@/components/Dialogs/EditPatientReviewNoteDialog';
import { FollowupFormModeTypes } from '@/components/Dialogs/FollowUpAddEditDialog';
import FollowUpTaskAddEditDialog from '@/components/Dialogs/FollowUpTaskAddEditDialog';
import { ProfileAvatar } from '@/components/ProfileAvatar';
import { Body1, Caption, H4, Small } from '@/components/Typography';
import type { GetFilteredRelyingPartyAdminsResponse } from '@/graphql/account';
import { FollowUpCategoryTypes } from '@/graphql/account';
import { DELETE_REVIEW_NOTE, SAVE_PATIENT_REVIEW_NOTE } from '@/graphql/remotePatientMonitoring';
import type { RelyingPartyAdminData } from '@/routes/Administration';
import { color } from '@/styles/assets/colors';
import { FollowUpRemindersTypes } from '@/types/accountProfile';
import type { GeneralUpdateResponseType } from '@/types/graphql';
import { ReviewNoteCategoryType, type PatientReviewNoteType } from '@/types/remotePatientMonitoring';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { formatDateAndTime } from '@/util/format';
import { ReviewNoteCategoryTypeColor } from '@/util/rpm';
import { convertToSentenceCase } from '@/util/string';
import { useAccountStore } from '@/zustand/AccountStore';
import { useMutation, useReactiveVar } from '@apollo/client';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import ReplyIcon from '@mui/icons-material/Reply';
import SendIcon from '@mui/icons-material/Send';
import { Box, Card, CardContent, CardHeader, Chip, Divider, IconButton, Stack, TextField } from '@mui/material';
import parse from 'html-react-parser';
import moment from 'moment';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import type { ChangeEvent, FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import RpmPatientReviewFollowUpCard from './RpmPatientReviewFollowUpCard';
import RpmPatientReviewReplyNote from './RpmPatientReviewReplyNote';

type RpmPatientReviewNoteProps = {
  reviewWorkflowId?: string;
  reviewNoteId: string;
  relyingPartyAdminId?: string;
  noteCategory: ReviewNoteCategoryType | null;
  user: {
    avatar?: string;
    name: string;
  };
  message: string;
  replyNotes?: PatientReviewNoteType[];
  followupReminders: FollowUpRemindersTypes[];
  createdAt: string;
  relyingPartyAdminData?: GetFilteredRelyingPartyAdminsResponse;
  truentityId?: string;
};

const RpmPatientReviewNote: FC<RpmPatientReviewNoteProps> = ({
  reviewWorkflowId,
  relyingPartyAdminId,
  reviewNoteId,
  message,
  noteCategory,
  user,
  replyNotes,
  followupReminders,
  createdAt,
  relyingPartyAdminData,
  truentityId
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const { showModal } = useModal();
  const { setIsReviewWorkflowChanged } = useAccountStore();

  const hasProperty = <T extends object>(obj: unknown, key: keyof T): obj is T => {
    return typeof obj === 'object' && obj !== null && key in obj;
  };

  const isReplyNote = (item: unknown): item is PatientReviewNoteType => hasProperty(item, 'authorType');
  const isFollowUpReminder = (item: unknown): item is FollowUpRemindersTypes => hasProperty(item, 'followUpOn');

  const replies = [...(replyNotes || []), ...followupReminders].sort(
    (a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf()
  );

  const [showReplies, setShowReplies] = useState<boolean>((!!replies && replies?.length > 0) || false);
  const [newReplyText, setNewReplyText] = useState<string>('');
  const [isCardHovered, setIsCardHovered] = useState<boolean>(false);

  const [deletePatientReviewNote, { loading: loadingDeleteNote }] = useMutation<{
    deleteAccountReviewNote: GeneralUpdateResponseType;
  }>(DELETE_REVIEW_NOTE);
  const [savePatientReviewNote, { loading: loadingSavePatientReviewNote }] = useMutation<{
    createAccountReviewNote: GeneralUpdateResponseType;
  }>(SAVE_PATIENT_REVIEW_NOTE);

  const isAuthor = useMemo(() => currentUser && currentUser.id === relyingPartyAdminId, [currentUser, relyingPartyAdminId]);
  const hideReply = useMemo(() => noteCategory === ReviewNoteCategoryType.INTRODUCTORY, [noteCategory]);
  const hideTask = useMemo(
    () => noteCategory && [ReviewNoteCategoryType.INTRODUCTORY, ReviewNoteCategoryType.ASSESSMENT].includes(noteCategory),
    [noteCategory]
  );
  const hideDelete = useMemo(() => noteCategory === ReviewNoteCategoryType.INTRODUCTORY, [noteCategory]);

  const handleChooseEdit = useCallback(() => {
    const modal = showModal(EditPatientReviewNoteDialog, {
      hideDialog: () => {
        modal.hide();
      },
      note: message,
      noteId: reviewNoteId,
      title: 'Update Patient Review Note'
    });
  }, [message, reviewNoteId, showModal]);

  const handleShowReply = (isShow: boolean) => setShowReplies(isShow);

  const handleCreateTask = useCallback(() => {
    try {
      const relyingPartyAdmins = (relyingPartyAdminData?.filteredRelyingPartyAdmins?.relyingPartyAdmins || []) as RelyingPartyAdminData[];
      const modal = showModal(FollowUpTaskAddEditDialog, {
        title: 'Add New Chart Review Task',
        truentityId,
        category: FollowUpCategoryTypes.CHART_REVIEW,
        formMode: FollowupFormModeTypes.ADD,
        accountAssignees: relyingPartyAdmins,
        reviewNoteData: { reviewNoteId, message },
        hideDialog: () => modal.hide(),
        onUpdated: () => {
          setIsReviewWorkflowChanged(true);
          modal.hide();
        }
      });
    } catch (error) {
      console.error('Failed to fetch relying party admins:', error);
    }
  }, [message, relyingPartyAdminData?.filteredRelyingPartyAdmins?.relyingPartyAdmins, reviewNoteId, showModal, truentityId]);

  const handleDeleteReviewNote = useCallback(async () => {
    try {
      const response = await deletePatientReviewNote({
        variables: {
          accountReviewNoteId: reviewNoteId
        }
      });

      if (response.data?.deleteAccountReviewNote?.status === 'Success') {
        enqueueSnackbar('Review Note Deleted Successfully', {
          variant: 'success'
        });
        setIsReviewWorkflowChanged(true);
      } else {
        const errorMessage = response?.errors?.[0]?.message ?? 'Failed to delete Review Note. Please try again later.';
        enqueueSnackbar(errorMessage, {
          variant: 'error'
        });
      }
    } catch (err: unknown) {
      let errorMessage = 'Failed to delete Review Note. Please try again later.';

      if (err instanceof Error) {
        errorMessage = err?.message;
      }
      enqueueSnackbar(errorMessage, {
        variant: 'error'
      });
    }
  }, [deletePatientReviewNote, enqueueSnackbar, reviewNoteId]);

  const handleNewReplyChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setNewReplyText(event.target.value);
    },
    [setNewReplyText]
  );

  const handleReplyReviewNote = useCallback(async () => {
    try {
      const response = await savePatientReviewNote({
        variables: {
          accountReviewWorkflowId: reviewWorkflowId,
          note: newReplyText,
          accountReviewNoteId: reviewNoteId
        }
      });

      if (response?.data?.createAccountReviewNote?.status === 'Success') {
        enqueueSnackbar('Reply Added Successfully', {
          variant: 'success'
        });
        setIsReviewWorkflowChanged(true);
        setNewReplyText('');
      } else {
        const errorMessage = response?.errors?.[0]?.message ?? 'Failed to save Reply. Please try again later.';
        enqueueSnackbar(errorMessage, {
          variant: 'error'
        });
      }
    } catch (err: unknown) {
      let errorMessage = 'Failed to save Reply. Please try again later.';

      if (err instanceof Error) {
        errorMessage = err?.message;
      }

      enqueueSnackbar(errorMessage, {
        variant: 'error'
      });
    }
  }, [savePatientReviewNote, enqueueSnackbar, setNewReplyText, newReplyText, reviewNoteId, reviewWorkflowId]);

  return (
    <Card
      sx={{ width: '95%', height: 'auto', bgcolor: 'white' }}
      raised
      elevation={0}
      variant="outlined"
      onMouseLeave={() => setIsCardHovered(false)}
      onMouseEnter={() => setIsCardHovered(true)}
    >
      <CardHeader
        avatar={
          <ProfileAvatar
            avatarUrl={user?.avatar}
            name={user?.name}
            sx={{ bgcolor: color.primaryMain, width: 35, height: 35, fontSize: 'small' }}
            aria-label="me-avatar"
          />
        }
        title={<H4 sx={{ color: color.black100 }}>{user?.name}</H4>}
        action={
          <>
            {!isCardHovered ? (
              <Caption sx={{ color: color.grey600 }}>{formatDateAndTime(createdAt)}</Caption>
            ) : (
              <>
                {!hideReply && (
                  <IconButton size="small" onClick={() => handleShowReply(!showReplies)}>
                    <ReplyIcon />
                  </IconButton>
                )}
                <IconButton size="small" onClick={handleChooseEdit} disabled={!isAuthor}>
                  <EditIcon />
                </IconButton>
                {!hideTask && (
                  <IconButton size="small" onClick={handleCreateTask} disabled={!isAuthor}>
                    <EditCalendarIcon />
                  </IconButton>
                )}
                {!hideDelete && (
                  <IconButton size="small" onClick={handleDeleteReviewNote} disabled={!isAuthor || loadingDeleteNote}>
                    <DeleteOutlineIcon />
                  </IconButton>
                )}
              </>
            )}
          </>
        }
        sx={{
          p: 1,
          m: 1,
          '& .MuiCardHeader-action': {
            alignSelf: 'center'
          }
        }}
      ></CardHeader>
      <CardContent sx={{ p: 1, m: 1, pb: '8px !important' }}>
        <Body1 sx={{ color: color.black100 }}>{parse(message)}</Body1>
        {noteCategory && (
          <Box mt={1}>
            <Chip
              label={convertToSentenceCase(noteCategory)}
              size="small"
              variant="outlined"
              color={ReviewNoteCategoryTypeColor.get(noteCategory)}
            />
          </Box>
        )}
        {!hideReply && (
          <Box mt={2}>
            <Divider textAlign="left">{showReplies && replies && replies.length >= 0 ? <Small>Replies</Small> : null}</Divider>
            {!showReplies && replies && replies.length === 0 ? (
              <Stack justifyContent="flex-end" alignItems="flex-end" width="100%" pt={2}>
                <Button size="small" variant="text" onClick={() => handleShowReply(true)}>
                  Reply
                </Button>
              </Stack>
            ) : !showReplies && replies && replies.length > 0 ? (
              <Stack justifyContent="flex-end" alignItems="flex-end" width="100%" pt={2}>
                <Button size="small" variant="text" onClick={() => handleShowReply(true)}>
                  Show Replies
                </Button>
              </Stack>
            ) : null}
          </Box>
        )}
      </CardContent>

      {showReplies ? (
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          marginLeft={3}
          sx={{
            backgroundColor: theme => theme.palette.background.default
          }}
        >
          {replies && replies.length > 0 && (
            <CardContent sx={{ p: 1, m: 1 }}>
              {replies.map(reply => (
                <>
                  {isReplyNote(reply) ? (
                    <RpmPatientReviewReplyNote reply={reply} key={reply.id} />
                  ) : isFollowUpReminder(reply) ? (
                    <RpmPatientReviewFollowUpCard followUp={reply} key={reply.id} />
                  ) : null}
                </>
              ))}
            </CardContent>
          )}

          <CardContent sx={{ m: 1, p: 1 }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <TextField
                id="note-reply"
                multiline
                minRows={1}
                maxRows={3}
                size="small"
                placeholder="Write a reply..."
                variant="outlined"
                fullWidth
                value={newReplyText}
                onChange={handleNewReplyChange}
              />
              <IconButton type="submit" disabled={newReplyText === '' || loadingSavePatientReviewNote} onClick={handleReplyReviewNote}>
                <SendIcon />
              </IconButton>
            </Stack>
          </CardContent>

          <Stack width="100%" direction="row" justifyContent="flex-end">
            <Button
              sx={{
                fontSize: 'small',
                padding: 0.5,
                margin: 0.5
              }}
              size="small"
              type="button"
              variant={'text'}
              onClick={() => handleShowReply(false)}
            >
              Collapse
            </Button>
          </Stack>
        </Stack>
      ) : null}
    </Card>
  );
};

export default RpmPatientReviewNote;
