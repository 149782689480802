import type { Theme } from '@mui/material/styles';
import type { SxProps } from '@mui/system';

interface StyleProps {
  isWindowExpanded: boolean;
  isPoppedToNewWindow: boolean;
  drawerWidth: string;
}

const styles = {
  card: ({ isWindowExpanded, isPoppedToNewWindow, drawerWidth }: StyleProps): SxProps<Theme> =>
    isPoppedToNewWindow
      ? { height: '100%', display: 'flex', background: '#fff' }
      : {
          zIndex: theme => theme.zIndex.drawer + 20,
          position: 'fixed',
          bottom: isWindowExpanded ? 0 : 10,
          right: isWindowExpanded ? 0 : 40,
          width: isWindowExpanded ? `calc(100% - ${drawerWidth})` : 720,
          height: isWindowExpanded ? `calc(100% - 85px)` : 590,
          background: '#fff',
          display: 'flex'
        },

  newChatButton: {
    color: theme => theme.palette.primary.main,
    minWidth: 'auto',
    padding: '8px',
    my: '0.5rem',
    borderRadius: '4px',
    backgroundColor: 'rgba(43, 82, 129, 0.1)',
    '&:hover': {
      backgroundColor: 'rgba(43, 82, 129, 0.2)'
    }
  },

  conversationStack: {
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      borderRadius: 8
    }
  },

  conversationButton: (isSelected: boolean): SxProps<Theme> => ({
    textTransform: 'none',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
    p: 1,
    width: '100%',
    backgroundColor: isSelected ? 'rgba(43, 82, 129, 0.1)' : 'inherit',
    '&:hover': {
      backgroundColor: 'rgba(43, 82, 129, 0.2)'
    }
  }),

  cardHeader: (isWindowExpanded: boolean): SxProps<Theme> => ({
    p: 2,
    cursor: isWindowExpanded ? 'auto' : 'move',
    borderBottom: 1,
    borderColor: theme => theme.palette.grey[300]
  }),

  cardContent: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    flexGrow: 1,
    padding: 1,
    gap: 1.5,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.5em'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.12)',
      borderRadius: 8
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    }
  },

  alert: {
    backgroundColor: 'grey.100',
    color: 'grey.800',
    border: '1px solid',
    borderColor: 'grey.400',
    my: '1rem'
  },

  suggestionButton: ({
    backgroundColor = 'rgba(0, 0, 0, 0.05)',
    hoverColor = 'rgba(0, 0, 0, 0.1)',
    borderColor = '#cb3772'
  }: {
    backgroundColor?: string;
    hoverColor?: string;
    borderColor?: string;
  }): SxProps<Theme> => ({
    fontSize: '0.8125rem',
    flexShrink: 0,
    justifyContent: 'flex-start',
    borderRadius: 32,
    textTransform: 'none',
    padding: '8px 12px',
    borderColor: borderColor,
    textAlign: 'left',
    lineHeight: '19.5px',
    backgroundColor,
    '&:hover': {
      backgroundColor: hoverColor
    }
  })
};

export default styles;
