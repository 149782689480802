import { color } from '@/styles/assets/colors';
import { Stack } from '@mui/material';
import RpmMyPatientHeader from '../Componenets/RpmMyPatientHeader';
import RpmMyPatientKpiSection from '../Componenets/RpmMyPatientKpiSection';
import RpmMyPatientsDataGrid from './RpmMyPatientsDataGrid';
import RpmMyPatientsFilters from './RpmMyPatientsFilters';
import { RpmMyPatientsProvider } from './RpmMyPatientsProvider';
import RpmMyPatientsTabs from './RpmMyPatientsTabs';

const RpmMyPatients = () => {
  return (
    <RpmMyPatientsProvider>
      <Stack
        spacing={2}
        sx={{
          padding: 3,
          backgroundColor: color.paper,
          borderRadius: '8px'
        }}
      >
        <RpmMyPatientHeader />
        <RpmMyPatientKpiSection />
      </Stack>
      <Stack
        sx={{
          marginTop: '16px',
          '&.MuiStack-root': { backgroundColor: color.paper, borderRadius: '8px', padding: '24px 16px' }
        }}
      >
        <Stack sx={{ pt: 2 }}>
          <RpmMyPatientsTabs />
          <RpmMyPatientsFilters />
          <RpmMyPatientsDataGrid />
        </Stack>
      </Stack>
    </RpmMyPatientsProvider>
  );
};

export default RpmMyPatients;
