import type { CopilotConversationMessage, CopilotConversationTitle, MessageState } from '@/types/coPilot';
import { gql } from '@apollo/client';

export const SEND_COPILOT_MESSAGE = gql`
  mutation SendMessage($truentityId: ID!, $relyingPartyId: ID!, $message: String!) {
    sendMessage(truentityId: $truentityId, relyingPartyId: $relyingPartyId, message: $message) {
      status
      message
    }
  }
`;

export type SendCoPilotMessageResponse = {
  sendMessage: {
    status: string;
    message: string;
  };
};

export const COPILOT_MESSAGING_SUBSCRIPTION = gql`
  subscription OnCopilotMessage($truentityId: ID!, $relyingPartyId: ID!, $relyingPartyAdminId: ID!) {
    copilotMessaging(truentityId: $truentityId, relyingPartyId: $relyingPartyId, relyingPartyAdminId: $relyingPartyAdminId) {
      id
      truentityId
      relyingPartyAdminId
      relyingPartyId
      type
      message
      messageState
    }
  }
`;

export type CopilotConversationTitlesResponse = {
  getAccountCoPilotConversationTitles: CopilotConversationTitle;
};

export const QUERY_COPILOT_CONVERSATION_TITLES = gql`
  query GetAccountCoPilotConversationTitles($truentityId: ID!) {
    getAccountCoPilotConversationTitles(truentityId: $truentityId) {
      id
      truentityId
      title
      feature
      createdAt
    }
  }
`;

export type CopilotConversationMessageResponse = {
  getAccountCoPilotConversations: CopilotConversationMessage;
};

export const QUERY_COPILOT_CONVERSATION_MESSAGES = gql`
  query GetAccountCoPilotConversations($truentityId: ID!, $titleId: ID!) {
    getAccountCoPilotConversations(truentityId: $truentityId, titleId: $titleId) {
      truentityId
      message
      messageType
    }
  }
`;

export type CopilotMessagingSubscriptionResponse = {
  copilotMessaging: {
    id?: string;
    truentityId: string;
    relyingPartyId: string;
    relyingPartyAdminId: string;
    type: string;
    message: string;
    messageState: MessageState;
  };
};

export type LoadMessageContextResponse = {
  messageContextLoad: {
    status: string;
    message: string;
  };
};

export const LOAD_MESSAGE_CONTEXT = gql`
  mutation MessageContextLoad($truentityId: ID!, $relyingPartyId: ID!) {
    messageContextLoad(truentityId: $truentityId, relyingPartyId: $relyingPartyId) {
      status
      message
    }
  }
`;

export type RemoveMessageContextResponse = {
  messageContextLoad: {
    status: string;
    message: string;
  };
};

export const REMOVE_MESSAGE_CONTEXT = gql`
  mutation RemoveMessageContext($truentityId: ID!) {
    removeMessageContext(truentityId: $truentityId) {
      message
      status
    }
  }
`;
