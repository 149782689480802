import type { JSONData } from '@/components/JsonKit/JsonEditor';
import CircleIcon from '@mui/icons-material/Circle';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import type React from 'react';

type Props = {
  immediateActions: JSONData;
};

const FollowUpImmediateActionsFiled: React.FC<Props> = ({ immediateActions }) => {
  return (
    <List dense>
      {immediateActions.map(action => (
        <ListItem alignItems="flex-start">
          <ListItemIcon>
            <CircleIcon color="primary" sx={{ fontSize: '.8rem !important' }} />
          </ListItemIcon>
          <ListItemText primary={action} />
        </ListItem>
      ))}
    </List>
  );
};

export default FollowUpImmediateActionsFiled;
