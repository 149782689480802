import { UPDATE_ACCOUNT_REVIEW_NOTE } from '@/graphql/remotePatientMonitoring';
import type { GeneralUpdateResponseType } from '@/types/graphql';
import { useAccountStore } from '@/zustand/AccountStore';
import { useMutation } from '@apollo/client';
import { DialogActions, DialogContent, Stack, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import type React from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import Button from '../Button';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  noteId: string;
  note: string;
  hideDialog: () => void;
};

type FormValues = {
  note: string;
};

const EditPatientReviewNoteDialog = ({ hideDialog, noteId, note, ...props }: Props): React.ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const { setIsReviewWorkflowChanged } = useAccountStore();
  const [updatePatientReviewNote, { loading: updatePatientReviewNoteLoading }] = useMutation<{
    updateAccountReviewNote: GeneralUpdateResponseType;
  }>(UPDATE_ACCOUNT_REVIEW_NOTE);
  const { control, reset, handleSubmit, watch } = useForm<FormValues>({ defaultValues: { note } });

  const onSubmit: SubmitHandler<FormValues> = data => handleUpdateReviewNote(data);
  const notesWatch = watch('note');

  const handleUpdateReviewNote = async (values: FormValues) => {
    try {
      const response = await updatePatientReviewNote({
        variables: {
          accountReviewNoteId: noteId,
          note: values.note
        }
      });

      if (response?.data?.updateAccountReviewNote?.status === 'Success') {
        enqueueSnackbar('Patient Review Note updated successfully.', {
          variant: 'success'
        });
        setIsReviewWorkflowChanged(true);
      } else {
        const errorMessage = response?.errors?.[0]?.message ?? 'Could not update Patient Review Note.';
        enqueueSnackbar(errorMessage, {
          variant: 'error'
        });
      }
      hideDialog();
    } catch (err: any) {
      enqueueSnackbar(err?.message ?? 'Could not update Patient Review Note.', {
        variant: 'error'
      });
    }
  };

  return (
    <BaseDialog hideDialog={hideDialog} fullWidth maxWidth={'sm'} {...props}>
      <DialogContent>
        <Stack
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          sx={{
            width: '100%',
            height: '100%',
            flex: '1 1 auto'
          }}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            control={control}
            name="note"
            render={({ field: { onChange, value } }) => (
              <TextField autoFocus={true} required minRows={3} onChange={onChange} value={value} fullWidth multiline placeholder="Note" />
            )}
          />
          <DialogActions sx={{ justifyContent: 'end', p: 0 }}>
            <Button
              type="reset"
              a11yLabel="Cancel"
              appearance="outline"
              onClick={() => {
                reset();
                hideDialog();
              }}
            />
            <Button
              disabled={note === notesWatch}
              isLoading={updatePatientReviewNoteLoading}
              type="submit"
              a11yLabel="Save"
              appearance="primary"
            />
          </DialogActions>
        </Stack>
      </DialogContent>
    </BaseDialog>
  );
};

export default EditPatientReviewNoteDialog;
