import Button from '@/components/Button';
import MultilineCell from '@/components/DataGrid/MultilineCell';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import AddAccountGoalDialog from '@/components/Dialogs/AddAccountGoalDialog';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import DropdownMenu from '@/components/DropdownMenu';
import TruentityTextField from '@/components/TruentityTextField';
import { H4 } from '@/components/Typography';
import PatientDetailContext from '@/context/patientDetailContext';
import { GET_ACCOUNT_SMART_GOALS, UPDATE_ACCOUNT_SMART_GOAL, type GetAccountSmartGoalResponse } from '@/graphql/remotePatientMonitoring';
import useDebounce from '@/hooks/useDebounce';
import type { AccountSmartGoalType } from '@/types/remotePatientMonitoring';
import { RpmAccountSmartGoalStatuses } from '@/types/remotePatientMonitoring';
import { formatDateAndTime } from '@/util/format';
import { formatFromSnakeCase, unknown } from '@/util/string';
import { useMutation, useQuery } from '@apollo/client';
import { VisibilityOff } from '@mui/icons-material';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import type { GridColDef, GridRowId } from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';
import type { MenuItemData } from 'mui-nested-menu';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

type Props = {
  rpmFollowupConsultId?: string;
  isAccountApproved?: boolean;
  setHasGoals: (value: boolean) => void;
  onSmartGoalsUpdate: () => void;
  isReadOnly: boolean;
  hasClinicalDiagnoses: boolean;
  isFollowupCompleted?: boolean;
};

const SmartGoalsDataGrid: React.FC<Props> = ({
  rpmFollowupConsultId,
  isAccountApproved,
  setHasGoals,
  onSmartGoalsUpdate,
  isReadOnly,
  hasClinicalDiagnoses,
  isFollowupCompleted = false
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { hideModal, showModal } = useModal();
  const { id: truentityId } = useParams();
  const confirmationModalId = useRef<string | null>(null);
  const { patientInfo } = useContext(PatientDetailContext);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebounce<string>(searchTerm, 500);

  const {
    data: accountSmartGoalData,
    loading: accountSmartGoalLoading,
    refetch: accountSmartGoalRefetch
  } = useQuery<GetAccountSmartGoalResponse>(GET_ACCOUNT_SMART_GOALS, {
    variables: {
      truentityId,
      filterOptions: {
        searchTerm: debouncedSearchTerm
      },
      pageNum: currentPage + 1,
      pageSize: DEFAULT_PAGE_SIZE
    },
    fetchPolicy: 'cache-and-network'
  });

  const [updateAccountSmartGoal] = useMutation(UPDATE_ACCOUNT_SMART_GOAL, {
    variables: {
      truentityId
    },
    onCompleted: response => {
      enqueueSnackbar(response.updateAccountSmartGoal.message, { variant: 'success' });
      accountSmartGoalRefetch();
      onSmartGoalsUpdate();
      confirmationModalId.current && hideModal(confirmationModalId.current);
    },
    onError: err => {
      if (err.graphQLErrors && err.graphQLErrors[0]) {
        enqueueSnackbar(err.graphQLErrors[0].message, { variant: 'error' });
      } else {
        enqueueSnackbar('Unable to update the goal. Please try again later.', { variant: 'error' });
      }
    }
  });

  const handleArchiveGoals = useCallback(
    async (goalId: GridRowId) => {
      updateAccountSmartGoal({
        variables: {
          accountSmartGoalId: goalId,
          isArchived: true
        }
      });
    },
    [updateAccountSmartGoal]
  );

  const handleConfirmationModal = useCallback(
    (goalId: GridRowId) => {
      const modal = showModal(ConfirmDialog, {
        title: 'Archive Goal Confirmation',
        message: 'Are you sure you want to archive this goal? This action cannot be undone.',
        onAgree: () => handleArchiveGoals(goalId),
        onDisagree: () => modal.hide()
      });
      confirmationModalId.current = modal.id;
    },
    [handleArchiveGoals, showModal]
  );

  const handleGoalStatus = useCallback(
    (goalId: GridRowId, status: string, goalConsultId?: string) => {
      if ((goalConsultId === null && rpmFollowupConsultId === null) || goalConsultId === rpmFollowupConsultId) {
        // show dialog
        const modal = showModal(ConfirmDialog, {
          title: 'Confirm Goal Status Update',
          message:
            'This is a new goal. The status of new goals should be updated during subsequent follow-ups. Are you sure you want to change the status now?',
          onAgree: () => {
            updateAccountSmartGoal({
              variables: {
                accountSmartGoalId: goalId,
                status
              }
            });
            modal.hide();
          },
          onDisagree: () => modal.hide()
        });
      } else {
        updateAccountSmartGoal({
          variables: {
            accountSmartGoalId: goalId,
            status
          }
        });
      }
    },
    [updateAccountSmartGoal, rpmFollowupConsultId]
  );

  const handleAddGoal = () => {
    const modal = showModal(AddAccountGoalDialog, {
      title: 'Add Goals',
      truentityId,
      rpmFollowupConsultId,
      reasonForVisit: patientInfo?.accountRpmMonitoredCondition,
      hideDialog: () => modal.hide(),
      onSuccess: () => {
        accountSmartGoalRefetch();
        onSmartGoalsUpdate();
        modal.hide();
      }
    });
  };

  const dropDownMenuItems = useCallback(
    (goalId: GridRowId, goalConsultId?: string): MenuItemData[] => {
      return Object.values(RpmAccountSmartGoalStatuses).map(status => {
        return {
          label: formatFromSnakeCase(status),
          callback: () => handleGoalStatus(goalId, status, goalConsultId)
        };
      });
    },
    [handleGoalStatus]
  );

  const getButtonColor = useCallback((value: string) => {
    switch (value) {
      case RpmAccountSmartGoalStatuses.PENDING:
        return 'info';
      case RpmAccountSmartGoalStatuses.MET:
        return 'success';
      case RpmAccountSmartGoalStatuses.PARTIALLY_MET:
        return 'warning';
      case RpmAccountSmartGoalStatuses.NOT_MET:
        return 'error';
      default:
        return 'info';
    }
  }, []);

  const isSmartGoalsDisabled = isReadOnly || !hasClinicalDiagnoses || (!rpmFollowupConsultId && isAccountApproved) || isFollowupCompleted;

  const smartGoalsColumns: GridColDef<AccountSmartGoalType>[] = useMemo(
    () => [
      {
        field: 'goalContent',
        headerName: 'Goal',
        minWidth: 350,
        sortable: true,
        flex: 3,
        align: 'left',
        headerAlign: 'left',
        valueGetter: params => params.row.goalContent,
        renderCell: params => (
          <Tooltip title={params.value} arrow>
            <MultilineCell>{params.value}</MultilineCell>
          </Tooltip>
        )
      },
      {
        field: 'createdAt',
        headerName: 'Created At',
        minWidth: 110,
        sortable: true,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        valueGetter: params => formatDateAndTime(params.row.createdAt) || unknown()
      },
      {
        field: 'updatedAt',
        headerName: 'Updated At',
        minWidth: 110,
        sortable: true,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        valueGetter: params => formatDateAndTime(params.row.updatedAt) || unknown()
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 150,
        maxWidth: 150,
        sortable: false,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        renderCell: cellValue => (
          <DropdownMenu
            menuItemsData={{
              label: formatFromSnakeCase(cellValue.value),
              items: dropDownMenuItems(cellValue.id, cellValue.row.rpmFollowupConsult?.id)
            }}
            ButtonProps={{
              variant: 'outlined',
              size: 'small',
              color: getButtonColor(cellValue.value),
              sx: { borderRadius: 8, padding: 0.5 }
            }}
          />
        )
      },
      {
        field: 'action',
        headerName: 'Archive',
        maxWidth: 80,
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: cellValue => {
          return (
            <IconButton onClick={() => handleConfirmationModal(cellValue.id)}>
              <VisibilityOff fontSize="small" />
            </IconButton>
          );
        }
      }
    ],
    [dropDownMenuItems, getButtonColor, handleConfirmationModal]
  );

  useEffect(() => {
    const accountGoalsLength = accountSmartGoalData?.getAccountSmartGoals?.accountGoals?.length ?? 0;
    setHasGoals(accountGoalsLength > 0);
  }, [accountSmartGoalData?.getAccountSmartGoals?.accountGoals]);

  return (
    <Box mt={4}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
        <H4>Goals</H4>
        <Button size="small" onClick={handleAddGoal} disabled={isSmartGoalsDisabled}>
          Add Goal
        </Button>
      </Stack>
      <TruentityDataGrid
        name={'dg-smart-goals'}
        autoHeight
        customFilter={
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <TruentityTextField
              size="small"
              name="search"
              label="Search"
              sx={{ width: '50%' }}
              onChange={input => setSearchTerm(input.target.value)}
            />
          </Stack>
        }
        rows={accountSmartGoalData?.getAccountSmartGoals?.accountGoals || []}
        paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
        onPaginationModelChange={({ page }) => {
          setCurrentPage(page);
        }}
        getRowHeight={() => 'auto'}
        paginationMode="server"
        rowCount={accountSmartGoalData?.getAccountSmartGoals?.meta?.totalCount || 0}
        columns={smartGoalsColumns}
        disableRowSelectionOnClick
        sx={{ backgroundColor: '#ffffff' }}
        disabled={isSmartGoalsDisabled}
        loading={accountSmartGoalLoading}
      />
    </Box>
  );
};

export default SmartGoalsDataGrid;
