import { color } from '@/styles/assets/colors';
import { Avatar, Box } from '@mui/material';
import type React from 'react';
import TruentityIcon from '../TruentityIcon';

interface CopilotIconProps {
  isLoading?: boolean;
  isFailed?: boolean;
  iconSize?: number;
}

const CopilotIcon: React.FC<CopilotIconProps> = ({ isLoading, isFailed, iconSize }) => {
  const calculatedSize = iconSize ? iconSize : 26;
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'start',
        top: '-10px'
      }}
    >
      {/* Rotating Spiner */}
      <Box
        sx={{
          display: !isLoading ? 'none' : '',
          position: 'absolute',
          width: calculatedSize + 8, // Slightly larger than Avatar
          height: calculatedSize + 8,
          border: '2px solid #20416E4D',
          borderRadius: '50%',
          borderTopColor: 'transparent',
          animation: isLoading ? 'spinAnimation 1s linear infinite' : '',
          '@keyframes spinAnimation': {
            from: { transform: 'rotate(0deg)' },
            to: { transform: 'rotate(360deg)' }
          }
        }}
      />

      {/* Avatar - Truentity icon */}
      <Avatar
        sx={{
          width: calculatedSize,
          height: calculatedSize,
          backgroundColor: 'transparent',
          border: 1,
          borderWidth: '0.5px',
          borderColor: isFailed ? color.errorLight : '#20416E4D',
          padding: '2px',
          position: 'relative',
          zIndex: 1
        }}
      >
        <TruentityIcon width={calculatedSize - 8} height={calculatedSize - 8} />
      </Avatar>
    </Box>
  );
};

export default CopilotIcon;
