import Alert from '@/components/Alert';
import Button from '@/components/Button';
import TruentityLogo from '@/components/TruentityLogo';
import TruentityTextField from '@/components/TruentityTextField';
import { H2, H6 } from '@/components/Typography';
import { LOGGED_USER, LOGIN_TENANT_ADMIN } from '@/graphql/account';
import useSettings from '@/hooks/useSettings';
import useToken from '@/hooks/useToken';
import { color } from '@/styles/assets/colors';
import { AuthErrorCodes } from '@/types/error';
import type { LoggedUserAccountData } from '@/types/graphql';
import { getRedirectUrl } from '@/util/adminstration';
import { currentLoggedRelyingParty, currentLoggedUserVar, loggedUserData } from '@/util/apollo/cache';
import { handleMutationFormErrorCb } from '@/util/error';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { Box, Paper, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import qs from 'query-string';
import { useEffect, useState } from 'react';
import { BrowserView, isMobile, isSafari, MobileView } from 'react-device-detect';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';

type FormValues = { email: string; password: string };

const defaultValues: FormValues = {
  email: '',
  password: ''
};

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [otpId, setOtpId] = useState(null);
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const [relyingPartyLogin] = useMutation(LOGIN_TENANT_ADMIN);
  const { setTokenAndRefreshToken, setRelayingPartyId, setRoleType, getIsIdle } = useToken();
  const [loggedUser, { data }] = useLazyQuery(LOGGED_USER);
  const { register, handleSubmit } = useForm<FormValues>({ defaultValues });
  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);
  const { enqueueSnackbar } = useSnackbar();

  const { parseSettingsJsonString } = useSettings();

  const showError = err => {
    console.error('err', err);
    enqueueSnackbar(err.message, { variant: 'error' });
  };

  const handleSubmitImpl = async (values: FormValues) => {
    try {
      const result = await relyingPartyLogin({
        variables: { ...values }
      });

      if (result.data!.relyingPartyLogin === null) {
        const errors = result.errors || [];
        const invalidLoginError = errors.find(err => err?.extensions?.code === 'INVALID_LOGIN');

        if (invalidLoginError) {
          invalidLoginError.message = 'Either the email or password entered is incorrect. Please try again';
          showError(invalidLoginError);
          return;
        }
      }

      if (result.data!.relyingPartyLogin!.otpId != null) {
        setOtpId(result.data!.relyingPartyLogin!.otpId);
      } else {
        const { accessToken, refreshToken, relyingPartyAdmin } = result.data!.relyingPartyLogin;
        parseSettingsJsonString(relyingPartyAdmin?.settings);
        setTokenAndRefreshToken(accessToken, refreshToken);
        currentLoggedUserVar(relyingPartyAdmin);
        const {
          relyingParty: { id },
          roleType
        } = relyingPartyAdmin;
        setRelayingPartyId(id);
        setRoleType(roleType);
      }
    } catch (err) {
      handleMutationFormErrorCb(err, {
        cb: showError,
        errorMap: {
          [AuthErrorCodes.INVALID_LOGIN]: () => ({
            message: 'Either the email or password entered is incorrect. Please try again'
          }),
          all: gqlError => ({
            message: gqlError.message
          })
        }
      });
    }
  };

  useEffect(() => {
    if (otpId) {
      navigate(`/twofactor-login`, { state: { otpId: otpId }, replace: true });
    }
  }, [navigate, otpId]);

  useEffect(() => {
    if (currentUser) {
      loggedUser({
        variables: {
          relyingPartyId: currentUser.relyingParty.id
        }
      }).catch(err => console.error(err));
    }
  }, [currentUser, loggedUser]);

  useEffect(() => {
    if (data) {
      const userData: LoggedUserAccountData = {
        name: data.relyingParty.name,
        logo: data.relyingParty.logoUrl,
        orgHandle: data.relyingParty.orgHandle,
        settings: data.relyingParty.settings,
        isCtaAccepted: data.relyingParty.isCtaAccepted
      };

      loggedUserData(userData);
      currentLoggedRelyingParty(data.relyingParty);

      const parsedUrl = qs.parse(location.search);
      const redirectTo = getRedirectUrl({ roleType: currentUser?.roleType, parsedUrl: parsedUrl.to?.toString() });
      navigate(redirectTo, { replace: true });
    }
  }, [data, location.search, navigate]);

  useEffect(() => {
    if (isMobile) {
      setTimeout(() => {
        window.location.replace('https://truentity.health');
      }, 4000);
    }
  }, []);

  return (
    <Stack alignItems={'center'} sx={{ width: '100%', height: '100%', backgroundColor: color.truentityCyan[500] }}>
      <BrowserView>
        {isSafari ? (
          <Stack width="100vw" height="100vh" justifyContent="center" alignItems="center">
            <Box maxWidth={500} bgcolor="white" p={5} textAlign="center" borderRadius={6}>
              <Box mb={2}>
                <TruentityLogo />
              </Box>
              <H2>
                Our application is not supported on this browser. Please use Google Chrome or a Chromium-based browser for optimal
                performance.
              </H2>
            </Box>
          </Stack>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Paper component={Stack} spacing={2} p={2} sx={{ width: '300px', marginTop: '10vh' }}>
              <Box
                sx={{
                  paddingBottom: '24px',
                  borderBottom: '2px solid $truentityLigthGray',
                  marginBottom: '19px'
                }}
              >
                <TruentityLogo />
              </Box>

              {getIsIdle() && <Alert status="info" description="You have been logged out due to inactivity" title={'Inactive Timeout'} />}
              {/* {location?.state?.statusCode === 401 && (
              <Alert status="error" description="You need to be logged in to access the previous page" title={'Unauthorized'} />
            )} */}

              <TruentityTextField autoFocus required {...register('email')} label="Email " type="email" />
              <TruentityTextField required {...register('password')} label="Password" type="password" />
              <Button type="submit" a11yLabel="Login" color={'secondary'} fullWidth />

              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '19px' }}>
                <H6 component={Link} color={color.truentityCyan[600]} sx={{ textDecoration: 'none' }} to="/forgot-password">
                  Forgot Password?
                </H6>
              </Box>
            </Paper>
          </form>
        )}
      </BrowserView>
      <MobileView>
        <Paper component={Stack} spacing={2} p={2} sx={{ width: '300px', marginTop: '10vh' }}>
          <Box
            sx={{
              paddingBottom: '24px',
              borderBottom: '2px solid $truentityLigthGray',
              marginBottom: '24px'
            }}
          >
            <TruentityLogo />
          </Box>
          <h3>Access to this site from your device is not allowed. You will now be redirected to https://truentity.health</h3>
        </Paper>
      </MobileView>
    </Stack>
  );
};

export default Login;
