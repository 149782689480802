export async function fetchImage(imageUrl: string) {
  try {
    const imageResponse = await fetch(imageUrl, {
      mode: 'cors',
      headers: {
        'Cache-Control': 'no-cache'
      }
    });

    if (!imageResponse.ok) {
      console.error(`Failed to fetch image from ${imageUrl}: ${imageResponse.statusText}`);
      return null;
    }
    return await imageResponse.arrayBuffer();
  } catch (error) {
    console.error('Error fetching image:', error);
    return null;
  }
}
