import Button from '@/components/Button';
import RpmApprovalStatusChip from '@/components/ChipLabel/RpmApprovalStatusChip';
import { GridItem } from '@/components/GridItem';
import GridNestedItem from '@/components/GridNestedItem';
import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import { H1, H2 } from '@/components/Typography';
import Icon from '@/elements/Icon';
import useShowMedicalServices from '@/hooks/useShowMedicalServices';
import { MedicalServices } from '@/types/admin';
import { ContactedType, RpmStatusTypes } from '@/types/remotePatientMonitoring';
import { formatDate, formatDateAndTime, formatSecondsToHMS } from '@/util/format';
import { getColorForMonthlyTimeSpent, getColorForTotalReadings } from '@/util/rpm';
import { unknown } from '@/util/string';
import { faVenusMars } from '@fortawesome/free-solid-svg-icons';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ApprovalIcon from '@mui/icons-material/Approval';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import PeopleIcon from '@mui/icons-material/People';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import PhoneIcon from '@mui/icons-material/Phone';
import RoomIcon from '@mui/icons-material/Room';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import TagIcon from '@mui/icons-material/Tag';
import { Chip, Drawer, Grid, Stack } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { PatientsData } from '../Patients/patients';

type Props = {
  patientsData: PatientsData;
};

const RpmPatientInfoDrawer = ({ patientsData }: Props) => {
  const isRPMViewable = useShowMedicalServices(MedicalServices.RPM);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [currentDate] = useState(new Date());
  const navigate = useNavigate();
  const [patientInfo, setPatientInfo] = useState<PatientsData>({} as PatientsData);
  const [fullName, setFullName] = useState<string>();
  const birthDateTodate = patientInfo?.birthDate ? new Date(patientInfo?.birthDate.replace(/-/g, '/')) : '';
  const years = moment(currentDate).diff(moment(birthDateTodate), 'years');

  const loadContactIcon = useCallback(() => {
    if (patientInfo?.lastContacted?.type === ContactedType.IN_PERSON_VISIT) {
      return <PeopleIcon />;
    } else if (patientInfo?.lastContacted?.type === ContactedType.PHONE) {
      return <PhoneIcon />;
    } else {
      return <></>;
    }
  }, [patientInfo]);

  useEffect(() => {
    if (patientsData) {
      setPatientInfo(patientsData);
    }
  }, [patientsData]);

  useEffect(() => {
    if (patientInfo?.user) {
      setFullName(
        patientInfo?.user?.lastName && patientInfo?.user?.firstName
          ? `${patientInfo?.user?.lastName}, ${patientInfo?.user?.firstName}`
          : '---'
      );
      setOpenDrawer(true);
    }
  }, [patientInfo]);

  return (
    <Drawer
      container={this}
      anchor={'right'}
      open={openDrawer}
      sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
      color="inherit"
      onClose={() => setOpenDrawer(false)}
    >
      <Stack spacing={6} p={4} sx={{ maxWidth: '600px' }}>
        <Stack spacing={2}>
          <Stack direction="row" alignItems={'center'} spacing={2}>
            <H1>{fullName} </H1>
            <Chip
              variant={'filled'}
              sx={{ textTransform: 'uppercase', letterSpacing: 2, height: 20, fontSize: '.75rem' }}
              label={patientInfo?.doNotCall ? 'Do Not Follow Up' : 'May Follow Up'}
              color={patientInfo?.doNotCall ? 'error' : 'success'}
            />
            <Box flex={1}></Box>
            <Button
              label="RPM Initial Visit & Setup"
              onClick={() => navigate(`/patients/${patientInfo?.truentityId}/details/rpm/patient-setup/encounter/consent-management`)}
            />
          </Stack>
        </Stack>

        <Stack spacing={1}>
          <H2>Profile</H2>
          <Grid container rowSpacing={2}>
            <GridItem xs={12} icon={<TagIcon />} label="Truentity Id" value={patientInfo?.truentityId?.toUpperCase()} />
            {/* @2024/04/05 Patient emails hidden */}
            {/* <GridItem xs={12} icon={<MailIcon />} label="Email" value={patientInfo?.user?.email} /> */}
            {patientInfo?.birthDate && (
              <GridItem xs={12} icon={<CalendarMonthIcon />} label="Date of Birth" value={`${formatDate(birthDateTodate)} (${years} yr)`} />
            )}
            <GridItem xs={12} icon={<LocalPhoneIcon />} label="Phone" value={<TruentityPhoneNumber value={patientInfo?.phone} />} />
            <GridItem xs={12} icon={<RoomIcon />} label="Location" value={patientInfo?.zipcode} />
            <GridItem xs={12} icon={<Icon icon={faVenusMars} />} label="Gender" value={patientInfo?.gender ?? unknown()} />
            <GridItem xs={12} icon={<MedicalInformationIcon />} label="Health Plan" value={patientInfo?.healthPlan?.orgName ?? unknown()} />
          </Grid>
        </Stack>

        {isRPMViewable && (
          <Stack spacing={1}>
            <H2>RPM</H2>
            <Grid container rowSpacing={2}>
              <GridItem
                xs={12}
                icon={<CalendarMonthIcon />}
                label="Date Enrolled"
                value={formatDate(patientInfo?.rpmEnrolledAt) ?? unknown()}
              />
              <GridItem
                xs={12}
                icon={<CalendarMonthIcon />}
                label="Onboarded On"
                value={formatDate(patientInfo?.onboardedAt) ?? unknown()}
              />
              <GridNestedItem
                parents={[
                  {
                    parentLabel: 'Provider Approvals',
                    parentIcon: <ApprovalIcon />,
                    children: [
                      {
                        childLabel: 'Approval Status',
                        childIcon: <BeenhereIcon />,
                        childValue: patientInfo.rpmApprovalStatus ? (
                          <RpmApprovalStatusChip
                            approvalStatus={patientInfo?.rpmApprovalStatus}
                            rpmSignOffStatus={patientInfo?.rpmSignOffStatus}
                          />
                        ) : (
                          unknown()
                        )
                      },
                      ...(patientInfo.rpmApprovalStatus === RpmStatusTypes.APPROVED
                        ? [
                            {
                              childLabel: 'Approved On',
                              childIcon: <CalendarMonthIcon />,
                              childValue: formatDate(patientInfo.rpmStatusUpdatedAt) || unknown()
                            }
                          ]
                        : [])
                    ]
                  }
                ]}
              />
              <GridNestedItem
                parents={[
                  {
                    parentLabel: 'Patient Outreach',
                    parentIcon: <ContactPhoneIcon />,
                    children: [
                      {
                        childLabel: 'Last Contacted At',
                        childIcon: loadContactIcon(),
                        childValue: formatDate(patientInfo?.lastContacted?.date) || unknown()
                      }
                    ]
                  }
                ]}
              />
              <GridNestedItem
                parents={[
                  {
                    parentLabel: "Current Month's Target Metrics",
                    parentIcon: <EqualizerIcon />,
                    children: [
                      {
                        childLabel: 'Monthly Total Days of Readings',
                        childIcon: <MonitorHeartIcon />,
                        childValue:
                          patientInfo?.numOfReadingsInCurrentMonth >= 0 ? (
                            <Chip
                              size="small"
                              sx={{ minWidth: '40px' }}
                              label={patientInfo?.numOfReadingsInCurrentMonth}
                              color={getColorForTotalReadings(patientInfo?.numOfReadingsInCurrentMonth)}
                            />
                          ) : (
                            unknown()
                          )
                      },
                      {
                        childLabel: 'Monthly Time Spent',
                        childIcon: <AccessTimeIcon />,
                        childValue:
                          (
                            <Chip
                              size="small"
                              sx={{ minWidth: '50px' }}
                              label={
                                patientInfo.totalTimeSpentSecsInCurrentMonth
                                  ? formatSecondsToHMS(patientInfo.totalTimeSpentSecsInCurrentMonth)
                                  : '0s'
                              }
                              color={getColorForMonthlyTimeSpent(patientInfo.totalTimeSpentSecsInCurrentMonth)}
                            />
                          ) || unknown()
                      }
                    ]
                  }
                ]}
              />
              <GridNestedItem
                parents={[
                  {
                    parentLabel: 'Devices',
                    parentIcon: <PersonalVideoIcon />,
                    children: (
                      <GridNestedItem
                        parents={
                          patientInfo?.accountsMonitoringDevices?.map(device => {
                            return {
                              parentLabel: device.monitoringDevice.name,
                              parentIcon: <CropSquareIcon />,
                              children: [
                                {
                                  childLabel: 'Last Reading Date',
                                  childIcon: <CalendarMonthIcon />,
                                  childValue:
                                    device.latestReadings && device.latestReadings.length > 0
                                      ? device.latestReadings.map((reading, index) => (
                                          <Box key={index}>{formatDateAndTime(reading?.recordedAt)}</Box>
                                        ))
                                      : unknown()
                                },
                                {
                                  childLabel: 'Last Reading',
                                  childIcon: <SsidChartIcon />,
                                  childValue:
                                    device.latestReadings && device.latestReadings.length > 0
                                      ? device.latestReadings.map((reading, index) => {
                                          const { value, unit } = reading;

                                          return <Box key={index}>{value || unit ? `${value} ${unit}` : unknown()}</Box>;
                                        })
                                      : unknown()
                                }
                              ]
                            };
                          }) || []
                        }
                      />
                    )
                  }
                ]}
              />
            </Grid>
          </Stack>
        )}
      </Stack>
    </Drawer>
  );
};

export default RpmPatientInfoDrawer;
