import type { DropzoneFile, FileWithOrigin } from '@/components/TruentityDropzone';
import TruentityDropzone from '@/components/TruentityDropzone';
import { H3 } from '@/components/Typography';
import { Stack } from '@mui/material';
import type { Dispatch, SetStateAction } from 'react';

type Props = {
  files: FileWithOrigin[];
  setFiles: Dispatch<SetStateAction<FileWithOrigin[]>>;
};

const AnnouncementMediaForm = ({ files, setFiles }: Props) => {
  const handleFiles = (files: DropzoneFile[]) => {
    setFiles(files.map(file => file));
  };

  return (
    <Stack gap={2}>
      <H3>Add Media (optional)</H3>
      <TruentityDropzone
        label="Drag your photos or videos here, or"
        maxFileCount={3}
        mediaFiles={files}
        acceptedFiles={[
          { files: { 'video/mp4': ['.mp4'], 'video/mpeg': ['.mpeg'], 'video/webm': ['.webm'] }, maxFileSize: 20 },
          { files: { 'image/*': ['.jpg', '.jpeg', '.png'] }, maxFileSize: 2 }
        ]}
        onFileUpload={handleFiles}
        onRemoveFile={handleFiles}
      />
    </Stack>
  );
};

export default AnnouncementMediaForm;
