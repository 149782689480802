import { Body1, H4, H5, Small } from '@/components/Typography';
import type { PatientReviewTemplateResponse } from '@/graphql/remotePatientMonitoring';
import { GET_PATIENT_REVIEW_TEMPLATES } from '@/graphql/remotePatientMonitoring';
import { color } from '@/styles/assets/colors';
import { useQuery } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, IconButton, Paper, Slide, Stack } from '@mui/material';
import type React from 'react';

type Props = {
  isOpen: boolean;
  monthYear?: string | null;
  setValue: (arg1: number, arg2: string) => void;
  closeDrawer: () => void;
};

const RpmPatientReviewTemplate: React.FC<Props> = ({ isOpen, monthYear, setValue, closeDrawer }) => {
  const { data: templateData } = useQuery<PatientReviewTemplateResponse>(GET_PATIENT_REVIEW_TEMPLATES);

  const onClickOnNote = (id: number, templateText: string) => {
    setValue(id, templateText);
    closeDrawer();
  };

  const replaceMonthTemplate = (templateString: string): string => {
    if (!monthYear) return templateString;

    const monthRegex = /<<month>>/g;

    return templateString.replace(monthRegex, `${monthYear}`);
  };

  return (
    <Slide direction="left" in={isOpen} mountOnEnter unmountOnExit>
      <Stack width={600} sx={{ bgcolor: color.primaryLight }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" p={2}>
          <H4>Assessment Templates</H4>
          <IconButton onClick={closeDrawer}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Stack>
        <Divider />
        <Box flex={1} pt={2} px={1} overflow="auto">
          {templateData && templateData.getPatientReviewReportTemplates.length > 0 ? (
            <Stack gap={1}>
              {templateData.getPatientReviewReportTemplates?.map(({ category, templates }, index) => (
                <Stack key={index} mb={1} gap={2}>
                  <H5>{category}</H5>
                  <Stack gap={1}>
                    {templates.map((template, index) => {
                      const templateText = replaceMonthTemplate(template.templateString);
                      return (
                        <Paper
                          component={Box}
                          key={index}
                          p={2}
                          elevation={0}
                          sx={{
                            backgroundColor: 'white',
                            cursor: 'pointer',
                            ':hover': {
                              backgroundColor: color.grey50
                            }
                          }}
                          onClick={() => onClickOnNote(index, templateText)}
                        >
                          <Small>{templateText}</Small>
                        </Paper>
                      );
                    })}
                  </Stack>
                </Stack>
              ))}
            </Stack>
          ) : (
            <Stack width="100%" height="100%" justifyContent="center" alignItems="center">
              <Body1>No Assessment Templates are available</Body1>
            </Stack>
          )}
        </Box>
        <Divider />
      </Stack>
    </Slide>
  );
};

export default RpmPatientReviewTemplate;
