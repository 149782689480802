import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import ProviderAddDialog from '@/components/Dialogs/ProviderAddDialog';
import ProviderUpdateDialog from '@/components/Dialogs/ProviderUpdateDialog';
import MainSideMenu from '@/components/SideMenus/MainSideMenu';
import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import TruentityTextField from '@/components/TruentityTextField';
import { H1, Subtitle } from '@/components/Typography';
import SideMenuContext from '@/context/sideMenuContext';
import type { GetAllProvidersResponse, GetProvidersByFilterResponse } from '@/graphql/remotePatientMonitoring';
import { GET_ALL_PROVIDERS, GET_PROVIDERS_BY_FILTER } from '@/graphql/remotePatientMonitoring';
import useToken from '@/hooks/useToken';
import { color } from '@/styles/assets/colors';
import { Role } from '@/types/admin';
import { ProvidersListSearchFilters } from '@/types/providers';
import type { ProviderType } from '@/types/remotePatientMonitoring';
import { addIfExists } from '@/util/object';
import { useLazyQuery, useQuery } from '@apollo/client';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Grid, IconButton, Paper, Stack } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

type LookupFilterOptions = {
  firstName?: string;
  lastName?: string;
};

const Providers = () => {
  const { setContent } = useContext(SideMenuContext);
  const { showModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { roleType } = useToken();
  const [searchParams] = useSearchParams();

  const [isSuperAdmin] = useState<boolean>(roleType === Role.SUPER);
  const [currentPage, setCurrentPage] = useState(0);
  const [providers, setProviders] = useState<ProviderType[]>([]);
  const [rowCount, setRowCount] = useState(DEFAULT_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(rowCount);
  const [providerType, setProviderType] = useState<ProvidersListSearchFilters>(ProvidersListSearchFilters.Lookup);
  const [searchQuery, setSearchQuery] = useState<{ value: string; filterOption: string }>({ value: '', filterOption: '' });

  const {
    control,
    reset: resetLookupForm,
    setValue,
    handleSubmit
  } = useForm<LookupFilterOptions>({
    defaultValues: {
      firstName: '',
      lastName: ''
    }
  });

  // NOTE: This code is commented out because the Provider Portal is no longer in use.
  // const [sendProviderSessionToAdmin] = useMutation<{ sendProviderSessionToAdmin: GeneralUpdateResponseType }>(
  //   SEND_PROVIDER_SESSION_TO_ADMIN_REVIEW
  // );

  const [getInfoByLookup, { loading: loadingProviders, error: errorProvidersLookup }] = useLazyQuery<GetProvidersByFilterResponse>(
    GET_PROVIDERS_BY_FILTER,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network'
    }
  );

  const {
    loading: providersDataLoading,
    data: providersData,
    refetch: providersDataReFetch,
    error: errorAllProviders
  } = useQuery<GetAllProvidersResponse>(GET_ALL_PROVIDERS, {
    variables: {
      pageNum: currentPage + 1,
      pageSize: DEFAULT_PAGE_SIZE
    },
    notifyOnNetworkStatusChange: true
  });

  const callGetInfoByLookup = async (values: LookupFilterOptions) => {
    try {
      const response = await getInfoByLookup({
        variables: {
          filterOptions: {
            firstName: addIfExists(values.firstName),
            lastName: addIfExists(values.lastName)
          },
          pageNum: 0,
          pageSize: DEFAULT_PAGE_SIZE
        }
      });

      if (response?.data?.providersLookup) {
        handleProviderData(response?.data);
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to retrieve providers', { variant: 'error' });
    }
  };

  const handleProviderData = (response: GetProvidersByFilterResponse) => {
    const data = response?.providersLookup?.providers || [];
    if (data?.length === 0) {
      enqueueSnackbar('No Records Found', { variant: 'info' });
    }
    setProviders(data);
  };

  useEffect(() => {
    setProviders([]);
  }, [providerType]);

  useEffect(() => {
    setContent(<MainSideMenu />);
  }, [setContent]);

  useEffect(() => {
    if (providerType === ProvidersListSearchFilters.Lookup) {
      handleLookupSearchQuery();
    }
  }, [providerType, searchQuery]);

  const handleLookupSearchQuery = () => {
    resetLookupForm();

    if (searchQuery.filterOption === '') {
      const searchValue = searchQuery.value;
      if (searchValue?.length > 1) {
        if (searchValue.split(' ').length > 1) {
          setValue('firstName', searchValue?.split(' ')[0]);
          setValue('lastName', searchValue?.split(' ')[1]);
        } else {
          setValue('firstName', searchValue?.split(' ')[0]);
          setValue('lastName', '');
        }

        formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
      }
    } else if (['firstName', 'lastName'].includes(searchQuery.filterOption)) {
      setValue(searchQuery.filterOption as keyof LookupFilterOptions, searchQuery.value);
      formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  };

  const showEditModal = useCallback(
    (provider: ProviderType) => {
      const modalRef = showModal(ProviderUpdateDialog, {
        title: 'Edit Provider',
        hideDialog: () => {
          modalRef.hide();
          providersDataReFetch();
        },
        existingProvider: provider
      });
    },
    [providersDataReFetch, showModal]
  );

  const calculateProviderType = useCallback(() => {
    const providersSearchFilter = searchParams.get('search') || ProvidersListSearchFilters.Lookup;
    setProviderType(providersSearchFilter as ProvidersListSearchFilters);
    const query = searchParams.get('q');
    const filterOption = searchParams.get('filterOption') || '';
    if (query) {
      setSearchQuery({
        value: query,
        filterOption: filterOption
      });
    } else {
      setSearchQuery({
        value: '',
        filterOption: ''
      });
    }
  }, [searchParams]);

  const formRef = useRef<HTMLFormElement>();

  // const troubleshootProviderSession = useCallback(
  //   async (providerId: string) => {
  //     try {
  //       const response = await sendProviderSessionToAdmin({
  //         variables: {
  //           providerId: providerId
  //         }
  //       });
  //       if (response.data?.sendProviderSessionToAdmin?.status === 'Success') {
  //         enqueueSnackbar('Provider session troubleshoot link sent to email successfully', { variant: 'success' });
  //       } else if (response?.errors && response.errors.length > 0) {
  //         enqueueSnackbar(response.errors[0].message, { variant: 'error' });
  //       } else {
  //         enqueueSnackbar('Unable to send provider session troubleshoot link to email', { variant: 'error' });
  //       }
  //     } catch (err) {
  //       enqueueSnackbar('Unable to send provider session to super admin for review', { variant: 'error' });
  //     }
  //   },
  //   [enqueueSnackbar, sendProviderSessionToAdmin]
  // );

  // const onTroubleshootProvider = useCallback(
  //   (providerId: string) => {
  //     const modal = showModal(ConfirmDialog, {
  //       title: 'Troubleshoot Provider',
  //       message: `Are you sure you want to troubleshoot provider's session via ${currentUser?.user?.email ?? ''} email?`,
  //       onAgree: () => {
  //         troubleshootProviderSession(providerId);
  //         modal.hide();
  //       },
  //       onDisagree: () => {
  //         modal.hide();
  //       }
  //     });
  //   },
  //   [showModal, troubleshootProviderSession, currentUser]
  // );

  const showAddModal = () => {
    const modalRef = showModal(ProviderAddDialog, {
      title: 'Add Provider',
      hideDialog: () => {
        modalRef.hide();
        providersDataReFetch();
      }
    });
  };

  const onSubmit: SubmitHandler<LookupFilterOptions> = data => handleLookup(data);

  const handleLookup = async (values: LookupFilterOptions) => {
    if ([values?.firstName, values?.lastName].every(entry => !entry || entry?.length === 0)) {
      enqueueSnackbar('First Name or Last Name is required', {
        variant: 'error'
      });

      resetLookupForm();
      return;
    }

    await callGetInfoByLookup(values);
  };

  const columns: GridColDef<ProviderType>[] = useMemo(
    () => [
      {
        field: 'individualFirstName',
        headerName: 'First Name',
        sortable: true,
        flex: 1,
        align: 'left'
      },
      {
        field: 'individualLastName',
        headerName: 'Last Name',
        sortable: true,
        flex: 1,
        align: 'left'
      },
      {
        field: 'npiNumber',
        headerName: 'NPI Number',
        sortable: true,
        flex: 1,
        align: 'left'
      },
      {
        field: 'email',
        headerName: 'Email',
        sortable: true,
        flex: 1,
        align: 'left'
      },
      {
        field: 'phone',
        headerName: 'Phone',
        sortable: true,
        flex: 1,
        align: 'left',
        renderCell: params => {
          return <>{params?.value ? <TruentityPhoneNumber editable={false} value={params.value} /> : <span>-</span>}</>;
        }
      },
      {
        field: 'fax',
        headerName: 'Fax',
        sortable: true,
        flex: 1,
        align: 'left',
        renderCell: params => <TruentityPhoneNumber value={params.value} />
      },
      {
        field: 'action',
        headerName: 'Actions',
        sortable: false,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        filterable: false,
        renderCell: params => {
          return (
            <Stack spacing={1} direction="row" sx={{ width: '100%' }} alignItems="center" justifyContent={'center'}>
              <IconButton size={'small'} onClick={() => showEditModal(params.row)}>
                <EditIcon />
              </IconButton>
              {/*{isSuperAdmin && (*/}
              {/*  <IconButton*/}
              {/*    disabled={params.row?.hasValidSession === false}*/}
              {/*    size={'small'}*/}
              {/*    onClick={() => {*/}
              {/*      onTroubleshootProvider(params.row?.id);*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <RoomPreferencesIcon />*/}
              {/*  </IconButton>*/}
              {/*)}*/}
            </Stack>
          );
        }
      }
    ],
    [showEditModal, isSuperAdmin]
  );

  useEffect(() => {
    if (!providersDataLoading && providersData) {
      setProviders(providersData.getAllProviders.providers);
      setRowCount(providersData.getAllProviders.meta.totalCount);
    }
  }, [providersDataLoading, providersData]);

  useEffect(() => {
    setRowCountState(prevRowCountState => (rowCount !== undefined ? rowCount : prevRowCountState));
  }, [rowCount, setRowCountState]);

  useEffect(() => {
    calculateProviderType();
  }, [calculateProviderType]);

  useEffect(() => {
    if (errorProvidersLookup || errorAllProviders) {
      enqueueSnackbar('Something went wrong, could not load Providers.', {
        variant: 'error'
      });
    }
  }, [errorProvidersLookup, errorAllProviders]);

  return (
    <Stack>
      <Grid container spacing={2} key={'search-bar'} alignItems="center" style={{ display: 'flex' }}>
        <Grid item xs={12}>
          <Stack alignItems={'center'} direction="row" justifyContent={'center'}>
            <H1>All Providers</H1>
          </Stack>
          <Stack alignItems={'center'} direction="row" justifyContent={'flex-end'} marginBottom={'10px'}>
            <Button label="Add Provider" onClick={() => showAddModal()} />
          </Stack>
        </Grid>
      </Grid>

      {providerType === ProvidersListSearchFilters.Lookup && (
        <Box component={'form'} ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <Stack flex={1} mr={1} direction="row" flexWrap={'wrap'} justifyContent={'start'}>
            <Controller
              control={control}
              name="firstName"
              render={({ field: { onChange, value } }) => (
                <TruentityTextField
                  sx={{ width: '24.7%', marginRight: '6px' }}
                  autoFocus
                  onChange={onChange}
                  value={value}
                  label={'First Name'}
                />
              )}
            />

            <Controller
              control={control}
              name="lastName"
              render={({ field: { onChange, value } }) => (
                <TruentityTextField sx={{ width: '24.7%', marginRight: '6px' }} onChange={onChange} value={value} label={'Last Name'} />
              )}
            />

            <Button
              startIcon={<SearchIcon />}
              label={'Lookup'}
              type="submit"
              size="small"
              sx={{ marginRight: 1, height: '50px', mt: '0.5rem' }}
            />

            <Grid container spacing={1} pb={1} pt={1}>
              <Grid item xs={12}>
                <Subtitle color={color.truentityCyan[600]} fontSize={'small'}>
                  Lookup Providers by First Name and/or Last Name. You may enter the first few letters for first and last names (in either
                  lowercase or uppercase).
                </Subtitle>
              </Grid>
              {/* <Grid item xs={6} sx={{ mt: '0.5rem' }}>
              <Stack direction={'row'}>
                <Button startIcon={<SearchIcon />} label={'Lookup'} type="submit" size="small" sx={{ marginRight: 1 }} />
              </Stack>
            </Grid> */}
            </Grid>
          </Stack>
        </Box>
      )}

      <Paper
        sx={{
          marginBottom: 1
        }}
        component={Stack}
        spacing={2}
      >
        <TruentityDataGrid
          name={'dg-provider'}
          autoHeight
          rows={providers}
          rowCount={rowCountState}
          loading={providersDataLoading || loadingProviders}
          columns={columns}
          paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
          paginationMode="server"
          onPaginationModelChange={({ page }) => {
            setCurrentPage(page);
          }}
          sx={{ paddingLeft: '10px' }}
          disableRowSelectionOnClick
        />
      </Paper>
    </Stack>
  );
};

export default Providers;
