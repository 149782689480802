import type { SelectChangeEvent } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import type React from 'react';

interface SelectFormControlProps {
  label: string;
  value: string;
  placeholder: string;
  onChange: (event: SelectChangeEvent) => void;
  defaultValue?: string;
  defaultOptions?: { label: string; value: string }[];
  options?: { label: string; value: string }[];
  disabled?: boolean;
}

export const SelectFormControl: React.FC<SelectFormControlProps> = ({
  label,
  value,
  placeholder,
  onChange,
  defaultOptions,
  options,
  disabled = false
}) => {
  return (
    <FormControl sx={{ width: '100%' }} variant="outlined" margin="dense" size="medium">
      <InputLabel id={`${label}-input`}>{label}</InputLabel>
      <Select disabled={disabled} labelId={`${label}-input`} label={label} placeholder={placeholder} value={value} onChange={onChange}>
        {defaultOptions &&
          defaultOptions.map(item => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        {options &&
          options.map(item => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default SelectFormControl;
