import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import type { BaseDialogProps } from '@/components/Dialogs/BaseDialog';
import BaseDialog from '@/components/Dialogs/BaseDialog';
import MuiTabs from '@/components/MuiTabs';
import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import TruentityTextField from '@/components/TruentityTextField';
import type {
  GetProvidersByFilterResponse,
  GetRelyingPartyProvidersResponse,
  RelyingPartyProviderType,
  UpdateProviderResponse
} from '@/graphql/remotePatientMonitoring';
import {
  GET_PROVIDERS_BY_FILTER,
  GET_RELYING_PARTY_PROVIDERS,
  UPDATE_PROVIDER,
  UPDATE_RELYING_PARTY_PROVIDERS
} from '@/graphql/remotePatientMonitoring';
import { color } from '@/styles/assets/colors';
import { theme } from '@/styles/mui-theme';
import type { ProviderType } from '@/types/remotePatientMonitoring';
import { addIfExists } from '@/util/object';
import { useProviderStore } from '@/zustand/ProviderStore';
import { useLazyQuery, useMutation } from '@apollo/client';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import { Box, DialogContent, Stack } from '@mui/material';
import type { GridColDef, GridEventListener, GridRowId, GridRowModel, GridRowModesModel } from '@mui/x-data-grid-pro';
import { GridActionsCellItem, GridRowEditStopReasons, GridRowModes } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useRef, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Alert from '../Alert';
import { H1 } from '../Typography';

type Props = BaseDialogProps & {
  relyingPartyId: string;
  assignedRelyingPartyProviders: RelyingPartyProviderType[];
  fullName: string;
};

type LookupFilterOptions = {
  firstName?: string;
  lastName?: string;
};

const ManageRelyingPartyProvidersDialog = ({
  title,
  fullName,
  assignedRelyingPartyProviders,
  hideDialog,
  relyingPartyId,
  ...props
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setAssignedRelyingPartyProviders, setRelyingPartyProviders, relyingPartyProviders } = useProviderStore();
  const formRef = useRef<HTMLFormElement>();

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowCount, setRowCount] = useState(DEFAULT_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(rowCount);
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowId[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const {
    getValues,
    control,
    reset: resetLookupForm,
    handleSubmit
  } = useForm<LookupFilterOptions>({
    defaultValues: {
      firstName: '',
      lastName: ''
    }
  });

  const [
    getRelyingPartyProvidersDataQuery,
    { data: relyingPartyProvidersData, called: relyingPartyProvidersCalled, loading: relyingPartyProvidersDataLoading }
  ] = useLazyQuery<GetRelyingPartyProvidersResponse>(GET_RELYING_PARTY_PROVIDERS, { fetchPolicy: 'cache-and-network' });

  const [getInfoByLookup, { data: providersData, refetch: refetchProviderData, called: calledProviderData, loading: loadingProviderData }] =
    useLazyQuery<GetProvidersByFilterResponse>(GET_PROVIDERS_BY_FILTER, {
      fetchPolicy: 'cache-and-network'
    });

  const firstName = getValues('firstName');
  const lastName = getValues('lastName');

  const [updateRelyingPartyProvider] = useMutation(UPDATE_RELYING_PARTY_PROVIDERS);
  const [updateProvider] = useMutation<UpdateProviderResponse>(UPDATE_PROVIDER);

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const validateProviderUpdate = (updatedRow: GridRowModel): boolean => {
    const { individualFirstName, individualLastName, npiNumber } = updatedRow;
    if (!individualFirstName || !individualLastName || !npiNumber) {
      enqueueSnackbar('Please fill out all required fields', { variant: 'error' });
      return false;
    }
    return true;
  };

  const processProviderUpdate = async (newRow: GridRowModel): Promise<ProviderType> => {
    const validateResult = validateProviderUpdate(newRow);
    if (!validateResult) {
      return relyingPartyProviders.find(row => row.id === newRow.id) as ProviderType;
    }
    const updatedRow: ProviderType = {
      contacts: newRow['contacts'],
      deaNumber: newRow['deaNumber'],
      id: newRow['id'],
      individualFirstName: newRow['individualFirstName'],
      individualLastName: newRow['individualLastName'],
      individualMiddleName: newRow['individualMiddleName'],
      npiNumber: newRow['npiNumber'],
      stateLicense: newRow['stateLicense'],
      taxonomyCode: newRow['taxonomyCode'],
      email: newRow['email'],
      phone: newRow['phone'],
      fax: newRow['fax']
    };
    setRelyingPartyProviders(relyingPartyProviders.map(row => (row.id === newRow.id ? updatedRow : row)));
    try {
      const updateResponse = await updateProvider({
        variables: {
          id: newRow['id'],
          input: {
            firstName: newRow['individualFirstName'],
            lastName: newRow['individualLastName'],
            npiNumber: newRow['npiNumber'],
            email: newRow['email'],
            phone: newRow['phone'],
            fax: newRow['fax']
          }
        }
      });

      if (updateResponse.data?.updateProvider?.status === 'Success') {
        enqueueSnackbar('Successfully updated provider', { variant: 'success' });
        refetchProviderData();
        return updatedRow;
      } else {
        if (updateResponse?.errors && updateResponse?.errors.length > 0) {
          enqueueSnackbar(updateResponse?.errors[0]?.message ?? 'Could not update provider details', { variant: 'error' });
        } else {
          enqueueSnackbar('Could not update provider details', { variant: 'error' });
        }
      }
    } catch (error: any) {
      enqueueSnackbar(error?.message ?? 'Could not update provider details', { variant: 'error' });
    }
    return relyingPartyProviders.find(row => row.id === newRow.id) as ProviderType;
  };

  const callGetInfoByLookup = async (values: LookupFilterOptions) => {
    try {
      const response = await getInfoByLookup({
        variables: {
          filterOptions: {
            firstName: addIfExists(values.firstName),
            lastName: addIfExists(values.lastName)
          },
          pageNum: 0,
          pageSize: DEFAULT_PAGE_SIZE
        }
      });

      const responseData = response?.data?.providersLookup?.providers;
      if (responseData?.length === 0) {
        enqueueSnackbar('No Records Found', { variant: 'info' });
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to retrieve providers', { variant: 'error' });
    }
  };

  const handleClick = async () => {
    try {
      await updateRelyingPartyProvider({
        variables: {
          providerIds: selectionModel,
          relyingPartyId: relyingPartyId
        }
      });
      refetchProviderData();
      getRelyingPartyProviders();
      enqueueSnackbar('Successfully assigned providers', { variant: 'success' });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Request failed', { variant: 'error' });
    }
    hideDialog && hideDialog();
  };

  const onSubmit: SubmitHandler<LookupFilterOptions> = data => handleLookup(data);

  const handleLookup = async (values: LookupFilterOptions) => {
    if ([values?.firstName, values?.lastName].every(entry => !entry || entry?.length === 0)) {
      enqueueSnackbar('First Name or Last Name is required', {
        variant: 'error'
      });

      resetLookupForm();
      return;
    }

    await callGetInfoByLookup(values);
    await callGetInfoByLookup(values);
  };

  const getRelyingPartyProviders = async () => {
    try {
      await getRelyingPartyProvidersDataQuery({
        variables: {
          relyingPartyId: relyingPartyId,
          pageSize: DEFAULT_PAGE_SIZE,
          pageNum: 1
        }
      });
      resetLookupForm();
    } catch (error) {
      console.error(error);
    }
  };

  const getAllProviders = async () => {
    try {
      await getInfoByLookup({
        variables: {
          filterOptions: {
            firstName: null,
            lastName: null
          },
          pageNum: currentPage + 1,
          pageSize: DEFAULT_PAGE_SIZE
        }
      });
      resetLookupForm();
    } catch (error) {
      console.error(error);
    }
  };

  const columns: GridColDef<ProviderType>[] = useMemo(
    () => [
      {
        field: 'individualFirstName',
        headerName: 'First Name',
        sortable: true,
        editable: true,
        flex: 1
      },
      {
        field: 'individualLastName',
        headerName: 'Last Name',
        sortable: true,
        editable: true,
        flex: 1
      },
      {
        field: 'npiNumber',
        headerName: 'NPI Number',
        sortable: true,
        editable: true,
        flex: 1
      },
      {
        field: 'email',
        headerName: 'Email',
        type: 'string',
        sortable: true,
        editable: true,
        flex: 1,
        valueGetter:
          (params => {
            let val = '';
            params.row?.contacts?.map(contact => {
              if (contact.type === 'Email') {
                val = contact.value;
              }
            });
            return val;
          }) || ''
      },
      {
        field: 'phone',
        headerName: 'Phone',
        type: 'string',
        sortable: true,
        editable: true,
        flex: 1,
        renderCell: params => {
          return <TruentityPhoneNumber value={params.value} />;
        },
        valueGetter:
          (params => {
            let val = '';
            params.row?.contacts?.map(contact => {
              if (contact.type === 'Telephone') {
                val = contact.value;
              }
            });
            return val;
          }) || ''
      },
      {
        field: 'fax',
        headerName: 'Fax',
        type: 'string',
        sortable: true,
        editable: true,
        flex: 1,
        renderCell: params => <TruentityPhoneNumber value={params.value} />,
        valueGetter:
          (params => {
            let val = '';
            params.row?.contacts?.map(contact => {
              if (contact.type === 'Fax') {
                val = contact.value;
              }
            });
            return val;
          }) || ''
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 100,
        cellClassName: 'actions',
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: 'primary.main'
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />
            ];
          }

          return [
            <GridActionsCellItem icon={<EditIcon />} label="Edit" className="textPrimary" onClick={handleEditClick(id)} color="inherit" />
          ];
        }
      }
    ],
    [rowModesModel]
  );

  useEffect(() => {
    setSelectionModel(prevSelectionModel => {
      const prevSet = new Set(prevSelectionModel);

      rowSelectionModel.forEach(item => prevSet.add(item));

      Array.from(prevSet).forEach(item => {
        if (!rowSelectionModel.includes(item)) {
          prevSet.delete(item);
        }
      });
      const updatedSelectionArray = Array.from(prevSet);

      return updatedSelectionArray;
    });
  }, [rowSelectionModel]);

  useEffect(() => {
    if (relyingPartyProvidersCalled && !relyingPartyProvidersDataLoading && relyingPartyProvidersData) {
      const { relyingPartyProviders } = relyingPartyProvidersData?.relyingPartyProviders;
      setAssignedRelyingPartyProviders(relyingPartyProviders);
    }
  }, [relyingPartyProvidersData, relyingPartyProvidersDataLoading, relyingPartyProvidersCalled]);

  useEffect(() => {
    if (calledProviderData && !loadingProviderData && providersData) {
      const { providers } = providersData?.providersLookup;
      setRelyingPartyProviders(providers);
      setRowSelectionModel(assignedRelyingPartyProviders.map(item => item.provider.id));
    }
  }, [loadingProviderData, providersData, calledProviderData]);

  useEffect(() => {
    setRowCountState(prevRowCountState => (rowCount !== undefined ? rowCount : prevRowCountState));
  }, [rowCount, setRowCountState]);

  useEffect(() => {
    if (providersData?.providersLookup?.providers) {
      setRelyingPartyProviders(providersData?.providersLookup?.providers);
      setRowCount(providersData?.providersLookup?.meta.totalCount || 0);
    }
  }, [providersData, currentPage]);

  useEffect(() => {
    if (firstName === '' && lastName === '') {
      getAllProviders();
    }
  }, [providersData, currentPage, firstName, lastName]);

  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog} maxWidth="lg" fullWidth={true}>
      <Stack ml={6}>
        <H1 style={{ fontSize: '16px', fontWeight: 500, lineHeight: '40px', color: color.grey900 }}>
          Providers Assignments for {fullName}
        </H1>
      </Stack>
      <Stack direction={'row'} justifyContent={'flex-end'} marginRight={'20px'}>
        <Button label="Assign Provider(s)" appearance="primary" onClick={() => handleClick()} disabled={rowSelectionModel.length === 0} />
      </Stack>
      <DialogContent sx={{ padding: 0, marginTop: 2 }}>
        <MuiTabs
          TabContentProps={{ backgroundColor: 'white' }}
          tabs={[
            {
              label: 'Providers',
              children: (
                <Stack p={2} spacing={3} sx={{ background: theme.palette.background.default, minHeight: '60vh' }}>
                  <Box component={'form'} ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                    <Stack flex={1} mr={1} direction="row" flexWrap={'wrap'} justifyContent={'start'}>
                      <Controller
                        control={control}
                        name="firstName"
                        render={({ field: { onChange, value } }) => (
                          <TruentityTextField
                            sx={{ width: '24.7%', marginRight: '6px' }}
                            autoFocus
                            onChange={onChange}
                            value={value}
                            label={'First Name'}
                          />
                        )}
                      />

                      <Controller
                        control={control}
                        name="lastName"
                        render={({ field: { onChange, value } }) => (
                          <TruentityTextField
                            sx={{ width: '24.7%', marginRight: '6px' }}
                            onChange={onChange}
                            value={value}
                            label={'Last Name'}
                          />
                        )}
                      />

                      <Button
                        startIcon={<SearchIcon />}
                        label={'Search'}
                        type="submit"
                        size="small"
                        sx={{ marginRight: 1, height: '40px', mt: '1rem' }}
                      />

                      <Button
                        startIcon={<RestartAltIcon />}
                        label={'Reset'}
                        onClick={getAllProviders}
                        size="small"
                        sx={{ marginRight: 1, height: '40px', mt: '1rem' }}
                      />
                    </Stack>
                  </Box>
                  {providersData?.providersLookup?.providers.length === 0 && (
                    <Stack direction={'row'}>
                      <Alert
                        description={
                          <>
                            Provider you're looking for is not available here, please{' '}
                            <Link to="/administration/providers/">Add a New Provider</Link> first.
                          </>
                        }
                        status={'info'}
                      />
                    </Stack>
                  )}
                  <TruentityDataGrid
                    checkboxSelection={true}
                    rowSelectionModel={rowSelectionModel}
                    onRowSelectionModelChange={e => {
                      setRowSelectionModel(e);
                    }}
                    name={'providers-dg'}
                    columns={columns}
                    rows={relyingPartyProviders}
                    paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
                    onPaginationModelChange={({ page }) => {
                      setCurrentPage(page);
                    }}
                    loading={loadingProviderData}
                    rowCount={rowCountState}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    paginationMode="server"
                    processRowUpdate={processProviderUpdate}
                  />

                  <Stack direction={'row'} justifyContent={'flex-end'} spacing={3}>
                    <Button a11yLabel="Cancel" type="reset" variant={'text'} onClick={hideDialog} />
                  </Stack>
                </Stack>
              )
            }
          ]}
        />
      </DialogContent>
    </BaseDialog>
  );
};

export default ManageRelyingPartyProvidersDialog;
