import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import PreviewDialog from '@/components/Dialogs/PreviewDialog';
import type { TextSelectOption } from '@/components/SelectList';
import SelectList from '@/components/SelectList';
import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import { Body1, H1, H5 } from '@/components/Typography';
import type { GetClientOrganizationsResponse } from '@/graphql/administration';
import { GET_CLIENT_ORGANIZATIONS_STANDARD } from '@/graphql/administration';
import type { GetRpmProviderApprovalResponse } from '@/graphql/remotePatientMonitoring';
import { GET_RPM_PROVIDER_APPROVAL_ACCOUNTS } from '@/graphql/remotePatientMonitoring';
import { color } from '@/styles/assets/colors';
import type { ClientOrganizationObject } from '@/types/administration';
import type { RpmStatusCounts, RpmStatusTypeTab } from '@/types/remotePatientMonitoring';
import { RpmStatusTypes } from '@/types/remotePatientMonitoring';
import { getAccountUserFullName } from '@/util/account';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { addOneYearToDate, today } from '@/util/date';
import { formatDate, formatDateAndTime } from '@/util/format';
import { getRpmStatusCount, mapRpmSignOffStatus, rpmStatusMapping } from '@/util/rpm';
import type { ApolloError } from '@apollo/client';
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import NoteIcon from '@mui/icons-material/Note';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Chip, IconButton, Stack, Tab, Tabs } from '@mui/material';
import type { GridColDef, GridColumnVisibilityModel, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import type { SyntheticEvent } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import type { PatientsData } from '../Patients/patients';
import RpmPatientInfoDrawer from './RpmPatientDrawer';

const SELECT_DEFAULT: { id: string; name: string }[] = [{ id: 'all', name: 'All' }];

const rpmStatusTabs: RpmStatusTypeTab[] = [
  {
    status: RpmStatusTypes.SCHEDULE_FOR_REVIEW,
    label: 'Scheduled for Review',
    path: 'schedule-for-review',
    icon: <ScheduleSendIcon />
  },
  {
    status: RpmStatusTypes.IN_REVIEW,
    label: 'In Review',
    path: 'in-review',
    icon: <ImageSearchIcon />
  },
  {
    status: RpmStatusTypes.APPROVED,
    label: 'Approved',
    path: 'approved',
    icon: <CheckIcon />
  },
  {
    status: RpmStatusTypes.REJECTED,
    label: 'Rejected',
    path: 'rejected',
    icon: <CloseIcon />
  },
  {
    status: RpmStatusTypes.SKIPPED,
    label: 'Incomplete',
    path: 'skipped',
    icon: <WarningAmberIcon />
  }
];

const defaultStatusCounts: RpmStatusCounts = {
  SCHEDULE_FOR_REVIEW: 0,
  IN_REVIEW: 0,
  APPROVED: 0,
  REJECTED: 0,
  SKIPPED: 0
};

function RpmEnrollment() {
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const { enqueueSnackbar } = useSnackbar();
  const { showModal } = useModal();

  const [rowCount, setRowCount] = useState(DEFAULT_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(rowCount);
  const [currentPage, setCurrentPage] = useState(0);
  const [rpmCandidatesTableData, setRpmCandidatesTableData] = useState<PatientsData[]>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    reSchedule: false,
    approvalMode: false,
    approvalNote: false
  });

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

  const [selectedCandidate, setSelectedCandidate] = useState<PatientsData>({} as PatientsData);
  const [statusCounts, setStatusCounts] = useState<RpmStatusCounts>(defaultStatusCounts);
  const [tab, setTab] = useState<number>(0);

  const [clientOrganizations, setClientOrganizations] = useState<ClientOrganizationObject[]>([]);
  const [selectedOrgId, setSelectedOrgId] = useState<string>('');

  const navigate = useNavigate();

  // TODO: RPM Oct 2023
  const startDate = '2020-01-01';
  const endDate = useMemo(() => addOneYearToDate(today()), []);

  const {
    loading: organizationDataLoading,
    data: organizationData,
    called: organizationDataCalled
  } = useQuery<GetClientOrganizationsResponse>(GET_CLIENT_ORGANIZATIONS_STANDARD, {
    fetchPolicy: 'network-only',
    variables: {
      pageNum: 1,
      pageSize: DEFAULT_PAGE_SIZE
    }
  });

  const [getRpmCandidatesQuery, { data: rpmApprovalAccountsQueryData, loading: rpmApprovalAccountsQueryLoading }] =
    useLazyQuery<GetRpmProviderApprovalResponse>(GET_RPM_PROVIDER_APPROVAL_ACCOUNTS, { fetchPolicy: 'network-only' }); // cache-and-network not working

  const onCandidatesTableDataError = (error: ApolloError) => {
    enqueueSnackbar('Failed to retrieve reports', { variant: 'error' });
    console.error(error);
    setRpmCandidatesTableData([]);
  };

  useEffect(() => {
    setColumnVisibilityModel(prevModel => ({
      ...prevModel,
      approvalMode: rpmStatusesName(tab) === RpmStatusTypes.APPROVED,
      approvalNote: [RpmStatusTypes.REJECTED, RpmStatusTypes.APPROVED, RpmStatusTypes.SKIPPED].includes(rpmStatusesName(tab)),
      reSchedule: rpmStatusesName(tab) === RpmStatusTypes.IN_REVIEW
    }));
  }, [tab]);

  useEffect(() => {
    const rpmCandidatesData = rpmApprovalAccountsQueryData?.getProviderApprovalAccounts;

    if (!rpmApprovalAccountsQueryLoading && rpmCandidatesData) {
      const { readyForReviewCount, scheduleForReviewCount, inReviewCount, approvedCount, skippedCount } =
        rpmCandidatesData?.accountsCounts ?? defaultStatusCounts;

      const fetchedCounts: RpmStatusCounts = {
        READY_FOR_REVIEW: readyForReviewCount,
        SCHEDULE_FOR_REVIEW: scheduleForReviewCount,
        IN_REVIEW: inReviewCount,
        APPROVED: approvedCount,
        SKIPPED: skippedCount
      };

      setStatusCounts(fetchedCounts);
    }
  }, [rpmApprovalAccountsQueryData, selectedOrgId]);

  useEffect(() => {
    if (organizationData && organizationDataCalled && !organizationDataLoading) {
      const { clientOrganizations } = organizationData.clientOrganizations;

      if (currentUser) {
        try {
          const settingsString = currentUser.relyingParty?.settings;
          const settingsObject = settingsString ? JSON.parse(settingsString) : {};
          const currentUserOrgName = settingsObject.defaults?.orgName;
          const organizationOption = clientOrganizations?.find(item => item?.name === currentUserOrgName);

          if (organizationOption) {
            setSelectedOrgId(organizationOption.id);
          } else {
            setSelectedOrgId('all');
          }
        } catch (error) {
          console.error('Error occurred while parsing user settings:', error);
          setSelectedOrgId('all');
        }
      } else {
        setSelectedOrgId('all');
      }

      setClientOrganizations([
        ...SELECT_DEFAULT,
        ...organizationData.clientOrganizations.clientOrganizations
      ] as ClientOrganizationObject[]);
    }
  }, [organizationData]);

  const callGetRpmCandidates = async () => {
    try {
      await getRpmCandidatesQuery({
        variables: {
          pageSize: DEFAULT_PAGE_SIZE,
          pageNum: currentPage + 1,
          filterOptions: {
            rpmApprovalStatus: rpmStatusesName(tab),
            organization: selectedOrgId,
            startDate: formatDate(startDate),
            endDate: formatDate(endDate)
          },
          relyingPartyAdminId: 'all'
        }
      });
    } catch (error: any) {
      onCandidatesTableDataError(error);
    }
  };

  useEffect(() => {
    callGetRpmCandidates();
  }, [currentPage, tab, selectedOrgId]);

  useEffect(() => {
    setRowCountState(prevRowCountState => rowCount ?? prevRowCountState);
  }, [rowCount, setRowCountState]);

  useEffect(() => {
    if (rpmApprovalAccountsQueryData?.getProviderApprovalAccounts?.accounts) {
      setRpmCandidatesTableData(rpmApprovalAccountsQueryData?.getProviderApprovalAccounts?.accounts);
      setRowCount(rpmApprovalAccountsQueryData?.getProviderApprovalAccounts?.meta.totalCount ?? 0);
    }
  }, [rpmApprovalAccountsQueryData]);

  const handleTabChange = useCallback((event: SyntheticEvent, selectedTabIndex: number): void => {
    setTab(selectedTabIndex);
    setRowSelectionModel([]);
  }, []);

  const getCustomRowId = useCallback((row: PatientsData) => {
    return `${row.truentityId}`;
  }, []);

  const sendPatientDataToDrawer = (patientData: PatientsData) => {
    setSelectedCandidate(patientData);
  };

  // NOTE: Commented because we use have different patient review flow (4 June 2024)
  //
  // const [sessionUrl, setSessionUrl] = useState<string>('');
  // const [openDialog, setOpenDialog] = useState<boolean>(false);
  // const [confirmButtonText, setConfirmButtonText] = useState<string>('');
  //
  // const createProviderSessionAndOpenTab = async () => {
  //   await createProviderSession({
  //     variables: {
  //       truentityIds: rowSelectionModel
  //     }
  //   });
  //   handleCloseDialog();
  // };
  // const [updateRpmCandidateStatus] = useMutation(UPDATE_RPM_CANDIDATE_STATUS);
  // const [createProviderSession] = useMutation<CreateProviderSessionResponse>(CREATE_PROVIDER_SESSION);
  // const handleRpmStatusChange = async (targetTab: string) => {
  //   try {
  //     await updateRpmCandidateStatus({
  //       variables: {
  //         truentityIds: rowSelectionModel,
  //         rpmStatus: targetTab
  //       }
  //     });
  //
  //     if (targetTab === RpmStatusTypes.IN_REVIEW) {
  //       await createProviderSessionAndOpenTab();
  //
  //       const currentTab = rpmStatusesName(tab);
  //       const patientText = rowSelectionModel?.length > 1 ? 'patients' : 'patient';
  //       if (currentTab === RpmStatusTypes.READY_FOR_REVIEW) {
  //         enqueueSnackbar(`Successfully sent ${patientText} for review.`, { variant: 'success' });
  //         setTab(2);
  //       } else if (currentTab === RpmStatusTypes.IN_REVIEW) {
  //         enqueueSnackbar(`Successfully resent ${patientText} for review.`, { variant: 'success' });
  //       }
  //     }
  //
  //     if (targetTab === RpmStatusTypes.SCHEDULE_FOR_REVIEW) {
  //       setTab(2);
  //       handleCloseDialog();
  //     }
  //
  //     rpmApprovalAccountsQueryRefetch();
  //     setRowSelectionModel([]);
  //   } catch (err) {
  //     console.log(err);
  //     enqueueSnackbar('Something went wrong.', { variant: 'error' });
  //   }
  // };
  // const changeRpmStatus = (targetTab: string) => {
  //   handleRpmStatusChange(targetTab);
  // };

  // NOTE: Commented because we use different flow for enrollment (2024 March 11)
  // const enrollPatient = useCallback(
  //   async (truentityId: string) => {
  //     try {
  //       await updateRpmCandidateStatus({
  //         variables: {
  //           truentityIds: [truentityId],
  //           rpmStatus: RpmStatusTypes.ENROLLED
  //         }
  //       });
  //
  //       rpmCandidateQueryRefetch();
  //     } catch (err) {
  //       console.log(err);
  //       enqueueSnackbar('Something went wrong.', { variant: 'error' });
  //     }
  //   },
  //   [enqueueSnackbar, rpmCandidateQueryRefetch, updateRpmCandidateStatus]
  // );

  const rpmStatusesName = (tab: number): RpmStatusTypes => {
    const rpmStatusString = rpmStatusMapping[tab];

    if (rpmStatusString) {
      return rpmStatusString as RpmStatusTypes;
    }

    throw new Error(`Invalid tab value: ${tab}`);
  };

  const loadDateTimeHeaderText = useCallback((status: RpmStatusTypes) => {
    switch (status) {
      case RpmStatusTypes.IN_REVIEW:
        return 'Requested On';
      case RpmStatusTypes.APPROVED:
        return 'Approved On';
      case RpmStatusTypes.REJECTED:
        return 'Rejected On';
      case RpmStatusTypes.SCHEDULE_FOR_REVIEW:
        return 'Scheduled On';
      default:
        return 'Status Updated On';
    }
  }, []);

  const columns: GridColDef<PatientsData>[] = useMemo(
    () => [
      {
        field: 'patientName',
        headerName: 'Patient Name',
        sortable: true,
        flex: 1,
        align: 'left',
        valueGetter: params => getAccountUserFullName(params.row.user),
        renderCell: params => {
          return (
            <Link to={`../../patients/${params.row.truentityId}/details/rpm/patient-setup/encounter/consent-management`}>
              {params.value}
            </Link>
          );
        }
      },
      {
        field: 'dateOfBirth',
        headerName: 'Date of Birth',
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => formatDate(params.row.birthDate)
      },
      {
        field: 'phone',
        headerName: 'Phone number',
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => params.row.phone,
        renderCell: params => <TruentityPhoneNumber value={params.value} />
      },
      {
        field: 'zipcode',
        headerName: 'Zipcode',
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => params.row.zipcode
      },
      {
        field: 'statusUpdatedOn',
        headerName: loadDateTimeHeaderText(rpmStatusesName(tab)),
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => formatDateAndTime(params.row.rpmStatusUpdatedAt)
      },
      {
        field: 'approvalMode',
        headerName: 'Approval Mode',
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params =>
          mapRpmSignOffStatus({
            rpmSignOffStatus: params.row.rpmSignOffStatus
          })
      },
      {
        field: 'approvalNote',
        headerName: 'Provider Note',
        sortable: false,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => params.row?.rpmStatusComment,
        renderCell: params => (
          <Stack direction="row" justifyContent={'left'}>
            {params.value ? (
              <IconButton
                onClick={() => {
                  const modal = showModal(PreviewDialog, {
                    title: `Provider Note for ${getAccountUserFullName(params.row.user)}`,
                    content: params.value,
                    fullWidth: true,
                    onClose: () => {
                      modal.hide();
                    }
                  });
                }}
              >
                <NoteIcon sx={{ color: color.primaryMain }} />
              </IconButton>
            ) : (
              <span>-</span>
            )}
          </Stack>
        )
      },
      // NOTE: Commented because we only show Patients in enrolment flow (18 Oct 2024)
      // {
      //   field: 'clinicalSummary',
      //   headerName: 'Clinical Summary',
      //   sortable: true,
      //   flex: 1,
      //   align: 'center',
      //   headerAlign: 'center',
      //   renderCell: cellValues => {
      //     return (
      //       <Stack direction="row" justifyContent={'left'}>
      //         <IconButton
      //           onClick={() => {
      //             navigate(`/patients/${cellValues.row.truentityId}/details/rpm/patient-setup/care-activity/clinical-summary`);
      //           }}
      //         >
      //           <ContentPasteGoIcon sx={{ color: color.primaryMain }} />
      //         </IconButton>
      //       </Stack>
      //     );
      //   }
      // },
      // {
      //   field: 'rpmTasks',
      //   headerName: 'RPM Tasks',
      //   sortable: true,
      //   flex: 1,
      //   align: 'center',
      //   headerAlign: 'center',
      //   renderCell: cellValues => {
      //     return (
      //       <Stack direction="row" justifyContent={'left'}>
      //         <IconButton
      //           onClick={() => navigate(`/patients/${cellValues.row.truentityId}/details/rpm/patient-setup/encounter/consent-management`)}
      //         >
      //           <AssignmentIcon sx={{ color: color.primaryMain }} />
      //         </IconButton>
      //       </Stack>
      //     );
      //   }
      // },
      {
        field: 'reSchedule',
        headerName: 'Re-Schedule',
        sortable: false,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: cellValues => {
          return (
            <Stack direction="row" justifyContent={'left'}>
              <IconButton
                onClick={() => navigate(`/patients/${cellValues.row.truentityId}/details/rpm/patient-setup/encounter/consent-management`)}
              >
                <EventRepeatIcon sx={{ color: color.primaryMain }} />
              </IconButton>
            </Stack>
          );
        }
      }
    ],
    [loadDateTimeHeaderText, navigate, tab]
  );

  return (
    <Stack>
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        sx={{
          padding: 3,
          backgroundColor: color.paper,
          borderRadius: '8px'
        }}
      >
        <H1
          sx={{
            fontSize: '24px',
            fontWeight: 600,
            lineHeight: '32px',
            flex: 1,
            textAlign: 'left'
          }}
        >
          Provider Approvals
        </H1>
        <SelectList
          fullWidth={false}
          formControlProps={{ sx: { width: 300 } }}
          label="Organization"
          options={clientOrganizations.map(
            (item: ClientOrganizationObject) =>
              ({
                value: item.id,
                label: item.name
              } as TextSelectOption)
          )}
          placeholder="Select an option..."
          value={selectedOrgId}
          onChange={event => setSelectedOrgId(event.target.value as string)}
          sx={{
            flex: '0 0 auto'
          }}
        />
      </Stack>

      <Stack
        sx={{
          marginTop: '16px',
          '&.MuiStack-root': { backgroundColor: color.paper, borderRadius: '8px', padding: '12px 16px' }
        }}
      >
        <Tabs
          value={tab}
          variant="scrollable"
          scrollButtons="auto"
          onChange={handleTabChange}
          sx={{
            overflowY: 'auto'
          }}
        >
          {rpmStatusTabs?.map((tab: RpmStatusTypeTab, index: number) => (
            <Tab
              style={{ minWidth: 'auto' }}
              icon={tab.icon}
              iconPosition={'start'}
              label={
                <Body1 textTransform="Capitalize">
                  {tab.label}
                  <Chip sx={{ marginLeft: 1 }} label={getRpmStatusCount(tab.status, statusCounts)} />
                </Body1>
              }
              key={tab.status}
              id={`tab-${index}`}
            />
          ))}
        </Tabs>
      </Stack>

      <Stack
        sx={{
          backgroundColor: '#fff',
          marginTop: '16px',
          '&.MuiStack-root': { backgroundColor: color.paper, borderRadius: '8px', padding: '8px 16px' }
        }}
      >
        {rowSelectionModel?.length > 0 && (
          <Stack sx={{ borderRadius: '8px', padding: '8px 16px' }}>
            <Stack direction="row" alignItems="center">
              <H5
                sx={{
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  color: color.primaryMain,
                  flex: 1,
                  textAlign: 'left'
                }}
              >
                {rowSelectionModel.length} Selected
              </H5>
            </Stack>
          </Stack>
        )}
        <TruentityDataGrid
          name={'dg-rpm-enrollment'}
          style={{ border: 'none', outline: 'none' }}
          autoHeight
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={newModel => setColumnVisibilityModel(newModel)}
          checkboxSelection={false}
          onCellClick={e => {
            if (['enroll', '__check__', 'approvalNote', 'reSchedule', 'rpmTasks'].includes(e.field)) {
              return;
            } else {
              sendPatientDataToDrawer({ ...(e.row as PatientsData) });
            }
          }}
          disableColumnMenu={false}
          rows={rpmCandidatesTableData}
          columns={columns}
          paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
          onPaginationModelChange={({ page }) => {
            setCurrentPage(page);
          }}
          onRowSelectionModelChange={newRowSelectionModel => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          getRowId={getCustomRowId}
          rowSelectionModel={rowSelectionModel}
          loading={rpmApprovalAccountsQueryLoading}
          rowCount={rowCountState}
          paginationMode="server"
          hideFooterSelectedRowCount
          keepNonExistentRowsSelected
        />
      </Stack>
      <RpmPatientInfoDrawer patientsData={selectedCandidate} />
    </Stack>
  );
}

export default RpmEnrollment;
