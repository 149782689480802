import { Body1, H3, H4 } from '@/components/Typography';
import { color } from '@/styles/assets/colors';
import type { RpmAlertsCount } from '@/types/remotePatientMonitoring';
import { RpmAlertsLabelTypes, RpmAlertTabEnum } from '@/types/remotePatientMonitoring';
import { getAlertTypeStyles } from '@/util/rpm';
import CircleIcon from '@mui/icons-material/Circle';
import DoneIcon from '@mui/icons-material/Done';
import { Card, Grid, Stack, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import type React from 'react';
import { useCallback, useState } from 'react';

type AlertTypeFilterProps = {
  type: RpmAlertsLabelTypes;
  count: number;
  onSelectAlertType: (alertType: RpmAlertsLabelTypes) => void;
  isSelected: boolean;
  isInPatientContext: boolean;
};
const AlertTypeFilter: React.FC<AlertTypeFilterProps> = ({ type, onSelectAlertType, count, isSelected, isInPatientContext = false }) => {
  const alertTypeStyles = getAlertTypeStyles(type);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleClose = () => {
    setTooltipOpen(false);
  };

  const handleOpen = () => {
    setTooltipOpen(true);
  };

  return (
    <Tooltip
      title={`Click to filter by ${alertTypeStyles?.labelText}`}
      placement="top"
      arrow
      open={tooltipOpen && !isSelected}
      onClose={handleClose}
      onOpen={handleOpen}
    >
      <Card
        onClick={() => {
          onSelectAlertType(type);
        }}
        sx={{
          paddingX: 0.5,
          paddingY: 1,
          borderRadius: 1,
          minWidth: 100,
          height: '100%',
          maxWidth: 140,
          backgroundColor: theme => theme.palette.background.default,
          boxShadow: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          borderWidth: isSelected ? 3 : 2,
          borderStyle: 'solid',
          borderColor: isSelected ? color.primary400 : 'transparent',
          alignItems: 'center',
          cursor: 'pointer'
        }}
      >
        <Stack display="inline-flex" width="100%" direction="row" alignItems="center" justifyContent="flex-start" gap={1}>
          <CircleIcon fontSize={'small'} sx={{ color: alertTypeStyles?.labelColor }} />
          <Box
            sx={{
              flex: '1 1 auto',
              whiteSpace: {
                xs: 'wrap'
              },
              textAlign: 'center'
            }}
          >
            <Body1>{alertTypeStyles?.labelText}</Body1>
          </Box>
        </Stack>
        <Stack gap={1} flexDirection="row" justifyContent="center" alignItems="center" marginY={0.5} marginX={1}>
          <H3>{count}</H3>
          {!isInPatientContext && <Body1 margin={0}>Patients</Body1>}
        </Stack>
        {isSelected && (
          <DoneIcon
            fontSize="small"
            sx={{
              position: 'absolute',
              bottom: 3,
              right: 3,
              color: color.primary400
            }}
          />
        )}
      </Card>
    </Tooltip>
  );
};

export type RpmAlertTypesFiltersSectionProps = {
  alertsTabEnum: RpmAlertTabEnum;
  alertsCount: RpmAlertsCount | undefined;
  alertTypes: { [key: string]: boolean };
  setAlertTypes: (val: { [key: string]: boolean }) => void;
  isInPatientContext?: boolean;
};

export const RpmAlertTypesFiltersSection: React.FC<RpmAlertTypesFiltersSectionProps> = ({
  alertsTabEnum,
  alertTypes,
  setAlertTypes,
  alertsCount,
  isInPatientContext = false
}) => {
  const onSelectAlertType = useCallback(
    (alertType: RpmAlertsLabelTypes) => {
      setAlertTypes({
        ...alertTypes,
        [alertType]: !alertTypes[alertType]
      });
    },
    [alertTypes, setAlertTypes]
  );

  return (
    <Stack
      justifyContent={'flex-end'}
      direction="row"
      sx={{
        width: '100%',
        height: 'auto'
      }}
    >
      {alertsTabEnum === RpmAlertTabEnum.PATIENTS && (
        <Grid maxWidth={1200} width="100%" gap={1} container spacing={1} columns={7} flexWrap="nowrap">
          <Grid
            item
            xs={5}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'stretch',
              gap: 1
            }}
          >
            <Stack justifyContent="center" alignItems="center" gap={1}>
              <H4>Readings</H4>
            </Stack>
            <Grid flex={1} container spacing={1} columns={5} alignItems={'stretch'}>
              <Grid item xs={1}>
                <AlertTypeFilter
                  onSelectAlertType={onSelectAlertType}
                  type={RpmAlertsLabelTypes.CRITICALLY_HIGH}
                  count={alertsCount?.criticallyHigh ?? 0}
                  isSelected={alertTypes[RpmAlertsLabelTypes.CRITICALLY_HIGH]}
                  isInPatientContext={isInPatientContext}
                />
              </Grid>
              <Grid item xs={1}>
                <AlertTypeFilter
                  onSelectAlertType={onSelectAlertType}
                  type={RpmAlertsLabelTypes.CRITICALLY_LOW}
                  count={alertsCount?.criticallyLow ?? 0}
                  isSelected={alertTypes[RpmAlertsLabelTypes.CRITICALLY_LOW]}
                  isInPatientContext={isInPatientContext}
                />
              </Grid>
              <Grid item xs={1}>
                <AlertTypeFilter
                  onSelectAlertType={onSelectAlertType}
                  type={RpmAlertsLabelTypes.HIGH}
                  count={alertsCount?.high ?? 0}
                  isSelected={alertTypes[RpmAlertsLabelTypes.HIGH]}
                  isInPatientContext={isInPatientContext}
                />
              </Grid>
              <Grid item xs={1}>
                <AlertTypeFilter
                  onSelectAlertType={onSelectAlertType}
                  type={RpmAlertsLabelTypes.LOW}
                  count={alertsCount?.low ?? 0}
                  isSelected={alertTypes[RpmAlertsLabelTypes.LOW]}
                  isInPatientContext={isInPatientContext}
                />
              </Grid>
              <Grid item xs={1}>
                <AlertTypeFilter
                  onSelectAlertType={onSelectAlertType}
                  type={RpmAlertsLabelTypes.NO_READINGS}
                  count={alertsCount?.noReadings ?? 0}
                  isSelected={alertTypes[RpmAlertsLabelTypes.NO_READINGS]}
                  isInPatientContext={isInPatientContext}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'stretch',
              gap: 1
            }}
          >
            <Stack justifyContent="center" alignItems="center" gap={1}>
              <H4>Other</H4>
            </Stack>
            <Grid flex={1} container spacing={1} columns={2} alignItems={'stretch'}>
              <Grid item xs={1}>
                <AlertTypeFilter
                  onSelectAlertType={onSelectAlertType}
                  type={RpmAlertsLabelTypes.RPM_SETUP}
                  count={alertsCount?.rpmSetup ?? 0}
                  isSelected={alertTypes[RpmAlertsLabelTypes.RPM_SETUP]}
                  isInPatientContext={isInPatientContext}
                />
              </Grid>
              <Grid item xs={1}>
                <AlertTypeFilter
                  onSelectAlertType={onSelectAlertType}
                  type={RpmAlertsLabelTypes.MED_REGIMEN}
                  count={alertsCount?.medRegimen ?? 0}
                  isSelected={alertTypes[RpmAlertsLabelTypes.MED_REGIMEN]}
                  isInPatientContext={isInPatientContext}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {alertsTabEnum === RpmAlertTabEnum.REPORTS && (
        <Stack
          justifyContent="flex-end"
          display="inline-flex"
          height="auto"
          direction="row"
          alignItems="stretch"
          flexWrap="nowrap"
          marginTop={2}
          marginX={1}
          gap={1}
          width="100%"
        >
          <AlertTypeFilter
            onSelectAlertType={onSelectAlertType}
            type={RpmAlertsLabelTypes.REPORT_FAX_FAILED}
            count={alertsCount?.reportFaxFailed ?? 0}
            isSelected={alertTypes[RpmAlertsLabelTypes.REPORT_FAX_FAILED]}
            isInPatientContext={isInPatientContext}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default RpmAlertTypesFiltersSection;
