import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import ScheduledReportsListDialog from '@/components/Dialogs/ScheduledReportsListDialog';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import type { CreateScheduledReportResponse } from '@/graphql/administration';
import { CREATE_SCHEDULED_REPORT, GET_CLIENT_ORGANIZATIONS_STANDARD } from '@/graphql/administration';
import type { ClientOrganizationObject, ClientStoreObject, GetClientOrganizationResponse } from '@/types/administration';
import { ScheduledReportTypeEnum } from '@/types/administration';
import type { TaskType } from '@/types/graphql';
import { formatDate } from '@/util/format';
import { useMutation, useQuery } from '@apollo/client';
import { FormControl, Grid, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import moment from 'moment';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import type { Dispatch, SetStateAction } from 'react';
import { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import type { FormType } from '../Billing';

type Props = {
  getTasksLoading: boolean;
  setTasks: Dispatch<SetStateAction<TaskType[]>>;
};

const BillingDetailsFilter = ({ getTasksLoading, setTasks }: Props) => {
  const defaults: { id: string; name: string }[] = [{ id: 'all', name: 'All' }];

  const { enqueueSnackbar } = useSnackbar();
  const { showModal } = useModal();

  const [clientOrganizations, setClientOrganizations] = useState<ClientOrganizationObject[]>([]);
  const [filteredClientStores, setFilteredClientStores] = useState<ClientStoreObject[]>([]);
  const [resetTrigger, setResetTrigger] = useState(0);

  useQuery<GetClientOrganizationResponse>(GET_CLIENT_ORGANIZATIONS_STANDARD, {
    variables: {
      pageNum: 0,
      pageSize: DEFAULT_PAGE_SIZE
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: data => setClientOrganizations([...defaults, ...data.clientOrganizations.clientOrganizations])
  });
  const [createScheduledReport, { loading: createScheduledReportLoading }] =
    useMutation<CreateScheduledReportResponse>(CREATE_SCHEDULED_REPORT);

  const { control, setValue, getValues, reset, watch } = useFormContext<FormType>();

  const organization = watch('organization');
  const orgStore = watch('orgStore');
  const performedOnStartDate = watch('performedOnStartDate');
  const performedOnEndDate = watch('performedOnEndDate');

  const isFiltersApplied = useMemo(() => {
    let filterApplied = false;

    if (organization !== 'all') filterApplied = true;
    if (orgStore !== 'all' && orgStore !== '') filterApplied = true;

    const firstDayOfCurrentMonth = moment().startOf('month');
    const lastDateOfPreviousMonth = moment().endOf('day');
    const startDate = moment(performedOnStartDate);
    const endDate = moment(performedOnEndDate);

    if (startDate.isValid() && !startDate.isSame(firstDayOfCurrentMonth, 'day')) filterApplied = true;
    if (endDate.isValid() && !endDate.isSame(lastDateOfPreviousMonth, 'day')) filterApplied = true;

    return filterApplied;
  }, [orgStore, organization, performedOnEndDate, performedOnStartDate]);

  const handleScheduleReport = async () => {
    const modal = showModal(ConfirmDialog, {
      title: 'Confirm Schedule Report',
      message: 'This will schedule to generate a report for the selected options. Are you sure you want to proceed?',
      onAgree: async () => {
        await scheduleMTMBillingReport();
        modal.hide();
      },
      onDisagree: () => {
        modal.hide();
      },
      maxWidth: 'md'
    });
  };

  const scheduleMTMBillingReport = async () => {
    const { organization, performedOnEndDate, performedOnStartDate } = getValues();
    try {
      const response = await createScheduledReport({
        variables: {
          reportType: ScheduledReportTypeEnum.MTM_BILLING_REPORT,
          scheduledReportInput: {
            filterOrganizationId: organization === 'all' ? undefined : organization,
            filterStartDate: formatDate(performedOnStartDate, 'YYYY-MM-DD'),
            filterEndDate: formatDate(performedOnEndDate, 'YYYY-MM-DD')
          }
        }
      });

      if (response?.data?.createScheduledReport?.status === 'Success') {
        enqueueSnackbar('Report scheduled successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('Failed to schedule report', { variant: 'error' });
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Failed to schedule report', { variant: 'error' });
    }
  };

  const onViewReportsClicked = () => {
    const modal = showModal(ScheduledReportsListDialog, {
      hideDialog: () => {
        modal.hide();
      },
      reportType: ScheduledReportTypeEnum.MTM_BILLING_REPORT,
      title: 'Scheduled Reports'
    });
  };

  const setClientStores = (selectedOrgId: string) => {
    setFilteredClientStores([]);
    setValue('orgStore', '');

    const org = clientOrganizations?.find(o => o.id === selectedOrgId);
    if (org && org.clientStores?.length > 0) {
      setFilteredClientStores([...defaults, ...org.clientStores]);
      setValue('orgStore', 'all');
    }
  };

  const handleReset = () => {
    reset();
    setFilteredClientStores([]);
    setResetTrigger(prev => prev + 1);
    setTasks([]);
  };

  return (
    <Stack>
      <Grid container spacing={1} pb={1}>
        <Grid item xs={4}>
          <Controller
            control={control}
            name="performedOnStartDate"
            render={({ field }) => (
              <TruentityDatePicker
                key={`start-date-${resetTrigger}`}
                label="Start Date"
                fullWidth={false}
                sx={{ width: '100%' }}
                value={field.value}
                format="MMM DD, YYYY"
                onChange={field.onChange}
                disableFuture
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Controller
            control={control}
            name="performedOnEndDate"
            render={({ field }) => (
              <TruentityDatePicker
                key={`end-date-${resetTrigger}`}
                label="End Date"
                fullWidth={false}
                sx={{ width: '100%' }}
                value={field.value}
                format="MMM DD, YYYY"
                onChange={field.onChange}
                disableFuture
                shouldDisableDate={date => {
                  const startDate = moment(performedOnStartDate);
                  return startDate.isValid() && date.isBefore(startDate, 'day');
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <Controller
            control={control}
            name="organization"
            render={({ field }) => (
              <FormControl sx={{ width: '100%', marginRight: 1 }} variant="outlined" margin="dense" size="medium">
                <InputLabel id={'organizations-input'}>Organization</InputLabel>

                <Select
                  labelId={'organizations'}
                  label={'Organizations'}
                  placeholder={'select an organization'}
                  value={field.value}
                  onChange={({ target }) => {
                    field.onChange(target.value);
                    setClientStores(target.value);
                  }}
                >
                  {clientOrganizations.map((item: ClientOrganizationObject) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={4}>
          {filteredClientStores.length > 0 && (
            <Controller
              control={control}
              name="orgStore"
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }} variant="outlined" fullWidth margin="dense" size="medium">
                  <InputLabel id={'organizations-input'}>Location</InputLabel>

                  <Select labelId={'stores'} label={'Stores'} placeholder={'select a store'} value={field.value} onChange={field.onChange}>
                    {filteredClientStores.map((item: ClientStoreObject) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          )}
        </Grid>
      </Grid>

      <Grid container spacing={1} pb={1}>
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%'
          }}
          item
          xs={12}
        >
          <Stack spacing={1} direction="row">
            <Button type="submit" size="small" isLoading={getTasksLoading}>
              Run Report
            </Button>
            <Button type="button" size="small" onClick={handleScheduleReport} isLoading={createScheduledReportLoading}>
              Schedule Report
            </Button>
            <Button disabled={!isFiltersApplied} label={'Reset'} type="reset" size="small" color="secondary" onClick={handleReset} />
          </Stack>
          <Button type="button" variant="outlined" size="small" onClick={onViewReportsClicked} isLoading={createScheduledReportLoading}>
            View Scheduled Reports
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default BillingDetailsFilter;
