export type MessageType = {
  id: string;
  type: MessageState;
  isCompleted: boolean;
  sender: SenderType;
  body: string;
};

export type CopilotConversationTitle = {
  id: string;
  truentityId: string;
  title: string;
  feature: string;
  createdAt: Date;
};

export type CopilotConversationMessage = {
  truentityId: string;
  message: string;
  messageType: string;
};

export enum SenderType {
  BOT = 'bot_streaming',
  USER = 'human'
}

export enum MessageState {
  LOADER = 'LOADER',
  STREAM = 'STREAM',
  COMPLETED = 'COMPLETED',
  USER = 'USER',
  FAILED = 'FAILED'
}
