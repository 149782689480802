import type { JSONData } from '@/components/JsonKit/JsonEditor';
import type { Moment } from 'moment';
import type { ReactElement } from 'react';
import type { AccountType, RelyingPartyAdminType, RelyingPartyType } from './graphql';
import type Meta from './meta';
import type { ProviderType } from './remotePatientMonitoring';

export enum ClaimSubmissionType {
  UNSCHEDULED = 'UNSCHEDULED',
  SCHEDULED = 'SCHEDULED',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED'
}

export type ClaimSubmissionTabType = {
  type: ClaimSubmissionType;
  icon: ReactElement;
  label: string;
  path: string;
};

export type ClaimActivityLog = {
  performerRelyingParty: RelyingPartyType;
  performer: RelyingPartyAdminType;
  logType: string;
  logText: string;
  additionalData: JSONData;
  processData: JSONData;
};

export type ClaimSubmission = {
  id: string;
  account: AccountType;
  relyingParty: RelyingPartyType;
  provider: ProviderType;
  billingKey: string;
  claimsService: string;
  status: ClaimSubmissionType;
  scheduledOn: string;
  additionalData: JSONData;
  processData: JSONData;
  serviceDate: string;
  performedOn: string | null;
  claimActivityLog: ClaimActivityLog[];
};

export type ClaimSubmissionInfo = {
  submissionAllowedRelyingParties: RelyingPartyType[];
  submissionAllowedCptCodes: SubmissionCodeType[];
};

export type relyingPartyServiceDates = {
  relyingPartyId: string;
  serviceDate: Moment;
};

export type SubmissionCodeType = { name: string; code: string };

export type GetClaimSubmissionInfoResponse = {
  getClaimSubmissionInfo: ClaimSubmissionInfo;
};

export type GetClaimSubmissionResponse = {
  getClaimSubmissions: {
    claimSubmissions: ClaimSubmission[];
    meta: Meta;
  };
};
