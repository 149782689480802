import { Small } from '@/components/Typography';
import { color } from '@/styles/assets/colors';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Stack } from '@mui/material';
import type { FC } from 'react';

type Props = {
  alertText: string;
};

const OrganizationAlert: FC<Props> = ({ alertText }) => {
  return (
    <Stack direction="row" py={0.5} alignItems="center" gap={0.9}>
      <ErrorOutlineIcon color="error" sx={{ fontSize: '.75rem' }} />
      <Small color={color.red900}>{alertText}</Small>
    </Stack>
  );
};

export default OrganizationAlert;
