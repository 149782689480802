import RpmApprovalStatusChip from '@/components/ChipLabel/RpmApprovalStatusChip';
import { MEDIUM_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import RpmMyPatientsMetaDataDialog from '@/components/Dialogs/RpmMyPatientsMetaDataDialog';
import RpmReportPreviewDialog from '@/components/Dialogs/RpmReportPreviewDialog';
import ScheduleEnrollmentDialog from '@/components/Dialogs/ScheduleEnrollmentDialog';
import StarredPatient from '@/components/StarredPatient';
import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import { Body1 } from '@/components/Typography';
import { DELETE_ACCOUNT_FOLLOWUP, FollowUpTypes } from '@/graphql/account';
import type { PatientsData } from '@/routes/Patients/patients';
import { AccountNameOrder } from '@/types/accountProfile';
import { MedicalServices } from '@/types/admin';
import type {
  AccountsMonitoringDeviceType,
  ParsedDeviceType,
  RpmAccountSnapshotType,
  RpmPatientPrioritizationReportType
} from '@/types/remotePatientMonitoring';
import { ContactedType, RpmStatusTypes } from '@/types/remotePatientMonitoring';
import { getAccountRedirectUrl, getAccountUserFullName } from '@/util/account';
import { addOneYearToDate, today } from '@/util/date';
import { clusterFollowupsByDate } from '@/util/followUp';
import { formatDate, formatDateAndTime, formatSecondsToHMS } from '@/util/format';
import { getCustomRowHeight, getReadingTypeValue } from '@/util/get';
import {
  DEFAULT_DEVICE_LENGTH,
  extractValueFromJson,
  getColorForMonthlyTimeSpent,
  getColorForTotalReadings,
  mapEnrolledAccounts
} from '@/util/rpm';
import { useMutation } from '@apollo/client';
import { TapAndPlayOutlined } from '@mui/icons-material';
import ArticleIcon from '@mui/icons-material/Article';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import DataObjectIcon from '@mui/icons-material/DataObject';
import PeopleIcon from '@mui/icons-material/People';
import PhoneIcon from '@mui/icons-material/Phone';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import { Box, Chip, IconButton, Stack, Tooltip } from '@mui/material';
import type { GridCellParams, GridColDef, GridColumnVisibilityModel, GridFilterModel } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import RpmPatientInfoDrawer from '../RpmPatientDrawer';
import { useRpmMyPatientContext } from './RpmMyPatientsProvider';
import { columnVisibility } from './RpmMyPatientsUtils';

type RenderDeviceReadingsProps = {
  device: ParsedDeviceType;
  truentityId: string;
};

const RpmMyPatientsDataGrid = () => {
  const {
    showModal,
    selectionModel,
    setSelectionModel,
    tabs: { tab, rpmPatientStatusTab },
    filters: { monthAndYear, sortModel, setSortModel, filteredPatientsByCharts },
    rpmEnrolledAccountsQuery: { callGetRpmEnrolledAccounts, rpmEnrolledAccountsData, rpmEnrolledAccountsLoading },
    accountsByRpmStatusQuery: {
      callGetAccountByRpmStatus,
      rpmAccountsByStatusQueryData,
      rpmAccountsByStatusQueryRefetch,
      rpmAccountsByStatusQueryLoading
    },
    patientDetailsQuery: { getPatientDetail },
    rpmCountByStatusQuery: { getRpmAccountsCountByStatus },
    setUnenrolledAccountsCount,
    reports: { setPatientPriotizationReportData }
  } = useRpmMyPatientContext();
  const { enqueueSnackbar } = useSnackbar();

  const startDate = useMemo(() => '2020-01-01', []);
  const endDate = useMemo(() => addOneYearToDate(today()), []);

  const [rows, setRows] = useState<RpmAccountSnapshotType[]>([]);
  const [selectedCandidate, setSelectedCandidate] = useState<PatientsData>({} as PatientsData);
  const [totalRowCount, setTotalRowCount] = useState(MEDIUM_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(columnVisibility.scheduledForEnrollment);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });

  const tempVitalAccessEnabled = (settings: string) => {
    const extractedValue = extractValueFromJson(settings, 'temporaryVitalReadingAccess');
    if (!extractedValue) return false;
    return extractedValue;
  };

  const [deleteFollowup] = useMutation(DELETE_ACCOUNT_FOLLOWUP);

  const resetStates = useCallback(() => {
    setRows([]);
  }, [setRows]);

  const renderDeviceReadingDates = useCallback(
    ({ device, truentityId }: RenderDeviceReadingsProps) => {
      return (
        <Stack
          flex={1}
          flexDirection="column"
          flexWrap={'nowrap'}
          key={`${device?.id}`}
          justifyContent={'flex-start'}
          alignItems={'stretch'}
          width={'100%'}
        >
          {device?.latestReadings?.length === 0
            ? 'N/A'
            : device?.latestReadings?.map((reading, index) => (
                <Box key={`${monthAndYear}-${truentityId}-${device?.id}-${index}-date`} textTransform="capitalize">
                  {reading?.recordedAt ? `${formatDateAndTime(reading?.recordedAt)}` : 'N/A'}
                </Box>
              ))}
        </Stack>
      );
    },
    [monthAndYear]
  );

  useEffect(() => {
    if (rpmAccountsByStatusQueryData?.getAccountsByRpmStatus && !rpmAccountsByStatusQueryLoading) {
      const rpmAccountsData = rpmAccountsByStatusQueryData?.getAccountsByRpmStatus;
      const rpmAccounts = rpmAccountsData?.rpmAccounts;
      const meta = rpmAccountsData?.meta;

      if (rpmAccounts) {
        setRows(rpmAccounts);

        /*
         * Set the CSV report generation
         */
        const reportList: RpmPatientPrioritizationReportType[] = [];
        rpmAccounts.forEach(account => {
          reportList.push({
            id: account?.id,
            patientName: `${account?.user?.firstName} ${account?.user?.lastName}`,
            rpmPriorityScore: account?.accountMetric?.rpmPriorityScore ?? 0,
            dateOfBirth: account?.birthDate ?? '',
            healthPlan: account?.healthPlan?.orgName,
            phone: account?.phone ?? ''
          });
        });
        setPatientPriotizationReportData(reportList);
      }
      if (meta) {
        setTotalRowCount(meta.totalCount ?? 0);
      } else {
        setTotalRowCount(0);
      }
    } else {
      resetStates();
    }
  }, [resetStates, rpmAccountsByStatusQueryData, rpmAccountsByStatusQueryLoading, setPatientPriotizationReportData]);

  const renderDeviceReadingValues = useCallback(
    ({ device, truentityId }: RenderDeviceReadingsProps) => {
      return (
        <Stack
          flexDirection="column"
          flex={1}
          flexWrap={'nowrap'}
          key={`${device?.id}`}
          justifyContent={'flex-start'}
          alignItems={'stretch'}
          width={'100%'}
        >
          {device?.latestReadings?.length === 0
            ? 'N/A'
            : device?.latestReadings?.map((reading, index) => (
                <Box key={`${monthAndYear}-${truentityId}-${device?.id}-${index}-value`}>
                  {reading ? `${getReadingTypeValue(reading)}` : 'N/A'}
                </Box>
              ))}
        </Stack>
      );
    },
    [monthAndYear]
  );

  const setDefaultPageOptions = () => {
    setCurrentPage(0);
  };

  const onRPMReportClick = useCallback(
    (truentityId: string) => {
      const modal = showModal(RpmReportPreviewDialog, {
        truentityId: truentityId,
        filterMonthYear: monthAndYear,
        hideDialog: () => {
          modal.hide();
        },
        title: 'RPM Report Preview'
      });
    },
    [showModal, monthAndYear]
  );

  const getAccountByRpmStatus = useCallback(
    async (status: string) => {
      if (status === RpmStatusTypes.ENROLLED) {
        const filterByAccountIds = filteredPatientsByCharts?.[0]?.truentityIds || [];

        await callGetRpmEnrolledAccounts({
          variables: {
            pageSize: MEDIUM_PAGE_SIZE,
            pageNum: currentPage + 1,
            filterMonthYear: monthAndYear,
            filterByAccountIds,
            sortModel: sortModel
          }
        });
      } else {
        const patientStatusTab = rpmPatientStatusTab[tab];
        await callGetAccountByRpmStatus({
          variables: {
            pageSize: MEDIUM_PAGE_SIZE,
            pageNum: currentPage + 1,
            filterOptions: {
              rpmStatus: patientStatusTab?.status,
              startDate: formatDate(startDate),
              endDate: formatDate(endDate),
              sortModel: sortModel,
              includeMetrics: patientStatusTab?.path === 'priorityCandidate'
            },
            relyingPartyAdminId: 'all'
          }
        });
      }
    },
    [
      filteredPatientsByCharts,
      callGetRpmEnrolledAccounts,
      currentPage,
      monthAndYear,
      sortModel,
      callGetAccountByRpmStatus,
      rpmPatientStatusTab,
      tab,
      startDate,
      endDate
    ]
  );

  const handleScheduleForEnrollment = useCallback(
    (data: RpmAccountSnapshotType) => {
      const patientName = getAccountUserFullName(data.user, AccountNameOrder.LAST_FIRST);

      const modal = showModal(ScheduleEnrollmentDialog, {
        title: `Schedule for Enrollment: ${patientName}`,
        patientInfo: data,
        hideDialog: () => modal.hide(),
        refetch: rpmAccountsByStatusQueryRefetch
      });
    },
    [rpmAccountsByStatusQueryRefetch, showModal]
  );

  const handleCancelScheduleForEnrollment = useCallback(
    (data: RpmAccountSnapshotType) => {
      const allInitialVisitFollowupIds =
        data?.accountsFollowupReminders
          ?.filter(followup => followup.type === FollowUpTypes.RPM_INITIAL_VISIT)
          .map(followup => followup.id) ?? [];

      const modal = showModal(ConfirmDialog, {
        title: 'Confirmation Required',
        message: `There ${
          allInitialVisitFollowupIds?.length > 1 ? `are ${allInitialVisitFollowupIds.length} visits` : 'is 1 visit'
        } scheduled for ${getAccountUserFullName(data?.user)}. This action will modify the calendar items. Are you sure?`,
        onAgree: async () => {
          await deleteFollowup({ variables: { followUpIds: allInitialVisitFollowupIds } })
            .then(response => {
              const data = response.data.removeAccountFollowup;
              const variant = data?.status === 'Success' ? 'success' : 'error';
              const message = data?.status === 'Success' ? 'Visit(s) deleted successfully' : data?.message;
              enqueueSnackbar(message, { variant });
              modal.hide();
              rpmAccountsByStatusQueryRefetch();
            })
            .catch(() => {
              enqueueSnackbar('Failed to delete visit(s)', {
                variant: 'error'
              });
              modal.hide();
            });
        },
        onDisagree: () => {
          modal.hide();
        }
      });
    },
    [deleteFollowup, enqueueSnackbar, rpmAccountsByStatusQueryRefetch, showModal]
  );

  useEffect(() => {
    if (rpmEnrolledAccountsData?.getRpmEnrolledAccounts && !rpmEnrolledAccountsLoading) {
      const { rpmEnrolledAccounts, meta } = rpmEnrolledAccountsData.getRpmEnrolledAccounts;

      if (rpmEnrolledAccounts) {
        setRows(mapEnrolledAccounts(rpmEnrolledAccounts));
      }
      if (meta) {
        setTotalRowCount(meta.totalCount ?? 0);
      } else {
        setTotalRowCount(0);
      }
    } else {
      resetStates();
    }
  }, [resetStates, rpmEnrolledAccountsData, rpmEnrolledAccountsLoading]);

  useEffect(() => {
    getRpmAccountsCountByStatus({
      variables: {
        status: RpmStatusTypes.UNENROLLED
      }
    })
      .then(response => {
        if (response.data?.getRpmAccountsCountByStatus) {
          setUnenrolledAccountsCount(response.data?.getRpmAccountsCountByStatus.count);
        } else {
          enqueueSnackbar('No data', { variant: 'warning' });
        }
      })
      .catch(error => {
        console.error(error);
        enqueueSnackbar('Failed to get Unenrolled Patients count', { variant: 'error' });
      });
  }, [getRpmAccountsCountByStatus, enqueueSnackbar, setUnenrolledAccountsCount]);

  // Update column visibility when the tab changes
  useEffect(() => {
    const selectedTab = rpmPatientStatusTab[tab];
    const visibility = columnVisibility[selectedTab.path];
    setColumnVisibilityModel(prevModel => ({ ...prevModel, ...visibility }));
  }, [tab, rpmPatientStatusTab]);

  // Reset pagination only when the tab changes
  useEffect(() => {
    setDefaultPageOptions();
  }, [tab]);

  useEffect(() => {
    const selectedTab = rpmPatientStatusTab[tab];
    getAccountByRpmStatus(selectedTab?.status).catch(err => console.error(err));
  }, [tab, currentPage, monthAndYear, sortModel, filteredPatientsByCharts, rpmPatientStatusTab, getAccountByRpmStatus]);

  const columns: GridColDef<RpmAccountSnapshotType>[] = useMemo(
    () => [
      {
        field: 'truentityId',
        headerAlign: 'left',
        width: 30,
        minWidth: 30,
        cellClassName: 'star-column',
        headerClassName: 'star-column',
        align: 'left',
        sortable: false,
        disableColumnMenu: true,
        renderHeader: () => null,
        renderCell: params => {
          return <StarredPatient truentityId={params.value} />;
        }
      },
      {
        field: 'patientName',
        headerName: 'Patient Name (Last, First)',
        sortable: true,
        flex: 2,
        minWidth: 135,
        align: 'left',
        valueGetter: params => getAccountUserFullName(params.row.user),
        renderCell: params => {
          const name = getAccountUserFullName(params.row.user, AccountNameOrder.LAST_FIRST);
          const status = params.row.rpmStatus;
          const accountMonitoringDevices = params.row.devices as AccountsMonitoringDeviceType[];

          let redirectUrl = '';
          if (rpmPatientStatusTab[tab].path === 'priorityCandidate' || rpmPatientStatusTab[tab].path === 'candidate') {
            redirectUrl = `../../patients/${params.row.truentityId}/details/notes`;
          } else {
            redirectUrl = getAccountRedirectUrl(params?.row?.truentityId, MedicalServices.RPM, status, accountMonitoringDevices) ?? '';
          }

          return (
            <Stack spacing={1} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="center" justifyContent={'start'}>
              <Box flex={1} sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <Link to={redirectUrl}>{name}</Link>
              </Box>
              {tempVitalAccessEnabled(params?.row?.setting ?? '{}') && (
                <Box flexBasis={'5px'}>
                  <TapAndPlayOutlined sx={{ fontSize: '1rem' }} />
                </Box>
              )}
            </Stack>
          );
        }
      },
      {
        field: 'phone',
        headerName: 'Phone number',
        sortable: true,
        flex: 2,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => params.row.phone,
        renderCell: params => <TruentityPhoneNumber value={params.value} />
      },
      {
        field: 'rpmPriorityScore',
        headerName: 'Priority Score',
        sortable: true,
        flex: 2,
        align: 'center',
        headerAlign: 'center',
        valueGetter: ({ row: { accountMetric } }) => accountMetric?.rpmPriorityScore ?? 0
      },
      {
        field: 'dateOfBirth',
        headerName: 'Date of Birth',
        sortable: true,
        flex: 2,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => formatDate(params.row.birthDate)
      },
      {
        field: 'healthPlan',
        headerName: 'Health Plan',
        sortable: true,
        flex: 2,
        minWidth: 110,
        width: 110,
        align: 'left',
        headerAlign: 'left',
        valueGetter: params => (params.row.healthPlan ? params.row.healthPlan.orgName : '')
      },
      {
        field: 'medicationData',
        headerName: 'Medication Data',
        sortable: true,
        flex: 2,
        align: 'center',
        headerAlign: 'center',
        valueGetter: ({ row: { accountMetric } }) => accountMetric?.medicationData,
        renderCell: ({ value }) => (
          <IconButton
            onClick={() => {
              const modal = showModal(RpmMyPatientsMetaDataDialog, {
                title: 'Medications Data',
                hideDialog: () => {
                  modal.hide();
                },
                medicationData: value
              });
            }}
          >
            <Tooltip title="View medication data">
              <DataObjectIcon />
            </Tooltip>
          </IconButton>
        )
      },
      {
        field: 'claimsData',
        headerName: 'Claims Data',
        sortable: true,
        flex: 2,
        align: 'center',
        headerAlign: 'center',
        valueGetter: ({ row: { accountMetric } }) => accountMetric?.claimsData,
        renderCell: ({ value }) => (
          <IconButton
            onClick={() => {
              const modal = showModal(RpmMyPatientsMetaDataDialog, {
                title: 'Claims data',
                hideDialog: () => {
                  modal.hide();
                },
                claimsData: value
              });
            }}
          >
            <Tooltip title="View claims data">
              <DataObjectIcon />
            </Tooltip>
          </IconButton>
        )
      },
      {
        field: 'zipcode',
        headerName: 'Zipcode',
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => params.row.zipcode
      },
      {
        field: 'devices',
        headerName: 'Devices',
        sortable: false,
        flex: 3,
        minWidth: 150,
        align: 'left',
        headerAlign: 'left',
        renderCell: params => {
          const devices = params.row?.devices?.slice(0, DEFAULT_DEVICE_LENGTH);
          return (
            <Stack
              paddingY={1}
              width="100%"
              height="100%"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="stretch"
              flexWrap="nowrap"
              gap=".3125rem"
            >
              {devices?.length === 0 ? (
                <Box>N/A</Box>
              ) : (
                <>
                  {devices?.map(device => (
                    <Box
                      width="100%"
                      key={device?.id}
                      textTransform="capitalize"
                      sx={{
                        textOverflow: 'ellipsis',
                        height: getCustomRowHeight(device?.latestReadings?.length ?? 0)
                      }}
                    >
                      <Chip size="small" label={device?.monitoringDevice?.name} />
                    </Box>
                  ))}
                </>
              )}
            </Stack>
          );
        }
      },
      {
        field: 'lastReadingAt',
        headerName: 'Last Reading Date',
        sortable: false,
        minWidth: 180,
        flex: 3,
        align: 'left',
        headerAlign: 'left',
        renderCell: params => {
          const devices = params.row?.devices?.slice(0, DEFAULT_DEVICE_LENGTH);
          return (
            <Stack
              paddingY={1}
              width="100%"
              height="100%"
              flexDirection={'column'}
              justifyContent={'flex-start'}
              alignItems={'stretch'}
              gap=".3125rem"
            >
              {devices?.length === 0 ? (
                <Box>N/A</Box>
              ) : (
                <>
                  {devices?.map(device =>
                    renderDeviceReadingDates({
                      device,
                      truentityId: params.row.truentityId
                    })
                  )}
                </>
              )}
            </Stack>
          );
        }
      },
      {
        field: 'lastReadingValue',
        headerName: 'Last Reading',
        sortable: false,
        flex: 3,
        minWidth: 130,
        maxWidth: 200,
        align: 'left',
        headerAlign: 'left',
        renderCell: params => {
          const devices = params.row?.devices?.slice(0, DEFAULT_DEVICE_LENGTH);
          return (
            <Stack paddingY={1} width="100%" height="100%" flexDirection={'column'} justifyContent={'flex-start'} alignItems={'stretch'}>
              {devices?.length === 0 ? (
                <Box>N/A</Box>
              ) : (
                <>
                  {devices?.map(device =>
                    renderDeviceReadingValues({
                      device,
                      truentityId: params.row.truentityId
                    })
                  )}
                </>
              )}
            </Stack>
          );
        }
      },
      {
        field: 'dateEnrolled',
        headerName: 'Date Enrolled',
        sortable: true,
        flex: 2,
        minWidth: 110,
        align: 'left',
        headerAlign: 'left',
        type: 'date',
        valueGetter: params => {
          const rpmEnrolledAt = params.row.rpmEnrolledAt;
          return rpmEnrolledAt ? new Date(rpmEnrolledAt) : null;
        },
        valueFormatter: params => (params?.value ? formatDate(params?.value) : '-')
      },
      {
        field: 'dateUnenrolled',
        headerName: 'Date Unenrolled',
        sortable: true,
        flex: 2,
        minWidth: 110,
        align: 'left',
        headerAlign: 'left',
        type: 'date',
        valueGetter: params => {
          const rpmUnenrolledAt = params.row.rpmUnenrolledAt;
          return rpmUnenrolledAt ? new Date(rpmUnenrolledAt) : null;
        },
        valueFormatter: params => (params?.value ? formatDate(params?.value) : '-')
      },
      {
        field: 'onboardedOn',
        headerName: 'Onboarded On',
        sortable: true,
        flex: 2,
        minWidth: 110,
        align: 'center',
        headerAlign: 'center',
        type: 'date',
        valueGetter: params => {
          const onboardedAt = params.row.onboardedAt;
          return onboardedAt ? new Date(onboardedAt) : null;
        },
        valueFormatter: params => (params?.value ? formatDate(params?.value) : '-')
      },
      {
        field: 'initialVisitOn',
        headerName: 'Initial Visit On',
        sortable: true,
        flex: 2,
        minWidth: 110,
        align: 'center',
        headerAlign: 'center',
        type: 'date',
        valueGetter: params => {
          const initialVisitFollowups = params.row.accountsFollowupReminders?.filter(
            followup => followup.type === FollowUpTypes.RPM_INITIAL_VISIT && followup.followUpOn
          );

          const currentUserInitialVisitFollowup = clusterFollowupsByDate(initialVisitFollowups || [], 'desc');

          return currentUserInitialVisitFollowup?.[0]?.followUpOn;
        },
        valueFormatter: params => (params?.value ? formatDateAndTime(params?.value) : '-')
      },
      {
        field: 'rpmApprovalStatus',
        headerName: 'Provider Approvals',
        sortable: true,
        flex: 2,
        minWidth: 100,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => {
          if (params?.value) {
            return <RpmApprovalStatusChip approvalStatus={params.value} rpmSignOffStatus={params.row.rpmSignOffStatus} />;
          } else {
            return <span>-</span>;
          }
        }
      },
      {
        field: 'lastContacted',
        headerName: 'Last Contacted At',
        sortable: true,
        minWidth: 130,
        flex: 2.5,
        align: 'left',
        headerAlign: 'left',
        renderCell: params => {
          if (params?.value?.type === ContactedType.PHONE) {
            return (
              <Stack padding={0} margin={0} flexDirection={'row'} alignItems={'center'}>
                <Body1>{formatDate(params?.value?.date)}&nbsp;</Body1>
                <PhoneIcon fontSize={'small'} />
              </Stack>
            );
          } else if (params?.value?.type === ContactedType.IN_PERSON_VISIT) {
            return (
              <Stack padding={0} margin={0} flexDirection={'row'} alignItems={'center'}>
                <Body1>{formatDate(params?.value?.date)}&nbsp;</Body1>
                <PeopleIcon fontSize={'small'} />
              </Stack>
            );
          } else {
            return <span>-</span>;
          }
        }
      },
      {
        field: 'monthlyTotalReadings',
        headerName: 'Monthly Total Readings',
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => (
          <Chip size="small" sx={{ minWidth: '40px' }} label={params.value} color={getColorForTotalReadings(params.value)} />
        ),
        valueGetter: params => params?.row?.monthlyTotalReadings ?? 'N/A'
      },
      {
        field: 'monthlyTimeSpent',
        headerName: 'Monthly Time Spent',
        sortable: true,
        flex: 1,
        minWidth: 80,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => params?.row?.monthlyTimeSpent,
        renderCell: params => (
          <Chip
            size="small"
            sx={{ minWidth: '50px' }}
            label={params?.value ? formatSecondsToHMS(params?.value) : '0s'}
            color={getColorForMonthlyTimeSpent(params.value)}
          />
        )
      },
      {
        field: 'reportActions',
        headerName: 'Report',
        flex: 1,
        renderCell: params => {
          return (
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
              <Tooltip title="View RPM Report">
                <span>
                  <IconButton
                    size="small"
                    onClick={() => {
                      onRPMReportClick(params?.row?.truentityId);
                    }}
                    disabled={!params?.row?.isRpmReportAvailable}
                  >
                    <ArticleIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          );
        }
      },
      {
        field: 'scheduledForEnrollmentAction',
        headerName: 'Action',
        sortable: false,
        flex: 1,
        minWidth: 80,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => (
          <Tooltip title="Schedule for Enrollment">
            <IconButton color="success" onClick={() => handleScheduleForEnrollment(params.row)}>
              <ScheduleSendIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )
      },
      {
        field: 'cancelScheduledForEnrollmentAction',
        headerName: 'Action',
        sortable: false,
        flex: 1,
        minWidth: 80,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => (
          <Tooltip title="Cancel Schedule for Enrollment">
            <IconButton color="success" onClick={() => handleCancelScheduleForEnrollment(params.row)}>
              <CancelScheduleSendIcon fontSize="small" color="error" />
            </IconButton>
          </Tooltip>
        )
      }
    ],
    [
      renderDeviceReadingDates,
      renderDeviceReadingValues,
      onRPMReportClick,
      handleScheduleForEnrollment,
      handleCancelScheduleForEnrollment,
      rpmPatientStatusTab,
      tab,
      showModal
    ]
  );

  const sendPatientDataToDrawer = (patientData: PatientsData) => {
    setSelectedCandidate(patientData);
  };

  const getCustomRowId = useCallback(
    (row: PatientsData) => {
      return `${row.truentityId}-${rpmPatientStatusTab[tab]?.label}-${
        rpmPatientStatusTab[tab]?.status === RpmStatusTypes.ENROLLED ? monthAndYear : ''
      }`;
    },
    [tab, monthAndYear, rpmPatientStatusTab]
  );

  const onSelectPatient = useCallback(
    async (data: PatientsData) => {
      if (data?.truentityId) {
        try {
          const patientResponse = await getPatientDetail({
            variables: {
              truentityId: data?.truentityId
            }
          });

          if (!patientResponse.data?.accountGet) {
            throw Error('Unable to find patient');
          } else {
            sendPatientDataToDrawer({ ...patientResponse.data?.accountGet });
          }
        } catch (err) {
          console.error(err);
          enqueueSnackbar('Unable to retrieve patient data', {
            variant: 'error'
          });
        }
      }
    },
    [enqueueSnackbar, getPatientDetail]
  );

  const handleRowClick = (param: GridCellParams) => {
    if (
      ![
        'reportActions',
        'scheduledForEnrollmentAction',
        'cancelScheduledForEnrollmentAction',
        'medicationData',
        'claimsData',
        '__check__'
      ].includes(param.field)
    ) {
      onSelectPatient(param.row as PatientsData);
    }
  };

  return (
    <>
      <TruentityDataGrid
        name={`dg-rpm-patients-${rpmPatientStatusTab[tab]?.label}`}
        autoHeight
        getRowId={getCustomRowId}
        getRowHeight={() => 'auto'}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={newModel => setColumnVisibilityModel(newModel)}
        onCellClick={handleRowClick}
        rows={rows}
        disableColumnMenu={false}
        columns={columns}
        paginationModel={{ pageSize: MEDIUM_PAGE_SIZE, page: currentPage }}
        onPaginationModelChange={({ page }) => {
          setCurrentPage(page);
        }}
        sortModel={sortModel}
        onSortModelChange={newModel => {
          setSortModel(newModel);
        }}
        filterModel={filterModel}
        onFilterModelChange={newModel => {
          setFilterModel(newModel);
        }}
        loading={rpmAccountsByStatusQueryLoading || rpmEnrolledAccountsLoading}
        rowCount={totalRowCount}
        paginationMode="server"
        sortingMode="server"
        hideFooterSelectedRowCount
        keepNonExistentRowsSelected
        checkboxSelection={rpmPatientStatusTab[tab]?.status === RpmStatusTypes.ENROLLED}
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={newSelection => {
          setSelectionModel(newSelection);
        }}
        isRowSelectable={params => {
          return params.row?.rpmStatus === RpmStatusTypes.ENROLLED && params?.row?.isRpmReportAvailable;
        }}
        disableRowSelectionOnClick
        pinnedColumns={{ left: ['patientName'] }}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'dateEnrolled',
                sort: 'desc'
              }
            ]
          }
        }}
        autoPageSize={false}
        sx={{
          '& .MuiDataGrid-cell': {
            paddingY: 1
          },
          // wrap data grid header text
          '& .MuiDataGrid-columnHeaderTitle': {
            whiteSpace: 'normal',
            lineHeight: 'normal'
          }
        }}
      />
      <RpmPatientInfoDrawer patientsData={selectedCandidate} />
    </>
  );
};

export default RpmMyPatientsDataGrid;
