import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import type { ButtonProps, IconButtonProps, SvgIconProps } from '@mui/material';
import { Box, Button, IconButton } from '@mui/material';
import type { DatePickerProps } from '@mui/x-date-pickers-pro';
import { DatePicker } from '@mui/x-date-pickers-pro';
import type { Moment } from 'moment';
import type { MutableRefObject, ReactNode } from 'react';
import { forwardRef, useRef, useState } from 'react';

type ButtonType = 'icon' | 'button';

interface TruentityButtonDatePickerBaseProps {
  value: Moment | null;
  onChange: (date: Moment | null) => void;
  label?: string;
  disabled?: boolean;
  buttonType?: ButtonType;
  buttonLabel?: string;
  customIcon?: ReactNode;
  datePickerProps?: Partial<DatePickerProps<Moment>>;
  iconProps?: SvgIconProps;
}

type TruentityButtonDatePickerProps =
  | (TruentityButtonDatePickerBaseProps & { buttonType?: 'icon'; buttonProps?: IconButtonProps })
  | (TruentityButtonDatePickerBaseProps & { buttonType: 'button'; buttonProps?: ButtonProps });

const TruentityButtonDatePicker = forwardRef<HTMLButtonElement | HTMLDivElement, TruentityButtonDatePickerProps>(
  (
    {
      value,
      onChange,
      label = 'Select Date',
      buttonType = 'icon',
      buttonLabel = 'Select Date',
      customIcon,
      buttonProps,
      iconProps,
      datePickerProps
    },
    ref
  ) => {
    const [open, setOpen] = useState(false);
    const buttonRef = useRef<HTMLButtonElement | HTMLDivElement | null>(null);

    const handleDateChange = (newValue: Moment | null): void => {
      if (onChange) {
        onChange(newValue);
      }
    };

    const renderButton = (): ReactNode => {
      if (buttonType === 'button') {
        return (
          <Button
            ref={node => {
              buttonRef.current = node;
              if (typeof ref === 'function') {
                ref(node);
              } else if (ref) {
                (ref as MutableRefObject<HTMLButtonElement | null>).current = node;
              }
            }}
            onClick={() => setOpen(true)}
            color="primary"
            variant="contained"
            disabled={buttonProps?.disabled}
            {...(buttonProps as ButtonProps)}
          >
            {customIcon || <CalendarTodayIcon {...iconProps} />} {buttonLabel}
          </Button>
        );
      }

      return (
        <IconButton
          ref={node => {
            buttonRef.current = node;
            if (typeof ref === 'function') {
              ref(node);
            } else if (ref) {
              (ref as MutableRefObject<HTMLButtonElement | null>).current = node;
            }
          }}
          onClick={() => setOpen(true)}
          color="primary"
          aria-label={label}
          {...(buttonProps as IconButtonProps)}
        >
          {customIcon || <CalendarTodayIcon {...iconProps} />}
        </IconButton>
      );
    };

    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {renderButton()}
        <DatePicker
          label={label}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          value={value}
          onChange={handleDateChange}
          {...datePickerProps}
          slotProps={{
            textField: {
              sx: { display: 'none' }
            },
            popper: {
              sx: { zIndex: 1300 },
              anchorEl: buttonRef.current,
              popperOptions: {
                placement: 'bottom-end'
              }
            },
            field: {
              readOnly: true
            },
            ...datePickerProps?.slotProps
          }}
        />
      </Box>
    );
  }
);

export default TruentityButtonDatePicker;
