import type { TaskType } from '@/types/graphql';

export enum TaskStatuses {
  Pending = 'pending',
  Completed = 'completed',
  Attempted = 'attempted'
}

export type TaskStatus = TaskStatuses.Pending | TaskStatuses.Completed | TaskStatuses.Attempted;

export type AccountType = {
  truentityId: string;
  user: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
  clientOrgs: {
    name: string;
  }[];
  clientStores: {
    name: string;
  }[];
};

export type MetaDataType = {
  totalPages: number;
  totalCount: number;
};

export type GetTasksByFilterDataResponse = {
  tasks: TaskType[];
  totalBonusAmount: number;
  totalBilledAmount: number;
  meta: MetaDataType;
};

export type GetTasksByFilterResponse = {
  tasksByFilter: GetTasksByFilterDataResponse;
};

export type GetTaskByFilterStatsResponse = {
  taskStatsByFilter: Pick<GetTasksByFilterDataResponse, 'totalBilledAmount' | 'totalBonusAmount'>;
};
