import SideMenuContext from '@/context/sideMenuContext';
import type { GetSystemOptionsResponse } from '@/graphql/administration';
import { GET_SYSTEM_OPTIONS } from '@/graphql/administration';
import type {
  CopilotConversationMessageResponse,
  CopilotConversationTitlesResponse,
  LoadMessageContextResponse,
  RemoveMessageContextResponse
} from '@/graphql/coPilot';
import {
  LOAD_MESSAGE_CONTEXT,
  QUERY_COPILOT_CONVERSATION_MESSAGES,
  QUERY_COPILOT_CONVERSATION_TITLES,
  REMOVE_MESSAGE_CONTEXT,
  SEND_COPILOT_MESSAGE
} from '@/graphql/coPilot';
import CopilotMessageSubscriptionHandler from '@/hooks/useCopilotMessage';
import { SystemOptionKeyEnum } from '@/types/administration';
import type { CopilotConversationMessage, CopilotConversationTitle } from '@/types/coPilot';
import { MessageState, SenderType } from '@/types/coPilot';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { categorizeDate } from '@/util/date';
import { useCopilotMessageStore } from '@/zustand/CoPilotMessageStore';
import { useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { CloseFullscreen, Edit, Home, OpenInFull, OpenInNew } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import MedicationIcon from '@mui/icons-material/Medication';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import SendIcon from '@mui/icons-material/Send';
import SpaIcon from '@mui/icons-material/Spa';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import { Alert, Card, CardActions, CardHeader, Grid, Skeleton, Stack, styled, TextField } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import type { ReactElement } from 'react';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Button from '../Button';
import { Small, Subtitle } from '../Typography';
import CopilotIcon from './CoPilotIcon';
import CoPilotMessage from './CoPilotMessage';
import LoaderMessage from './LoaderMessage';
import styles from './styles';

type Props = {
  title?: string;
  isPoppedToNewWindow?: boolean;
  isHidden?: boolean;
  onClose?: () => void;
};

interface ICategorizedConvoTitles {
  [category: string]: CopilotConversationTitle[];
}

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: '32px'
  },
  '& .MuiInputBase-input': {
    padding: '13.5px 14px',
    borderRadius: '32px'
  }
});

const CoPilotWindow = ({ title = 'AI Copilot', isPoppedToNewWindow = false, isHidden = false, onClose }: Props) => {
  const { id } = useParams();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const { messages, addMessage, loaderMessage, clearMessages, setIsPoppedToNewWindow, setPopupWindow } = useCopilotMessageStore();

  const [convoHistoryTitles, setConvoHistoryTitles] = useState<CopilotConversationTitle[]>([]);
  const [convoHistoryMessages, setConvoHistoryMessages] = useState<CopilotConversationMessage[]>([]);
  const [input, setInput] = useState<string>('');
  const [contextLoadError, setContextLoadError] = useState<boolean>(false);
  const [sendMessageLoading, setSendMessageLoading] = useState<boolean>(false);
  const [isWindowExpanded, setIsWindowExpanded] = useState(false);
  const [isConvoHistorySelected, setIsConvoHistorySelected] = useState<boolean>(false);
  const [selectedConversationId, setSelectedConversationId] = useState<string>('');
  const [isStreaming, setIsStreaming] = useState<boolean>(false);
  // const [isHistoryRefetchTriggered, setIsHistoryRefetchTriggered] = useState<boolean>(false);

  const { drawerOpen } = useContext(SideMenuContext);

  const POLL_INTERVAL = 10000; // 10 seconds //TODO: remove this workaround later

  CopilotMessageSubscriptionHandler({
    truentityId: id,
    relyingPartyId: currentUser?.relyingParty?.id,
    relyingPartyAdminId: currentUser?.id
  });

  const [loadMessageContext] = useMutation<LoadMessageContextResponse>(LOAD_MESSAGE_CONTEXT, {
    variables: {
      truentityId: id,
      relyingPartyId: currentUser?.relyingParty?.id
    },
    onError: error => {
      console.error('Error loading message context:', error.message);
      setContextLoadError(true);
    }
  });

  const [removeMessageContext] = useMutation<RemoveMessageContextResponse>(REMOVE_MESSAGE_CONTEXT, {
    variables: {
      truentityId: id
    },
    onError: error => {
      console.error('Error removing the message context: ', error.message);
    }
  });

  const [sendMessage] = useMutation(SEND_COPILOT_MESSAGE, {
    onCompleted: () => {
      setSendMessageLoading(false);
    },
    onError: error => {
      console.error('Send Message Error:', error.message);
      addMessage({
        id: uuidv4(),
        isCompleted: true,
        sender: SenderType.BOT,
        type: MessageState.FAILED,
        body: 'There was an error sending your message. Please try again later.'
      });
      setSendMessageLoading(false);
    }
  });

  const { refetch: refetchCopilotTitles } = useQuery<CopilotConversationTitlesResponse>(QUERY_COPILOT_CONVERSATION_TITLES, {
    variables: {
      truentityId: id
    },
    onCompleted: data => {
      const convoHistoryTitlesData = data?.getAccountCoPilotConversationTitles;
      if (Array.isArray(convoHistoryTitlesData)) {
        setConvoHistoryTitles(convoHistoryTitlesData);
      }
    },
    onError: error => {
      console.error(error);
    }
  });

  // The following useMemo state is a date categorized list of the conversation history
  const categorizedConvoHistoryTitles = useMemo(() => {
    const categorized: ICategorizedConvoTitles = {};

    convoHistoryTitles.forEach(conversation => {
      const category = categorizeDate(new Date(conversation?.createdAt));
      if (!categorized[category]) {
        categorized[category] = [];
      }
      categorized[category].push(conversation);
    });

    // Define the predefined order
    const predefinedOrder = ['Today', 'Yesterday', 'Earlier this month', 'Last month', 'Older'];

    // Sort the categories based on the predefined order
    const sortedCategorized = {} as ICategorizedConvoTitles;

    predefinedOrder.forEach(category => {
      if (categorized[category]) {
        sortedCategorized[category] = categorized[category];
      }
    });

    return sortedCategorized;
  }, [convoHistoryTitles]);

  const [getAccountCoPilotConversations, { loading: convoMessagesLoading }] = useLazyQuery<CopilotConversationMessageResponse>(
    QUERY_COPILOT_CONVERSATION_MESSAGES,
    {
      onCompleted: data => {
        if (Array.isArray(data?.getAccountCoPilotConversations)) {
          setConvoHistoryMessages(data?.getAccountCoPilotConversations);
        }
      },
      onError: error => {
        console.error(error);
      }
    }
  );
  const {
    data: systemOptionsData,
    error: systemOptionsError,
    loading
  } = useQuery<GetSystemOptionsResponse>(GET_SYSTEM_OPTIONS, {
    variables: {
      optionKey: SystemOptionKeyEnum.RPM_CO_PILOT_SUGGESTIONS
    },
    fetchPolicy: 'cache-first'
  });

  useEffect(() => {
    if (systemOptionsError) {
      enqueueSnackbar('Could not fetch Suggestions', {
        variant: 'error'
      });
    }
  }, [enqueueSnackbar, systemOptionsError]);

  const transformedOptions = useMemo(() => {
    if (!systemOptionsData?.getSystemOptions?.options) return [];

    return systemOptionsData.getSystemOptions.options.map(option => ({
      category: option.value,
      suggestions: option.nestedOptions?.map(nestedOption => ({
        title: nestedOption.value
      }))
    }));
  }, [systemOptionsData]);

  const handleSend = (suggestion?: string | undefined) => {
    if (!input.trim() && !suggestion) {
      return;
    }

    if (Array.isArray(messages)) {
      sendMessage({
        variables: {
          truentityId: id,
          relyingPartyId: currentUser?.relyingParty.id,
          message: suggestion || input
        }
      }).catch(error => {
        console.error('Error caught in handleSend:', error.message);
      });
    }

    addMessage({
      id: uuidv4(),
      isCompleted: true,
      sender: SenderType.USER,
      type: MessageState.COMPLETED,
      body: suggestion || input
    });
    setInput('');
    setSendMessageLoading(true);
  };

  const handleClose = () => {
    onClose?.();
  };

  const onSuggestionSelected = suggestion => {
    handleSend(suggestion.title);
  };

  useEffect(() => {
    loadMessageContext();

    //Use a cleanup function to remove the message context based on the component unmount
    return () => {
      removeMessageContext();
      clearMessages();
    };
  }, [clearMessages, loadMessageContext, removeMessageContext]);

  // useEffect(() => {
  //   /*
  //    * The following condition is also a hack to determine if the streaming message is finalized rendering
  //    * If so the refetch of the chat history will be invoked
  //    * NOTE: Instead of this the better approach would be havign a seperate trigger from the AI backend when the title is generated
  //    */
  //   if (!isConvoHistorySelected && Array.isArray(messages) && messages.length === 2) {
  //     if (messages[1].isCompleted && messages[1].sender === SenderType.BOT && !isHistoryRefetchTriggered) {
  //       setIsHistoryRefetchTriggered(true);
  //       refetchCopilotTitles();
  //     }
  //   }
  // }, [isConvoHistorySelected, messages, isHistoryRefetchTriggered, setIsHistoryRefetchTriggered, refetchCopilotTitles]);

  /*
   * TODO : Remove the following useEffect after the proper solution
   */
  useEffect(() => {
    // if (!isHistoryRefetchTriggered) return;

    const interval = setInterval(() => {
      refetchCopilotTitles();
    }, POLL_INTERVAL);

    return () => clearInterval(interval);
  }, [refetchCopilotTitles]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const handleExpand = () => {
    isWindowExpanded ? setIsWindowExpanded(false) : setIsWindowExpanded(true);
  };

  const handlePopToNewWindow = () => {
    isPoppedToNewWindow ? setIsPoppedToNewWindow(false) : setIsPoppedToNewWindow(true);

    const url = window.location.pathname.split('/details')[0] + '/copilot';
    const width = 800;
    const height = 600;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    // Enhanced window features for minimal UI
    const features = `
      width=${width},
      height=${height},
      left=${left},
      top=${top},
      resizable=yes,
      scrollbars=yes,
      status=no,
      location=no,
      toolbar=no,
      menubar=no,
      directories=no,
      copyhistory=no
    `.replace(/\s/g, '');

    // Try to open as a popup first
    let newWindow = window.open(url, title, features);

    if (!newWindow) {
      console.warn('Popup blocked - falling back to regular window');
      newWindow = window.open(url, title, `width=${width},height=${height}`);
    }
    setPopupWindow(newWindow);
  };

  const onClickConversationTitle = async (titleId: string) => {
    setIsConvoHistorySelected(true);
    setSelectedConversationId(titleId);
    clearMessages();
    await getAccountCoPilotConversations({
      variables: {
        truentityId: id,
        titleId: titleId
      }
    });
  };

  const drawerWidth = drawerOpen ? '360px' : '72px';

  const onClickNewChat = () => {
    setIsConvoHistorySelected(false);
    setSelectedConversationId('');
    removeMessageContext();
    clearMessages();
  };

  if (isHidden) {
    return null;
  }

  /*
   * The follownig is a temprorary function that can generate the colors and icons based on the index value
   * TODO : refactor this to a better component and use system optins for the icon and colors
   */
  const tempColorAndIconMapper = (
    index: number
  ): { backgroundColor: string; hoverColor: string; borderColor: string; categoryIcon: ReactElement } => {
    if (index === 0) {
      return {
        backgroundColor: '#f9fdfd',
        hoverColor: '#A4EAEA',
        borderColor: '#3facc0',
        categoryIcon: <MedicationIcon sx={{ color: '#3facc0' }} />
      };
    } else if (index === 1) {
      return {
        backgroundColor: '#fdf9fb',
        hoverColor: '#FBDAEC',
        borderColor: '#cb3772',
        categoryIcon: <MonitorHeartIcon sx={{ color: '#cb3772' }} />
      };
    } else if (index === 2) {
      return {
        backgroundColor: '#f9fcf8',
        hoverColor: '#BEF7AA',
        borderColor: '#85c87b',
        categoryIcon: <SpaIcon sx={{ color: '#85c87b' }} />
      };
    } else {
      return {
        backgroundColor: '#fdf9fb',
        hoverColor: '#FBDAEC',
        borderColor: '#cb3772',
        categoryIcon: <MedicationIcon sx={{ color: '#cb3772' }} />
      };
    }
  };

  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      position={isWindowExpanded ? { x: 0, y: 0 } : undefined}
      disabled={isWindowExpanded || isPoppedToNewWindow}
    >
      <Card elevation={14} sx={styles.card({ isWindowExpanded, isPoppedToNewWindow, drawerWidth })}>
        <Stack direction="row" flexGrow={1}>
          <Stack
            padding={1.5}
            flexShrink={0}
            flexDirection="column"
            flexBasis={280}
            maxWidth={280}
            bgcolor={theme.palette.background.paper}
            gap={1}
          >
            <Stack direction="row" gap={1}>
              <Button
                sx={styles.newChatButton}
                variant="contained"
                size="small"
                onClick={onClickNewChat}
                disabled={!isConvoHistorySelected && Array.isArray(messages) && messages.length === 0}
              >
                <Home />
              </Button>
              <Button
                sx={styles.newChatButton}
                variant="contained"
                size="small"
                onClick={onClickNewChat}
                disabled={!isConvoHistorySelected && Array.isArray(messages) && messages.length === 0}
              >
                <Edit />
              </Button>
            </Stack>

            <Stack direction="column" gap={1} overflow="auto" sx={styles.conversationStack}>
              {(Object.entries(categorizedConvoHistoryTitles) ?? [])?.map(([category, convoTitleItems]) => (
                <Stack key={category}>
                  <Subtitle sx={{ fontSize: 'sm' }}>{category}</Subtitle>
                  <Stack gap="4px">
                    {convoTitleItems.map((conversationTitle, index) => (
                      <Button
                        key={index}
                        variant="text"
                        component="div"
                        sx={styles.conversationButton(conversationTitle?.id === selectedConversationId)}
                        onClick={() => onClickConversationTitle(conversationTitle?.id)}
                      >
                        <Box fontSize="0.875rem" width="100%" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                          {conversationTitle?.title}
                        </Box>
                        <Small>{conversationTitle?.feature}</Small>
                      </Button>
                    ))}
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Stack>
          <Stack flexDirection="column" flexGrow={1}>
            <CardHeader
              title={title}
              id="draggable-dialog-title"
              action={
                <Stack direction="row">
                  {!isPoppedToNewWindow && (
                    <>
                      <IconButton aria-label="Close" onClick={handlePopToNewWindow}>
                        <OpenInNew fontSize="small" />
                      </IconButton>
                      <IconButton aria-label="Close" onClick={handleExpand}>
                        {isWindowExpanded ? <CloseFullscreen fontSize="small" /> : <OpenInFull fontSize="small" />}
                      </IconButton>
                      <IconButton aria-label="Close" onClick={handleClose}>
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </>
                  )}
                </Stack>
              }
              sx={styles.cardHeader(isWindowExpanded)}
            />
            <CardContent sx={styles.cardContent}>
              {isConvoHistorySelected && (
                <>
                  {convoMessagesLoading && (
                    <>
                      {[...Array(4)].map((_, index) => (
                        <Stack gap={1} key={index}>
                          <Skeleton
                            variant="rounded"
                            width={Math.floor(Math.random() * (150 - 50 + 1)) + 180} // Generate a random width for the conversation skeleton
                            height={50}
                            animation="wave"
                            sx={{ alignSelf: 'end', borderRadius: '20px 0 20px 20px' }}
                          />
                          <Skeleton
                            variant="rounded"
                            width="80%"
                            height={(index + 1) * 70}
                            animation="wave"
                            sx={{ borderRadius: '0 20px 20px 20px' }}
                          />
                        </Stack>
                      ))}
                    </>
                  )}
                  {Array.isArray(convoHistoryMessages) &&
                    convoHistoryMessages.map((message, index) => (
                      <CoPilotMessage
                        key={index}
                        sender={message.messageType as SenderType}
                        message={message.message}
                        sendMessageLoading={false}
                        isLastMessage={index === convoHistoryMessages.length - 1}
                        shouldAnimate={false}
                        isCompleted
                      />
                    ))}

                  <Alert severity="info" sx={styles.alert}>
                    End of conversation. Click on New Chat to start a new conversation
                  </Alert>
                </>
              )}

              {!isConvoHistorySelected && Array.isArray(messages) && messages.length > 0 ? (
                <>
                  {messages.map(({ id, body, sender, type, isCompleted }, index) => (
                    <CoPilotMessage
                      key={id}
                      message={body}
                      sender={sender}
                      type={type}
                      isCompleted={isCompleted}
                      sendMessageLoading={sendMessageLoading}
                      isLastMessage={index === messages.length - 1}
                      setIsStreaming={setIsStreaming}
                    />
                  ))}
                  {!!loaderMessage && <LoaderMessage message={loaderMessage.body} />}
                </>
              ) : loading ? (
                <Stack justifyContent="center" direction="row" sx={{ width: '100%', mt: 10, height: '100%' }}>
                  <CopilotIcon isLoading={loading} iconSize={75} />
                </Stack>
              ) : (
                transformedOptions?.length > 0 &&
                !isConvoHistorySelected && (
                  <>
                    {/* Render the custom message  */}
                    <Box sx={{ mt: '1rem' }}>
                      <CoPilotMessage
                        key={id}
                        message={
                          'Hi there! I can help you with information about medications, care plans, and more. What would you like to know today?'
                        }
                        sender={SenderType.BOT}
                        type={MessageState.COMPLETED}
                        isCompleted
                        sendMessageLoading={sendMessageLoading}
                        isLastMessage={false}
                      />
                    </Box>

                    {transformedOptions.map((option, index) => {
                      const { backgroundColor, hoverColor, borderColor, categoryIcon } = tempColorAndIconMapper(index);
                      return (
                        <Grid container spacing={1} key={index} alignSelf="center" width="100%" sx={{ paddingX: '1rem' }}>
                          <Grid item xs={12} spacing={1} alignItems="center">
                            {categoryIcon}
                            <Subtitle sx={{ fontSize: '0.875rem' }}>{option.category}</Subtitle>
                          </Grid>
                          {option.suggestions?.map((suggestion, suggestionIndex) => (
                            <Grid item xs={!isWindowExpanded ? 12 : 6}>
                              <Button
                                key={suggestionIndex}
                                color="inherit"
                                variant="outlined"
                                fullWidth
                                onClick={() => onSuggestionSelected(suggestion)}
                                sx={styles.suggestionButton({
                                  backgroundColor,
                                  hoverColor,
                                  borderColor,
                                  ...(isWindowExpanded && { whiteSpace: 'no-wrap' })
                                })}
                              >
                                {suggestion.title}
                              </Button>
                            </Grid>
                          ))}
                        </Grid>
                      );
                    })}
                  </>
                )
              )}
              <div ref={messagesEndRef} />
            </CardContent>
            <CardActions sx={{ p: 2, backgroundColor: 'grey.50' }}>
              <Stack width="100%" display={isConvoHistorySelected ? 'none' : 'block'}>
                <Stack
                  direction="row"
                  component="form"
                  onSubmit={e => {
                    e.preventDefault();
                    handleSend();
                  }}
                  alignItems="center"
                  flexGrow={1}
                >
                  <StyledTextField
                    value={input}
                    onChange={e => setInput(e.target.value)}
                    placeholder={contextLoadError ? 'Error loading context. Try again later.' : 'Type a message...'}
                    variant="outlined"
                    fullWidth
                    sx={{ marginRight: '8px' }}
                    disabled={contextLoadError || sendMessageLoading}
                  />
                  <IconButton type="submit" disabled={!input.trim() || sendMessageLoading || contextLoadError || isStreaming}>
                    {isStreaming ? <StopCircleIcon /> : <SendIcon />}
                  </IconButton>
                </Stack>
              </Stack>
            </CardActions>
          </Stack>
        </Stack>
      </Card>
    </Draggable>
  );
};

export default CoPilotWindow;
