import type { GetSystemOptionsResponse } from '@/graphql/administration';
import { GET_SYSTEM_OPTIONS } from '@/graphql/administration';
import { UPDATE_RPM_ACTIVITY } from '@/graphql/remotePatientMonitoring';
import type { SystemOptionType } from '@/types/administration';
import { SystemOptionKeyEnum } from '@/types/administration';
import type { CareActivityType } from '@/types/remotePatientMonitoring';
import {
  ActivityMainCategoryType,
  ActivityMainTypeEnum,
  CareActivityContactModeType,
  CareActivityTypes,
  RpmStatusTypes
} from '@/types/remotePatientMonitoring';
import { secondsToDate, toMomentDateOrDefault } from '@/util/date';
import { formatDate, formatDateAndTime2, formatTime, getCurrentDate } from '@/util/format';
import type { CareActivityFormValues } from '@/util/rpm';
import {
  activityCategoryList,
  activityTypeList,
  timeStringToSeconds,
  validateCareActivityTimeInputs,
  validateCareActivityTitleInputs
} from '@/util/rpm';
import { capitalizeLetterBeforeCharacterAddSpace } from '@/util/string';
import { useMutation, useQuery } from '@apollo/client';
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers-pro';
import type { MomentInput } from 'moment';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { useEffect, useMemo, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import Button from '../Button';
import SelectList from '../SelectList';
import TruentityDateTimePicker from '../TruentityDateTimePicker';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  title: string;
  id: string | undefined;
  rpmEnrolledAtDate: MomentInput | undefined;
  rpmStatus: string;
  data: CareActivityType;
  hideDialog: () => void;
  onActivitiesChanged: () => void;
};

const defaultValues: CareActivityFormValues = {
  mainCategory: ActivityMainCategoryType.MONTHLY_CARE_MANAGEMENT,
  type: ActivityMainTypeEnum.RPM,
  category: '',
  subCategory: null,
  startTime: null,
  timeTaken: secondsToDate(0),
  contactMode: null,
  note: ''
};

const UpdateAccountActivityDialog = ({
  id,
  rpmEnrolledAtDate,
  rpmStatus,
  data,
  title,
  hideDialog,
  onActivitiesChanged,
  ...props
}: Props): React.ReactElement => {
  const [updateAccountActivity, { loading: loadingUpdateRpmActivity }] = useMutation(UPDATE_RPM_ACTIVITY);
  const { enqueueSnackbar } = useSnackbar();
  const isCandidateOrScheduledPatent = useMemo(
    () => rpmStatus === RpmStatusTypes.IS_CANDIDATE || rpmStatus === RpmStatusTypes.SCHEDULED_FOR_ENROLLMENT,
    [rpmStatus]
  );
  const [selectedActivityTitle, setSelectedActivityTitle] = useState<SystemOptionType | null>(null);
  const [titleOptions, setTitleOptions] = useState<{ [key: string]: SystemOptionType }>();
  const { data: systemOptionsData, error: systemOptionsError } = useQuery<GetSystemOptionsResponse>(GET_SYSTEM_OPTIONS, {
    variables: {
      optionKey: SystemOptionKeyEnum.RPM_CARE_ACTIVITY_DESCRIPTIONS
    },
    fetchPolicy: 'cache-first'
  });
  const mainTitleOptions = useMemo(() => {
    if (titleOptions) {
      return Object.keys(titleOptions);
    } else {
      return [];
    }
  }, [titleOptions]);

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { isValid }
  } = useForm<CareActivityFormValues>({ mode: 'all', defaultValues });

  const categoryValue = watch('category');
  const subCategoryValue = watch('subCategory');

  const isAdditionalDescRequired = subCategoryValue === 'Other';

  const displayErrorMessage = (message: string) => {
    enqueueSnackbar(message, { variant: 'error' });
  };

  const onSubmit: SubmitHandler<CareActivityFormValues> = data => handleSubmitImpl(data);

  const handleSubmitImpl = async (values: CareActivityFormValues) => {
    try {
      const validTimeInputs = validateCareActivityTimeInputs({
        startTime: values.startTime,
        timeTaken: values.timeTaken,
        rpmEnrolledAtDate: rpmEnrolledAtDate
      });
      if (!validTimeInputs) {
        displayErrorMessage('Failed to Update the Activity. Please check your date & time inputs.');
        return;
      }

      const validateTitleInputsMessage = validateCareActivityTitleInputs({
        values: values,
        careActivityTitleOptions: titleOptions
      });
      if (validateTitleInputsMessage) {
        displayErrorMessage(validateTitleInputsMessage);
        return;
      }

      const careActivityInput = {
        truentityId: id,
        title: values.category,
        subTitle: values.subCategory ?? null,
        category: capitalizeLetterBeforeCharacterAddSpace(values.mainCategory),
        notes: values.note,
        type: values.type.toUpperCase(),
        startTime: formatDateAndTime2(formatDate(values.startTime), formatTime(values.startTime, 'HH:mm')),
        contactMode: values.contactMode,
        timeTaken: timeStringToSeconds(formatTime(values.timeTaken, 'mm:ss'))
      };

      updateAccountActivity({
        variables: {
          activityId: data.id,
          careActivityInput
        }
      })
        .then(response => {
          const data = response.data!.updateRpmActivity;
          const variant = data!.status === 'Success' ? 'success' : 'error';

          enqueueSnackbar(data.message, {
            variant
          });
          onActivitiesChanged();
          hideDialog();
        })
        .catch(() => {
          enqueueSnackbar('Unable to update Activity', {
            variant: 'error'
          });
          hideDialog();
        });
    } catch (err) {
      const failMessage = 'Failed to update a activity';
      enqueueSnackbar(failMessage, {
        variant: 'error'
      });
      console.error(err);
    }
  };

  const resetDetails = () => {
    setSelectedActivityTitle(null);
    setValue('subCategory', null);
    setValue('contactMode', null);
  };

  const validateTime = (date: MomentInput) => {
    const momentDate = moment(date);
    const seconds = momentDate.seconds();
    const minutes = momentDate.minutes();

    return seconds >= 1 || minutes >= 1 || 'Time must be at least 1 seconds';
  };

  const onDescriptionValueChange = (descriptionValue: string | null) => {
    if (descriptionValue && titleOptions) {
      const selectedOption = titleOptions[descriptionValue];
      if (selectedOption) {
        setSelectedActivityTitle(selectedOption);
        setValue('subCategory', null);
        const defaultContactMode = selectedOption.defaultContactMode
          ? (selectedOption.defaultContactMode as CareActivityContactModeType)
          : null;
        setValue('contactMode', defaultContactMode);
      } else {
        resetDetails();
      }
    } else {
      resetDetails();
    }
  };

  useEffect(() => {
    if (data && titleOptions) {
      const accountActivity: CareActivityFormValues = {
        mainCategory: data?.subType ?? '',
        type: 'RPM',
        category: data?.title ?? '',
        subCategory: data?.subTitle ?? null,
        startTime: toMomentDateOrDefault(data?.startedAt, getCurrentDate()),
        contactMode: data?.contactMode ?? null,
        timeTaken: secondsToDate(data?.totalTimeSpentSecs ?? 0),
        note: data?.notes ?? ''
      };
      reset(accountActivity);
      if (data?.title) {
        const selectedOption = titleOptions[data.title];
        if (selectedOption) {
          setSelectedActivityTitle(selectedOption);
        } else {
          setSelectedActivityTitle(null);
        }
      }
    }
  }, [data, reset, titleOptions]);

  useEffect(() => {
    if (systemOptionsError) {
      displayErrorMessage('Could not fetch Descriptions and Supporting Descriptions');
    }
  }, [systemOptionsError]);

  useEffect(() => {
    if (systemOptionsData) {
      const optionsDictionary: { [key: string]: SystemOptionType } = {};

      systemOptionsData?.getSystemOptions?.options.forEach(option => {
        optionsDictionary[option.value] = option;
      });

      setTitleOptions(optionsDictionary);
    }
  }, [systemOptionsData]);

  return (
    <BaseDialog title={title} hideDialog={hideDialog} fullWidth maxWidth={'sm'} {...props}>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            spacing={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'stretch'
            }}
          >
            {data?.subType === ActivityMainCategoryType.SETUP && (
              <Stack direction="row" sx={{ justifyContent: 'space-evenly' }}>
                <Controller
                  control={control}
                  name="category"
                  render={({ field: { onChange, value } }) => (
                    <SelectList
                      id={'category'}
                      label="Category"
                      options={activityCategoryList
                        .filter(option => !(isCandidateOrScheduledPatent && option.name === 'Monthly Care Management'))
                        .map(x => ({ label: x?.name, value: x?.id }))}
                      placeholder="Select a category"
                      value={value}
                      required
                      MenuProps={{
                        sx: { maxHeight: 220 }
                      }}
                      onChange={onChange}
                      sx={{ marginRight: 1 }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="type"
                  render={({ field: { onChange, value } }) => (
                    <SelectList
                      id={'type'}
                      label="Type"
                      options={activityTypeList.map(x => ({ label: x?.name, value: x?.id }))}
                      placeholder="Select a type"
                      value={value}
                      required
                      clearFunction={() => {
                        setValue('type', '');
                      }}
                      MenuProps={{
                        sx: { maxHeight: 220 }
                      }}
                      onChange={onChange}
                    />
                  )}
                />
              </Stack>
            )}
            <Stack direction="row" sx={{ justifyContent: 'flex-start' }}>
              <Controller
                control={control}
                name="category"
                render={({ field }) => (
                  <Autocomplete
                    freeSolo
                    sx={{ width: '100%', marginTop: 1 }}
                    value={field.value}
                    options={mainTitleOptions}
                    onChange={(_event, newValue) => {
                      setValue('category', newValue ?? '');
                      onDescriptionValueChange(newValue);
                    }}
                    clearOnEscape
                    placeholder={'Enter Description'}
                    renderInput={params => <TextField required {...params} label={`Category`} />}
                  />
                )}
              />
            </Stack>
            {(selectedActivityTitle?.nestedOptions && selectedActivityTitle.nestedOptions.length > 0) ||
            selectedActivityTitle?.isOptionsEditable ? (
              <Stack direction="row" sx={{ justifyContent: 'flex-start' }}>
                <Controller
                  control={control}
                  name="subCategory"
                  render={({ field: { value } }) => (
                    <Autocomplete
                      key={selectedActivityTitle?.value ?? 'supporting-description'}
                      freeSolo={selectedActivityTitle?.isOptionsEditable ?? false}
                      sx={{ width: '100%', marginTop: 1 }}
                      value={value}
                      options={selectedActivityTitle?.nestedOptions?.map(subTitle => subTitle.value) ?? []}
                      onChange={(_event, newValue) => {
                        setValue('subCategory', newValue ?? '');
                      }}
                      clearOnEscape
                      placeholder={`${selectedActivityTitle?.isOptionsEditable ? 'Enter' : 'Select '} Supporting Description`}
                      renderInput={params => (
                        <TextField
                          required
                          {...params}
                          label={`Supporting Description`}
                          onChange={event => {
                            if (selectedActivityTitle?.isOptionsEditable) {
                              setValue('subCategory', event.target.value);
                            }
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Stack>
            ) : (
              <></>
            )}
            <Stack direction="row" sx={{ justifyContent: 'stretch' }}>
              <Controller
                control={control}
                name="note"
                rules={{ required: isAdditionalDescRequired ? 'Additional description is required' : false }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    autoFocus={true}
                    minRows={3}
                    required={isAdditionalDescRequired}
                    onChange={onChange}
                    value={value}
                    fullWidth
                    multiline
                    rows={8}
                    label={`Additional Description${isAdditionalDescRequired ? '' : ' (Optional)'}`}
                    placeholder={'Enter Additional Description'}
                  />
                )}
              />
            </Stack>
            <Stack>
              <Controller
                control={control}
                name="contactMode"
                rules={{ required: 'Contact Mode is required' }}
                render={({ field }) => (
                  <FormControl required>
                    <FormLabel id="contact-mode-label">Contact Mode</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="contact-mode-label"
                      name="contactMode"
                      value={field.value}
                      onChange={event => {
                        field.onChange(event.target.value);
                      }}
                    >
                      <FormControlLabel value={CareActivityContactModeType.PHONE_CALL} control={<Radio />} label="Phone Call" />
                      <FormControlLabel value={CareActivityContactModeType.IN_PERSON_VISIT} control={<Radio />} label="In Person Visit" />
                      <FormControlLabel value={CareActivityContactModeType.NOT_APPLICABLE} control={<Radio />} label="Not Applicable" />
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </Stack>
            <Divider />
            <Stack direction="row" sx={{ justifyContent: 'space-evenly' }}>
              <Controller
                control={control}
                name="startTime"
                render={({ field: { onChange, value } }) => (
                  <TruentityDateTimePicker
                    label="Start Time"
                    onChange={onChange}
                    value={value}
                    sx={{ flex: 1, marginRight: 1 }}
                    TextFieldProps={undefined}
                    required
                    minDateTime={categoryValue.toLowerCase() === CareActivityTypes.SETUP.toLowerCase() ? undefined : rpmEnrolledAtDate}
                  />
                )}
              />
              <Controller
                control={control}
                name="timeTaken"
                rules={{ required: 'This field is required', validate: validateTime }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TimePicker
                    ampm={false}
                    views={['minutes', 'seconds']}
                    format="mm:ss"
                    slotProps={{
                      textField: {
                        label: 'Time Taken (mm:ss)',
                        fullWidth: true,
                        helperText: error ? error.message : null
                      }
                    }}
                    sx={{
                      flex: 1
                    }}
                    value={value}
                    onChange={onChange}
                    minTime={secondsToDate(1)}
                  />
                )}
              />
            </Stack>
          </Stack>
          <DialogActions sx={{ justifyContent: 'end', padding: 2 }}>
            <Button
              type="reset"
              a11yLabel="Cancel"
              appearance="outline"
              onClick={() => {
                reset();
                hideDialog();
              }}
            />
            <Button type="submit" a11yLabel="Save" appearance="primary" isLoading={loadingUpdateRpmActivity} disabled={!isValid} />
          </DialogActions>
        </form>
      </DialogContent>
    </BaseDialog>
  );
};

export default UpdateAccountActivityDialog;
