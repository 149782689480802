import Button from '@/components/Button';
import type { MainMenuItem } from '@/zustand/AppFrameStore';
import { MoreHoriz } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, Stack } from '@mui/material';
import type React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  items: MainMenuItem[];
};

const BreadcrumbRibbonMenu = ({ items }: Props) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const maxVisibleButtons = 3;
  const visibleItems = items.slice(0, maxVisibleButtons);
  const hiddenItems = items.slice(maxVisibleButtons);

  return (
    <Stack direction={'row'} spacing={2} justifyContent={'end'} alignItems={'center'}>
      {visibleItems.map((item, index) => (
        <Button
          color={'inherit'}
          key={index}
          sx={{
            fontWeight: '400',
            textTransform: 'capitalize',
            ...item.buttonProps?.sx
          }}
          size={'small'}
          variant={'text'}
          startIcon={item.icon}
          onClick={() => navigate(item.navigatePath)}
          label={item.label}
          disableElevation
        />
      ))}
      {hiddenItems.length > 0 && (
        <>
          <IconButton color="inherit" onClick={handleMenuOpen} aria-controls="simple-menu" aria-haspopup="true">
            <MoreHoriz />
          </IconButton>
          <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
            {hiddenItems.map((item, index) => (
              <MenuItem
                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}
                key={index}
                onClick={() => {
                  navigate(item.navigatePath);
                  handleMenuClose();
                }}
              >
                {item.icon}
                {item.label}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </Stack>
  );
};

export default BreadcrumbRibbonMenu;
