import { Body1 } from '@/components/Typography';
import useToken from '@/hooks/useToken';
import { Role } from '@/types/admin';
import type { RpmStatusTypeTab } from '@/types/remotePatientMonitoring';
import { RpmStatusTypes } from '@/types/remotePatientMonitoring';
import { isProd } from '@/util/environment';
import { formatTime, getCurrentDate } from '@/util/format';
import { Tab, Tabs } from '@mui/material';
import type { SyntheticEvent } from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useRpmMyPatientContext } from './RpmMyPatientsProvider';
import type { SortModalDefaultsKeyTypes } from './RpmMyPatientsUtils';
import { getDefaultSortModels } from './RpmMyPatientsUtils';

const RpmMyPatientsTabs = () => {
  const {
    tabs: { tab, setTab, rpmPatientStatusTab },
    filters: { setSortModel, setFilteredPatientsByCharts, monthAndYear, currentMonthYear }
  } = useRpmMyPatientContext();
  const { roleType } = useToken();
  const defaultMonthYear = useRef<string | null>(null);
  const isSuperAdmin = useMemo(() => roleType === Role.SUPER, [roleType]);

  const changeSortModelWithTabChange = useCallback(
    (selectedTabIndex: number) => {
      const { status, includeMetrics } = rpmPatientStatusTab[selectedTabIndex];

      if (
        [RpmStatusTypes.ENROLLED, RpmStatusTypes.IS_CANDIDATE, RpmStatusTypes.SCHEDULED_FOR_ENROLLMENT, RpmStatusTypes.UNENROLLED].includes(
          status as SortModalDefaultsKeyTypes
        )
      ) {
        setSortModel([getDefaultSortModels(includeMetrics)[status]]);
      }
    },
    [rpmPatientStatusTab, setSortModel]
  );

  const changeDefaultMonthYear = useCallback(() => {
    defaultMonthYear.current = formatTime(getCurrentDate(), 'YYYY-MM-DD HH:mm:ss');
  }, [defaultMonthYear]);

  const changeTab = useCallback(
    (tabIndex: number) => {
      setTab(tabIndex);
      changeSortModelWithTabChange(tabIndex);
      setFilteredPatientsByCharts([]);
      if (monthAndYear !== currentMonthYear) changeDefaultMonthYear();
    },
    [changeDefaultMonthYear, changeSortModelWithTabChange, currentMonthYear, monthAndYear, setFilteredPatientsByCharts, setTab]
  );

  const handleTabChange = (_event: SyntheticEvent | null, selectedTabIndex: number): void => {
    changeTab(selectedTabIndex);
  };

  // use effect to change the tab based on the changes if the tab attribute
  useEffect(() => {
    changeTab(tab);
  }, [tab, changeTab]);

  /*
   * INFO: The following functions are TEMPMORARY implementation that should be later removed in the upcoming releases
   * Enable the patient priority tab only if it's the Prod environment or if the role is super admin. With this condition the Candidate tab also should be hidden
   * TODO : Remove this functionality after the feature is finalized for prod release
   */
  const isPatientPriorityTabAllowed = () => !isProd() || isSuperAdmin;

  const isTabAvailable = (tab: RpmStatusTypeTab) => {
    if (tab.path === 'candidate' && isPatientPriorityTabAllowed()) {
      return false;
    } else if (tab.path === 'priorityCandidate' && !isPatientPriorityTabAllowed()) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Tabs value={tab} onChange={handleTabChange}>
      {rpmPatientStatusTab?.map((tab: RpmStatusTypeTab, index: number) => (
        <Tab
          style={{ minWidth: 'auto' }}
          icon={tab.icon}
          iconPosition={'start'}
          label={<Body1 textTransform="Capitalize">{tab.label}</Body1>}
          key={tab.label}
          id={`tab-${index}`}
          sx={{
            display: isTabAvailable(tab) ? '' : 'none'
          }}
        />
      ))}
    </Tabs>
  );
};

export default RpmMyPatientsTabs;
