import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import SelectFormControl from '@/components/Selects/SelectFormControl';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import { H1 } from '@/components/Typography';
import type { GetAllCompaniesResponse } from '@/graphql/company';
import { GET_ALL_COMPANIES } from '@/graphql/company';
import type { GetAllRpmChartReviewProvidersResponse, GetAllRpmChartReviewsResponse } from '@/graphql/remotePatientMonitoring';
import { GET_ALL_RPM_CHART_REVIEWS_QUERY, GET_ALL_RPM_CHART_REVIEW_PROVIDERS } from '@/graphql/remotePatientMonitoring';
import useToken from '@/hooks/useToken';
import { color } from '@/styles/assets/colors';
import { Role } from '@/types/admin';
import type { RpmReportsType } from '@/types/remotePatientMonitoring';
import { getAccountUserFullName } from '@/util/account';
import { formatDateAndTime, formatTime, getCurrentDate, secondsToTime } from '@/util/format';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Paper, Stack } from '@mui/material';
import { Box } from '@mui/system';
import type { GridColDef, GridSortModel } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';

const columnVisibility: Record<string, Record<string, boolean>> = {
  PROVIDER: {
    providerName: false
  },
  SUPER_ADMIN: {
    providerName: true
  }
};

const csvHeaders = [
  { label: 'Patient Name', key: 'patientName' },
  { label: 'Report Month Year', key: 'reportMonthYear' },
  { label: 'Provider Name', key: 'providerName' },
  { label: 'Signed-Off At', key: 'signedOffAt' },
  { label: 'Company Name', key: 'companyName' },
  { label: 'Num of Critical Alerts', key: 'numCriticalAlerts' }
];

const RpmAllChartReviews = () => {
  const { roleType } = useToken();
  const { enqueueSnackbar } = useSnackbar();
  const currentMonthYear = useMemo(() => formatTime(getCurrentDate(), 'MMM YYYY'), []);
  const isProviderAdmin = useMemo(() => roleType === Role.PROVIDER, [roleType]);
  const isPSM = useMemo(() => roleType === Role.ADMIN, [roleType]);
  const isSuperAdmin = useMemo(() => roleType === Role.SUPER, [roleType]);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [provider, setProvider] = useState<string>('All');
  const [company, setCompany] = useState<string>('All');
  const [monthAndYear, setMonthAndYear] = useState<string>(currentMonthYear);
  const [exportRpmReviewReports, setExportRpmReviewReports] = useState<RpmReportsType[]>([]);
  const [showExportButton, setShowExportButton] = useState<boolean>(false);
  const columnVisibilityModel = useMemo(() => columnVisibility[isSuperAdmin ? 'SUPER_ADMIN' : 'PROVIDER'], [isSuperAdmin]);

  const { data: rpmReviewReportsData, loading: isRpmReviewReportsLoading } = useQuery<GetAllRpmChartReviewsResponse>(
    GET_ALL_RPM_CHART_REVIEWS_QUERY,
    {
      variables: {
        filterOptions: {
          filterRpmReportMonthYear: monthAndYear,
          filterProviderId: provider,
          filterRelyingPartyId: company
        },
        pageNum: currentPage + 1,
        pageSize: DEFAULT_PAGE_SIZE,
        monthYear: monthAndYear
      },
      onError(error) {
        const errorMessage = error?.message ?? 'Something went wrong, failed to retrieve Signed-Off Reports. Please try again later.';
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      fetchPolicy: 'cache-and-network',
      skip: !monthAndYear
    }
  );

  const { data: allRpmCompaniesData, loading: isAllRpmCompaniesLoading } = useQuery<GetAllCompaniesResponse>(GET_ALL_COMPANIES, {
    variables: {
      filterMedicalService: 'RPM'
    },
    onError(error) {
      const errorMessage = error?.message ?? 'Something went wrong, failed to retrieve Review RPM Companies. Please try again later.';
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
    fetchPolicy: 'cache-and-network',
    skip: !isSuperAdmin && !isProviderAdmin && !isPSM
  });

  const { data: rpmReviewProviders, loading: isRpmReviewProvidersLoading } = useQuery<GetAllRpmChartReviewProvidersResponse>(
    GET_ALL_RPM_CHART_REVIEW_PROVIDERS,
    {
      onError(error) {
        const errorMessage = error?.message ?? 'Something went wrong, failed to retrieve Review Providers. Please try again later.';
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      fetchPolicy: 'cache-and-network',
      skip: !isSuperAdmin
    }
  );

  const [getAllRpmReviewReportsToExport, { loading: loadingAllRpmReviewReports }] = useLazyQuery<GetAllRpmChartReviewsResponse>(
    GET_ALL_RPM_CHART_REVIEWS_QUERY,
    {
      variables: {
        filterOptions: {
          filterRpmReportMonthYear: monthAndYear,
          filterProviderId: provider,
          filterRelyingPartyId: company
        },
        monthYear: monthAndYear
      },
      onCompleted: data => {
        setExportRpmReviewReports(data?.getAllRpmChartReviews?.reports ?? []);
      },
      onError(error) {
        const errorMessage =
          error?.message ?? 'Something went wrong, failed to retrieve Signed-Off Reports for export. Please try again later.';
        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  const handleMonthYear = (monthYear: string) => {
    setMonthAndYear(formatTime(monthYear, 'MMM YYYY'));
  };

  const columns = useMemo<GridColDef<RpmReportsType>[]>(
    () => [
      {
        field: 'patientName',
        headerName: 'Patient Name',
        flex: 2,
        minWidth: 135,
        sortable: true,
        valueGetter: params => getAccountUserFullName(params.row?.account?.user)
      },
      {
        field: 'reportMonthYear',
        headerName: 'Report Month Year',
        flex: 2,
        valueGetter: params => params.row.monthYear,
        sortable: false
      },
      {
        field: 'providerName',
        headerName: 'Provider Name',
        flex: 2,
        headerAlign: 'left',
        align: 'left',
        valueGetter: params => params.row.rpmAccountReviewWorkflow?.provider?.nameWithTitle
      },
      {
        field: 'signedOffAt',
        headerName: 'Signed-Off At',
        flex: 2,
        headerAlign: 'left',
        align: 'left',
        minWidth: 110,
        valueGetter: params => formatDateAndTime(params.row.rpmAccountReviewWorkflow?.signOffAt)
      },
      {
        field: 'activityTime',
        headerName: 'Activity Time (HH:mm:ss)',
        flex: 2,
        headerAlign: 'left',
        align: 'left',
        minWidth: 110,
        valueGetter: params =>
          params.row.rpmAccountReviewWorkflow?.careActivity?.totalTimeSpentSecs
            ? secondsToTime(params.row.rpmAccountReviewWorkflow?.careActivity?.totalTimeSpentSecs)
            : 'N/A'
      },
      {
        field: 'companyName',
        headerName: 'Company Name',
        valueGetter: params => params.row.relyingParty?.name ?? '',
        flex: 2,
        minWidth: 130,
        sortable: true
      },
      {
        field: 'numCriticalAlerts',
        headerName: 'Num of Critical Alerts',
        sortable: true,
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        valueGetter: ({ row: { account } }) => account.criticalAlertCount || 0
      }
    ],
    []
  );

  const onExportData = async () => {
    getAllRpmReviewReportsToExport({
      variables: {
        filterOptions: {
          filterRpmReportMonthYear: monthAndYear,
          filterProviderId: provider
        },
        monthYear: monthAndYear
      }
    });
    setShowExportButton(true);
  };

  const generateCsvData = useCallback((rpmReviewReports: RpmReportsType[]) => {
    return rpmReviewReports.map(report => ({
      patientName: getAccountUserFullName(report.account.user),
      reportMonthYear: report.monthYear,
      providerName: report.rpmAccountReviewWorkflow?.provider?.nameWithTitle,
      signedOffAt: formatDateAndTime(report.rpmAccountReviewWorkflow?.signOffAt),
      companyName: report.relyingParty?.name,
      numCriticalAlerts: report.account.criticalAlertCount
    }));
  }, []);

  const getCsvFileName = useCallback(() => {
    const providerName =
      provider === 'All'
        ? 'All'
        : rpmReviewProviders?.getAllRpmChartReviewProviders?.providers?.find(p => p.id === provider)?.nameWithTitle;
    const companyName =
      provider === 'All' ? 'All' : allRpmCompaniesData?.relyingPartiesAll?.relyingParties?.find(c => c.id === company)?.name;
    return `RPM-Chart-Reviews-${monthAndYear.replace(' ', '_')}-${(providerName ?? 'Provider').replace(' ', '_')}-${(
      companyName ?? 'Company'
    ).replace(' ', '_')}.csv`;
  }, [monthAndYear, provider]);

  const getCustomRowId = useCallback((row: RpmReportsType) => {
    return `${row.id}-${row.rpmAccountReviewWorkflow?.provider?.id}}`;
  }, []);

  useEffect(() => {
    if (showExportButton) {
      setShowExportButton(false);
    }
  }, [monthAndYear, provider]);

  return (
    <Stack spacing={2} paddingBottom={2} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'stretch'}>
      <Stack
        sx={{
          backgroundColor: color.paper,
          padding: 2,
          marginBottom: 2,
          borderRadius: 2
        }}
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="stretch"
      >
        <Stack flexDirection="row" justifyContent="flex-start">
          <H1 textAlign="left">All Signed-Off Chart Reviews</H1>
        </Stack>
        <Stack flexDirection="column" justifyContent="flex-start" alignItems="stretch">
          <Stack flexDirection="row" justifyContent="flex-end" alignItems="flex-end">
            {(isProviderAdmin || isSuperAdmin || isPSM) && (
              <Box margin={1} minWidth={120}>
                <SelectFormControl
                  label={'Company'}
                  value={company}
                  disabled={isAllRpmCompaniesLoading}
                  placeholder={'Select Company'}
                  onChange={event => setCompany(event.target.value)}
                  options={[{ name: 'All', id: 'All' }, ...(allRpmCompaniesData?.relyingPartiesAll?.relyingParties ?? [])].map(item => ({
                    label: item.name,
                    value: item.id
                  }))}
                />
              </Box>
            )}
            {isSuperAdmin && (
              <Box margin={1} minWidth={120}>
                <SelectFormControl
                  label={'Provider'}
                  value={provider}
                  disabled={isRpmReviewProvidersLoading}
                  placeholder={'Select Provider'}
                  onChange={event => setProvider(event.target.value)}
                  options={[
                    { nameWithTitle: 'All', id: 'All' },
                    ...(rpmReviewProviders?.getAllRpmChartReviewProviders?.providers ?? [])
                  ].map(item => ({
                    label: item.nameWithTitle,
                    value: item.id
                  }))}
                />
              </Box>
            )}
            <Box margin={1}>
              <TruentityDatePicker
                label="Signed-Off Month"
                showMonth
                showYear={false}
                slotProps={{
                  actionBar: {
                    actions: ['accept']
                  }
                }}
                openTo="month"
                views={['month', 'year']}
                value={monthAndYear}
                onChange={handleMonthYear}
                defaultValue={currentMonthYear}
                disableFuture
              />
            </Box>
          </Stack>
          <Stack flexDirection="row" justifyContent="flex-end" alignItems="center">
            {(rpmReviewReportsData?.getAllRpmChartReviews?.reports?.length ?? 0) > 0 && (
              <>
                {!showExportButton ? (
                  <Button color="primary" variant="contained" size="small" disabled={loadingAllRpmReviewReports} onClick={onExportData}>
                    Load for Export
                  </Button>
                ) : (
                  <Button isLoading={loadingAllRpmReviewReports} color="primary" variant="contained" size="small">
                    {exportRpmReviewReports?.length > 0 && (
                      <CSVLink
                        headers={csvHeaders}
                        data={generateCsvData(exportRpmReviewReports)}
                        filename={getCsvFileName()}
                        style={{ textDecoration: 'none', color: '#fff' }}
                      >
                        {loadingAllRpmReviewReports ? 'Loading CSV...' : 'Export All'}
                      </CSVLink>
                    )}
                  </Button>
                )}
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
      <Paper component={Stack} direction="column" spacing={2}>
        <TruentityDataGrid
          name={'rpm-chart-reviews'}
          autoHeight
          rows={rpmReviewReportsData?.getAllRpmChartReviews?.reports ?? []}
          rowCount={rpmReviewReportsData?.getAllRpmChartReviews?.meta?.totalCount ?? 0}
          paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
          columnVisibilityModel={columnVisibilityModel}
          onPaginationModelChange={({ page }) => {
            setCurrentPage(page);
          }}
          sortModel={sortModel}
          onSortModelChange={newModel => {
            setSortModel(newModel);
          }}
          disableRowSelectionOnClick
          disableMultipleRowSelection
          hideFooterSelectedRowCount
          loading={isRpmReviewReportsLoading}
          paginationMode="server"
          columns={columns}
          getRowId={getCustomRowId}
        />
      </Paper>
    </Stack>
  );
};

export default RpmAllChartReviews;
