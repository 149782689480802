import CoPilotWindow from '.';

const StandAloneCopilotWindow = () => {
  return (
    <>
      <CoPilotWindow isPoppedToNewWindow={true} />
    </>
  );
};

export default StandAloneCopilotWindow;
