import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import type { TextSelectOption } from '@/components/SelectList';
import SelectList from '@/components/SelectList';
import TruentityDateFullRangePicker from '@/components/TruentityDateFullRangePicker';
import { H1 } from '@/components/Typography';
import type { GetInitialVisitFollowupsResponse } from '@/graphql/account';
import { GET_ALL_INITIAL_FOLLOW_UPS } from '@/graphql/account';
import type { GetUserCompaniesResponse } from '@/graphql/company';
import { GET_USER_COMPANIES } from '@/graphql/company';
import type { FollowUpRemindersTypes } from '@/types/accountProfile';
import { AccountNameOrder } from '@/types/accountProfile';
import { getAccountUserFullName } from '@/util/account';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { incrementDates } from '@/util/date';
import { formatDate, formatDateAndTime, formatDateIgnoreTZ } from '@/util/format';
import { formatRpmStatusString } from '@/util/rpm';
import { unknown } from '@/util/string';
import { useQuery, useReactiveVar } from '@apollo/client';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Paper, Stack } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useMemo, useState } from 'react';

const RpmPatientSchedules = () => {
  const currentUser = useReactiveVar(currentLoggedUserVar);

  const [companyOptions, setCompanyOptions] = useState<TextSelectOption[]>([{ label: 'All', value: 'ALL' }]);
  const [companyId, setCompanyId] = useState<'ALL' | string>('ALL');
  const [currentPage, setCurrentPage] = useState<number>(0);

  const [startDate, setStartDate] = useState<string>(formatDate(new Date(), 'YYYY-MM-DD'));
  const [endDate, setEndDate] = useState<string>(formatDate(incrementDates(new Date(), 30), 'YYYY-MM-DD'));

  const handleDateChange = (newStartDate: Date, newEndDate: Date): void => {
    if (formatDateIgnoreTZ(newStartDate, 'YYYY-MM-DD') !== formatDateIgnoreTZ(startDate, 'YYYY-MM-DD')) {
      setStartDate(formatDateIgnoreTZ(newStartDate, 'YYYY-MM-DD'));
    }
    if (formatDateIgnoreTZ(newEndDate, 'YYYY-MM-DD') !== formatDateIgnoreTZ(endDate, 'YYYY-MM-DD')) {
      setEndDate(formatDateIgnoreTZ(newEndDate, 'YYYY-MM-DD'));
    }
  };

  useQuery<GetUserCompaniesResponse>(GET_USER_COMPANIES, {
    onCompleted: data => handleCompanies(data)
  });
  const { data: getInitialFollowups, loading: initialFollowupsLoading } = useQuery<GetInitialVisitFollowupsResponse>(
    GET_ALL_INITIAL_FOLLOW_UPS,
    {
      variables: {
        filterOptions: {
          startDate: startDate,
          endDate: endDate,
          relyingPartyIds: companyId.toUpperCase() === 'ALL' ? [] : [companyId]
        },
        pageNum: currentPage + 1,
        pageSize: DEFAULT_PAGE_SIZE
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  const handleCompanies = (data: GetUserCompaniesResponse) => {
    if (data && data.getUserRelyingParties && data.getUserRelyingParties.relyingParties) {
      const relyingParties = data.getUserRelyingParties.relyingParties;
      const refactoredCompanies = relyingParties.map(relyingParty => {
        return {
          label: relyingParty.name,
          value: relyingParty.id
        };
      });

      if (currentUser?.hasMultipleRelyingParties) {
        refactoredCompanies.unshift({ label: 'All', value: 'ALL' });
      } else {
        setCompanyId(refactoredCompanies[0].value);
      }

      setCompanyOptions(refactoredCompanies);
    }
  };

  const columns: GridColDef<FollowUpRemindersTypes>[] = useMemo(
    () => [
      {
        field: 'patientName',
        headerName: 'Patient Name (Last, First)',
        valueGetter: params => getAccountUserFullName(params.row.account.user, AccountNameOrder.LAST_FIRST),
        flex: 1,
        minWidth: 130,
        sortable: true
      },
      {
        field: 'rpmStatus',
        headerName: 'RPM Status',
        flex: 1,
        minWidth: 130,
        sortable: true,
        valueGetter: params => (params.row.account.rpmStatus ? formatRpmStatusString(params.row.account.rpmStatus) : unknown())
      },
      {
        field: 'type',
        headerName: 'Visit Type',
        flex: 1,
        minWidth: 130,
        sortable: true
      },
      {
        field: 'relyingPartyName',
        headerName: 'Company Name',
        valueGetter: params => params.row.relyingParty.name,
        flex: 1,
        minWidth: 130,
        sortable: true
      },
      {
        field: 'followupDate',
        headerName: 'Date',
        valueGetter: params => formatDateAndTime(params.row.followUpOn),
        flex: 1,
        minWidth: 135,
        sortable: true
      },

      {
        field: 'checkedIn',
        headerName: 'Telehealth Checked-In',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 130,
        sortable: true,
        valueGetter: params => params.row.account.accountSummary?.isTelehealthVisitLaunched || false,
        renderCell: params => (params.value ? <CheckCircleIcon color="success" /> : <CheckCircleOutlineIcon />)
      },
      {
        field: 'signOffAt',
        headerName: 'Signed Off On',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 130,
        sortable: true,
        valueGetter: params =>
          params.row.account.accountSummary?.signOffAt ? formatDateAndTime(params.row.account.accountSummary?.signOffAt) ?? unknown() : ''
      },
      {
        field: 'createdAt',
        headerName: 'Scheduled On',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 130,
        sortable: true,
        valueGetter: params => formatDateAndTime(params.row.createdAt) ?? unknown()
      }
    ],
    []
  );

  return (
    <Stack spacing={2}>
      <Paper component={Stack} direction="row" justifyContent="space-between" alignItems="center" padding={2} elevation={0}>
        <H1 flex={0.5}>Patient Schedules</H1>
        <Stack direction="row" spacing={2} justifyContent="flex-end" alignItems="center" flex={0.5}>
          <SelectList
            label="Company"
            options={companyOptions}
            placeholder="Select an option..."
            value={companyId}
            disabled={!currentUser?.hasMultipleRelyingParties}
            onChange={event => {
              setCompanyId(event.target.value as string);
            }}
          />

          <TruentityDateFullRangePicker
            label="Schedules date range"
            startDate={new Date(startDate)}
            endDate={new Date(endDate)}
            onChange={handleDateChange}
          />
        </Stack>
      </Paper>
      <Paper component={Stack} padding={2} elevation={0}>
        <TruentityDataGrid
          name="dg-all_initial-visit-followups"
          autoHeight
          rows={getInitialFollowups?.getAllInitialFollowUps?.accountFollowups || []}
          loading={initialFollowupsLoading}
          rowCount={getInitialFollowups?.getAllInitialFollowUps?.meta.totalCount ?? 0}
          columns={columns}
          disableRowSelectionOnClick
          paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
          onPaginationModelChange={({ page }) => {
            setCurrentPage(page);
          }}
          pagination
          paginationMode="server"
        />
      </Paper>
    </Stack>
  );
};

export default RpmPatientSchedules;
