import type { RelyingPartyAdminNotificationType } from '@/types/admin';
import { gql } from '@apollo/client';

export const GET_CHAT_NOTIFICATIONS = gql`
  query getRelyingPartyAdminConversations {
    relyingPartyAdminConversations {
      createdAt
      sourceType
      messageBody
      messageDirection
      isRead
      id
      messageTo
      messageFrom
      createdAt
      account {
        id
        truentityId
        user {
          firstName
          lastName
        }
      }
    }
  }
`;

export const READ_ALL_NOTIFICATIONS = gql`
  mutation ReadAllRelyingPartyAdminNotification {
    readAllRelyingPartyAdminNotification {
      status
      message
    }
  }
`;

export const READ_NOTIFICATION = gql`
  mutation ReadRelyingPartyAdminNotification($notificationId: ID!) {
    readRelyingPartyAdminNotification(notificationId: $notificationId) {
      status
      message
    }
  }
`;

export const ON_RELYING_PARTY_ADMIN_NOTIFICATION = gql`
  subscription OnRelyingPartyAdminNotification {
    relyingPartyAdminNotifications {
      totalUnreadCount
    }
  }
`;

export type GetRelyingPartyAdminNotificationType = {
  relyingPartyAdminNotifications: RelyingPartyAdminNotificationType;
};

export const GET_RELYING_PARTY_ADMIN_NOTIFICATIONS = gql`
  query RelyingPartyAdminNotificationsQuery($pageNum: Int, $pageSize: Int, $readStatus: RelyingPartyAdminNotificationReadStatusTypeEnum) {
    relyingPartyAdminNotifications(pageNum: $pageNum, pageSize: $pageSize, readStatus: $readStatus) {
      totalReadNotificationCount
      totalUnreadNotificationCount
      notifications {
        id
        recipient {
          user {
            firstName
            lastName
          }
        }
        createdByAdmin {
          user {
            firstName
            lastName
          }
          presignedAvatarUrl
        }
        account {
          truentityId
          user {
            firstName
            lastName
          }
        }
        notificationType
        message
        isRead
        reference {
          ... on AccountFollowupReminder {
            id
            followUpOn
          }
          ... on AccountMessage {
            id
            messageBody
          }
        }
        updatedAt
      }
      meta {
        totalCount
        totalPages
      }
    }
  }
`;
