import Alert from '@/components/Alert';
import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import type { JSONData } from '@/components/JsonKit/JsonEditor';
import TruentitySwiper from '@/components/Swiper';
import { Body2 } from '@/components/Typography';
import type { AccountsFollowupResponse, GetFilteredRelyingPartyAdminsResponse } from '@/graphql/account';
import {
  ADD_ACCOUNTS_FOLLOWUPS,
  FollowUpCategoryTypes,
  FollowUpStatusTypes,
  FollowUpSubTypes,
  GET_FILTERED_RELYING_PARTY_ADMINS
} from '@/graphql/account';
import type { UpdateRpmAlertsResponse } from '@/graphql/remotePatientMonitoring';
import { UPDATE_RPM_ALERTS } from '@/graphql/remotePatientMonitoring';
import type { RelyingPartyAdminData } from '@/routes/Administration';
import AlertReviewFollowUpForm from '@/routes/PatientDetails/FollowUps/Components/AlertReviewFollowUpForm';
import { Role } from '@/types/admin';
import type { RPMAccountAlert } from '@/types/remotePatientMonitoring';
import { formatDateAndTime, formatDateIgnoreTZAddDays, formatEndOfDay, getCurrentDate } from '@/util/format';
import { getAlertTypeStyles } from '@/util/rpm';
import { unknown } from '@/util/string';
import { useAccountStore } from '@/zustand/AccountStore';
import { useMutation, useQuery } from '@apollo/client';
import type { DateInput } from '@fullcalendar/core';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Paper, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useRef, useState } from 'react';
import Draggable from 'react-draggable';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';

type Props = {
  selectedCriticalAlerts: RPMAccountAlert[];
};

type DefaultFollowUpType = {
  truentityId: string;
  followUpDueDate: DateInput;
  type: string;
  note: string;
  taskAssignees: RelyingPartyAdminData;
  isCreateFollowUpTask: boolean;
  isReadAlert: boolean;
  alertId: string;
  immediateActions: JSONData;
  alertInfo: {
    description: string;
    alertType: string;
    reading: string;
    recordedAt: string;
  };
};

export type DefaultValueType = { followUps: Array<DefaultFollowUpType> };

const AlertReviewFollowUpWindow: FC<Props> = ({ selectedCriticalAlerts }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setShowAlertReviewFollowupWindow, setAlertReviewFollowupData } = useAccountStore();

  const category = FollowUpCategoryTypes.ALERT_REVIEW;

  const [accountAssignees, setAccountAssignees] = useState<RelyingPartyAdminData[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const swiperRef = useRef<{ goNext: () => void; goPrev: () => void }>(null);

  useQuery<GetFilteredRelyingPartyAdminsResponse>(GET_FILTERED_RELYING_PARTY_ADMINS, {
    variables: {
      pageNum: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      excludedRoles: [Role.SUPER]
    },
    onCompleted: data => setAccountAssignees(data.filteredRelyingPartyAdmins.relyingPartyAdmins)
  });

  const [updateRpmAlerts] = useMutation<UpdateRpmAlertsResponse>(UPDATE_RPM_ALERTS, {
    onCompleted({ updateRpmAlerts: { errors, status } }) {
      if (status === 'Success') {
        enqueueSnackbar('RPM Alert(s) Updated Successfully.', { variant: 'success' });
      } else if (status === 'Failure') {
        enqueueSnackbar(errors?.length > 0 ? errors[0] : 'Could not update RPM Alert(s).', {
          variant: 'error'
        });
      }
      closeFollowupCard();
    },
    onError(error) {
      console.error(error);
      enqueueSnackbar('Could not update RPM Alert(s).', { variant: 'error' });
    }
  });

  const [addAccountsFollowUps] = useMutation<AccountsFollowupResponse>(ADD_ACCOUNTS_FOLLOWUPS, {
    onCompleted: data => {
      const status = data?.addAccountsFollowups?.status === 'Success' ? 'success' : 'error';
      const message = data?.addAccountsFollowups?.message || "Unexpected error occurred while creating a task'";
      enqueueSnackbar(message, { variant: status });
      closeFollowupCard();
    },
    onError: err => {
      const code = err.graphQLErrors?.[0]?.extensions?.code;
      const message = err.graphQLErrors?.[0]?.message;

      if (code && code === 'UNEXPECTED_CODE') {
        enqueueSnackbar(message, { variant: 'error' });
      } else {
        enqueueSnackbar('Unexpected error occurred while creating a task', { variant: 'error' });
      }
      hideWindow();
    }
  });

  const onSubmitted = (items: string[]) => {
    updateRpmAlerts({
      variables: {
        alertIds: items,
        input: {
          isRead: true
        }
      }
    });
  };

  const hideWindow = () => closeFollowupCard();

  const methods = useForm<DefaultValueType>({
    mode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      followUps: selectedCriticalAlerts?.map(criticalAlert => ({
        truentityId: criticalAlert.account.truentityId,
        followUpDueDate: formatDateIgnoreTZAddDays(getCurrentDate(), 3),
        type: '',
        note: '',
        taskAssignees: undefined,
        isCreateFollowUpTask: true,
        isReadAlert: true,
        alertId: criticalAlert.id,
        immediateActions: criticalAlert.immediateActions,
        alertInfo: {
          alertType: criticalAlert.label ? getAlertTypeStyles(criticalAlert.label).labelText : unknown(),
          description: criticalAlert.description,
          reading: criticalAlert.reading,
          recordedAt: formatDateAndTime(criticalAlert.recordedAt) || unknown()
        }
      }))
    }
  });

  const { control, watch, handleSubmit } = methods;
  const { fields } = useFieldArray({ control: control, name: 'followUps' });

  const [slideIndex, setSlideIndex] = useState<number>(0);

  const currentFollowUp = watch(`followUps.${slideIndex}`);

  const closeFollowupCard = () => {
    setShowAlertReviewFollowupWindow(false);
    setAlertReviewFollowupData([]);
  };

  const onSubmit: SubmitHandler<DefaultValueType> = async data => {
    // Filter submitted data
    const filteredData = data.followUps.filter(followUp => followUp.isCreateFollowUpTask);
    const alertReadOnly = data.followUps.filter(followUp => followUp.isReadAlert);

    const followUpObjects = filteredData.map(data => ({
      truentityId: data.truentityId,
      alertId: data.alertId,
      followUpAssigneeIds: data.taskAssignees.id,
      followUpOn: formatEndOfDay(data.followUpDueDate),
      type: data.type,
      subType: FollowUpSubTypes.RPM,
      note: data.note,
      status: FollowUpStatusTypes.PENDING,
      category: category,
      immediateActions: data.immediateActions
    }));

    try {
      if (followUpObjects.length > 0) {
        await addAccountsFollowUps({ variables: { followUps: followUpObjects } });
      }

      if (alertReadOnly.length > 0) {
        const alertReadOnlyIds = alertReadOnly.map(data => data.alertId);
        onSubmitted(alertReadOnlyIds);
      } else {
        hideWindow();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unexpected error occurred while creating a task', { variant: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const isCurrentSlideValid =
    !currentFollowUp.isCreateFollowUpTask ||
    (!!currentFollowUp?.followUpDueDate &&
      !!currentFollowUp?.type &&
      !!currentFollowUp?.taskAssignees &&
      currentFollowUp?.note.trim().length > 0);

  return (
    <Draggable handle="#draggable-dialog-title" positionOffset={{ x: '-50%', y: '-50%' }}>
      <Stack
        component={Paper}
        gap={1}
        maxWidth={600}
        p={2}
        pr={0}
        position="fixed"
        top="50%"
        left="50%"
        elevation={14}
        overflow="hidden"
        sx={{
          zIndex: theme => theme.zIndex.drawer + 20
        }}
      >
        <Stack
          direction="row"
          maxWidth="inherit"
          alignItems="center"
          justifyContent="space-between"
          pr={2}
          id="draggable-dialog-title"
          sx={{ cursor: 'move' }}
        >
          <Body2>Alert Review Follow-up</Body2>
          <IconButton onClick={closeFollowupCard}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Box maxWidth="inherit" maxHeight={590} pr={2} overflow="auto">
          {fields && fields?.length > 0 && (
            <Alert
              description={`Reviewing ${fields.length} critical alerts. Use this form to initiate follow-ups or mark them as read.`}
              status="warning"
              style={{ marginBottom: '16px' }}
            />
          )}
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TruentitySwiper ref={swiperRef} onSlideChange={swiper => setSlideIndex(swiper.activeIndex)}>
                {fields.map((filed, index) => (
                  <AlertReviewFollowUpForm key={filed.id} index={index} accountAssignees={accountAssignees} category={category} />
                ))}
              </TruentitySwiper>

              <Stack direction="row" width="100%" justifyContent="space-between" mt={2}>
                <Button onClick={hideWindow} variant="outlined">
                  Cancel
                </Button>
                <Stack direction="row" gap={1}>
                  {slideIndex > 0 && (
                    <Button onClick={() => swiperRef.current?.goPrev()} variant="outlined">
                      Prev
                    </Button>
                  )}
                  <Button
                    onClick={() => {
                      if (slideIndex < fields.length - 1) {
                        swiperRef.current?.goNext();
                      } else {
                        setIsSubmitting(true);
                      }
                    }}
                    type={isSubmitting ? 'submit' : 'button'}
                    disabled={!isCurrentSlideValid}
                    color={slideIndex === fields.length - 1 ? 'success' : 'primary'}
                  >
                    {slideIndex === fields.length - 1 ? 'Submit' : 'Next'}
                  </Button>
                </Stack>
              </Stack>
            </form>
          </FormProvider>
        </Box>
      </Stack>
    </Draggable>
  );
};

export default AlertReviewFollowUpWindow;
