import { ProfileAvatar } from '@/components/ProfileAvatar';
import { Body2, Caption, H5 } from '@/components/Typography';
import { color } from '@/styles/assets/colors';
import type { PatientReviewNoteType } from '@/types/remotePatientMonitoring';
import { getAccountUserFullName } from '@/util/account';
import { formatDateAndTime } from '@/util/format';
import { Paper, Stack } from '@mui/material';
import parse from 'html-react-parser';
import type { FC } from 'react';

type Props = {
  reply: PatientReviewNoteType;
};

const RpmPatientReviewReplyNote: FC<Props> = ({ reply }) => {
  return (
    <Stack
      component={Paper}
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={2}
      marginBottom={1.5}
      padding={2}
      elevation={0}
      sx={{ backgroundColor: color.grey100 }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={1}>
          <ProfileAvatar
            avatarUrl={reply?.relyingPartyAdmin?.presignedAvatarUrl}
            name={getAccountUserFullName(reply.relyingPartyAdmin.user)}
            sx={{ bgcolor: color.primaryMain, width: 30, height: 30, fontSize: 'small' }}
          />
          <H5 sx={{ color: color.black100 }}>{getAccountUserFullName(reply.relyingPartyAdmin.user)}</H5>
        </Stack>
        <Caption sx={{ color: color.grey600 }}>{formatDateAndTime(reply.createdAt)}</Caption>
      </Stack>
      <Body2 sx={{ color: color.black100 }}>{parse(reply.note)}</Body2>
    </Stack>
  );
};

export default RpmPatientReviewReplyNote;
