import { color } from '@/styles/assets/colors';
import { MessageState, SenderType } from '@/types/coPilot';
import { Box, CircularProgress, Stack } from '@mui/material';
import type React from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Markdown from 'react-markdown';
import CopilotIcon from './CoPilotIcon';

interface CoPilotMessageProps {
  message: string;
  sender: SenderType;
  type?: MessageState;
  isCompleted?: boolean;
  sendMessageLoading?: boolean;
  isLastMessage?: boolean;
  animationSpeed?: number;
  setIsStreaming?: Dispatch<SetStateAction<boolean>>;
  shouldAnimate?: boolean;
}

const CoPilotMessage: React.FC<CoPilotMessageProps> = ({
  message,
  sender,
  sendMessageLoading,
  type,
  isLastMessage,
  isCompleted = false,
  setIsStreaming,
  animationSpeed = 3,
  shouldAnimate = false
}) => {
  const isFailed = useMemo(() => type === MessageState.FAILED, [type]);
  const isStreaming = useMemo(() => type === MessageState.STREAM, [type]);

  const [animatedText, setAnimatedText] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(isStreaming);
  const [isAnimationCompleted, setIsAnimationCompleted] = useState<boolean>(false);

  const lastRenderedIndexRef = useRef(0);
  const animationFrameRef = useRef<number | null>(null);
  const lastAnimationTimeRef = useRef<number>(0);

  const animateText = useCallback(
    (timestamp: number) => {
      if (!lastAnimationTimeRef.current || timestamp - lastAnimationTimeRef.current >= animationSpeed) {
        setAnimatedText(prev => {
          if (lastRenderedIndexRef.current < message.length) {
            const nextChar = message.charAt(lastRenderedIndexRef.current);
            lastRenderedIndexRef.current += 1;
            lastAnimationTimeRef.current = timestamp;
            return prev + nextChar;
          } else {
            setIsAnimationCompleted(true);
            return prev;
          }
        });
      }

      if (lastRenderedIndexRef.current < message.length) {
        animationFrameRef.current = requestAnimationFrame(animateText);
      }
    },
    [message, animationSpeed]
  );

  useEffect(() => {
    if (isStreaming && animatedText.length < message.length && shouldAnimate && !isAnimationCompleted) {
      lastRenderedIndexRef.current = 0;
      lastAnimationTimeRef.current = 0;
      setAnimatedText('');

      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      animationFrameRef.current = requestAnimationFrame(animateText);
    }

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [message, isStreaming, animatedText, animateText, setIsStreaming, shouldAnimate, isAnimationCompleted]);

  useEffect(() => {
    if (isCompleted) {
      setIsStreaming?.(false);
      setIsLoading(false);
    } else {
      setIsStreaming?.(true);
      setIsLoading(true);
    }
  }, [isCompleted, setIsStreaming]);

  return (
    <Stack direction={sender === SenderType.BOT ? 'row' : 'row-reverse'} spacing={1}>
      {sender === SenderType.BOT && <CopilotIcon isLoading={isLoading} isFailed={isFailed} />}

      <Box
        sx={{
          padding: 1,
          backgroundColor: sender === SenderType.BOT ? (isFailed ? color.red50 : '#f8fafc') : color.paper,
          color: isFailed ? color.errorMain : 'black',
          maxWidth: sender === SenderType.BOT ? '90%' : '85%',
          position: 'relative',
          overflow: 'visible',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: sender === SenderType.USER ? '32px 32px 0 32px' : '0 32px 32px 32px',
          paddingLeft: '1rem',
          border: '1px solid',
          borderColor: sender === SenderType.BOT ? (isFailed ? color.errorLight : '#e3e9f2') : '#e3e9f2'
        }}
      >
        <Box py="0.5rem" px="0.25rem" width="100%" sx={{ textWrap: 'wrap' }}>
          <Markdown components={{ p: 'div' }}>{animatedText || message}</Markdown>
        </Box>

        {sendMessageLoading && sender === SenderType.USER && isLastMessage && (
          <CircularProgress
            size={15}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '-20px',
              marginTop: '-7.5px',
              zIndex: 1
            }}
          />
        )}
      </Box>
    </Stack>
  );
};

export default CoPilotMessage;
