import Button from '@/components/Button';
import ChipsList from '@/components/ChipsList';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import MuiAccordion from '@/components/MuiAccordion';
import Link from '@/elements/Link';
import { GET_TASKS_BY_FILTER, GET_TASKS_BY_FILTER_TO_EXPORT } from '@/graphql/taskEncounter';
import type { TaskType } from '@/types/graphql';
import type { GetTasksByFilterResponse } from '@/types/tasks';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { getFirstDateOfCurrentMonth, toDateOrNull } from '@/util/date';
import { formatDate, formatDateIgnoreTZ } from '@/util/format';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { Box, Chip, Paper, Stack } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import type { MomentInput } from 'moment';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { FormProvider, useForm } from 'react-hook-form';
import BillingDetailsFilter from './components/BillingDetailsFilter';
import BillingStat from './components/BillingStat';

export type FormType = {
  organization?: string;
  orgStore?: string;
  performedOnStartDate?: MomentInput;
  performedOnEndDate?: MomentInput;
  status: string[];
};

export enum BillingReportType {
  Full = 'Full',
  Limited = 'Limited'
}

const Billing = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [today] = useState(new Date());
  const [rowCount, setRowCount] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(0);
  const [tasks, setTasks] = useState<TaskType[]>([]);
  const [billingExportReportData, setBillingExportReportData] = useState<TaskType[]>([]);
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const status = ['COMPLETED', 'ATTEMPTED'];

  const [taskAmounts, setTaskAmounts] = useState<{ totalBilledAmount: number; totalBonusAmount }>({
    totalBilledAmount: 0,
    totalBonusAmount: 0
  });

  const [getTasks, { loading: getTasksLoading }] = useLazyQuery<GetTasksByFilterResponse>(GET_TASKS_BY_FILTER, {
    variables: {
      pageSize: DEFAULT_PAGE_SIZE,
      pageNum: currentPage + 1,
      isExport: false
    },
    nextFetchPolicy: 'cache-and-network',
    onCompleted({
      tasksByFilter: {
        tasks,
        totalBilledAmount,
        totalBonusAmount,
        meta: { totalCount }
      }
    }) {
      if (tasks.length > 0) {
        setTasks(tasks || []);
        setTaskAmounts({
          totalBilledAmount,
          totalBonusAmount
        });
        setRowCount(totalCount);
      } else {
        setTasks([]);
      }
    },
    onError(error) {
      enqueueSnackbar('Failed to retrieve tasks', { variant: 'error' });
      console.error(error);
      setTasks([]);
    }
  });
  const [getExport, { loading: getExportDataLoading, called: getExportDataCalled }] = useLazyQuery<GetTasksByFilterResponse>(
    GET_TASKS_BY_FILTER_TO_EXPORT,
    {
      variables: {
        pageSize: DEFAULT_PAGE_SIZE,
        pageNum: currentPage + 1,
        isExport: true
      },
      nextFetchPolicy: 'cache-and-network',
      onCompleted({ tasksByFilter: { tasks } }) {
        setBillingExportReportData(tasks || []);
      }
    }
  );

  const methods = useForm<Required<FormType>>({
    defaultValues: {
      organization: 'all',
      orgStore: 'all',
      performedOnStartDate: getFirstDateOfCurrentMonth(today),
      performedOnEndDate: today,
      status: status
    }
  });

  const callGetTasks = async (values: FormType) => {
    const filters = values;
    await getTasks({
      variables: {
        relyingPartyAdminId: currentUser?.id,
        filterOptions: {
          ...filters,
          performedOnStartDate: formatDate(filters.performedOnStartDate, 'YYYY-MM-DD'),
          performedOnEndDate: formatDate(filters.performedOnEndDate, 'YYYY-MM-DD')
        }
      }
    });
  };

  const onFetchDataClicked = () => {
    const filters = methods.getValues();
    getExport({
      variables: {
        filterOptions: {
          ...filters,
          performedOnStartDate: formatDate(filters.performedOnStartDate, 'YYYY-MM-DD'),
          performedOnEndDate: formatDate(filters.performedOnEndDate, 'YYYY-MM-DD')
        }
      }
    });
  };

  const getBilledForName = (firstName: string, lastName: string, reportType: BillingReportType) => {
    let billingNameStr = 'Not Provided';
    if (reportType === BillingReportType.Limited) {
      let firstNameInitial = '';
      let lastNameInitial = '';
      if (firstName) {
        firstNameInitial = firstName[0].toUpperCase();
      }
      if (lastName) {
        lastNameInitial = lastName[0].toUpperCase();
      }
      billingNameStr = firstNameInitial + lastNameInitial || 'Not Provided';
    } else {
      billingNameStr = firstName + ' ' + lastName || 'Not Provided';
    }
    return billingNameStr;
  };

  const newBillingReport = (reportType: BillingReportType) => {
    try {
      const newBillingReportData = billingExportReportData.map(
        data => ({
          Name: getBilledForName(String(data?.account?.user?.firstName), String(data?.account?.user?.lastName), reportType),
          Type: data?.type || 'Not Provided',
          Status: data?.status || 'Not Provided',
          Billed: data?.billedAmount || '0',
          Bonus: data?.bonusAmount || '0',
          Resolution: data?.modeOfResolution || 'Not Provided',
          'Wrap Up Status': data?.wrapUpStatus || 'Not Provided',
          'Performed By': data?.completedByAdmin || 'Not Provided',
          'Performed On': data?.performedOn || 'Not Provided',
          Organization: data?.account?.currentRelyingPartyClientOrgs?.map(org => org.name) || 'Not Provided',
          Location: data?.account?.currentRelyingPartyClientStores?.map(loc => loc.name) || 'Not Provided'
        }),
        []
      );

      return newBillingReportData;
    } catch (error) {
      console.error(error);
    }
  };

  const formatUserName = () => {
    return [
      { label: 'Name', key: 'Name' },
      { label: 'Type', key: 'Type' },
      { label: 'Status', key: 'Status' },
      { label: 'Billed ($)', key: 'Billed' },
      { label: 'Bonus ($)', key: 'Bonus' },
      { label: 'Resolution', key: 'Resolution' },
      { label: 'Wrap Up Status', key: 'Wrap Up Status' },
      { label: 'Performed By', key: 'Performed By' },
      { label: 'Performed On', key: 'Performed On' },
      { label: 'Organization', key: 'Organization' },
      { label: 'Location', key: 'Location' }
    ];
  };

  const createCsvFileName = (reportType: BillingReportType) =>
    `Billing Report ${reportType} -- ${moment(methods.getValues('performedOnStartDate')).format('YYYY-MM-DD')} - ${moment(
      methods.getValues('performedOnEndDate')
    ).format('YYYY-MM-DD')}.csv`;

  const headers = formatUserName();

  const columns: GridColDef<TaskType>[] = useMemo(
    () => [
      {
        field: 'account-fullname',
        headerName: 'Name',
        sortable: true,
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        renderCell: params => {
          return (
            <Link to={`/providers/${params.row.account.truentityId}/manage`}>
              {String(params.row.account.user.firstName) + ' ' + String(params.row.account.user.lastName)}
            </Link>
          );
        }
      },
      {
        field: 'type',
        headerName: 'Type',
        sortable: true,
        valueGetter: params => params.row.type,
        headerAlign: 'center',
        align: 'center',
        maxWidth: 120,
        flex: 1
      },
      {
        field: 'status',
        headerName: 'Status',
        sortable: true,
        renderCell: params => <Box sx={{ textTransform: 'capitalize' }}>{params.row.status.toLowerCase()}</Box>,
        headerAlign: 'center',
        align: 'center',
        maxWidth: 120,
        flex: 1
      },
      {
        field: 'billedAmount',
        headerName: 'Billed',
        sortable: true,
        headerAlign: 'center',
        align: 'center',
        maxWidth: 120,
        flex: 1,
        renderCell: params => <Box>{`$ ${params.row.billedAmount || 0}`}</Box>
      },
      {
        field: 'bonusAmount',
        headerName: 'Bonus',
        sortable: true,
        headerAlign: 'center',
        align: 'center',
        maxWidth: 120,
        flex: 1,
        renderCell: params => <Box>{`$ ${params.row.bonusAmount || 0}`}</Box>
      },
      {
        field: 'modeOfResolution',
        headerName: 'Resolution',
        sortable: true,
        flex: 1,
        valueGetter: params => params.row.modeOfResolution?.replace(/([A-Z])/g, ' $1').trim(),
        align: 'left'
      },
      {
        field: 'wrapUpStatus',
        headerName: 'Wrap Up Status',
        sortable: true,
        flex: 1,
        valueGetter: params => params.row.wrapUpStatus?.replace(/([A-Z])/g, ' $1').trim(),
        align: 'left'
      },
      {
        field: 'completedByAdmin',
        headerName: 'Performed By',
        sortable: true,
        align: 'left',
        flex: 1
      },
      {
        field: 'performedOn',
        headerName: 'Performed On',
        sortable: true,
        flex: 1,
        type: 'date',
        valueGetter: params => toDateOrNull(params.value),
        renderCell: params => (
          <Link to={`patients/${params.row.account.truentityId}/details/medications/tasks/list`}>
            {formatDateIgnoreTZ(params.row.performedOn, 'MMM DD, YYYY')}
          </Link>
        ),
        align: 'left'
      },
      {
        field: 'organization',
        headerName: 'Organization',
        sortable: true,
        renderCell: ({ row: { account } }) => {
          const relyingPartyClientOrgs = account?.currentRelyingPartyClientOrgs || [];
          return <ChipsList size="small" items={relyingPartyClientOrgs.map(v => v.name as string)} />;
        },
        flex: 2,
        align: 'center',
        headerAlign: 'center'
      },
      {
        field: 'store',
        headerName: 'Location',
        sortable: true,
        renderCell: ({ row: { account } }) => {
          const relyingPartyClientStores = account?.currentRelyingPartyClientStores || [];
          return <ChipsList variant="outlined" size="small" items={relyingPartyClientStores.map(store => store.name as string)} />;
        },
        flex: 2,
        headerAlign: 'center',
        align: 'center'
      }
    ],
    []
  );

  return (
    <Stack spacing={2}>
      <BillingStat status={status} />
      <MuiAccordion
        options={[
          {
            label: 'Billing Details',
            defaultExpand: false,
            content: (
              <>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(callGetTasks)}>
                    <BillingDetailsFilter getTasksLoading={getTasksLoading} setTasks={setTasks} />
                  </form>
                </FormProvider>
                <Paper component={Stack} direction="column" spacing={2}>
                  <TruentityDataGrid
                    name={'dg-billing'}
                    paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
                    onPaginationModelChange={({ page }) => setCurrentPage(page)}
                    disableColumnMenu={false}
                    sx={{
                      '& .MuiDataGrid-columnHeaderTitle': {
                        textOverflow: 'clip',
                        whiteSpace: 'break-spaces',
                        lineHeight: 1
                      }
                    }}
                    slots={{
                      toolbar: () => {
                        const billedAmountLabel =
                          'Total Billed Amount: $' + (taskAmounts.totalBilledAmount ? taskAmounts.totalBilledAmount.toFixed(2) : '0.00');
                        const bonusAmountLabel =
                          'Total Bonus Amount: $' + (taskAmounts.totalBonusAmount ? taskAmounts.totalBonusAmount.toFixed(2) : '0.00');
                        return (
                          <Stack>
                            <Stack
                              spacing={3}
                              direction="row"
                              sx={{
                                cursor: 'pointer',
                                margin: '15px',
                                justifyContent: 'flex-start'
                              }}
                            >
                              <Stack spacing={1} direction="row">
                                <Chip
                                  sx={{
                                    textTransform: 'uppercase',
                                    letterSpacing: 2,
                                    height: 23,
                                    fontSize: '0.80rem'
                                  }}
                                  label={billedAmountLabel}
                                  color="success"
                                  variant="outlined"
                                ></Chip>
                              </Stack>
                              <Stack spacing={1} direction="row">
                                <Chip
                                  sx={{
                                    textTransform: 'uppercase',
                                    letterSpacing: 2,
                                    height: 23,
                                    fontSize: '0.80rem'
                                  }}
                                  label={bonusAmountLabel}
                                  color="success"
                                  variant="outlined"
                                ></Chip>
                              </Stack>
                            </Stack>
                            <Stack
                              spacing={3}
                              direction="row"
                              sx={{
                                cursor: 'pointer',
                                margin: '15px',
                                justifyContent: 'flex-end'
                              }}
                            >
                              <Stack spacing={1} direction="row">
                                {!getExportDataCalled ? (
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    disabled={tasks.length === 0}
                                    onClick={() => {
                                      onFetchDataClicked();
                                    }}
                                  >
                                    Load for Export
                                  </Button>
                                ) : (
                                  <>
                                    <Button isLoading={getExportDataLoading} color="primary" variant="contained" size="small">
                                      {billingExportReportData.length > 0 ? (
                                        <CSVLink
                                          headers={headers}
                                          data={newBillingReport(BillingReportType.Full)}
                                          filename={createCsvFileName(BillingReportType.Full)}
                                          style={{ textDecoration: 'none', color: '#fff' }}
                                        >
                                          {getExportDataLoading ? 'Loading csv...' : 'Export All'}
                                        </CSVLink>
                                      ) : undefined}
                                    </Button>
                                    <Button isLoading={getExportDataLoading} variant="outlined" size="small">
                                      {billingExportReportData.length > 0 ? (
                                        <CSVLink
                                          headers={headers}
                                          data={newBillingReport(BillingReportType.Limited)}
                                          filename={createCsvFileName(BillingReportType.Limited)}
                                          style={{ textDecoration: 'none', color: '#000' }}
                                        >
                                          {getExportDataLoading ? 'Loading csv...' : 'Export Limited'}
                                        </CSVLink>
                                      ) : undefined}
                                    </Button>
                                  </>
                                )}
                              </Stack>
                            </Stack>
                          </Stack>
                        );
                      }
                    }}
                    rows={tasks}
                    rowCount={rowCount}
                    loading={getTasksLoading}
                    columns={columns}
                    paginationMode="server"
                    autoHeight
                  />
                </Paper>
              </>
            )
          }
        ]}
      />
    </Stack>
  );
};

export default Billing;
