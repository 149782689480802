import ActivityTracker from '@/components/ActivityTracker';
import AppFrame from '@/components/AppFrame';
import { NotificationProvider } from '@/context/notificationContext';
import { MessageProvider } from '@/context/patientMessageContext';
import { useIdleModal } from '@/hooks/useIdleModal';
import useSettings from '@/hooks/useSettings';
import useToken from '@/hooks/useToken';
import { sendHeartbeat } from '@/services/activity-log';
import { Role } from '@/types/admin';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const Authenticated = () => {
  const { token, handleLogout, roleType } = useToken();
  const location = useLocation();
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const navigate = useNavigate();
  const { settings } = useSettings();

  const { idleTimer } = useIdleModal({
    handleLogout
  });

  useEffect(() => {
    if (!token) {
      navigate('/login', { replace: true });
    } else {
      if (location.pathname === '/') {
        navigate('/home');
      }

      if (((settings['inactivityTimeoutSeconds'] as number) ?? 1) > 0) {
        idleTimer.start();

        const interval = setInterval(() => {
          sendHeartbeat().catch(console.error);
        }, 15000);

        return () => clearInterval(interval);
      } else {
        console.log('idle timer disabled');
      }
    }
  }, [token]);

  useEffect(() => {
    if (location.pathname !== '/agreement' && !currentUser?.relyingParty?.isCtaAccepted && roleType === Role.PHARMACIST) {
      navigate('/agreement');
    }
  }, [currentUser, roleType]);

  return (
    <NotificationProvider>
      <MessageProvider>
        <ActivityTracker />
        <AppFrame>
          <Outlet />
        </AppFrame>
      </MessageProvider>
    </NotificationProvider>
  );
};

export default Authenticated;
