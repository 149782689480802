import { AnnouncementType, type Announcement } from '@/types/announcement';
import { DialogContent } from '@mui/material';
import PrimaryAnnouncementBody from '../PrimaryAnnouncementBody';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  announcement: Announcement;
};

const AnnouncementInfoDialog = ({ title, hideDialog, announcement, ...props }: Props) => {
  return (
    <BaseDialog
      {...props}
      hideDialog={hideDialog}
      title={title}
      fullWidth
      maxWidth={announcement.type === AnnouncementType.SECONDARY ? 'sm' : 'md'}
    >
      <DialogContent>
        <PrimaryAnnouncementBody announcement={announcement} />
      </DialogContent>
    </BaseDialog>
  );
};

export default AnnouncementInfoDialog;
