import type { LoggedUser, RelyingParty, RelyingPartyType } from '@/types/graphql';
import type { MetaDataType } from '@/types/tasks';
import { gql } from '@apollo/client';

export type AddCompanyResponse = {
  addRelyingParty: {
    status: string;
    relyingParty: {
      id: string;
      name: string;
    };
  };
};

export const ADD_COMPANY = gql`
  mutation AddRelyingParty(
    $name: String!
    $adminsDetails: [RelyingPartyUsersInput!]
    $medicalServices: [MedicalServiceTypeEnum!]!
    $clientOrganizationId: String
    $clientStoreId: String
  ) {
    addRelyingParty(
      name: $name
      adminsDetails: $adminsDetails
      medicalServices: $medicalServices
      clientOrganizationId: $clientOrganizationId
      clientStoreId: $clientStoreId
    ) {
      status
      relyingParty {
        id
        name
      }
    }
  }
`;

export type GetAllCompaniesResponse = {
  relyingPartiesAll: {
    relyingParties: RelyingPartyType[];
    meta: MetaDataType;
  };
};

export const GET_ALL_COMPANIES = gql`
  query getRelyingPartiesAll($pageNum: Int, $pageSize: Int, $filterMedicalService: String) {
    relyingPartiesAll(pageNum: $pageNum, pageSize: $pageSize, filterMedicalService: $filterMedicalService) {
      relyingParties {
        name
        id
        createdAt
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export type GetCompanyResponse = {
  relyingParty: RelyingParty | null;
};

export const GET_COMPANY_BY_ID = gql`
  query GetRelyingParty($relyingPartyId: ID!) {
    relyingParty(relyingPartyId: $relyingPartyId) {
      id
      name
      email
      phone
      settings
      logo
      logoUrl
      orgHandle
      coverLetterBody
      addressLine1
      addressLine2
      addressCity
      addressState
      medicalServices {
        name
        shortName
        serviceType
        description
      }
      ctaAcceptedOn
      isCtaAccepted
      ctaAcceptedInfo
      ctaRejectedOn
      ctaAcceptedByAdmin {
        name
      }
      timezone
    }
  }
`;

export type GetUserCompaniesResponse = {
  getUserRelyingParties: {
    relyingParties: RelyingPartyType[];
  };
};

export const GET_USER_COMPANIES = gql`
  query GetUserRelyingParties($hideCurrent: Boolean) {
    getUserRelyingParties(hideCurrent: $hideCurrent) {
      relyingParties {
        id
        name
        logo
        logoUrl
        orgHandle
      }
    }
  }
`;

export type SwitchCompanyResponseType = {
  switchRelyingParty: {
    accessToken?: string;
    refreshToken?: string;
    otpId?: string;
    relyingPartyAdmin?: LoggedUser;
  };
};

export const SWITCH_COMPANY = gql`
  mutation SwitchRelyingParty($relyingPartyId: String!) {
    switchRelyingParty(relyingPartyId: $relyingPartyId) {
      accessToken
      refreshToken
      relyingPartyAdmin {
        id
        roleType
        presignedAvatarUrl
        contactsRelyingPartyAdmins {
          contactType
          value
          relyingPartyAdminId
        }
        title
        businessName
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZipcode
        phone
        alternateEmail
        hourlyRate
        supervisorEmail
        bankName
        bankRoutingNumber
        bankAccountNumber
        externalDialpadId
        settings
        hasMultipleRelyingParties
        relyingParty {
          id
          name
          logoUrl
          orgHandle
          settings
          addressLine1
          addressLine2
          addressState
          addressCity
          phone
          email
          phone
          coverLetterBody
          medicalServices {
            name
            shortName
            serviceType
          }
          isCtaAccepted
          primaryProvider {
            name
          }
        }
        roleType
        npiNumber
        techLicense
        user {
          id
          firstName
          lastName
          email
        }
      }
      otpId
    }
  }
`;

export type ConfirmSwitchCompanyResponseType = {
  confirmSwitchRelyingParty: {
    accessToken: string;
    refreshToken: string;
    relyingPartyAdmin: LoggedUser;
  };
};

export const CONFIRM_SWITCH_COMPANY = gql`
  mutation ConfirmSwitchRelyingParty($otpId: String!, $otpCode: String!) {
    confirmSwitchRelyingParty(otpId: $otpId, otpCode: $otpCode) {
      accessToken
      refreshToken
      relyingPartyAdmin {
        id
        roleType
        contactsRelyingPartyAdmins {
          contactType
          value
          relyingPartyAdminId
        }
        title
        businessName
        addressLine1
        addressLine2
        addressCity
        addressState
        addressZipcode
        phone
        alternateEmail
        hourlyRate
        bankName
        bankRoutingNumber
        bankAccountNumber
        externalDialpadId
        settings
        hasMultipleRelyingParties
        relyingParty {
          id
          name
          logoUrl
          orgHandle
          settings
          medicalServices {
            name
            shortName
            serviceType
          }
          isCtaAccepted
        }
        roleType
        user {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;
