import { color } from '@/styles/assets/colors';
import { subtractDates } from '@/util/date';
import { getCurrentDate } from '@/util/format';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { Box } from '@mui/material';
import BillingStatItem from './BillingStatItem';

type BillingStatProps = {
  status: string[];
};

const BillingStat = ({ status }: BillingStatProps) => {
  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(12, 1fr)', gap: 2 }}>
      <BillingStatItem
        type="MONTH"
        statItems={[
          { title: 'Total Billed Amount', type: 'BILLED', icon: <MonetizationOnIcon />, color: color.green50 },
          { title: 'Total Bonus Amount', type: 'BONUS', icon: <LocalAtmIcon />, color: color.indigo50 }
        ]}
        status={status}
      />
      <BillingStatItem
        type="DATE"
        statItems={[
          { title: 'Total Billed Amount', type: 'BILLED', icon: <MonetizationOnIcon />, color: color.green50 },
          { title: 'Total Bonus Amount', type: 'BONUS', icon: <LocalAtmIcon />, color: color.indigo50 }
        ]}
        datePickerProps={{
          shouldDisableDate: date => date.isBefore(subtractDates(getCurrentDate(), 7))
        }}
        status={status}
      />
    </Box>
  );
};

export default BillingStat;
