import Button from '@/components/Button';
import { ProfileAvatarWithUpload } from '@/components/ProfileAvatar';
import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import TruentityTextField from '@/components/TruentityTextField';
import { Caption, H3, H4 } from '@/components/Typography';
import type { GetRelyingPartyAdminByIdResponse, GetRelyingPartyRpmReviewerResponse, UploadAvatarResponse } from '@/graphql/account';
import { UPDATE_RELYING_PARTY_PROFILE, UPLOAD_AVATAR } from '@/graphql/account';
import { GET_DIALPAD_USERS, UPDATE_ADMIN_CONTACT_NUMBERS } from '@/graphql/accountMessages';
import type { PinSettingsInputType, RelyingPartyAdminSecuritySettingsResponse } from '@/graphql/administration';
import {
  GET_RELYING_PARTY_ADMIN_BY_ID,
  GET_RELYING_PARTY_ADMIN_SECURITY_SETTINGS,
  GET_RELYING_PARTY_RPM_REVIEWER,
  UPDATE_RELYING_PARTY_ADMIN_PIN_SETTINGS
} from '@/graphql/administration';
import useShowMedicalServices from '@/hooks/useShowMedicalServices';
import useToken from '@/hooks/useToken';
import UserSettingTab from '@/routes/Administration/Components/UserSettingTab';
import { MedicalServices, Role, Titles } from '@/types/admin';
import type { DialpadUsersResponse } from '@/types/chat';
import type { RelyingPartyAdminType } from '@/types/graphql';
import { AdminRoleTypeEnum } from '@/types/graphql';
import { getTitleLabel } from '@/util/account';
import { convertRoleToRoleType, getCurrentAdminsUsableTitles, getRoleTypeUsingTitle } from '@/util/adminstration';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { dateToSeconds, secondsToDate } from '@/util/date';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import type { SelectChangeEvent } from '@mui/material';
import {
  Autocomplete,
  Box,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  TextField
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers-pro';
import type { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import Alert from '../Alert';
import MuiTabs from '../MuiTabs';
import SelectList from '../SelectList';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

export type ProfileDialogFormValues = Pick<
  RelyingPartyAdminType,
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'isObserver'
  | 'isRpmPrimary'
  | 'businessName'
  | 'addressLine1'
  | 'addressLine2'
  | 'addressState'
  | 'addressCity'
  | 'addressZipcode'
  | 'phone'
  | 'alternateEmail'
  | 'npiNumber'
  | 'techLicense'
  | 'bankName'
  | 'bankRoutingNumber'
  | 'bankAccountNumber'
  | 'hourlyRate'
  | 'supervisorEmail'
  | 'externalDialpadId'
  | 'rpAdminFax'
  | 'rpAdminPhone'
  | 'securityPin'
  | 'inactivityPinPromptTimeoutSeconds'
  | 'inactivityTimeoutSeconds'
  | 'title'
  | 'roleType'
  | 'settings'
  | 'presignedAvatarUrl'
>;

type Props = BaseDialogProps & {
  hideDialog: () => void;
  type?: string;
  pharmacistInfo?: any;
  rpmReviewer?: any;
};

const defaultValues: ProfileDialogFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  title: '',
  isObserver: false,
  isRpmPrimary: false,
  businessName: '',
  addressLine1: '',
  addressLine2: '',
  addressState: '',
  addressCity: '',
  addressZipcode: '',
  phone: '',
  alternateEmail: '',
  npiNumber: '',
  techLicense: '',
  bankName: '',
  bankRoutingNumber: '',
  bankAccountNumber: '',
  hourlyRate: null,
  supervisorEmail: '',
  externalDialpadId: '',
  rpAdminPhone: '',
  securityPin: '',
  inactivityPinPromptTimeoutSeconds: undefined,
  inactivityTimeoutSeconds: undefined,
  rpAdminFax: '',
  settings: '',
  roleType: AdminRoleTypeEnum.PHARMACIST
};

const ProfileDialog = ({ hideDialog, pharmacistInfo, ...props }: Props) => {
  const { roleType } = useToken();
  const { enqueueSnackbar } = useSnackbar();
  const DEFAULT_PIN_TEXT = '******';
  const isMTMViewable = useShowMedicalServices(MedicalServices.MTM);
  const [isPharmacist] = useState<boolean>(roleType === Role.PHARMACIST);

  const [rpmReviewer, setRpmReviewer] = useState<string>('');

  const currentUser = useReactiveVar(currentLoggedUserVar);

  const [updateProfile] = useMutation(UPDATE_RELYING_PARTY_PROFILE);
  const [updateAdminContact] = useMutation(UPDATE_ADMIN_CONTACT_NUMBERS);
  const [updateAdminPinSettings] = useMutation(UPDATE_RELYING_PARTY_ADMIN_PIN_SETTINGS);

  const [isRpmReviewerDisabled, setIsRpmReviewerDisabled] = useState<boolean>(false);
  const [isSecurityPinSet, setIsSecurityPinSet] = useState<boolean>(false);
  const [editSecurityPin, setEditSecurityPin] = useState<boolean>(false);
  const [dialpadUserData, setDialpadUserData] = useState<DialpadUsersResponse['getDialpadUsers']>([]);

  const isCurrentUserSuperAdmin = useMemo(() => roleType === Role.SUPER, [roleType]);
  const isCurrentUserPsm = useMemo(() => roleType === Role.ADMIN, [roleType]);

  const isNotTitleEditable = useMemo(
    () => isCurrentUserSuperAdmin || isCurrentUserPsm || isPharmacist,
    [isCurrentUserPsm, isCurrentUserSuperAdmin, isPharmacist]
  );
  const isCurrentUsersEditModal = useMemo(() => currentUser?.id === pharmacistInfo?.id, [currentUser, pharmacistInfo]);
  const isSelfProfileEditing = useMemo(() => currentUser?.id === pharmacistInfo?.id, [currentUser, pharmacistInfo]);

  const [relyingPartyAdminByIdQuery, { data: relyingPartyAdminData }] = useLazyQuery<GetRelyingPartyAdminByIdResponse>(
    GET_RELYING_PARTY_ADMIN_BY_ID,
    { fetchPolicy: 'cache-and-network' }
  );
  const [getDialpadUsers] = useLazyQuery<DialpadUsersResponse>(GET_DIALPAD_USERS, {
    onCompleted: data => handleDialpadUsersData(data)
  });

  const [relyingPartyAdminSecuritySettingsQuery, { data: relyingPartyAdminSecuritySettingsData }] =
    useLazyQuery<RelyingPartyAdminSecuritySettingsResponse>(GET_RELYING_PARTY_ADMIN_SECURITY_SETTINGS, {
      fetchPolicy: 'cache-and-network'
    });

  const [
    relyingPartyRpmReviewerQuery,
    { data: relyingPartyRpmReviewerData, loading: relyingPartyRpmReviewerLoading, called: relyingPartyRpmReviewerCalled }
  ] = useLazyQuery<GetRelyingPartyRpmReviewerResponse>(GET_RELYING_PARTY_RPM_REVIEWER, { fetchPolicy: 'cache-and-network' });

  const { control, handleSubmit, setValue, getValues, reset, watch } = useForm<ProfileDialogFormValues>({ defaultValues, mode: 'all' });

  const avatarUrl = watch('presignedAvatarUrl');
  const firstName = watch('firstName');
  const lastName = watch('lastName');
  const title = watch('title');
  const settings = watch('settings');
  const externalDialpadId = watch('externalDialpadId');
  const isObserver = watch('isObserver');

  const requiredFields: Partial<keyof ProfileDialogFormValues>[] = ['firstName', 'lastName', 'email', 'phone'];

  const formCustomValidation = (values: ProfileDialogFormValues): Record<string, string> => {
    const errors = requiredFields.reduce((acc, field) => {
      if (!values[field]) {
        acc[field] = 'This field is required';
      }
      return acc;
    }, {});

    return errors;
  };

  const onSubmit: SubmitHandler<ProfileDialogFormValues> = data => handleSubmitImpl(data);

  const handleSubmitImpl = async (values: ProfileDialogFormValues) => {
    const customErrors = formCustomValidation(values);

    const {
      firstName,
      lastName,
      email,
      title,
      roleType,
      businessName,
      addressLine1,
      addressLine2,
      addressState,
      addressCity,
      addressZipcode,
      phone,
      alternateEmail,
      npiNumber,
      techLicense,
      bankName,
      bankRoutingNumber,
      bankAccountNumber,
      hourlyRate,
      supervisorEmail,
      externalDialpadId,
      rpAdminPhone,
      rpAdminFax,
      isObserver,
      isRpmPrimary,
      settings
    } = values;

    const profileData = {
      firstName,
      lastName,
      email,
      title,
      roleType,
      businessName,
      addressLine1,
      addressLine2,
      addressState,
      addressCity,
      addressZipcode,
      phone,
      alternateEmail,
      npiNumber,
      techLicense,
      bankName,
      bankRoutingNumber,
      bankAccountNumber,
      hourlyRate,
      supervisorEmail,
      externalDialpadId,
      isObserver,
      isRpmPrimary,
      settings
    };

    // validate fields
    if (Object.keys(customErrors).length > 0) {
      enqueueSnackbar('Please ensure all required fields in each tab are completed before submitting.', { variant: 'error' });
      return;
    }

    try {
      const result = await updateProfile({
        variables: {
          relyingPartyAdminId: pharmacistInfo?.id || currentUser?.id,
          profileData: {
            ...profileData,
            hourlyRate: Number(hourlyRate)
          }
        }
      });

      const contactResult = await updateAdminContact({
        variables: {
          relyingPartyAdminId: pharmacistInfo?.id ?? currentUser?.id,
          phone: rpAdminPhone ?? null,
          fax: rpAdminFax ?? null
        }
      });

      if (isPinPromptTimeoutSecondsDirty(values.inactivityPinPromptTimeoutSeconds) || isSecurityPinDirty(values.securityPin)) {
        let securityPin: string | undefined = undefined;
        if (isSecurityPinDirty(values.securityPin)) {
          if (values?.securityPin?.length !== 6) {
            enqueueSnackbar('PIN must be 6 digits', { variant: 'error' });
            return;
          }
          securityPin = values.securityPin;
        } else if (!isSecurityPinSet) {
          enqueueSnackbar('PIN is required', { variant: 'error' });
          return;
        }

        const adminPinSettingsInput: PinSettingsInputType = {
          securityPin: securityPin,
          inactivityPinPromptTimeoutSeconds: dateToSeconds(values.inactivityPinPromptTimeoutSeconds)
        };

        const pinSettingsResult = await updateAdminPinSettings({
          variables: {
            pinSettingsInput: adminPinSettingsInput
          }
        });

        const pinSettingsStatus = pinSettingsResult?.data?.updateRelyingPartyAdminPinSettings?.status;

        if (pinSettingsStatus !== 'Success') {
          enqueueSnackbar(pinSettingsResult?.data?.updateRelyingPartyAdminPinSettings?.message, { variant: 'error' });
        }
      }

      const status = result?.data?.updateRelyingPartyAdminProfile?.status;
      const contactStatus = contactResult?.data?.updateRelyingPartyAdminContact?.status;

      if (!status) {
        enqueueSnackbar('Unable to edit Profile', { variant: 'error' });
      } else if (!contactStatus) {
        enqueueSnackbar('Unable to edit contact status', { variant: 'error' });
      } else if (status && status !== 'Success') {
        enqueueSnackbar(result?.data?.updateRelyingPartyAdminProfile?.message, { variant: 'error' });
      } else if (contactStatus && contactStatus !== 'Success') {
        enqueueSnackbar(contactResult?.data?.updateRelyingPartyAdminContact?.message, { variant: 'error' });
      } else {
        enqueueSnackbar('Profile updated', { variant: 'success' });
        hideDialog();
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar('Unable to edit Profile', { variant: 'error' });
    }
  };

  const getRelyingPartyAdminById = async (id: string | undefined) => {
    try {
      await relyingPartyAdminByIdQuery({
        variables: {
          relyingPartyAdminId: id
        }
      });
      await relyingPartyAdminSecuritySettingsQuery({
        variables: {
          relyingPartyAdminId: id
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getRelyingPartyRpmReviewer = async () => {
    try {
      await relyingPartyRpmReviewerQuery();
    } catch (error) {
      console.error(error);
    }
  };

  const relyingPartyAdminSettings = useMemo(() => getValues('settings') ?? '{}', [settings]);

  const filteredDialpadPhoneNumbers = useMemo(() => {
    const filteredDilpadData = dialpadUserData?.filter(dialpadData => dialpadData.dialpadUserId === externalDialpadId) ?? [];
    return filteredDilpadData;
  }, [dialpadUserData, externalDialpadId]);

  const isPinPromptTimeoutSecondsDirty = (timeoutSeconds: Moment | undefined) => {
    return Boolean(
      timeoutSeconds !== undefined &&
        dateToSeconds(timeoutSeconds) !==
          relyingPartyAdminSecuritySettingsData?.relyingPartyAdminSecuritySettings?.inactivityPinPromptTimeoutSeconds
    );
  };

  const isSecurityPinDirty = (securityPin: string | undefined) => {
    return Boolean(securityPin && securityPin !== DEFAULT_PIN_TEXT && securityPin !== '');
  };

  const handleDialpadUsersData = (data: DialpadUsersResponse) => {
    if (data?.getDialpadUsers) {
      return setDialpadUserData(data.getDialpadUsers);
    }
    return setDialpadUserData([]);
  };

  const handleRequestPinChange = useCallback(() => {
    if (editSecurityPin) {
      setEditSecurityPin(false);
      if (isSecurityPinSet) {
        setValue('securityPin', DEFAULT_PIN_TEXT);
      } else {
        setValue('securityPin', '');
      }
    } else {
      setEditSecurityPin(true);
      setValue('securityPin', '');
    }
  }, [isSecurityPinSet, setValue, editSecurityPin]);

  const [uploadAvatar] = useMutation<UploadAvatarResponse>(UPLOAD_AVATAR);

  const handleUpload = async (file: File) => {
    try {
      const { data } = await uploadAvatar({
        variables: {
          file: file,
          relyingPartyAdminId: rpId
        }
      });
      if (data?.uploadAvatar) {
        setValue('presignedAvatarUrl', data.uploadAvatar.presignedUrl);
      }
    } catch (err) {
      console.error('Upload failed:', err);
      enqueueSnackbar('Could not upload logo.', { variant: 'error' });
    }
  };

  const handleUploadFailure = err => {
    console.error('File retrieval failed:', err);
    enqueueSnackbar('Could not retrieve file.', { variant: 'error' });
  };

  useEffect(() => {
    if (relyingPartyAdminData) {
      try {
        const data = relyingPartyAdminData.relyingPartyAdminById;

        const profileData: ProfileDialogFormValues = {
          firstName: data.user.firstName,
          lastName: data.user.lastName,
          email: data.user.email,
          title: data.title,
          isObserver: data.isObserver,
          isRpmPrimary: data.isRpmPrimary,
          businessName: data.businessName,
          addressLine1: data.addressLine1,
          addressLine2: data.addressLine2,
          addressState: data.addressState,
          addressCity: data.addressCity,
          addressZipcode: data.addressZipcode,
          phone: data.phone,
          alternateEmail: data.alternateEmail,
          npiNumber: data.npiNumber,
          techLicense: data.techLicense,
          bankName: data.bankName,
          bankRoutingNumber: data.bankRoutingNumber,
          bankAccountNumber: data.bankAccountNumber,
          hourlyRate: data.hourlyRate,
          supervisorEmail: data.supervisorEmail,
          externalDialpadId: data.externalDialpadId,
          settings: data.settings ? JSON.stringify(JSON.parse(data.settings), null, 2) : '',
          rpAdminPhone: data.contactsRelyingPartyAdmins?.find(c => c.contactType === 'Telephone')?.value,
          rpAdminFax: data.contactsRelyingPartyAdmins?.find(c => c.contactType === 'Fax')?.value,
          presignedAvatarUrl: data.presignedAvatarUrl,
          roleType: convertRoleToRoleType(data.roleType)
        };

        reset(profileData);
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Unable to retrieve profile data', { variant: 'error' });
        hideDialog();
      }
    }
  }, [relyingPartyAdminData, reset]);

  useEffect(() => {
    if (relyingPartyAdminSecuritySettingsData) {
      const data = relyingPartyAdminSecuritySettingsData?.relyingPartyAdminSecuritySettings;

      if (data?.isSecurityPinSet) {
        setIsSecurityPinSet(true);
        setValue('securityPin', DEFAULT_PIN_TEXT);
      } else {
        setIsSecurityPinSet(false);
        setValue('securityPin', '');
      }
      if (data?.inactivityPinPromptTimeoutSeconds) {
        setValue(
          'inactivityPinPromptTimeoutSeconds',
          data.inactivityPinPromptTimeoutSeconds === 0 ? undefined : secondsToDate(data.inactivityPinPromptTimeoutSeconds)
        );
      } else {
        setValue('inactivityPinPromptTimeoutSeconds', undefined);
      }

      if (data?.inactivityTimeoutSeconds) {
        setValue(
          'inactivityTimeoutSeconds',
          data.inactivityTimeoutSeconds === 0 ? undefined : secondsToDate(data.inactivityTimeoutSeconds)
        );
      } else {
        setValue('inactivityTimeoutSeconds', undefined);
      }
    }
  }, [relyingPartyAdminSecuritySettingsData, reset]);

  useEffect(() => {
    if (relyingPartyRpmReviewerCalled && !relyingPartyRpmReviewerLoading && relyingPartyRpmReviewerData) {
      setRpmReviewer(relyingPartyRpmReviewerData?.getRpmReviewer?.id);
    }
  }, [relyingPartyRpmReviewerCalled, relyingPartyRpmReviewerLoading, relyingPartyRpmReviewerData]);

  useEffect(() => {
    getRelyingPartyRpmReviewer();
  }, [currentUser, pharmacistInfo]);

  const updateIsRpmReviewerDisabled = useCallback(() => {
    if (rpmReviewer !== undefined) {
      if (!pharmacistInfo?.id) {
        setIsRpmReviewerDisabled(currentUser?.id === rpmReviewer ? false : true);
      } else {
        setIsRpmReviewerDisabled(pharmacistInfo?.id === rpmReviewer ? false : true);
      }
    } else {
      setIsRpmReviewerDisabled(false);
    }
  }, [currentUser, pharmacistInfo, rpmReviewer, relyingPartyRpmReviewerData]);

  const [rpId, setRpId] = useState('');

  useEffect(() => {
    if (!pharmacistInfo?.id) {
      setRpId(currentUser!.id);
    } else {
      setRpId(pharmacistInfo?.id);
    }
  }, [currentUser, pharmacistInfo, setValue]);

  useEffect(() => {
    if (rpId) {
      getRelyingPartyAdminById(rpId);
      getDialpadUsers({
        variables: {
          relyingPartyAdminId: rpId
        }
      });
    }
  }, [rpId]);

  useEffect(() => {
    updateIsRpmReviewerDisabled();
  }, [updateIsRpmReviewerDisabled]);

  useEffect(() => {
    if (title && Object.values<string>(Titles).includes(title)) {
      setValue('roleType', getRoleTypeUsingTitle(title));
    }
  }, [setValue, title]);

  const handleTitleChange = (event: SelectChangeEvent<unknown>) => {
    const { value } = event.target;
    if (value === Titles.CARE_OBSERVER) {
      return setValue('isObserver', true);
    }
    return setValue('isObserver', false);
  };

  return (
    <BaseDialog {...props} hideDialog={hideDialog} fullWidth maxWidth={isMTMViewable ? 'lg' : 'sm'}>
      <DialogContent>
        <Stack component={'form'} spacing={1} onSubmit={handleSubmit(onSubmit)}>
          <MuiTabs
            customPadding
            tabs={[
              {
                label: 'Profile',
                children: (
                  <Stack direction={'column'} spacing={2}>
                    <Alert
                      description={
                        isCurrentUsersEditModal
                          ? 'You are an Observer. Your activities will not be included for billing.'
                          : 'This user is an Observer. Activities of this user will not be included for billing.'
                      }
                      status="info"
                      hidden={!isObserver}
                    />
                    <Stack direction={'row'} justifyContent={'space-between'}>
                      <ProfileAvatarWithUpload
                        avatarUrl={avatarUrl}
                        name={firstName + ' ' + lastName}
                        onUploadSuccess={handleUpload}
                        onUploadFailure={handleUploadFailure}
                        modalTitle="Upload Profile Image"
                      />
                      <Stack direction={'row'} spacing={1}>
                        <Controller
                          control={control}
                          name="isRpmPrimary"
                          render={({ field: { onChange, value } }) => (
                            <FormControlLabel
                              control={<Switch checked={value} onChange={onChange} disabled={isRpmReviewerDisabled} />}
                              label="RPM Primary"
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name="isObserver"
                          render={({ field: { onChange, value } }) => (
                            <FormControlLabel
                              control={<Switch checked={value} onChange={onChange} />}
                              label="Observer"
                              disabled={title === Titles.CARE_OBSERVER}
                            />
                          )}
                        />
                      </Stack>
                    </Stack>

                    <Stack spacing={2}>
                      <Controller
                        control={control}
                        name="firstName"
                        render={({ field: { onChange, value } }) => (
                          <TruentityTextField shrink={true} onChange={onChange} value={value} label={'First Name'} required />
                        )}
                      />

                      <Controller
                        control={control}
                        name="lastName"
                        render={({ field: { onChange, value } }) => (
                          <TruentityTextField shrink={true} onChange={onChange} value={value} label={'Last Name'} required />
                        )}
                      />

                      <Controller
                        control={control}
                        name="email"
                        render={({ field: { onChange, value } }) => (
                          <TruentityTextField shrink={true} onChange={onChange} value={value} label={'Email'} required />
                        )}
                      />

                      <Controller
                        control={control}
                        name="title"
                        render={({ field: { onChange, value } }) => {
                          if (isNotTitleEditable && isCurrentUsersEditModal)
                            return (
                              <TruentityTextField shrink={true} disabled onChange={onChange} value={getTitleLabel(value)} label={'Title'} />
                            );
                          return (
                            <SelectList
                              required
                              options={getCurrentAdminsUsableTitles(currentUser?.roleType, isSelfProfileEditing)}
                              placeholder="Select an option..."
                              onChange={event => {
                                onChange(event);
                                handleTitleChange(event);
                              }}
                              value={value ?? ''}
                              label={'Title'}
                            />
                          );
                        }}
                      />

                      <Controller
                        control={control}
                        name="npiNumber"
                        render={({ field: { onChange, value } }) => (
                          <TruentityTextField onChange={onChange} value={value} label={'NPI Number'} />
                        )}
                      />

                      <Controller
                        control={control}
                        name="techLicense"
                        render={({ field: { onChange, value } }) => (
                          <TruentityTextField onChange={onChange} value={value} label={'License Number'} />
                        )}
                      />

                      <Controller
                        control={control}
                        name="phone"
                        render={({ field: { onChange, value } }) => (
                          <TruentityPhoneNumber label={'Mobile Phone'} value={value} onChange={onChange} editable={true} required />
                        )}
                      />

                      <Controller
                        control={control}
                        name="rpAdminFax"
                        render={({ field: { onChange, value } }) => (
                          <TruentityPhoneNumber label={'Fax Number'} value={value} onChange={onChange} editable={true} />
                        )}
                      />
                    </Stack>
                  </Stack>
                )
              },
              {
                label: 'Settings',
                children: (
                  <UserSettingTab
                    control={control}
                    setValue={setValue}
                    watch={watch}
                    relyingPartyAdminSettings={relyingPartyAdminSettings}
                  />
                ),
                disabled: !(isCurrentUserSuperAdmin || isCurrentUserPsm)
              },
              {
                label: 'Billing',
                children: (
                  <Stack spacing={2}>
                    <H4>Billing</H4>

                    <Controller
                      control={control}
                      name="businessName"
                      render={({ field: { onChange, value } }) => (
                        <TruentityTextField onChange={onChange} value={value} label={'Business Name'} />
                      )}
                    />

                    <Controller
                      control={control}
                      name="addressLine1"
                      render={({ field: { onChange, value } }) => (
                        <TruentityTextField onChange={onChange} value={value} label={'Address Line 1'} />
                      )}
                    />

                    <Controller
                      control={control}
                      name="addressLine2"
                      render={({ field: { onChange, value } }) => (
                        <TruentityTextField onChange={onChange} value={value} label={'Address Line 2'} />
                      )}
                    />

                    <Controller
                      control={control}
                      name="addressCity"
                      render={({ field: { onChange, value } }) => <TruentityTextField onChange={onChange} value={value} label={'City'} />}
                    />

                    <Controller
                      control={control}
                      name="addressState"
                      render={({ field: { onChange, value } }) => <TruentityTextField onChange={onChange} value={value} label={'State'} />}
                    />

                    <Controller
                      control={control}
                      name="addressZipcode"
                      render={({ field: { onChange, value } }) => (
                        <TruentityTextField onChange={onChange} value={value} label={'Zipcode'} />
                      )}
                    />

                    <Controller
                      control={control}
                      name="phone"
                      render={({ field: { onChange, value } }) => (
                        <TruentityPhoneNumber value={value} onChange={onChange} editable={true} label={'Phone'} />
                      )}
                    />

                    <Controller
                      control={control}
                      name="alternateEmail"
                      render={({ field: { onChange, value } }) => <TruentityTextField onChange={onChange} value={value} label={'Email'} />}
                    />

                    <Controller
                      control={control}
                      name="hourlyRate"
                      render={({ field: { onChange, value } }) => (
                        <TruentityTextField onChange={onChange} value={value} label={'Hourly Rate'} disabled={isPharmacist} />
                      )}
                    />

                    <Controller
                      control={control}
                      name="supervisorEmail"
                      render={({ field: { onChange, value } }) => (
                        <TruentityTextField onChange={onChange} value={value} label={'Supervisor Email'} disabled={isPharmacist} />
                      )}
                    />

                    <Controller
                      control={control}
                      name="bankName"
                      render={({ field: { onChange, value } }) => (
                        <TruentityTextField onChange={onChange} value={value} label={'Bank Name'} />
                      )}
                    />

                    <Controller
                      control={control}
                      name="bankRoutingNumber"
                      render={({ field: { onChange, value } }) => (
                        <TruentityTextField onChange={onChange} value={value} label={'Bank Routing Number'} />
                      )}
                    />

                    <Controller
                      control={control}
                      name="bankAccountNumber"
                      render={({ field: { onChange, value } }) => (
                        <TruentityTextField onChange={onChange} value={value} label={'Bank Account Number'} />
                      )}
                    />
                  </Stack>
                ),
                disabled: !isMTMViewable
              },
              {
                label: 'Dialpad',
                children: (
                  <Stack spacing={2}>
                    <Controller
                      control={control}
                      name="externalDialpadId"
                      render={({ field: { onChange, value } }) => {
                        const currentValue = dialpadUserData.find(option => option.dialpadUserId === value) || null;
                        return (
                          <Autocomplete
                            id="dialpad-id-selector"
                            options={dialpadUserData ?? []}
                            disabled={dialpadUserData.length === 0 || isPharmacist}
                            value={currentValue}
                            onChange={(_event, selectedOption) => {
                              onChange(selectedOption?.dialpadUserId ?? '');
                              if (value !== selectedOption?.dialpadUserId) setValue('rpAdminPhone', '');
                            }}
                            isOptionEqualToValue={(option, value) => option.dialpadUserId === value?.dialpadUserId}
                            getOptionLabel={option => option.dialpadUserId}
                            getOptionDisabled={option => option.isAssigned}
                            renderOption={(props, option) => (
                              <Box component="li" {...props}>
                                {`${option.dialpadUserId} (${option.dialpadUserFirstName} ${option.dialpadUserLastName})`}
                              </Box>
                            )}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="Dialpad ID"
                                placeholder="select an option"
                                disabled={!(isCurrentUserSuperAdmin || isCurrentUserPsm)}
                              />
                            )}
                          />
                        );
                      }}
                    />

                    <Controller
                      control={control}
                      name="rpAdminPhone"
                      render={({ field: { onChange, value } }) => (
                        <FormControl>
                          <InputLabel id="dialpad-phone-label-id">Dialpad Phone Number</InputLabel>
                          <Select
                            labelId="dialpad-phone-label-id"
                            id="dialpad-phone-id"
                            value={value}
                            onChange={onChange}
                            input={<OutlinedInput label="Dialpad Phone Number" id="dialpad-phone-id" />}
                            renderValue={selected => <TruentityPhoneNumber value={selected} />}
                            disabled={filteredDialpadPhoneNumbers?.length === 0 || isPharmacist}
                          >
                            {filteredDialpadPhoneNumbers?.map(dialpadUser =>
                              dialpadUser?.dialpadUserPhoneNumbers?.map(dialpadNumber => (
                                <MenuItem disabled={dialpadUser?.isAssigned ?? false} key={dialpadUser.dialpadUserId} value={dialpadNumber}>
                                  {dialpadNumber}
                                </MenuItem>
                              ))
                            )}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Stack>
                )
              },
              {
                label: 'Security',
                children: (
                  <Stack flexDirection={'column'} spacing={2}>
                    <H3>Inactivity Settings</H3>
                    <Controller
                      control={control}
                      name="inactivityTimeoutSeconds"
                      render={({ field: { onChange, value } }) => (
                        <TimePicker
                          ampm={false}
                          views={['hours', 'minutes']}
                          format="HH:mm"
                          slotProps={{
                            textField: {
                              label: 'Inactivity Time (HH:mm)',
                              helperText: 'Time for inactivity logout'
                            }
                          }}
                          disabled={true}
                          defaultValue={secondsToDate(900)}
                          minTime={secondsToDate(300)}
                          maxTime={secondsToDate(3600)}
                          sx={{ flex: 1 }}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                    <Divider
                      sx={{
                        marginY: 2,
                        width: '100%'
                      }}
                    />
                    <Stack flexDirection="column" justifyContent="flex-start" alignItems="left">
                      <H3>Re-Authentication</H3>
                      <Caption textTransform="none">Settings for re-authentication after inactivity</Caption>
                    </Stack>
                    <Stack direction={'row'} alignItems={'center'}>
                      <Controller
                        control={control}
                        name="securityPin"
                        render={({ field: { onChange, value } }) => (
                          <TruentityTextField
                            onChange={onChange}
                            type="password"
                            value={value}
                            label={'Re-Authenticate with PIN'}
                            maxLength={6}
                            helperText={value?.length ? '' : '6 digit PIN'}
                            sx={{
                              flex: 1
                            }}
                            disabled={!editSecurityPin}
                          />
                        )}
                      />
                      <Button
                        sx={{
                          marginX: 2
                        }}
                        onClick={handleRequestPinChange}
                      >
                        {editSecurityPin ? 'Cancel' : 'Edit PIN'}
                      </Button>
                    </Stack>
                    <Controller
                      control={control}
                      name="inactivityPinPromptTimeoutSeconds"
                      render={({ field: { onChange, value } }) => (
                        <TimePicker
                          ampm={false}
                          views={['hours', 'minutes']}
                          format="HH:mm"
                          slotProps={{
                            textField: {
                              label: 'Re-Authentication Time (HH:mm)'
                            }
                          }}
                          maxTime={secondsToDate(3600)}
                          sx={{ flex: 1 }}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  </Stack>
                ),
                disabled: !(isCurrentUserSuperAdmin || isCurrentUserPsm)
              }
            ]}
            hideDisabled
          />
          <Stack direction="row" spacing={2} alignContent="center" justifyContent={'flex-end'}>
            <Button type="submit" a11yLabel="Save" appearance="primary" />
          </Stack>
        </Stack>
      </DialogContent>
    </BaseDialog>
  );
};

export default ProfileDialog;
