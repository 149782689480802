import type { JSONData } from '@/components/JsonKit/JsonEditor';
import type { Moment } from 'moment';
import type { TruentityDateInput } from './date';
import type { TcmTask } from './dischargePatient';
import type { AccountsMonitoringDeviceType, ProviderType } from './remotePatientMonitoring';

//Concerns
type UUIDType = {
  id: string;
};
type TimestampsType = {
  createdAt: TruentityDateInput;
  updatedAt: TruentityDateInput;
};

//GraphQL types based on definitions in backend project
type BaseType = UUIDType & TimestampsType;

//TODO:  Look at using this in other places
export type EncounterType = BaseType & {
  title?: string;
  type: string;
  subType: string;
  disposition?: string;
  modeOfResolution?: string;
  calledOn: TruentityDateInput;
  notes?: string;
  tasks?: TaskType[];
  relyingParty: RelyingParty;
  relyingPartyAdmin: RelyingPartyAdminType;
  account: AccountType;
  accountCareEventId?: string;
  originatingClientOrg?: ClientOrganizationType;
  originatingClientStore?: ClientStoreType;
};

export type RelyingPartyType = BaseType & {
  name: string;
  logo?: string;
  logoUrl?: string;
  orgHandle?: string;
  email?: string;
  admins: RelyingPartyAdminType[];
  requests: RequestType[];
  settings?: string;
  settingsValidationErrors?: string[];
};

export type RequestType = BaseType & {
  body: string;
  reply?: string;
  type: string;
  relyingParty: RelyingPartyType;
  account: AccountType;
};

export enum AdminRoleTypeEnum {
  SUPER_ADMIN = 'superadmin',
  PROVIDER_ADMIN = 'provideradmin',
  PHARMACIST = 'pharmacist',
  PHARMACIST_ADMIN = 'pharmacistadmin',
  ADMIN = 'admin',
  ADT_ADMIN = 'adtadmin'
}

enum LocaleEnum {
  US_ENGLISH = 'en-US'
}

export type DeviceType = BaseType & {
  lastIssued: string;
  lastIssuedAt: TruentityDateInput;
  expiresAt: number;
  userAgent: string;
  ip?: string;
  client: string;
  clientVersion: string;
  user: UserType;
};

export type UserType = BaseType & {
  email: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  locale: LocaleEnum;
  passwordStale?: boolean;
  devices: DeviceType[];
  provider?: ProviderType;
};

export type RelyingPartyAdminType = BaseType & {
  roleType: AdminRoleTypeEnum;
  devices: DeviceType[];
  contactsRelyingPartyAdmins: [RelyingPartyAdminContact];
  user: UserType;
  lastSession?: RelyingPartyAdminSessionType;
  minutesToday?: string;
  minutesThisWeek?: string;
  minutesThisYear?: string;
  activityToday?: string;
  activityThisWeek?: string;
  activityThisYear?: string;
  npiNumber?: string;
  externalDialpadId?: string;
  techLicense?: string;
  firstName?: string;
  lastName?: string;
  email: string;
  title?: string;
  businessName?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressState?: string;
  addressZipcode?: string;
  addressCity?: string;
  phone?: string;
  alternateEmail?: string;
  hourlyRate?: number | null;
  supervisorEmail?: string;
  bankName?: string;
  bankRoutingNumber?: string;
  bankAccountNumber?: string;
  settings?: string;
  rpAdminPhone?: string;
  rpAdminFax?: string;
  isObserver?: boolean;
  isRpmPrimary?: boolean;
  securityPin?: string;
  inactivityTimeoutSeconds?: Moment;
  inactivityPinPromptTimeoutSeconds?: Moment;
  presignedAvatarUrl?: string;
};

export type RelyingPartyAdminSessionType = BaseType & {
  startedAt: TruentityDateInput;
  endedAt?: TruentityDateInput;
  durationInSecs?: number;
  timedOut?: boolean;
  device?: DeviceType;
};

export type ClientOrganizationType = BaseType & {
  name?: string;
  createdAt?: string;
  addresses?: AddressType[];
  contacts?: ContactType[];
  accountsCount?: number;
  accounts?: AccountType[];
  clientStoresCount?: number;
  clientStores?: ClientStoreType[];
  tasksCount?: number;
  tasks?: TaskType[];
  settings?: JSONData;
};

export type AddressType = {
  city?: string;
  state?: string;
  code?: string;
  line1?: string;
};

export type ContactType = {
  type?: string;
  value?: string;
};

export type ClientStoreType = BaseType & {
  name?: string;
  nameTag?: string;
  addresses?: AddressType[];
  contacts?: ContactType[];
  accounts?: AccountType[];
  clientOrg?: ClientOrganizationType;
  tasks?: TaskType[];
  isPrimary?: boolean;
  settings?: JSONData;
};

export type AccountType = BaseType & {
  truentityId?: string;
  data: string;
  setting: string;
  address?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressState?: string;
  addressCity?: string;
  zipcode?: string;
  consent?: string;
  clientOrgs?: ClientOrganizationType[];
  currentRelyingPartyClientOrgs?: ClientOrganizationType[];
  clientStores?: ClientStoreType[];
  currentRelyingPartyClientStores?: ClientStoreType[];
  phone?: string;
  gender?: string;
  rpmStatus?: string;
  rpmEnrolledAt?: string;
  accountsMonitoringDevices?: AccountsMonitoringDeviceType[];
  birthDate?: TruentityDateInput;
  emergencyContactName?: string;
  emergencyContactPhone?: string;
  emergencyContactEmail?: string;
  doNotCall: boolean;
  doNotCallLastSpecifiedAt?: TruentityDateInput;
  nextCallSuggestedAt?: TruentityDateInput;
  primaryLanguage: string;
  user: UserType;
  devices: DeviceType;
  pendingRequests: RequestType;
  currentMedsCount?: number;
  lastMedRecDate?: TruentityDateInput;
  numQuickUploads?: number;
  numQuickUploadsNew?: number;
  lastImport?: LastImportType;
  accountsAssignments?: AccountAssignmentType[];
  currentAccountsAssignments?: AccountAssignmentType[];
  tasks?: TaskType[];
  numPendingTasks?: number;
  numCompletedTasks?: number;
  lastEncounterDate?: TruentityDateInput;
  healthPlan?: {
    id: string;
    orgName: string;
  };
  rpmApprovalStatus?: string;
  accountSummary?: {
    isTelehealthVisitLaunched: boolean;
    signOffAt: string | null;
  };
  criticalAlertCount?: number | null;
};

export type LastImportType = BaseType & {
  status?: string;
  lastImportDate?: TruentityDateInput;
};

export type AccountAssignmentType = BaseType & {
  assignedAt?: TruentityDateInput;
  relyingPartyAdmin?: RelyingPartyAdminType;
};

export type TaskType = BaseType & {
  name: string;
  type: string;
  subType: string;
  status: string;
  resolutionStatus: string;
  resolutionNotes: string;
  performedOn: TruentityDateInput;
  completedByAdmin: string;
  relyingParty: RelyingParty;
  relyingPartyAdmin: RelyingPartyAdminType;
  relyingPartyAdmins: RelyingPartyAdminType[];
  account: AccountType;
  encounters: EncounterType[];
  notes: string;
  billedAmount: number;
  healthPlan: string;
  location: string;
  nextSteps: string;
  medicationName: string;
  modeOfResolution: string;
  wrapUpStatus: string;
  isPrimaryAffiliation: number;
  priority: string;
  bonusAmount: number;
};

export interface RelyingParty {
  id: string;
  name: string;
  email: string;
  phone?: string;
  logoUrl: string;
  logo?: string;
  coverLetterBody?: string;
  orgHandle: string;
  addressLine1?: string;
  addressLine2?: string;
  addressCity?: string;
  addressState?: string;
  settings?: string;
  medicalServices: [MedicalServiceType];
  isCtaAccepted: boolean;
  ctaAcceptedOn: string;
  ctaAcceptedInfo: string;
  ctaRejectedOn: string;
  ctaAcceptedByAdmin: any;
  primaryProvider: ProviderType;
  timezone?: string;
}

export interface RelyingPartyAdminContact {
  id: string;
  contactType: string;
  value: string;
}

interface User {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface LoggedUser {
  id: string;
  title: string;
  relyingParty: RelyingParty;
  contactsRelyingPartyAdmins: [RelyingPartyAdminContact];
  roleType: string;
  rpAdminPhone: string;
  rpAdminFax: string;
  businessName: string;
  addressLine1: string;
  addressLine2: string;
  addressCity: string;
  addressState: string;
  addressZipcode: string;
  phone: string;
  alternateEmail: string;
  hourlyRate: number;
  supervisorEmail: string;
  bankName: string;
  bankRoutingNumber: string;
  bankAccountNumber: string;
  npiNumber: string;
  externalDialpadId: string;
  techLicense: string;
  settings?: string;
  presignedAvatarUrl?: string;
  user: User;
  hasMultipleRelyingParties?: boolean;
}

export interface LoggedUserAccountData {
  name: string;
  logo: string;
  orgHandle: string;
  settings: string;
  isCtaAccepted: boolean;
}

export interface MedicalServiceType {
  name: string;
  serviceName: string;
  shortName: string;
  text: string;
  isSameOrg: boolean;
  isOfferOrg: boolean;
  isEnrolled: boolean;
}

export type ActivityLogType = BaseType & {
  logText: string;
  activityType: string;
  activityTime: Date;
  activityEndTime?: Date;
  timeTakenSecs?: number;
  data: string;
  tcmTask?: TcmTask; //TODO:  This should be added as a graphql Type
  relyingParty: RelyingPartyType;
  account?: AccountType;
  relyingPartyAdmin?: RelyingPartyAdminType;
};

export type FileMonitoringType = {
  id: string;
  fileName: string;
  numRecords: number;
  logText: string;
  sftpEndpoint: string;
  status: string;
  createdAt: string;
};

export type RelyingPartyPerformanceReportType = BaseType & {
  date: string;
  activeTime?: string;
  relyingPartyAdminName?: string;
  billedAmount?: number;
  bonusAmount?: number;
  numOfEncounters?: number;
  invoicedTime?: string;
  invoicedAmount?: number;
};

export type GeneralUpdateResponseType = {
  status: string;
  message: string;
};
