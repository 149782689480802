import MuiAccordion from '@/components/MuiAccordion';
import { H3 } from '@/components/Typography';
import type { RelyingParty } from '@/types/graphql';
import { formatDate } from '@/util/format';
import { Box, Stack, useTheme } from '@mui/material';
import type React from 'react';

const TermsOfAgreementTab = ({ documentData }: { documentData: RelyingParty }) => {
  return (
    <Stack spacing={1}>
      {documentData?.isCtaAccepted ? (
        <Stack>
          <H3 style={{ fontWeight: 700, fontSize: '14px', lineHeight: '21px' }}>
            Accepted On: <span style={{ fontWeight: 400 }}>{`${formatDate(documentData?.ctaAcceptedOn)}`}</span>
          </H3>
          <H3 style={{ fontWeight: 700, fontSize: '14px', lineHeight: '21px' }}>
            Accepted By: <span style={{ fontWeight: 400 }}>{`${documentData?.ctaAcceptedByAdmin?.name ?? ''}`}</span>
          </H3>
        </Stack>
      ) : (
        documentData?.ctaRejectedOn !== null && (
          <H3 style={{ fontWeight: 700, fontSize: '14px', lineHeight: '21px' }}>
            Rejected On: <span style={{ fontWeight: 400 }}>{`${formatDate(documentData?.ctaRejectedOn)}`}</span>
          </H3>
        )
      )}
    </Stack>
  );
};

const DocumentsSection: React.FC<{ documentData: RelyingParty }> = ({ documentData }) => {
  const theme = useTheme();

  return (
    <Stack mt={6}>
      <Box>
        <MuiAccordion
          options={[
            {
              label: 'Terms of Agreement',
              defaultExpand: false,
              content: <TermsOfAgreementTab documentData={documentData} />
            }
          ]}
          sx={{
            width: '100%',
            height: '100%',
            backgroundColor: theme.palette.background.default,
            '& .MuiAccordionDetails-root': {
              backgroundColor: theme.palette.background.default
            }
          }}
        />
      </Box>
    </Stack>
  );
};

export default DocumentsSection;
