import { MessageState as StateType, type MessageType } from '@/types/coPilot';
import { create } from 'zustand';

type MessageState = {
  messages: MessageType[];
  addMessage: (message: MessageType) => void;
  appendOrCreateMessage: (incomingMessage: MessageType) => void;
  loaderMessage: MessageType | null;
  setLoaderMessage: (message: MessageType | null) => void;
  clearMessages: () => void;
  isPoppedToNewWindow: boolean;
  setIsPoppedToNewWindow: (value: boolean) => void;
  popupWindow: Window | null;
  setPopupWindow: (value: Window | null) => void;
};

export const useCopilotMessageStore = create<MessageState>(set => ({
  messages: [],
  addMessage: message =>
    set(state => ({
      messages: [...state.messages, message]
    })),
  appendOrCreateMessage: incomingMessage =>
    set(state => {
      const existingMessageIndex = state.messages.findIndex(msg => msg.id === incomingMessage.id);

      if (existingMessageIndex !== -1) {
        const updatedMessages = [...state.messages];
        const existingMessage = updatedMessages[existingMessageIndex];

        updatedMessages[existingMessageIndex] = {
          ...existingMessage,
          ...(incomingMessage.type === StateType.FAILED ? { type: incomingMessage.type } : {}),
          isCompleted: incomingMessage.isCompleted,
          body: (existingMessage.body || '') + (incomingMessage.body || '')
        };

        return { messages: updatedMessages };
      } else {
        return { messages: [...state.messages, incomingMessage] };
      }
    }),
  loaderMessage: null,
  setLoaderMessage: message =>
    set(() => ({
      loaderMessage: message
    })),
  clearMessages: () => set({ messages: [] }),
  isPoppedToNewWindow: false,
  setIsPoppedToNewWindow: (value: boolean) => set({ isPoppedToNewWindow: value }),
  popupWindow: null,
  setPopupWindow: (value: Window | null) => set({ popupWindow: value })
}));
