import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import FollowUpTaskInfoDialog from '@/components/Dialogs/FollowUpTaskInfoDialog';
import type { TabContent } from '@/components/MuiTabs';
import type { TextSelectOption } from '@/components/SelectList';
import SelectList from '@/components/SelectList';
import MainSideMenu from '@/components/SideMenus/MainSideMenu';
import { H1 } from '@/components/Typography';
import SideMenuContext from '@/context/sideMenuContext';
import type { GetFilteredRelyingPartyAdminsResponse, UpdateAccountFollowupResponse } from '@/graphql/account';
import {
  FollowUpCategoryTypes,
  FollowUpStatusTypes,
  FollowUpTaskTypes,
  GET_FILTERED_RELYING_PARTY_ADMINS,
  UPDATE_ACCOUNT_FOLLOWUP
} from '@/graphql/account';
import type { RelyingPartyAdminFollowUpResponse } from '@/graphql/remotePatientMonitoring';
import { CURRENT_RELYING_PARTY_FOLLOWUPS } from '@/graphql/remotePatientMonitoring';
import type { FollowUpRemindersTypes } from '@/types/accountProfile';
import { MedicalServices, Role } from '@/types/admin';
import { getAccountUserFullName } from '@/util/account';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { followUpTaskTypes, followupTypeHandler, followUpTypes } from '@/util/followUp';
import { formatDateAndTime } from '@/util/format';
import { getAlertTypeStyles, showFollowupModal } from '@/util/rpm';
import { formatFromSnakeCase, unknown } from '@/util/string';
import { useAccountStore } from '@/zustand/AccountStore';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { ErrorOutlineRounded } from '@mui/icons-material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import RepeatIcon from '@mui/icons-material/Repeat';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Chip, IconButton, Paper, Stack, Tab, Tabs, Tooltip } from '@mui/material';
import type { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import type { SyntheticEvent } from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import type { RelyingPartyAdminData } from '../../Administration';

const followUpTasksTabs: TabContent[] = [
  {
    label: FollowUpStatusTypes.PENDING,
    key: FollowUpStatusTypes.PENDING,
    icon: <PauseCircleOutlineIcon />
  },
  {
    label: FollowUpStatusTypes.COMPLETED,
    key: FollowUpStatusTypes.COMPLETED,
    icon: <CheckCircleIcon />
  }
];

const columnVisibility: Record<FollowUpStatusTypes, Record<string, boolean>> = {
  PENDING: {
    actions: true
  },
  COMPLETED: {
    actions: false
  }
};

const FollowUpsTask = () => {
  const { showModal } = useModal();
  const navigate = useNavigate();
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const { enqueueSnackbar } = useSnackbar();
  const { setPatientReviewWorkflowMonthYear } = useAccountStore();

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [activeTab, setActiveTab] = useState<TabContent>(followUpTasksTabs[activeTabIndex]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(columnVisibility.PENDING);
  const [selectedAssignedAdmin, setSelectedAssignedAdmin] = useState<string>('All');
  const [relyingPartyAdmins, setRelyingPartyAdmins] = useState<RelyingPartyAdminData[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('All');
  const [selectedType, setSelectedType] = useState<string>('All');

  const { setContent, setDrawerOpen } = useContext(SideMenuContext);

  const {
    data: currentRelyingPartyFollowUps,
    loading: isCurrentRelyingPartyFollowUpsLoading,
    refetch: refetchCurrentRelyingPartyFollowUp
  } = useQuery<RelyingPartyAdminFollowUpResponse>(CURRENT_RELYING_PARTY_FOLLOWUPS, {
    variables: {
      pageNum: currentPage + 1,
      pageSize: DEFAULT_PAGE_SIZE,
      filterOptions: {
        relyingPartyAdminId: selectedAssignedAdmin,
        status: activeTab?.key || FollowUpStatusTypes.PENDING,
        category:
          selectedCategory.toLocaleLowerCase() === 'all'
            ? [FollowUpCategoryTypes.ALERT_REVIEW, FollowUpCategoryTypes.CHART_REVIEW, FollowUpCategoryTypes.GENERAL]
            : [selectedCategory.toUpperCase()],
        ...(selectedType.toLowerCase() !== 'all' && { type: selectedType })
      }
    },
    fetchPolicy: 'cache-and-network'
  });

  useQuery<GetFilteredRelyingPartyAdminsResponse>(GET_FILTERED_RELYING_PARTY_ADMINS, {
    variables: {
      pageNum: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      excludedRoles: [Role.SUPER]
    },
    onCompleted: data => {
      setRelyingPartyAdmins(data?.filteredRelyingPartyAdmins?.relyingPartyAdmins || []);
    }
  });

  const [updateAccountFollowUp] = useMutation<UpdateAccountFollowupResponse>(UPDATE_ACCOUNT_FOLLOWUP, {
    onCompleted: data => {
      const status = data?.updateAccountFollowup?.status === 'Success' ? 'success' : 'error';
      const message = data?.updateAccountFollowup?.message || 'Unexpected error occurred while updating a follow-up task';
      enqueueSnackbar(message, { variant: status });
      refetchCurrentRelyingPartyFollowUp();
    }
  });

  const handleTabChange = (_event: SyntheticEvent, selectedTabIndex: number) => {
    const activeTab = followUpTasksTabs[selectedTabIndex];

    setActiveTabIndex(selectedTabIndex);
    setActiveTab(activeTab);

    if (!activeTab.key) return;
    setColumnVisibilityModel(columnVisibility[activeTab.key]);
  };

  const handleCheckButton = useCallback(
    (data: FollowUpRemindersTypes) => {
      const { hide } = showModal(FollowUpTaskInfoDialog, {
        title: 'Follow-up Task Information',
        hideDialog: () => hide(),
        followUpId: data.id,
        onCompleted: () => {
          hide();
          refetchCurrentRelyingPartyFollowUp();
        }
      });
    },
    [refetchCurrentRelyingPartyFollowUp, showModal]
  );

  const handleEditButton = useCallback(
    async (followUpData: FollowUpRemindersTypes) => {
      showFollowupModal({
        followUp: followUpData,
        showModal: showModal,
        category: followUpData.category,
        truentityId: followUpData.account.truentityId,
        rpmStatus: followUpData.account.rpmStatus,
        doNotCall: followUpData.account.doNotCall,
        accountAssignments: relyingPartyAdmins.map(relyingPartyAdmin => ({
          relyingPartyAdmin
        })),
        onComplete: () => refetchCurrentRelyingPartyFollowUp()
      });
    },
    [showModal, relyingPartyAdmins, refetchCurrentRelyingPartyFollowUp]
  );

  const handleAssignSelfButton = useCallback(
    (followUpData: FollowUpRemindersTypes) => {
      const currentAssigneeName = getAccountUserFullName(followUpData.relyingPartyAdmin.user);

      const modal = showModal(ConfirmDialog, {
        title: 'Confirm Task Reassignment',
        message: `This task is currently assigned to <b>${currentAssigneeName}</b>. Would you like to reassign it to yourself?`,
        onAgree: () => {
          updateAccountFollowUp({
            variables: {
              followUpIds: followUpData.id,
              followUpAssigneeIds: currentUser?.id
            }
          }).finally(() => modal.hide());
        },
        onDisagree: () => modal.hide()
      });
    },
    [currentUser?.id, showModal, updateAccountFollowUp]
  );

  const renderAlertLabelChip = useCallback((value: string) => {
    const alertTypeStyles = getAlertTypeStyles(value);

    if (alertTypeStyles?.labelColor) {
      return (
        <Chip
          icon={
            <ErrorOutlineRounded
              sx={{
                color: `${alertTypeStyles?.labelColor} !important`
              }}
            />
          }
          variant="outlined"
          label={alertTypeStyles?.labelText}
          sx={{
            borderColor: alertTypeStyles?.labelColor,
            color: alertTypeStyles?.labelColor
          }}
        />
      );
    }
  }, []);

  const isTaskEditable = useCallback(
    (id: string) => {
      return id === currentUser?.id;
    },
    [currentUser?.id]
  );

  const taskTypeOptions = useCallback((category: string) => {
    const taskTypes = followUpTaskTypes(category.toUpperCase() as FollowUpCategoryTypes | 'ALL').filter(
      element => element.label !== FollowUpTaskTypes.NO_INTERVENTION_REQUIRED
    );

    const options: Record<string, TextSelectOption[]> = {
      ALL: [...followUpTypes(undefined, undefined, undefined, [MedicalServices.RPM]), ...taskTypes],
      [FollowUpCategoryTypes.GENERAL]: followUpTypes(undefined, undefined, undefined, [MedicalServices.RPM]),
      [FollowUpCategoryTypes.ALERT_REVIEW]: taskTypes,
      [FollowUpCategoryTypes.CHART_REVIEW]: taskTypes
    };

    const selectedOptions = options[category.toUpperCase()] ?? [];
    selectedOptions.unshift({ label: 'All', value: 'all' });

    return selectedOptions;
  }, []);

  const assignedOptions: TextSelectOption[] = useMemo(() => {
    const options = relyingPartyAdmins.map(admin => ({ label: admin.name, value: admin.id }));

    if (currentUser?.roleType && [Role.SUPER, Role.ADMIN].includes(currentUser?.roleType as Role)) {
      options.unshift({ label: getAccountUserFullName(currentUser.user), value: currentUser.id });
    }

    options.unshift({ label: 'All', value: 'all' });

    return options;
  }, [currentUser?.id, currentUser?.roleType, currentUser?.user, relyingPartyAdmins]);

  const taskCategories: TextSelectOption[] = useMemo(() => {
    const options = Object.values(FollowUpCategoryTypes).map(category => ({
      label: formatFromSnakeCase(category) as string,
      value: category as string
    }));

    options.unshift({ label: 'All', value: 'all' });
    return options;
  }, []);

  const getRedirectList = (category: FollowUpCategoryTypes, truentityId: string | undefined) => {
    if (!truentityId) return '#';

    if (category === FollowUpCategoryTypes.CHART_REVIEW) return `/patients/${truentityId}/details/rpm/reports`;

    return `/patients/${truentityId}/details/rpm/patient-setup/encounter/consent-management`;
  };

  const columns: GridColDef<FollowUpRemindersTypes>[] = useMemo(
    () => [
      {
        field: 'patientName',
        headerName: 'Patient Name',
        sortable: true,
        flex: 1,
        align: 'left',
        valueGetter: params => getAccountUserFullName(params.row?.account?.user),
        renderCell: ({ row: followUp, value }) => (
          <Link
            to={getRedirectList(followUp.category, followUp.account?.truentityId)}
            onClick={() =>
              followUp.category === FollowUpCategoryTypes.CHART_REVIEW &&
              setPatientReviewWorkflowMonthYear(followUp.rpmAccountReviewNote?.rpmAccountReviewWorkflow?.rpmReport?.monthYear ?? null)
            }
          >
            {value}
          </Link>
        )
      },
      {
        field: 'Task Type',
        headerName: 'Task Type',
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => followupTypeHandler(params.row)
      },
      {
        field: 'category',
        headerName: 'Category',
        sortable: false,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => formatFromSnakeCase(params.value)
      },
      {
        field: 'label',
        headerName: 'Alert Type',
        sortable: false,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => params.row.rpmAlert?.label || unknown(),
        renderCell: params => renderAlertLabelChip(params.value)
      },
      {
        field: 'taskDateAndTime',
        headerName: 'Task Due at',
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => formatDateAndTime(params.row.followUpOn)
      },
      {
        field: 'assignedTo',
        headerName: 'Assigned To',
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => getAccountUserFullName(params.row?.relyingPartyAdmin?.user)
      },
      {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => {
          const isEditDisabled = !isTaskEditable(params.row.relyingPartyAdmin.id);

          const editTooltipText = 'Edit Follow-up';
          const completeTooltipText = isEditDisabled
            ? `You cannot mark this follow-up as completed, because it is assigned to another assignee`
            : 'Complete Follow-up';
          const assignSelfTooltipText = !isEditDisabled ? 'This task is already assigned to you' : 'Assign to yourself';

          return (
            <Stack direction="row">
              <Tooltip title={assignSelfTooltipText}>
                <span>
                  <IconButton onClick={() => handleAssignSelfButton(params.row)} disabled={!isEditDisabled}>
                    <RepeatIcon color={isEditDisabled ? 'info' : 'disabled'} />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={completeTooltipText}>
                <span>
                  <IconButton onClick={() => handleCheckButton(params.row)} disabled={isEditDisabled}>
                    <TaskAltIcon color={!isEditDisabled ? 'info' : 'disabled'} />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={editTooltipText}>
                <span>
                  <IconButton onClick={() => handleEditButton(params.row)}>
                    <ModeEditIcon fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          );
        }
      }
    ],
    [handleAssignSelfButton, handleCheckButton, handleEditButton, isTaskEditable, renderAlertLabelChip, setPatientReviewWorkflowMonthYear]
  );

  useEffect(() => {
    setContent(<MainSideMenu />);
  }, [setContent, setDrawerOpen]);

  return (
    <Stack spacing={1}>
      <Stack
        component={Paper}
        spacing={2}
        direction="row"
        alignItems="center"
        elevation={0}
        sx={{
          padding: 3,
          borderRadius: '8px'
        }}
      >
        <H1
          sx={{
            fontSize: '24px',
            fontWeight: 600,
            lineHeight: '32px',
            flex: 1,
            textAlign: 'left'
          }}
        >
          Follow-up Tasks
        </H1>
        <SelectList
          label="Assigned To"
          options={assignedOptions}
          placeholder="Select an option..."
          value={selectedAssignedAdmin}
          formControlProps={{ sx: { minWidth: '200px' } }}
          fullWidth={false}
          onChange={event => setSelectedAssignedAdmin(event.target.value as string)}
        />
        <SelectList
          label="Task Type"
          options={taskTypeOptions(selectedCategory)}
          placeholder="Select an option..."
          value={selectedType}
          formControlProps={{ sx: { width: '150px' } }}
          fullWidth={false}
          onChange={event => setSelectedType(event.target.value as string)}
        />
        <SelectList
          label="Task Category"
          options={taskCategories}
          placeholder="Select an option..."
          value={selectedCategory}
          formControlProps={{ sx: { minWidth: '150px' } }}
          fullWidth={false}
          onChange={event => setSelectedCategory(event.target.value as string)}
        />
        <IconButton onClick={() => navigate('../../../patients?search=to-call-next')}>
          <CalendarMonthIcon />
        </IconButton>
      </Stack>

      <Paper component={Stack} padding={2} elevation={0} mt={3} spacing={3}>
        <Tabs value={activeTabIndex} onChange={handleTabChange} variant="standard">
          {followUpTasksTabs.map(tab => (
            <Tab label={<h6 style={{ marginBottom: 0 }}>{tab.label}</h6>} key={tab.key} icon={tab.icon} iconPosition="start" />
          ))}
        </Tabs>
        <TruentityDataGrid
          name={'dg-follow-up-task'}
          autoHeight
          rows={currentRelyingPartyFollowUps?.relyingPartyFollowups?.accountFollowups || []}
          columns={columns}
          columnVisibilityModel={columnVisibilityModel}
          paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
          onPaginationModelChange={({ page }) => {
            setCurrentPage(page);
          }}
          loading={isCurrentRelyingPartyFollowUpsLoading || false}
          rowCount={currentRelyingPartyFollowUps?.relyingPartyFollowups?.meta?.totalCount || 0}
          paginationMode="server"
          rowSelection={false}
        />
      </Paper>
    </Stack>
  );
};

export default FollowUpsTask;
