import type { RelyingPartyType } from '@/types/graphql';
import { gql } from '@apollo/client';

export type GetRelyingPartiesByAccountResponse = {
  relyingPartiesByAccount: {
    relyingParties: RelyingPartyType[];
  };
};

export const GET_CURRENT_PROVIDERS = gql`
  query getRelyingPartiesByAccountQuery($truentityId: String!, $pageNum: Int!, $pageSize: Int!) {
    relyingPartiesByAccount(truentityId: $truentityId, pageNum: $pageNum, pageSize: $pageSize) {
      relyingParties {
        name
        id
      }
    }
  }
`;

export const ADD_RELYING_PARTIES = gql`
  mutation addRelyingPartiesToAccount($truentityId: String!, $relyingPartyIds: [String!]!) {
    addRelyingPartiesToAccount(truentityId: $truentityId, relyingPartyIds: $relyingPartyIds) {
      status
    }
  }
`;

export type GetProviderSessionCommentResponse = {
  getProviderSessionComment: {
    commentText: string;
  };
};

export const GET_PROVIDER_SESSION_COMMENT = gql`
  query GetProviderSessionComment($providerActivityId: ID!, $authCode: String!, $reviewType: RpmProviderReviewTypeEnum!) {
    getProviderSessionComment(providerActivityId: $providerActivityId, authCode: $authCode, reviewType: $reviewType) {
      commentText
    }
  }
`;

export const DELETE_RELYING_PARTY_PROVIDER = gql`
  mutation DeleteRelyingPartyProvider($providerId: ID!, $relyingPartyId: ID) {
    deleteRelyingPartyProvider(providerId: $providerId, relyingPartyId: $relyingPartyId) {
      status
      message
    }
  }
`;

export const UPDATE_RPM_REVIEWER = gql`
  mutation UpdateRpmReviewer($providerId: ID!, $relyingPartyId: ID, $isRpmReviewer: Boolean!, $isPrimary: Boolean!) {
    updateRpmReviewer(providerId: $providerId, relyingPartyId: $relyingPartyId, isRpmReviewer: $isRpmReviewer, isPrimary: $isPrimary) {
      status
      message
    }
  }
`;

export const SEND_PROVIDER_SESSION_TO_ADMIN_REVIEW = gql`
  mutation SendProviderSessionToAdmin($providerId: String!) {
    sendProviderSessionToAdmin(providerId: $providerId) {
      status
      message
    }
  }
`;
