import DynamicTemplate from '@/components/DynamicTemplate';
import { H5 } from '@/components/Typography';
import type { DynamicTemplateType } from '@/types/remotePatientMonitoring';
import { RemoveCircleOutline } from '@mui/icons-material';
import { Box, IconButton, Stack } from '@mui/material';
import type React from 'react';

type Props = {
  assessment: DynamicTemplateType | null;
  setAssessment: React.Dispatch<React.SetStateAction<DynamicTemplateType | null>>;
};

const RpmPatientReviewAssessmentInput: React.FC<Props> = ({ assessment, setAssessment }) => {
  const handleInputChange = (key: string, value: string) => {
    if (!assessment) return;

    const updatedInputs = assessment.inputs.map(input => (input.key === key ? { ...input, value } : input));
    setAssessment({ ...assessment, inputs: updatedInputs });
  };

  const handleRemoveAssessment = () => setAssessment(null);

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2} width="100%" p={1} overflow="auto">
      <Box>
        {assessment ? (
          <DynamicTemplate
            templateText={assessment.templateText}
            templateId={assessment.templateId}
            inputs={assessment.inputs}
            textFieldStyle={{
              width: '40px',
              alignSelf: 'center',
              '& .MuiOutlinedInput-input': { p: '4px !important', textAlign: 'center', fontWeight: 'bold' }
            }}
            contentStyle={{ lineHeight: '2rem' }}
            onInputChange={handleInputChange}
          />
        ) : (
          <H5>Select Assessment Template</H5>
        )}
      </Box>
      {assessment && (
        <IconButton onClick={handleRemoveAssessment}>
          <RemoveCircleOutline color="error" fontSize="small" />
        </IconButton>
      )}
    </Stack>
  );
};

export default RpmPatientReviewAssessmentInput;
