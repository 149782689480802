import { gql } from '@apollo/client';

export const GET_CLAIM_SUBMISSION_INFO = gql`
  query GetClaimSubmissionInfo($monthYear: String!, $submissionIds: [ID!], $relyingPartyIds: [ID!]) {
    getClaimSubmissionInfo(monthYear: $monthYear, submissionIds: $submissionIds, relyingPartyIds: $relyingPartyIds) {
      submissionAllowedRelyingParties {
        id
        name
        settingsValidationErrors
      }
      submissionAllowedCptCodes {
        name
        code
      }
    }
  }
`;

export const GET_CLAIM_SUBMISSIONS = gql`
  query GetClaimSubmissions(
    $monthYear: String!
    $serviceType: MedicalServiceTypeEnum!
    $status: ClaimSubmissionStatusTypeEnum!
    $relyingPartyIds: [ID!]
    $pageNum: Int
    $pageSize: Int
  ) {
    getClaimSubmissions(
      monthYear: $monthYear
      serviceType: $serviceType
      status: $status
      relyingPartyIds: $relyingPartyIds
      pageNum: $pageNum
      pageSize: $pageSize
    ) {
      claimSubmissions {
        id
        account {
          rpmStatus
          truentityId
          user {
            firstName
            lastName
          }
          accountsMonitoringDevices {
            id
          }
        }
        relyingParty {
          id
          name
        }
        provider {
          individualFirstName
          individualLastName
        }
        billingKey
        status
        scheduledOn
        additionalData
        processData
        serviceDate
        performedOn
      }
      meta {
        totalCount
        totalPages
      }
    }
  }
`;

export const UPDATE_CLAIM_SUBMISSIONS = gql`
  mutation UpdateClaimSubmission($submissionIds: [ID!]!, $submissionStatus: ClaimSubmissionStatusTypeEnum!) {
    updateClaimSubmission(submissionIds: $submissionIds, submissionStatus: $submissionStatus) {
      status
      message
    }
  }
`;

export type ScheduleClaimSubmissionResponseType = {
  scheduleClaimSubmission: {
    status: string;
    message: {
      success: string;
      failed: string;
      skipped: string;
    };
  };
};

export const SCHEDULE_CLAIM_SUBMISSION = gql`
  mutation ScheduleClaimSubmission(
    $monthYear: String!
    $serviceType: MedicalServiceTypeEnum!
    $relyingPartyServiceDates: [ClaimSubmissionRelyingPartyServiceDate!]!
  ) {
    scheduleClaimSubmission(monthYear: $monthYear, serviceType: $serviceType, relyingPartyServiceDates: $relyingPartyServiceDates) {
      status
      message {
        success
        failed
        skipped
      }
    }
  }
`;
