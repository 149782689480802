import type { GetSystemOptionsResponse } from '@/graphql/administration';
import { GET_SYSTEM_OPTIONS } from '@/graphql/administration';
import { ADD_RPM_ACTIVITY } from '@/graphql/remotePatientMonitoring';
import type { SystemOptionType } from '@/types/administration';
import { SystemOptionKeyEnum } from '@/types/administration';
import {
  ActivityMainCategoryType,
  ActivityMainTypeEnum,
  CareActivityContactModeType,
  CareActivityTypes
} from '@/types/remotePatientMonitoring';
import { secondsToDate } from '@/util/date';
import { formatDate, formatDateAndTime2, formatTime, getCurrentDate } from '@/util/format';
import type { CareActivityFormValues } from '@/util/rpm';
import { timeStringToSeconds, validateCareActivityTimeInputs, validateCareActivityTitleInputs } from '@/util/rpm';
import { useMutation, useQuery } from '@apollo/client';
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers-pro';
import type { MomentInput } from 'moment';
import moment, { type Moment } from 'moment/moment';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { useEffect, useMemo, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import Button from '../Button';
import TruentityDateTimePicker from '../TruentityDateTimePicker';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  title: string;
  id: string | undefined;
  category?: string;
  rpmEnrolledAtDate: MomentInput | undefined;
  followUpId?: string;
  isNoteRequired?: boolean;
  defaultTimeTakenInSeconds?: number;
  reviewWorkflowId?: string;
  hideDialog: (activitiesChanged: boolean) => void;
  onActivitiesChanged: () => void;
};

const AddActivityDialog = ({
  id,
  rpmEnrolledAtDate,
  title,
  category,
  followUpId,
  isNoteRequired = false,
  defaultTimeTakenInSeconds = 0,
  hideDialog,
  onActivitiesChanged,
  reviewWorkflowId,
  ...props
}: Props): React.ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const defaultValues: CareActivityFormValues = {
    mainCategory: ActivityMainCategoryType.MONTHLY_CARE_MANAGEMENT,
    type: ActivityMainTypeEnum.RPM,
    category: category ?? '',
    subCategory: null,
    contactMode: null,
    startTime: null,
    timeTaken: secondsToDate(defaultTimeTakenInSeconds),
    note: ''
  };

  const [selectedCategory, setSelectedCategory] = useState<SystemOptionType | null>(null);
  const [categoryOptions, setCategoryOptions] = useState<{ [key: string]: SystemOptionType }>();
  const { data: systemOptionsData, error: systemOptionsError } = useQuery<GetSystemOptionsResponse>(GET_SYSTEM_OPTIONS, {
    variables: {
      optionKey: SystemOptionKeyEnum.RPM_CARE_ACTIVITY_DESCRIPTIONS
    },
    fetchPolicy: 'cache-first'
  });
  const [addRpmActivity, { loading: addRpmActivityLoading }] = useMutation(ADD_RPM_ACTIVITY);

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { isValid }
  } = useForm<CareActivityFormValues>({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      startTime: getCurrentDate()
    }
  });
  const mainTitleOptions = useMemo(() => {
    if (categoryOptions) {
      return Object.keys(categoryOptions);
    } else {
      return [];
    }
  }, [categoryOptions]);

  const categoryValue = watch('category');
  const subCategoryValue = watch('subCategory');

  const isAdditionalDescRequired = subCategoryValue === 'Other' || isNoteRequired;

  const onSubmit: SubmitHandler<CareActivityFormValues> = data => handleSubmitImpl(data);

  const prepareInputValues = (values: CareActivityFormValues) => {
    return {
      title: values.category,
      subTitle: values.subCategory ?? null,
      category: ActivityMainCategoryType.MONTHLY_CARE_MANAGEMENT,
      notes: values.note,
      type: ActivityMainTypeEnum.RPM,
      contactMode: values.contactMode,
      startTime: formatDateAndTime2(formatDate(values.startTime), formatTime(values.startTime, 'HH:mm')),
      timeTaken: timeStringToSeconds(formatTime(values.timeTaken, 'mm:ss')),
      isEncounter: false,
      ...(followUpId && { accountFollowupId: followUpId }),
      ...(reviewWorkflowId && { accountReviewWorkflowId: reviewWorkflowId })
    };
  };

  const handleSubmitImpl = async (values: CareActivityFormValues) => {
    try {
      const { startTime, timeTaken } = values;
      if ((rpmEnrolledAtDate as Moment).isValid()) {
        const validTimeInputs = validateCareActivityTimeInputs({
          startTime: startTime,
          timeTaken: timeTaken,
          rpmEnrolledAtDate: rpmEnrolledAtDate
        });
        if (!validTimeInputs) {
          displayErrorMessage('Failed to Create an Activity. Please check your date & time inputs.');
          return;
        }
      } else {
        displayErrorMessage('Failed to Create an Activity. Patient enrolled date is not valid.');
        return;
      }

      const validateTitleInputsMessage = validateCareActivityTitleInputs({
        values: values,
        careActivityTitleOptions: categoryOptions
      });
      if (validateTitleInputsMessage) {
        displayErrorMessage(validateTitleInputsMessage);
        return;
      }

      const inputValues = prepareInputValues(values);
      await addRpmActivityMutation(inputValues);
    } catch (error) {
      displayErrorMessage('Failed to Create an Activity');
    }
  };

  const addRpmActivityMutation = async (inputValues: any) => {
    try {
      const response = await addRpmActivity({
        variables: {
          careActivityInput: { truentityId: id, ...inputValues }
        }
      });

      const data = response.data?.addRpmActivity;
      const variant = data!.status === 'Success' ? 'success' : 'error';

      enqueueSnackbar(data.message, { variant });
      onActivitiesChanged();
      hideDialog();
    } catch (error) {
      enqueueSnackbar('Unable to add activity', { variant: 'error' });
      hideDialog();
    }
  };

  const displayErrorMessage = (message: string) => {
    enqueueSnackbar(message, { variant: 'error' });
  };

  const resetDetails = () => {
    setSelectedCategory(null);
    setValue('subCategory', null);
    setValue('contactMode', null);
  };

  const onDescriptionValueChange = (descriptionValue: string | null) => {
    if (descriptionValue && categoryOptions) {
      const selectedOption = categoryOptions[descriptionValue];
      if (selectedOption) {
        setSelectedCategory(selectedOption);
        setValue('subCategory', null);
        const defaultContactMode = selectedOption.defaultContactMode
          ? (selectedOption.defaultContactMode as CareActivityContactModeType)
          : null;
        setValue('contactMode', defaultContactMode);
      } else {
        resetDetails();
      }
    } else {
      resetDetails();
    }
  };

  const validateTime = (date: MomentInput) => {
    const momentDate = moment(date);
    const seconds = (momentDate.minutes() ?? 0) * 60 + (momentDate.seconds() ?? 0);
    return seconds >= 1 || 'Time must be at least 1 seconds';
  };

  useEffect(() => {
    if (systemOptionsError) {
      displayErrorMessage('Could not fetch Descriptions and Supporting Descriptions');
    }
  }, [systemOptionsError]);

  useEffect(() => {
    if (systemOptionsData) {
      const optionsDictionary: { [key: string]: SystemOptionType } = {};
      systemOptionsData?.getSystemOptions?.options.forEach(option => {
        optionsDictionary[option.value] = option;
      });
      setCategoryOptions(optionsDictionary);
    }
  }, [systemOptionsData]);

  return (
    <BaseDialog title={title} hideDialog={hideDialog} fullWidth maxWidth={'sm'} {...props}>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            spacing={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'stretch'
            }}
          >
            <Stack direction="row" sx={{ justifyContent: 'flex-start' }}>
              <Controller
                control={control}
                name="category"
                render={({ field }) => (
                  <Autocomplete
                    sx={{ width: '100%', marginTop: 1 }}
                    value={field.value}
                    options={mainTitleOptions}
                    clearOnEscape
                    onChange={(_event, newValue) => {
                      setValue('category', newValue ?? '');
                      onDescriptionValueChange(newValue);
                    }}
                    placeholder={'Enter Category'}
                    renderInput={params => <TextField required {...params} label={`Category`} />}
                  />
                )}
              />
            </Stack>
            {(selectedCategory?.nestedOptions && selectedCategory.nestedOptions.length > 0) || selectedCategory?.isOptionsEditable ? (
              <Stack direction="row" sx={{ justifyContent: 'flex-start' }}>
                <Controller
                  control={control}
                  name="subCategory"
                  render={({ field: { value } }) => (
                    <Autocomplete
                      freeSolo={selectedCategory?.isOptionsEditable ?? false}
                      sx={{ width: '100%', marginTop: 1 }}
                      value={value}
                      key={selectedCategory?.value ?? 'supporting-category'}
                      options={selectedCategory?.nestedOptions?.map(subTitle => subTitle.value) ?? []}
                      onChange={(_event, newValue) => {
                        setValue('subCategory', newValue ?? '');
                      }}
                      clearOnEscape
                      placeholder={`${selectedCategory?.isOptionsEditable ? 'Enter' : 'Select '} Subcategory`}
                      renderInput={params => (
                        <TextField
                          required
                          {...params}
                          label={`Subcategory`}
                          onChange={event => {
                            if (selectedCategory?.isOptionsEditable) {
                              setValue('subCategory', event.target.value);
                            }
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Stack>
            ) : (
              <></>
            )}
            <Stack direction="row" sx={{ justifyContent: 'stretch' }}>
              <Controller
                control={control}
                name="note"
                rules={{ required: isAdditionalDescRequired ? 'Additional description is required' : false }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    autoFocus={true}
                    minRows={3}
                    required={isAdditionalDescRequired}
                    onChange={onChange}
                    value={value}
                    fullWidth
                    multiline
                    rows={8}
                    label={`Additional Description${isAdditionalDescRequired ? '' : ' (Optional)'}`}
                    placeholder={'Enter Additional Description'}
                  />
                )}
              />
            </Stack>
            <Stack direction="row" justifyContent="flex-start">
              <Controller
                control={control}
                name="contactMode"
                rules={{ required: 'Contact Mode is required' }}
                render={({ field }) => (
                  <FormControl required>
                    <FormLabel id="contact-mode-label">Contact Mode</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="contact-mode-label"
                      name="contactMode"
                      value={field.value}
                      onChange={event => {
                        field.onChange(event.target.value);
                      }}
                    >
                      <FormControlLabel value={CareActivityContactModeType.PHONE_CALL} control={<Radio />} label="Phone Call" />
                      <FormControlLabel value={CareActivityContactModeType.IN_PERSON_VISIT} control={<Radio />} label="In Person Visit" />
                      <FormControlLabel value={CareActivityContactModeType.NOT_APPLICABLE} control={<Radio />} label="Not Applicable" />
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </Stack>
            <Divider />
            <Stack direction="row" sx={{ justifyContent: 'space-evenly' }}>
              <Controller
                control={control}
                name="startTime"
                render={({ field: { onChange, value } }) => (
                  <TruentityDateTimePicker
                    label="Start Time"
                    onChange={onChange}
                    sx={{ flex: 1, marginRight: 1 }}
                    TextFieldProps={undefined}
                    required
                    value={value}
                    minDateTime={categoryValue === CareActivityTypes.SETUP.toLowerCase() ? undefined : rpmEnrolledAtDate}
                  />
                )}
              />
              <Controller
                control={control}
                name="timeTaken"
                rules={{ required: 'This field is required', validate: validateTime }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TimePicker
                    ampm={false}
                    views={['minutes', 'seconds']}
                    format="mm:ss"
                    slotProps={{
                      textField: {
                        label: 'Time Taken (mm:ss)',
                        helperText: error ? error.message : null
                      }
                    }}
                    sx={{ flex: 1 }}
                    value={value}
                    onChange={onChange}
                    minTime={secondsToDate(1)}
                  />
                )}
              />
            </Stack>
          </Stack>
          <DialogActions sx={{ justifyContent: 'end', padding: 2 }}>
            <Button
              type="reset"
              a11yLabel="Cancel"
              appearance="outline"
              onClick={() => {
                reset();
                hideDialog();
              }}
            />
            <Button type="submit" isLoading={addRpmActivityLoading} a11yLabel="Save" appearance="primary" disabled={!isValid} />
          </DialogActions>
        </form>
      </DialogContent>
    </BaseDialog>
  );
};

export default AddActivityDialog;
