import Button from '@/components/Button';
import { H3 } from '@/components/Typography';
import type { UpdateRelyingPartyResponse } from '@/graphql/account';
import { UPDATE_RELYING_PARTY } from '@/graphql/account';
import useToken from '@/hooks/useToken';
import RpmReportCoverLetterBody from '@/routes/PatientDetails/RemotePatientMonitoring/Components/RpmReportCoverLetter/RpmReportCoverLetterBody';
import { Role } from '@/types/admin';
import type { RelyingParty } from '@/types/graphql';
import { useMutation } from '@apollo/client';
import { Grid, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';

type FormValues = {
  coverLetterContent: string;
};

const CoverLetterSection: React.FC<{ companyData: RelyingParty }> = ({ companyData }) => {
  const { roleType } = useToken();
  const isSuperAdmin = roleType === Role.SUPER;
  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm<FormValues>({ defaultValues: { coverLetterContent: '' }, mode: 'onBlur' });
  const { control, setValue, handleSubmit, watch, reset, formState } = methods;

  const [coverLetterBodyContent, setCoverLetterBodyContent] = useState<string>('');

  const watchCoverLetterContent = watch('coverLetterContent');
  const isDirty = formState.isDirty;

  const reportInitialValue = useRef<string>('');
  const isFormEdited = reportInitialValue.current !== watchCoverLetterContent;

  const isPsm = useMemo(() => roleType === Role.ADMIN, [roleType]);
  const isNotDisabled = useMemo(() => isSuperAdmin || isPsm, [isPsm, isSuperAdmin]);

  const [updateRelyingParty, { loading: updateRelyingPartyLoading, error: updateRelyingPartyError, data: updateRelyingPartyData }] =
    useMutation<UpdateRelyingPartyResponse>(UPDATE_RELYING_PARTY);

  const onSubmit: SubmitHandler<FormValues> = async (values: FormValues) => {
    try {
      await updateRelyingParty({
        variables: {
          relyingPartyId: companyData?.id,
          coverLetterBody: values.coverLetterContent
        }
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Could not update company rpm report cover latter', { variant: 'error' });
    }
  };

  const resetRpmReportCoverLetter = useCallback(() => {
    setValue('coverLetterContent', reportInitialValue.current);
  }, [setValue, reportInitialValue]);

  useEffect(() => {
    const rpmReportCoverLetterData = companyData?.coverLetterBody || '';
    setCoverLetterBodyContent(rpmReportCoverLetterData);
    reportInitialValue.current = rpmReportCoverLetterData;
  }, [companyData?.coverLetterBody, setCoverLetterBodyContent, setValue]);

  useEffect(() => {
    if (updateRelyingPartyData?.updateRelyingParty?.status === 'Success') {
      enqueueSnackbar('Company rpm report cover letter updated successfully', { variant: 'success' });
    }
  }, [enqueueSnackbar, updateRelyingPartyData]);

  useEffect(() => {
    if (updateRelyingPartyError) {
      enqueueSnackbar(updateRelyingPartyError?.message ?? 'Could not update company rpm report cover letter ', { variant: 'error' });
    }
  }, [enqueueSnackbar, updateRelyingPartyError]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} mt={6}>
        <Stack>
          <H3>Report Cover Letter</H3>
          <Stack justifyContent="space-evenly">
            <Stack sx={{ width: '100%', marginRight: 1, marginTop: 2 }}>
              <RpmReportCoverLetterBody
                control={control}
                reset={reset}
                coverLetterBody={coverLetterBodyContent}
                watchCoverLetterContent={watchCoverLetterContent}
                textareaProps={{ rows: 10, disabled: !isNotDisabled }}
                isDirty={isDirty}
              />
            </Stack>
          </Stack>
        </Stack>
        <Grid container justifyContent="flex-end" spacing={1}>
          <Grid item>
            <Button
              variant="outlined"
              label={'Reset form'}
              isLoading={updateRelyingPartyLoading}
              disabled={!isFormEdited}
              onClick={resetRpmReportCoverLetter}
              size="large"
            />
          </Grid>
          <Grid item>
            <Button label="Save details" type="submit" isLoading={updateRelyingPartyLoading} disabled={!isFormEdited} size="large" />
          </Grid>
        </Grid>
      </Stack>
    </form>
  );
};

export default CoverLetterSection;
