import { FollowupFormModeTypes } from '@/components/Dialogs/FollowUpAddEditDialog';
import type { Order } from '@/components/EnhancedTableHead';
import type { TextSelectOption } from '@/components/SelectList';
import { FollowUpCategoryTypes, FollowUpStatusTypes, FollowUpTaskTypes, FollowUpTypes } from '@/graphql/account';
import type { FollowUpRemindersTypes } from '@/types/accountProfile';
import { MedicalServices } from '@/types/admin';
import moment from 'moment';

export function followUpTypes(
  doNotCall?: boolean,
  isEnrolled?: boolean,
  isUnenrolled?: boolean,
  currentMedicalServices?: string[]
): TextSelectOption[] {
  const options = [
    { label: FollowUpTypes.CALL_PATIENT, value: FollowUpTypes.CALL_PATIENT, disabled: doNotCall ?? false },
    { label: FollowUpTypes.CALL_PROVIDER, value: FollowUpTypes.CALL_PROVIDER },
    { label: FollowUpTypes.TEXT_PATIENT, value: FollowUpTypes.TEXT_PATIENT, disabled: doNotCall ?? false },
    { label: FollowUpTypes.REVIEW_CHART, value: FollowUpTypes.REVIEW_CHART },
    { label: FollowUpTypes.REVIEW, value: FollowUpTypes.REVIEW }
  ];

  if (currentMedicalServices?.includes(MedicalServices.RPM)) {
    options.push({
      label: FollowUpTypes.RPM_INITIAL_VISIT,
      value: FollowUpTypes.RPM_INITIAL_VISIT,
      disabled: isUnenrolled
    });
    options.push({
      label: FollowUpTypes.FOLLOW_UP_VISIT,
      value: FollowUpTypes.FOLLOW_UP_VISIT,
      disabled: !(isEnrolled || isUnenrolled || (isEnrolled === undefined && isUnenrolled === undefined))
    });
    options.push({
      label: FollowUpTypes.PHARMACY_VISIT,
      value: FollowUpTypes.PHARMACY_VISIT
    });
  }

  return options;
}

export function followUpTaskTypes(
  category: FollowUpCategoryTypes | 'ALL',
  formMode: FollowupFormModeTypes = FollowupFormModeTypes.ADD
): TextSelectOption[] {
  const baseTasks: TextSelectOption[] = [{ label: FollowUpTaskTypes.CONTACT_PATIENT, value: FollowUpTypes.CALL_PATIENT, disabled: false }];

  const categoryTasks: Record<FollowUpCategoryTypes | 'ALL', TextSelectOption[]> = {
    [FollowUpCategoryTypes.GENERAL]: [],

    [FollowUpCategoryTypes.ALERT_REVIEW]: [
      {
        label: FollowUpTaskTypes.CONTACT_PRIMARY_CARE_PROVIDER,
        value: FollowUpTaskTypes.CONTACT_PRIMARY_CARE_PROVIDER,
        disabled: false
      }
    ],

    [FollowUpCategoryTypes.CHART_REVIEW]: [
      {
        label: FollowUpTaskTypes.CONTINUE_CURRENT_MONITORING_AND_CATE_PLAN,
        value: FollowUpTaskTypes.CONTINUE_CURRENT_MONITORING_AND_CATE_PLAN,
        disabled: false
      },
      {
        label: FollowUpTaskTypes.CONTACT_CARE_TEAM_MEMBER,
        value: FollowUpTaskTypes.CONTACT_CARE_TEAM_MEMBER,
        disabled: false
      },
      {
        label: FollowUpTaskTypes.NO_INTERVENTION_REQUIRED,
        value: FollowUpTaskTypes.NO_INTERVENTION_REQUIRED,
        disabled: formMode === FollowupFormModeTypes.UPDATE
      }
    ],

    ALL: [
      {
        label: FollowUpTaskTypes.CONTACT_PRIMARY_CARE_PROVIDER,
        value: FollowUpTaskTypes.CONTACT_PRIMARY_CARE_PROVIDER,
        disabled: false
      },
      {
        label: FollowUpTaskTypes.CONTINUE_CURRENT_MONITORING_AND_CATE_PLAN,
        value: FollowUpTaskTypes.CONTINUE_CURRENT_MONITORING_AND_CATE_PLAN,
        disabled: false
      },
      {
        label: FollowUpTaskTypes.CONTACT_CARE_TEAM_MEMBER,
        value: FollowUpTaskTypes.CONTACT_CARE_TEAM_MEMBER,
        disabled: false
      },
      {
        label: FollowUpTaskTypes.NO_INTERVENTION_REQUIRED,
        value: FollowUpTaskTypes.NO_INTERVENTION_REQUIRED,
        disabled: formMode === FollowupFormModeTypes.UPDATE
      }
    ]
  };
  console.log(categoryTasks[category]);

  return category === FollowUpCategoryTypes.GENERAL ? categoryTasks[category] : [...baseTasks, ...categoryTasks[category]];
}

export function followUpSubTypes(): TextSelectOption[] {
  return [
    { label: 'None', value: 'None' },
    { label: 'RPM', value: 'RPM' },
    { label: 'CMR', value: 'CMR' },
    { label: 'TIP', value: 'TIP' },
    { label: 'Adherence', value: 'Adherence' }
  ];
}

export function followUpStatuses(): TextSelectOption[] {
  return [
    { label: 'Pending', value: FollowUpStatusTypes.PENDING },
    { label: 'Completed', value: FollowUpStatusTypes.COMPLETED }
  ];
}

export function clusterFollowupsByDate(followups: FollowUpRemindersTypes[], sortOrder: Order = 'asc') {
  const groupedByDate = followups.reduce((acc, followup) => {
    const dateKey = moment(followup.followUpOn).format('YYYY-MM-DD');
    if (!acc[dateKey]) {
      acc[dateKey] = [];
    }
    acc[dateKey].push(followup);
    return acc;
  }, {} as { [date: string]: FollowUpRemindersTypes[] });

  const dates = Object.keys(groupedByDate).sort((a, b) => {
    return sortOrder === 'asc' ? moment(a).diff(moment(b)) : moment(b).diff(moment(a));
  });
  return groupedByDate[dates[0]] || [];
}

export function followupTypeHandler(followup: FollowUpRemindersTypes) {
  const { type, category } = followup;
  if (type !== FollowUpTypes.CALL_PATIENT) return type;

  if (category === FollowUpCategoryTypes.GENERAL) return type;

  return FollowUpTaskTypes.CONTACT_PATIENT;
}
