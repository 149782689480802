import { ON_RELYING_PARTY_ADMIN_NOTIFICATION } from '@/graphql/notifications';
import type { SubscriptionNotificationsResponse } from '@/types/notifications';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { useReactiveVar, useSubscription } from '@apollo/client';
import type { FC, ReactNode } from 'react';
import { createContext, useState } from 'react';

interface State {
  unreadNotificationCount: number;
}

interface NotificationContextValue extends State {
  setUnreadNotificationCount: (val: number) => void;
}

interface NotificationProviderProps {
  children: ReactNode;
}

const initialValue: NotificationContextValue = {
  unreadNotificationCount: 0,
  setUnreadNotificationCount: () => Object
};

const NotificationContext = createContext<NotificationContextValue>(initialValue);

export const NotificationProvider: FC<NotificationProviderProps> = ({ children }) => {
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);

  useSubscription<SubscriptionNotificationsResponse>(ON_RELYING_PARTY_ADMIN_NOTIFICATION, {
    skip: !currentUser,
    onSubscriptionData({ subscriptionData }) {
      const totalUnreadCount = subscriptionData?.data?.relyingPartyAdminNotifications?.totalUnreadCount ?? 0;
      setUnreadNotificationCount(totalUnreadCount);
    },
    onError(err) {
      console.error('Unexpected error occurred notification subscription: ' + err.message);
    }
  });

  return (
    <NotificationContext.Provider
      value={{
        unreadNotificationCount,
        setUnreadNotificationCount
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContext;
