import Button from '@/components/Button';
import MedHistoryRequestsDialog from '@/components/Dialogs/MedHistoryRequestsDialog';
import { H3 } from '@/components/Typography';
import AddIcon from '@mui/icons-material/Add';
import { Paper, Stack } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

type Props = {
  isProviderAdmin: boolean;
  goToNewMedication: () => void;
  goToTriageMedication: () => void;
  disableLockCertify: boolean;
  showModalMedRec: () => void;
  directImport: () => void;
  uploadFile: () => void;
  resetAllMeds: () => Promise<void>;
  medicationCount: number;
};
export const MedicationsMenuHeader = ({
  isProviderAdmin,
  goToNewMedication,
  goToTriageMedication,
  disableLockCertify,
  showModalMedRec,
  medicationCount
}: Props) => {
  const { pathname } = useLocation();
  const isTriage = pathname.includes('/triage');
  const { showModal } = useModal();

  const disableIfMedicationNotAvailable = medicationCount <= 0;

  const openMedHistoryRequestModal = useCallback(() => {
    const modal = showModal(MedHistoryRequestsDialog, {
      title: 'Medication History Request ',
      hideDialog: () => {
        modal.hide();
      }
    });
  }, [showModal]);

  return (
    <Paper component={Stack} direction="row" p={5} sx={{ justifyContent: 'space-between' }}>
      <H3>Medications</H3>

      <Stack direction="row" spacing={1}>
        {!isTriage && (
          <Button variant={'contained'} disableElevation disabled={isProviderAdmin} onClick={goToTriageMedication}>
            Triage
          </Button>
        )}
        <Button
          variant={'contained'}
          disableElevation
          disabled={isProviderAdmin || disableLockCertify || disableIfMedicationNotAvailable}
          onClick={showModalMedRec}
        >
          Lock & Certify
        </Button>
        <Button variant="outlined" disableElevation onClick={openMedHistoryRequestModal}>
          Medication History
        </Button>
        {/* {!isProd() ? (
          <MuiMenu
            variant={'outlined'}
            label="Import Medications"
            options={[
              {
                label: 'Request MedHistory',
                onAction: openMedHistoryRequestModal
              },
              {
                label: 'Direct Import',
                onAction: directImport
              },
              {
                label: 'Upload File',
                onAction: uploadFile
              },
              {
                label: 'Reset',
                onAction: () => {
                  resetAllMeds().catch(console.error);
                }
              }
            ]}
          ></MuiMenu>
        ) : (
          <MuiMenu
            variant={'outlined'}
            label="Import Medications"
            options={[
              {
                label: 'Request MedHistory',
                onAction: openMedHistoryRequestModal
              },
              {
                label: 'Direct Import',
                onAction: directImport
              },
              {
                label: 'Upload File',
                onAction: uploadFile
              }
            ]}
          ></MuiMenu>
        )} */}
        <Button variant={'contained'} startIcon={<AddIcon />} disableElevation disabled={isProviderAdmin} onClick={goToNewMedication}>
          New Medication
        </Button>
      </Stack>
    </Paper>
  );
};
