export const moveIndex = <T>(source: T[], fromIndex: number, toIndex: number): T[] => {
  const clone = Array.from(source);

  const [item] = clone.splice(fromIndex, 1);
  clone.splice(toIndex, 0, item);

  return clone;
};

export const removeAtIndex = <T>(source: T[], atIndex: number, count = 1) => {
  const clone = Array.from(source);

  clone.splice(atIndex, count);

  return clone;
};

export const insertAtIndex = <T>(source: T[], atIndex: number, ...items: T[]) => {
  return replaceAtIndex(source, atIndex, 0, ...items);
};

export const replaceAtIndex = <T>(source: T[], atIndex: number, removeCount: number, ...items: T[]) => {
  const clone = Array.from(source);

  clone.splice(atIndex, removeCount, ...items);

  return clone;
};

export const findPathIndex = (options: Array<{ path: string }>) => {
  const path = options.findIndex(item => location.pathname.includes(item.path));
  return path;
};

export const groupBy = <T>(array: T[], predicate: (v: T) => string) => {
  return array.reduce((acc, value) => {
    (acc[predicate(value)] ||= []).push(value);
    return acc;
  }, {} as { [key: string]: T[] });
};

// Zip two arrays into an array of pairs.
export const zip = (a, b) => {
  return a.map((val, i) => [val, b[i]]);
};

export function findMissingElements(arr1: string[], arr2: string[]) {
  const set1 = new Set(arr1);
  const set2 = new Set(arr2);

  const difference1 = [...set1].filter(item => !set2.has(item));
  const difference2 = [...set2].filter(item => !set1.has(item));

  return [...difference1, ...difference2];
}

export function base64ToUint8Array(base64: string): Uint8Array {
  const base64String = base64.split(',')[1];
  const binaryString = atob(base64String);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}
