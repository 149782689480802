import { formatDate } from '@/util/format';
import { Box, Card, CardContent, DialogContent, Stack } from '@mui/material';
import type React from 'react';
import Alert from '../Alert';
import LabelGroup from '../LabelGroup';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

interface IMetaDataProps extends BaseDialogProps {
  medicationData?: {
    days_supply: number;
    medication_name: string;
    date_filled_utc: Date;
    last_date_filled_utc: Date;
    refills_remaining: number;
    medication_strength: string;
  }[];
  claimsData?: Record<string, string>[];
}

const RpmMyPatientsMetaDataDialog: React.FC<IMetaDataProps> = ({ title, hideDialog, medicationData, claimsData, ...props }) => {
  const isMedicationsUnavailable = () => (Array.isArray(medicationData) && medicationData.length === 0) || medicationData === null;
  const isClaimsDataUnavailable = () => (Array.isArray(claimsData) && claimsData.length === 0) || claimsData === null;
  return (
    <BaseDialog {...props} maxWidth="md" title={title} hideDialog={hideDialog} fullWidth>
      <DialogContent>
        {isMedicationsUnavailable() && <Alert description={'No medications data available'} status={'warning'} />}
        {isClaimsDataUnavailable() && <Alert description={'No claims data available'} status={'warning'} />}

        {/* Render the medication data */}
        <Stack direction={'column'} gap={2} display={isMedicationsUnavailable() ? 'none' : ''}>
          {Array.isArray(medicationData) &&
            medicationData.map((medication, index) => (
              <Card key={index}>
                <CardContent>
                  <Box sx={{ display: 'flex' }}>
                    <LabelGroup label="Name">{medication?.medication_name}</LabelGroup>
                  </Box>
                  <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}>
                    <LabelGroup label="Medication strength">{medication?.medication_strength}</LabelGroup>
                    <LabelGroup label="Date filled">
                      {formatDate(medication?.date_filled_utc || medication?.last_date_filled_utc)}
                    </LabelGroup>
                  </Box>
                  <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}>
                    <LabelGroup label="Days supply">{medication?.days_supply}</LabelGroup>
                    <LabelGroup label="Refills remaining">{medication?.refills_remaining}</LabelGroup>
                  </Box>
                </CardContent>
              </Card>
            ))}
        </Stack>

        {/* Render the claims data */}
        <Stack direction={'column'} gap={2} display={isClaimsDataUnavailable() ? 'none' : ''}>
          {Array.isArray(claimsData) &&
            claimsData.map((claim, index) => (
              <Card key={index}>
                <CardContent>
                  <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: 2 }}>
                    {Object.entries(claim).map(([key, value]) => (
                      <LabelGroup label={key}>{value}</LabelGroup>
                    ))}
                  </Box>
                </CardContent>
              </Card>
            ))}
        </Stack>
      </DialogContent>
    </BaseDialog>
  );
};

export default RpmMyPatientsMetaDataDialog;
