import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import { ProfileAvatar } from '@/components/ProfileAvatar';
import SelectList from '@/components/SelectList';
import MainSideMenu from '@/components/SideMenus/MainSideMenu';
import { Caption, H3 } from '@/components/Typography';
import SideMenuContext from '@/context/sideMenuContext';
import type { GetRelyingPartyAdminNotificationType } from '@/graphql/notifications';
import { GET_RELYING_PARTY_ADMIN_NOTIFICATIONS, READ_NOTIFICATION } from '@/graphql/notifications';
import useMessages from '@/hooks/useMessages';
import { color } from '@/styles/assets/colors';
import { NotificationTypeEnum, type NotificationReadStatusType, type NotificationType } from '@/types/notifications';
import { getAccountUserFullName } from '@/util/account';
import { formatDateAndTime, titleCase } from '@/util/format';
import { unknown } from '@/util/string';
import { useMutation, useQuery } from '@apollo/client';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import type { SelectChangeEvent } from '@mui/material';
import { Box, Chip, Paper, Stack } from '@mui/material';
import type { GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import parse from 'html-react-parser';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

type AllNotificationType = NotificationReadStatusType | 'ALL';

const AllNotification = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { setIsMessageDrawerOpen } = useMessages();
  const { setContent, setDrawerOpen } = useContext(SideMenuContext);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [notificationReadStatus, setNotificationReadStatus] = useState<AllNotificationType>('ALL');

  const notificationStatues: AllNotificationType[] = ['ALL', 'READ', 'UNREAD'];

  const {
    data: adminNotifications,
    loading: isAdminNotificationLoading,
    refetch: refetchAdminNotification
  } = useQuery<GetRelyingPartyAdminNotificationType>(GET_RELYING_PARTY_ADMIN_NOTIFICATIONS, {
    variables: {
      pageNum: currentPage + 1,
      pageSize: DEFAULT_PAGE_SIZE,
      ...(notificationReadStatus !== 'ALL' && { readStatus: notificationReadStatus })
    }
  });

  const [triggerReadNotification] = useMutation(READ_NOTIFICATION, {
    onCompleted: ({ readRelyingPartyAdminNotification }) => {
      enqueueSnackbar(readRelyingPartyAdminNotification?.message ?? '', { variant: 'success' });
      refetchAdminNotification();
    }
  });

  const handleNotificationType = ({ target: { value } }: SelectChangeEvent<unknown>) =>
    setNotificationReadStatus((value as string).toUpperCase() as AllNotificationType);

  const handleRowClick = async (params: GridRowParams) => {
    const {
      row: { id, notificationType, account, isRead }
    } = params;

    if (notificationType === NotificationTypeEnum.MESSAGE) {
      setIsMessageDrawerOpen(true);
      navigate(`/patients/${account.truentityId}/details/medications/triage/list`);
    } else if (notificationType === NotificationTypeEnum.FOLLOWUP) {
      navigate(`/remote-patient-monitoring/follow-up-tasks`);
    }
    if (isRead) return;
    await triggerReadNotification({
      variables: {
        notificationId: id
      }
    });
  };

  const columns: GridColDef<NotificationType>[] = useMemo(
    () => [
      {
        field: 'avatar',
        headerAlign: 'left',
        sortable: false,
        width: 50,
        height: 50,
        disableColumnMenu: true,
        renderHeader: () => null,
        align: 'left',
        renderCell: ({ row }) => (
          <Stack direction="row">
            <ProfileAvatar
              avatarUrl={row?.createdByAdmin?.presignedAvatarUrl || '#'}
              name={getAccountUserFullName(row?.createdByAdmin?.user || row?.account?.user)}
              sx={{ bgcolor: color.primaryMain, width: 30, height: 30, fontSize: 'small' }}
            />
          </Stack>
        )
      },
      {
        field: 'createdBy',
        headerName: 'Created By',
        sortable: true,
        flex: 1,
        minWidth: 110,
        align: 'left',
        valueGetter: params => (params.row.createdByAdmin?.user ? getAccountUserFullName(params.row.createdByAdmin?.user) : unknown())
      },
      {
        field: 'message',
        headerName: 'Message',
        sortable: true,
        flex: 1,
        minWidth: 400,
        align: 'left',
        renderCell: params => <Caption>{parse(params.value)}</Caption>
      },
      {
        field: 'patientName',
        headerName: 'Patient Name',
        sortable: true,
        flex: 1,
        minWidth: 110,
        align: 'left',
        headerAlign: 'left',
        valueGetter: params => (params.row.account?.user ? getAccountUserFullName(params.row.account?.user) : unknown()),
        renderCell: params => {
          const name = params.value;

          return (
            <Link to={`../../patients/${params.row.account.truentityId}/details/rpm/patient-setup/encounter/consent-management`}>
              {name}
            </Link>
          );
        }
      },
      {
        field: 'notificationType',
        headerName: 'Notification Type',
        sortable: true,
        flex: 1,
        align: 'center',
        minWidth: 110,
        headerAlign: 'center',
        renderCell: ({ value }) => (
          <Chip label={titleCase(value)} color={value === NotificationTypeEnum.FOLLOWUP ? 'success' : 'info'} size="small" />
        )
      },
      {
        field: 'isRead',
        headerName: 'Is Read',
        sortable: false,
        width: 30,
        align: 'center',
        headerAlign: 'left',
        renderCell: ({ row: { isRead } }) =>
          !isRead ? <RadioButtonUncheckedIcon fontSize="small" /> : <RadioButtonCheckedIcon color="success" fontSize="small" />
      },
      {
        field: 'updatedAt',
        headerName: 'Created At',
        sortable: true,
        flex: 1,
        minWidth: 170,
        align: 'left',
        headerAlign: 'left',
        valueGetter: params => formatDateAndTime(params.value)
      }
    ],
    []
  );

  useEffect(() => {
    setContent(<MainSideMenu />);
  }, [setContent, setDrawerOpen]);

  return (
    <>
      <Stack spacing={2}>
        <Paper sx={{ padding: 2 }} elevation={0}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <H3>All Notifications</H3>
            <Box width={150}>
              <SelectList
                id="notification-type"
                label="Notification Type"
                options={notificationStatues.map(x => ({ label: x, value: x }))}
                placeholder="Noted During"
                value={notificationReadStatus}
                required
                onChange={handleNotificationType}
              />
            </Box>
          </Stack>
        </Paper>
        <Paper sx={{ padding: 2 }} elevation={0}>
          <TruentityDataGrid
            name="dg-all-notification"
            style={{ border: 'none', outline: 'none', backgroundColor: 'white' }}
            autoHeight
            disableColumnMenu={false}
            rows={adminNotifications?.relyingPartyAdminNotifications?.notifications || []}
            columns={columns}
            paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
            onPaginationModelChange={({ page }) => setCurrentPage(page)}
            onRowClick={handleRowClick}
            loading={isAdminNotificationLoading}
            rowCount={adminNotifications?.relyingPartyAdminNotifications.meta?.totalCount || 0}
            isRowSelectable={({ row }: GridRowParams) => row.isRead}
            paginationMode="server"
            hideFooterSelectedRowCount
            keepNonExistentRowsSelected
          />
        </Paper>
      </Stack>
    </>
  );
};

export default AllNotification;
