import Button from '@/components/Button';
import AddActivityDialog from '@/components/Dialogs/AddActivityDialog';
import ReportSignOffDialog from '@/components/Dialogs/ReportSignOffDialog';
import SelectList from '@/components/SelectList';
import { Small } from '@/components/Typography';
import type { GetRpmReportsMonthlyResponse, MonthlyResponse } from '@/graphql/rpmReports';
import useToken from '@/hooks/useToken';
import { color } from '@/styles/assets/colors';
import { Role } from '@/types/admin';
import { formatDateAndTime } from '@/util/format';
import { useAccountStore } from '@/zustand/AccountStore';
import { CloseFullscreen, OpenInFull } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import { CardHeader, FormControl, IconButton, Stack } from '@mui/material';
import moment from 'moment';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import type { Dispatch, FC, SetStateAction } from 'react';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

type Props = {
  reportMonth: MonthlyResponse | null;
  isWindowExpanded: boolean;
  rpmMonthlyReportsData: GetRpmReportsMonthlyResponse | undefined;
  isSignOffEnable: boolean;
  setReportMonth: Dispatch<SetStateAction<MonthlyResponse | null>>;
  setIsTemplateDrawerOpen: Dispatch<SetStateAction<boolean>>;
  setIsWindowExpanded: Dispatch<SetStateAction<boolean>>;
  onSignOffCompleted: () => void;
};

const RpmPatientReviewWorkflowWindowHeader: FC<Props> = ({
  reportMonth,
  isWindowExpanded,
  rpmMonthlyReportsData,
  isSignOffEnable,
  setReportMonth,
  setIsTemplateDrawerOpen,
  setIsWindowExpanded,
  onSignOffCompleted
}) => {
  const { id } = useParams();
  const { roleType } = useToken();
  const { showModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { account } = useAccountStore();
  const { setShowPatientReviewWindow, setPatientReviewWorkflowMonthYear, setIsReviewWorkflowChanged } = useAccountStore();

  const accountReportMonths = useMemo(
    () => rpmMonthlyReportsData?.getRpmReportsMonthly || [],
    [rpmMonthlyReportsData?.getRpmReportsMonthly]
  );

  const handleClose = () => {
    setShowPatientReviewWindow(false);
  };

  const handleExpand = () => {
    if (isWindowExpanded) {
      setIsTemplateDrawerOpen(false);
    }
    setIsWindowExpanded(!isWindowExpanded);
  };

  const handleMonthlyReportSelect = (value: string) => {
    const selectedReportMonth = accountReportMonths?.find(rpmMonthlyReport => {
      return rpmMonthlyReport.monthYearName.toLowerCase() === value;
    });
    if (selectedReportMonth) {
      setReportMonth(selectedReportMonth);
      setPatientReviewWorkflowMonthYear(selectedReportMonth.monthYearName);
    }
  };

  const checkReportSignOffDisabled = useCallback(() => {
    return Boolean(
      roleType !== Role.PROVIDER ||
        !reportMonth?.report?.rpmAccountReviewWorkflow ||
        reportMonth?.report?.rpmAccountReviewWorkflow?.signOffAt
    );
  }, [reportMonth?.report?.rpmAccountReviewWorkflow, roleType]);

  const showReportSignOffDialog = () => {
    if (reportMonth?.report?.rpmAccountReviewWorkflow?.id) {
      const modal = showModal(ReportSignOffDialog, {
        title: 'Chart Review Sign Off',
        hideDialog: () => {
          modal.hide();
        },
        accountReviewWorkflowId: reportMonth.report.rpmAccountReviewWorkflow.id,
        onSignOffComplete: (success: boolean) => {
          if (success) {
            onSignOffCompleted();
            handleCareActivityModal();
            setIsReviewWorkflowChanged(true);
          }
        }
      });
    } else {
      enqueueSnackbar('No RPM Patient Review Workflow found for this Month', {
        variant: 'error'
      });
    }
  };

  const handleCareActivityModal = () => {
    const modal = showModal(AddActivityDialog, {
      title: 'RPM Care Management Activities',
      hideDialog: () => modal.hide(),
      onActivitiesChanged: () => modal.hide(),
      id: id,
      rpmEnrolledAtDate: moment(account?.rpmEnrolledAt, 'YYYY-MM-DD HH:mm:ss'),
      category: 'Monthly Report Chart Review',
      defaultTimeTakenInSeconds: 300,
      reviewWorkflowId: reportMonth?.report?.rpmAccountReviewWorkflow?.id
    });
  };

  return (
    <CardHeader
      title="Chart Review"
      subheader={
        reportMonth?.report?.rpmAccountReviewWorkflow?.signOffAt && (
          <Stack display="flex" flexDirection="row" justifyContent="flex-start" alignItems="flex-end">
            <CheckCircleIcon fontSize="small" color="success" sx={{ marginRight: 1 }} />
            <Small marginTop={1} color={color.primaryDark} sx={{ display: 'inline' }}>
              Signed off on {formatDateAndTime(reportMonth.report.rpmAccountReviewWorkflow.signOffAt)}
            </Small>
          </Stack>
        )
      }
      id="draggable-dialog-title"
      action={
        <Stack flexDirection="row" justifyContent="center" alignItems="center" gap={0.5}>
          <FormControl color="primary" variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <SelectList
              fullWidth={false}
              size="small"
              value={reportMonth?.monthYearName || ''}
              options={accountReportMonths.map(({ monthYearName }) => ({ label: monthYearName, value: monthYearName }))}
              onChange={event => handleMonthlyReportSelect(event.target.value as string)}
            />
          </FormControl>
          <Button
            disabled={!isSignOffEnable || checkReportSignOffDisabled()}
            color="secondary"
            size="small"
            onClick={showReportSignOffDialog}
          >
            Sign Off
          </Button>
          <IconButton size="small" onClick={handleExpand}>
            {isWindowExpanded ? <CloseFullscreen fontSize="small" /> : <OpenInFull fontSize="small" />}
          </IconButton>
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Stack>
      }
      sx={{
        px: 2,
        py: 1,
        cursor: isWindowExpanded ? 'auto' : 'move',
        borderBottom: 1,
        bgcolor: color.primaryLight,
        borderColor: theme => theme.palette.grey[300]
      }}
    />
  );
};

export default RpmPatientReviewWorkflowWindowHeader;
