import Button from '@/components/Button';
import PatientSignOffDialog from '@/components/Dialogs/PatientSignOffDialog';
import { H3, H5, Small } from '@/components/Typography';
import PatientDetailContext from '@/context/patientDetailContext';
import type { UpdateRpmSetupResponse } from '@/graphql/remotePatientMonitoring';
import { UPDATE_RPM_SETUP } from '@/graphql/remotePatientMonitoring';
import type { GetRpmAccountSummaryResponse } from '@/graphql/rpmWorkflow';
import { GET_RPM_ACCOUNT_SUMMARY, REGENERATE_ACCOUNT_CARE_PLAN_REPORT } from '@/graphql/rpmWorkflow';
import { useDocumentPreview } from '@/hooks/useDocumentPreview';
import ClinicalDiagnosesDataGrid from '@/routes/PatientDetails/RemotePatientMonitoring/Components/ClinicalDiagnoses/ClinicalDiagnosesDataGrid';
import SmartGoalsDataGrid from '@/routes/PatientDetails/RemotePatientMonitoring/Components/SmartGoals/SmartGoalsDataGrid';
import OrdersDataGrid from '@/routes/PatientDetails/RemotePatientMonitoring/Orders/OrdersDataGrid';
import { color } from '@/styles/assets/colors';
import { theme } from '@/styles/mui-theme';
import { RpmApprovalStatusTypes, RpmWorkflowTab } from '@/types/remotePatientMonitoring';
import { formatDateAndTime } from '@/util/format';
import { updateRpmTabStatus } from '@/util/rpm';
import { useRpmSetupStore } from '@/zustand/RpmSetupStore';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Stack } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import BaseRpmWorkflowTabContent from './BaseRpmWorkflowTabContent';

const CarePlan = () => {
  const { showModal } = useModal();
  const { rpmSetupTabs, activeRpmSetupTab, editRpmStatus } = useRpmSetupStore();
  const { patientInfo, setReloadPatientInfo } = useContext(PatientDetailContext);
  const { enqueueSnackbar } = useSnackbar();
  const { fetchDocumentPreview } = useDocumentPreview({ documentTitle: 'Care Plan' });
  const { id: truentityId } = useParams();

  const [hasGoals, setHasGoals] = useState(false);
  const [hasOrders, setHasOrders] = useState(false);
  const [hasClinicalDiagnoses, setHasClinicalDiagnoses] = useState(false);

  const isReadOnly = useMemo(() => activeRpmSetupTab?.isReadOnly || false, [activeRpmSetupTab]);

  const {
    data: rpmAccountSummaryData,
    error: getRpmAccountSummaryError,
    loading: loadingRpmAccountSummary,
    refetch: reFetchRpmAccountSummary
  } = useQuery<GetRpmAccountSummaryResponse>(GET_RPM_ACCOUNT_SUMMARY, {
    variables: {
      truentityId
    },
    skip: !truentityId,
    fetchPolicy: 'cache-and-network'
  });

  const [regenerateCarePlanReport, { loading: loadingRegenerateCarePlanReport }] = useMutation(REGENERATE_ACCOUNT_CARE_PLAN_REPORT, {
    variables: {
      truentityId
    },
    onCompleted: () => {
      reFetchRpmAccountSummary();
    }
  });

  const [updateRpmSetup] = useMutation<UpdateRpmSetupResponse>(UPDATE_RPM_SETUP);

  const hasRpmCarePlanDocument = useMemo(
    () => (rpmAccountSummaryData?.getRpmAccountSummary?.carePlanS3Key?.length ?? 0) > 0,
    [rpmAccountSummaryData]
  );

  const signOffAt = useCallback(() => rpmAccountSummaryData?.getRpmAccountSummary?.signOffAt, [rpmAccountSummaryData]);

  const checkPatientSignOffDisabled = useCallback(() => {
    return !hasClinicalDiagnoses || patientInfo?.rpmApprovalStatus === RpmApprovalStatusTypes.APPROVED || isReadOnly || !hasOrders;
  }, [isReadOnly, patientInfo, hasOrders, hasClinicalDiagnoses]);

  const showPatientSignOffDialog = () => {
    const modal = showModal(PatientSignOffDialog, {
      title: 'Visit Sign Off',
      hideDialog: () => {
        modal.hide();
        reFetchRpmAccountSummary();
      },
      patientInfo: patientInfo,
      onSignOffComplete: (success: boolean) => {
        if (success) {
          setReloadPatientInfo(true);
          updateRpmTabStatus(rpmSetupTabs, RpmWorkflowTab.CARE_PLAN, editRpmStatus, updateRpmSetup, true).catch(err => console.error(err));
          regenerateCarePlanReport();
        }
      }
    });
  };

  useEffect(() => {
    if (getRpmAccountSummaryError) {
      enqueueSnackbar('Failed to retrieve Care Plan document ', { variant: 'error' });
    }
  }, [enqueueSnackbar, getRpmAccountSummaryError]);

  return (
    <BaseRpmWorkflowTabContent isReadOnly={isReadOnly}>
      <Stack sx={{ background: theme.palette.background.default, padding: theme.spacing(2) }}>
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
            <H3>Assessment and Plan</H3>
            <Stack alignItems="flex-end">
              <Button
                disabled={checkPatientSignOffDisabled()}
                sx={{ backgroundColor: color.secondaryMain }}
                size="small"
                onClick={showPatientSignOffDialog}
              >
                Sign off
              </Button>
              {checkPatientSignOffDisabled() && signOffAt() && (
                <Small marginTop={1} color={color.primaryDark} sx={{ display: 'inline' }}>
                  Signed off on {formatDateAndTime(signOffAt())}
                </Small>
              )}
            </Stack>
          </Stack>
        </Stack>
        <Box mt={1}>
          <Stack direction="row" justifyContent="space-between">
            <Stack
              flexDirection="row"
              alignItems="center"
              sx={{
                width: '100%',
                height: 'auto',
                my: 2,
                p: 1,
                border: '1px solid',
                borderColor: theme => theme.palette.divider,
                backgroundColor: color.grey50,
                borderRadius: 1
              }}
            >
              <Box flex={'1'} display={'flex'} flexDirection="row" justifyContent="flex-start" alignItems="center">
                {hasRpmCarePlanDocument ? (
                  <>
                    <Box
                      sx={{ width: '66px' }}
                      component={'img'}
                      src={'https://truentity-general.s3.amazonaws.com/Indycare-CarePlan-Thumbnail.png'}
                    />
                    <H5
                      sx={{
                        fontSize: '16px',
                        fontWeight: 400,
                        color: color.black100,
                        marginLeft: 2
                      }}
                    >
                      Preview Care Plan Document
                    </H5>
                  </>
                ) : (
                  <H5
                    sx={{
                      fontSize: '16px',
                      fontWeight: 400,
                      color: color.black100,
                      marginLeft: 2
                    }}
                  >
                    Care Plan Document
                  </H5>
                )}
              </Box>
              <Stack flexDirection="row" justifyContent="flex-end" columnGap={2}>
                <Button
                  variant="outlined"
                  label={hasRpmCarePlanDocument ? 'Regenerate' : 'Generate'}
                  disabled={loadingRpmAccountSummary || isReadOnly}
                  isLoading={loadingRegenerateCarePlanReport}
                  onClick={() => regenerateCarePlanReport()}
                />
                {hasRpmCarePlanDocument && (
                  <Button
                    sx={{ width: '86px' }}
                    label="View"
                    disabled={loadingRegenerateCarePlanReport || loadingRpmAccountSummary}
                    onClick={() => {
                      fetchDocumentPreview({
                        s3Key: rpmAccountSummaryData?.getRpmAccountSummary?.carePlanS3Key || ''
                      });
                    }}
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <ClinicalDiagnosesDataGrid setHasClinicalDiagnoses={setHasClinicalDiagnoses} />
        <SmartGoalsDataGrid
          isReadOnly={isReadOnly}
          setHasGoals={setHasGoals}
          onSmartGoalsUpdate={regenerateCarePlanReport}
          hasClinicalDiagnoses={hasClinicalDiagnoses}
          isAccountApproved={patientInfo?.rpmApprovalStatus === RpmApprovalStatusTypes.APPROVED}
        />
        <OrdersDataGrid
          isReadOnly={isReadOnly}
          setHasOrders={setHasOrders}
          onAccountOrdersUpdate={regenerateCarePlanReport}
          hasGoals={hasGoals}
          hasClinicalDiagnoses={hasClinicalDiagnoses}
          isAccountApproved={patientInfo?.rpmApprovalStatus === RpmApprovalStatusTypes.APPROVED}
        />
      </Stack>
    </BaseRpmWorkflowTabContent>
  );
};

export default CarePlan;
