import type { RpmReportsType } from '@/types/remotePatientMonitoring';
import { gql } from '@apollo/client';

export type CreatePdfRpmReportResponse = {
  createPdfRpmReport: {
    rpmReport?: RpmReportsType;
    errors: string[];
  };
};

export const CREATE_PDF_RPM_REPORT = gql`
  mutation createPdfRpmReport($truentityId: String!) {
    createPdfRpmReport(truentityId: $truentityId) {
      rpmReport {
        id
        reportedDocS3Key
        rpmAccountReviewWorkflow {
          id
        }
      }
      errors
    }
  }
`;

export type CreateRpmCoverLetterResponse = {
  createPdfRpmCoverLetter: {
    pdfDownloadUrl: string;
    errors: string[];
  };
};

export const CREATE_PDF_RPM_COVER_LETTER = gql`
  mutation createPdfRpmCoverLetter($truentityId: String!) {
    createPdfRpmCoverLetter(truentityId: $truentityId) {
      pdfDownloadUrl
      errors
    }
  }
`;

export type RegenerateRpmReportResponse = {
  regenerateRpmReports: {
    status: string;
    message: string;
  };
};

export const REGENERATE_RPM_REPORT = gql`
  mutation RegenerateRpmReports($reportIds: [ID!]!) {
    regenerateRpmReports(reportIds: $reportIds) {
      status
      message
    }
  }
`;

export type ReviewRpmReportResponse = {
  reviewRpmReport: {
    status: string;
    message: string;
  };
};
export const REVIEW_RPM_REPORT = gql`
  mutation ReviewRpmReport($rpmReportId: String!, $truentityId: ID, $reviewSubmitType: ReviewSubmitTypeEnum!) {
    reviewRpmReport(rpmReportId: $rpmReportId, truentityId: $truentityId, reviewSubmitType: $reviewSubmitType) {
      status
      message
    }
  }
`;

export type MonthlyResponse = {
  monthYearName: string;
  report: RpmReportsType;
};
export type GetRpmReportsMonthlyResponse = {
  getRpmReportsMonthly: MonthlyResponse[];
};

export const GET_RPM_REPORTS_MONTHLY = gql`
  query GetRpmReportsMonthlyQuery($truentityId: ID!, $includeCurrentMonth: Boolean) {
    getRpmReportsMonthly(truentityId: $truentityId, includeCurrentMonth: $includeCurrentMonth) {
      monthYearName
      report {
        id
        status
        reportedDocS3Key
        rpmAccountReviewWorkflow {
          id
          signOffAt
          provider {
            nameWithTitle
          }
          reportS3Key
        }
        summaryReportS3Key
        monthYear
      }
    }
  }
`;

export type GetReportAvailabilitySenderMethodAvailability = {
  getRpmReportMethodAvailability: {
    jsonMessage: string;
  };
};

export const GET_RPM_REPORT_SENDER_METHOD_AVAILABILITY = gql`
  query GetRpmReportMethodAvailabilityQuery($truentityId: ID!) {
    getRpmReportMethodAvailability(truentityId: $truentityId) {
      jsonMessage
    }
  }
`;

export type CreatePdfRpmChartReviewReportResponse = {
  createPdfRpmChartReviewReport: {
    pdfDownloadUrl: string;
    status: string;
  };
};

export const CREATE_PDF_RPM_CHART_REVIEW_REPORT = gql`
  mutation createPdfRpmChartReviewReport($rpmAccountReviewWorkflowId: ID!) {
    createPdfRpmChartReviewReport(rpmAccountReviewWorkflowId: $rpmAccountReviewWorkflowId) {
      pdfDownloadUrl
      status
    }
  }
`;

export type CreatePdfRpmSummaryReport = {
  createPdfRpmSummaryReport: {
    pdfDownloadUrl: string;
    status: string;
  };
};

export const CREATE_PDF_RPM_SUMMARY_REPORT = gql`
  mutation CreatePdfRpmSummaryReport($truentityId: String!, $monthYear: String!) {
    createPdfRpmSummaryReport(truentityId: $truentityId, monthYear: $monthYear) {
      pdfDownloadUrl
      status
    }
  }
`;
