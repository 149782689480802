import type { RpmStatusTypeTab } from '@/types/remotePatientMonitoring';
import { RpmStatusTypes } from '@/types/remotePatientMonitoring';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import PersonIcon from '@mui/icons-material/Person';
import type { GridSortItem } from '@mui/x-data-grid-pro';

export const rpmPatientStatusTabBase: RpmStatusTypeTab[] = [
  {
    status: RpmStatusTypes.IS_CANDIDATE,
    label: 'Candidates',
    path: 'candidate',
    icon: <PersonIcon />,
    includeMetrics: false
  },
  {
    status: RpmStatusTypes.IS_CANDIDATE,
    label: 'Priority Candidates',
    path: 'priorityCandidate',
    icon: <KeyboardDoubleArrowUpIcon />,
    includeMetrics: true
  },
  {
    status: RpmStatusTypes.SCHEDULED_FOR_ENROLLMENT,
    label: 'Scheduled for Enrollment',
    path: 'scheduledForEnrollment',
    icon: <PendingActionsIcon />,
    includeMetrics: false
  },
  {
    status: RpmStatusTypes.ENROLLED,
    label: 'Enrolled',
    path: 'enrolled',
    icon: <AssignmentTurnedInIcon />,
    includeMetrics: false
  }
];

export const columnVisibility = {
  candidate: {
    devices: false,
    hardwareId: false,
    lastReading: false,
    lastReadingAt: false,
    lastReadingValue: false,
    dateOfLastReview: false,
    dateEnrolled: false,
    dateUnenrolled: false,
    lastContacted: false,
    reportActions: false,
    scheduledForEnrollmentAction: true,
    cancelScheduledForEnrollmentAction: false,
    onboardedOn: true,
    initialVisitOn: false,
    monthlyTotalReadings: false,
    monthlyTimeSpent: false,
    rpmApprovalStatus: false,
    dateOfBirth: true,
    phone: true,
    zipcode: true,
    meds: true,
    rpmPriorityScore: false,
    medicationData: false,
    claimsData: false
  },
  priorityCandidate: {
    devices: false,
    hardwareId: false,
    lastReading: false,
    lastReadingAt: false,
    lastReadingValue: false,
    dateOfLastReview: false,
    dateEnrolled: false,
    dateUnenrolled: false,
    lastContacted: false,
    reportActions: false,
    scheduledForEnrollmentAction: true,
    cancelScheduledForEnrollmentAction: false,
    onboardedOn: true,
    initialVisitOn: false,
    monthlyTotalReadings: false,
    monthlyTimeSpent: false,
    rpmApprovalStatus: false,
    dateOfBirth: true,
    phone: true,
    zipcode: false,
    meds: false,
    rpmPriorityScore: true,
    medicationData: true,
    claimsData: true
  },
  scheduledForEnrollment: {
    devices: false,
    hardwareId: false,
    lastReading: false,
    lastReadingAt: false,
    lastReadingValue: false,
    dateOfLastReview: false,
    dateEnrolled: false,
    dateUnenrolled: false,
    lastContacted: false,
    reportActions: false,
    scheduledForEnrollmentAction: false,
    cancelScheduledForEnrollmentAction: true,
    onboardedOn: false,
    initialVisitOn: true,
    monthlyTotalReadings: false,
    monthlyTimeSpent: false,
    rpmApprovalStatus: false,
    dateOfBirth: true,
    phone: true,
    zipcode: true,
    meds: true,
    rpmPriorityScore: false,
    medicationData: false,
    claimsData: false
  },
  enrolled: {
    devices: true,
    hardwareId: true,
    lastReading: true,
    lastReadingAt: true,
    lastReadingValue: true,
    dateOfLastReview: true,
    lastContacted: true,
    dateEnrolled: true,
    dateUnenrolled: false,
    rpmApprovalStatus: true,
    reportActions: true,
    scheduledForEnrollmentAction: false,
    cancelScheduledForEnrollmentAction: false,
    onboardedOn: true,
    initialVisitOn: false,
    lastContactedAt: true,
    monthlyTotalReadings: true,
    monthlyTimeSpent: true,
    dateOfBirth: false,
    phone: false,
    zipcode: false,
    meds: false,
    rpmPriorityScore: false,
    medicationData: false,
    claimsData: false
  },
  unenrolled: {
    devices: false,
    hardwareId: false,
    lastReading: false,
    lastReadingAt: false,
    lastReadingValue: false,
    dateOfLastReview: false,
    dateEnrolled: true,
    dateUnenrolled: true,
    lastContacted: false,
    reportActions: false,
    scheduledForEnrollmentAction: false,
    cancelScheduledForEnrollmentAction: false,
    onboardedOn: true,
    initialVisitOn: false,
    monthlyTotalReadings: false,
    monthlyTimeSpent: false,
    rpmApprovalStatus: false,
    dateOfBirth: true,
    phone: true,
    zipcode: true,
    meds: true,
    rpmPriorityScore: false,
    medicationData: false,
    claimsData: false
  }
};

export type SortModalDefaultsKeyTypes =
  | RpmStatusTypes.ENROLLED
  | RpmStatusTypes.UNENROLLED
  | RpmStatusTypes.SCHEDULED_FOR_ENROLLMENT
  | RpmStatusTypes.IS_CANDIDATE;

export type DefaultSortModelsTypes = {
  [key in SortModalDefaultsKeyTypes]: GridSortItem;
};

export const getDefaultSortModels = (includeMetrics = false): DefaultSortModelsTypes => ({
  [RpmStatusTypes.ENROLLED]: {
    field: 'dateEnrolled',
    sort: 'desc'
  },
  [RpmStatusTypes.UNENROLLED]: {
    field: 'dateUnenrolled',
    sort: 'desc'
  },
  [RpmStatusTypes.SCHEDULED_FOR_ENROLLMENT]: {
    field: 'initialVisitOn',
    sort: 'desc'
  },
  [RpmStatusTypes.IS_CANDIDATE]: includeMetrics ? { field: 'rpmPriorityScore', sort: 'desc' } : { field: 'onboardedOn', sort: 'desc' }
});
